import Modal from "react-modal";
import React, { FunctionComponent, useState } from "react";
import styles from "./AddModal.module.css";
import { IoIosClose } from "react-icons/io";

Modal.setAppElement("#root");

type props = {
  show: boolean;
  heading: string;
  subHeading: string;
  close: () => void;
  onSave: (v: any) => void;
};

const AddModal: FunctionComponent<props> = ({
  show,
  heading,
  subHeading,
  close,
  onSave,
}) => {
  const [value, setValue] = useState("");
  return (
    <Modal
      isOpen={show}
      className={styles.parent}
      overlayClassName={styles.overlay}
      onRequestClose={close}
      shouldCloseOnOverlayClick={true}
      shouldCloseOnEsc={true}
    >
      <div className={styles.icon} onClick={close}>
        <IoIosClose size={35} color={"#808080"} />
      </div>
      <span className={styles.heading}>{heading}</span>
      <div className={styles.nameBox}>
        <span className={styles.name}>{`${subHeading} : `}</span>
        <input
          className={styles.input}
          placeholder={`${subHeading}`}
          onChange={(event) => setValue(event.target.value)}
        />
      </div>
      <button className={styles.button} onClick={() => onSave(value)}>
        Save
      </button>
    </Modal>
  );
};

export default AddModal;

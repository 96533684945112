import React from "react";
import { Route } from "react-router-dom";
import { connect } from "react-redux";
import SuperAdminRoute from "./SuperAdminRoute";
import OrganizationRoute from "./OrganizationRoute";
import UserRoute from "./UserRoute";
import LeadManagerRoute from "./LeadManagerRoute";
import DrilldownDataRoute from "./DrilldownDataRoute";

const HomeRoute = (props: any) => {
  console.log(props);
  return (
    <>
      {String(props.history.location.pathname).startsWith("/resetpassword") !==
        true &&
        props.userStatus && (
          <>
            {props.userStatus === true && (
              <>
                {String(props.history.location.pathname).startsWith(
                  "/drilldownData"
                ) !== true &&
                  String(props.history.location.pathname).startsWith(
                    "/taskDrilldownData"
                  ) !== true &&
                  String(props.history.location.pathname).startsWith(
                    "/callDrilldownData"
                  ) !== true && (
                    <>
                      {props.userRole === "superAdmin" && (
                        <Route component={SuperAdminRoute} />
                      )}

                      {props.userRole === "organization" && (
                        <Route component={OrganizationRoute} />
                      )}

                      {props.userRole !== "organization" &&
                        props.userRole !== "superAdmin" &&
                        props.userRole !== "Lead Manager" && (
                          <Route component={UserRoute} />
                        )}

                      {props.userRole === "Lead Manager" && (
                        <Route component={LeadManagerRoute} />
                      )}
                    </>
                  )}

                {String(props.history.location.pathname).startsWith(
                  "/drilldownData"
                ) === true && (
                  <Route path="/drilldownData" component={DrilldownDataRoute} />
                )}
                {String(props.history.location.pathname).startsWith(
                  "/taskDrilldownData"
                ) === true && (
                  <Route
                    path="/taskDrilldownData"
                    component={DrilldownDataRoute}
                  />
                )}
                {String(props.history.location.pathname).startsWith(
                  "/callDrilldownData"
                ) === true && (
                  <Route
                    path="/callDrilldownData"
                    component={DrilldownDataRoute}
                  />
                )}
              </>
            )}
          </>
        )}
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    userStatus: state.user.status,
    userRole: state.user.role,
  };
};

export default connect(mapStateToProps)(HomeRoute);

import React, { useMemo, useState, useEffect } from "react";
import styles from "./ProjectsPanel.module.css";
import commonStyle from "../common.module.css";
import Topbar from "../../Components/TopBar/TopBar";
import { PROJECT_COLUMNS } from "../../Values/tables";
import { useTable, useSortBy, useFilters, useColumnOrder } from "react-table";
import TableHeader from "../../Components/TableHeader/TableHeader";
import { deleteProject, fetchProjects } from "../../Services/contacts";
import ImportContactsModal from "../../Components/Modals/ImportModal/ImportContactsModal";
import { connect, useDispatch } from "react-redux";
import {
  getFilterObject,
  filterProjectStatus,
  searchProjectItem,
} from "../../Values/utils";
import Loading from "../../Components/Loading/Loading";
import ColumnManagerModal from "../../Components/Modals/ColumnManagerModal/ColumnManager";

import { MdDelete } from "react-icons/md";
import { FiEdit3 } from "react-icons/fi";

const Project = ({ history, user, organizationUsers }: any) => {
  const dispatcher = useDispatch();
  const [projectsList, setProjectsList] = useState<any[] | undefined>(
    undefined
  );
  const [filterData, setFilterData] = useState<any[] | undefined>(undefined);
  const [temporaryData, setTemporaryData] = useState<any[]>([]);
  const [searchQuery, setsearchQuery] = useState("");
  const [status, setStatus] = useState("ALL");
  const [columnModal, setColumnModal] = useState(false);
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const [showImportModal, setShowImportModal] = useState(false);
  const [load, setLoad] = useState(false);

  useEffect(() => {
    if (user.organization_id) {
      const unsub = fetchProjects(
        (data) => setProjectsList(data),
        user.organization_id
      );
      return () => {
        unsub();
      };
    }
  }, [user]);

  useEffect(() => {
    if (projectsList !== undefined) {
      setFilterData(projectsList);
    }
  }, [projectsList, dispatcher]);
  useEffect(() => {
    if (status === "ALL") {
      if (projectsList) {
        const data = searchProjectItem(projectsList, searchQuery);
        setTemporaryData(data);
        setFilterData(data);
      }
    } else {
      const data = searchProjectItem(temporaryData, searchQuery);
      setFilterData(data);
    }
    // eslint-disable-next-line
  }, [searchQuery, projectsList]);

  useEffect(() => {
    if (searchQuery === "") {
      if (projectsList) {
        const data = filterProjectStatus(projectsList, status);
        setTemporaryData(data);
        setFilterData(data);
      }
    } else {
      const data = filterProjectStatus(temporaryData, status);
      setFilterData(data);
    }
    // eslint-disable-next-line
  }, [status, projectsList]);

  const selectAll = (checked: boolean) => {
    if (checked === true) {
      setSelectedRows(Array.from(Array(filterData?.length).keys()));
    } else {
      setSelectedRows([]);
    }
  };

  const columns = useMemo(() => PROJECT_COLUMNS, []);
  const data = useMemo(() => (filterData ? filterData : []), [filterData]);

  const tableInstance: any = useTable(
    {
      columns,
      data,
    },
    useFilters,
    useSortBy,
    useColumnOrder
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    allColumns,
    setColumnOrder,
    toggleHideAllColumns,

    //@ts-ignore
    setAllFilters,
  } = tableInstance;

  const setColumns = (columnList: any) => {
    setColumnOrder(columnList);
  };

  useEffect(() => {
    if (filterData) {
      getFilterObject(filterData, dispatcher);
    }
  }, [filterData, dispatcher]);

  const clearSort = () => {
    headerGroups[0].headers.forEach((header: any) => {
      header.clearSortBy();
    });
  };

  const deleteProjectFirebase = (project: string, index: number) => {
    if (projectsList) {
      setLoad(true);

      deleteProject(
        project,
        user.organization_id,
        projectsList,
        index,
        dispatcher,
        (data) => setLoad(data)
      );
    }
  };
  return (
    <>
      {load === true && <Loading />}
      <div className={commonStyle.topBar}>
        <Topbar
          history={history}
          title={"Add Project"}
          path={"/addProjects"}
          filterText="Property Type"
          onChange={(val) => setsearchQuery(val)}
          projectFilter={true}
          setStatus={(status) => setStatus(status)}
          status={status}
          setColumnModal={(data) => setColumnModal(data)}
          show={false}
          showStatusBox={true}
          onExport={() => {}}
          onClick={() => {
            setShowImportModal(true);
          }}
        />
      </div>
      <div className={commonStyle.parent}>
        {projectsList === undefined && <Loading logo />}
        <div className={commonStyle.child}>
          <div style={{ width: "100%" }}>
            <div className={commonStyle.table} {...getTableProps()}>
              <TableHeader
                headerGroups={headerGroups}
                selectAll={(data) => selectAll(data)}
                panel={"Project"}
              />

              {projectsList && (
                <div {...getTableBodyProps()}>
                  {rows.map((row: any, index: number) => {
                    prepareRow(row);
                    return (
                      <div
                        {...row.getRowProps()}
                        style={{ display: "flex", flexDirection: "row" }}
                      >
                        <div className={styles.sticky}>
                          <input
                            type="checkbox"
                            onChange={(e) => {
                              if (e.target.checked) {
                                let data = [...selectedRows];
                                data.push(index);
                                setSelectedRows(data);
                              } else if (!e.target.checked) {
                                let data = [...selectedRows];
                                let item = selectedRows.indexOf(index);
                                if (item > -1) {
                                  data.splice(item, 1);
                                }
                                setSelectedRows(data);
                              }
                            }}
                            checked={selectedRows.includes(index)}
                          />

                          <FiEdit3
                            size={15}
                            color="#279EA0"
                            style={{ marginRight: "10px" }}
                            onClick={() => {
                              history.push({
                                pathname: "/editProjects",
                                state: {
                                  detail: row.original,
                                  projectList: projectsList,
                                },
                              });
                            }}
                          />

                          {index + 1}
                        </div>

                        {row.cells.map((cell: any) => {
                          return (
                            <div
                              className={styles.td}
                              {...cell.getCellProps()}
                              onClick={() =>
                                history.push({
                                  pathname: "/projectDetails",
                                  state: {
                                    detail: {
                                      ...cell.row.original,
                                      created_at: cell.row.original[
                                        "created_at"
                                      ]
                                        ? cell.row.original[
                                            "created_at"
                                          ].toDate()
                                          ? cell.row.original[
                                              "created_at"
                                            ].toDate()
                                          : ""
                                        : "",
                                    },
                                    projectList: projectsList,
                                  },
                                })
                              }
                            >
                              {cell.render("Cell")}
                            </div>
                          );
                        })}
                        <div
                          className={styles.td}
                          style={{ minWidth: "150px" }}
                        >
                          <MdDelete
                            color={"#ff0000"}
                            size={20}
                            onClick={() =>
                              deleteProjectFirebase(
                                row.original.project_name,
                                index
                              )
                            }
                          />
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          </div>
        </div>

        <div className={commonStyle.count}>
          <button
            className={commonStyle.clearFilter}
            onClick={() => {
              setAllFilters([]);
              setsearchQuery && setsearchQuery("");
              var key: any;
              for (var i = 0; i < localStorage.length; i++) {
                key = localStorage.key(i);

                if (key !== "columns") {
                  localStorage.removeItem(key);
                }
              }

              clearSort();
            }}
          >
            Clear Filter
          </button>
          <div className={commonStyle.countContainer}>
            <p className={commonStyle.countHead}>Total Records:</p>
            <p className={commonStyle.number}>{rows.length}</p>
          </div>
        </div>
        {columnModal === true && (
          <ColumnManagerModal
            columnsList={allColumns}
            open={columnModal}
            close={() => setColumnModal(false)}
            onChangeColumns={(data: any[]) => setColumns(data)}
            toggleAllColumns={toggleHideAllColumns}
          />
        )}

        {showImportModal && (
          <ImportContactsModal
            open={showImportModal}
            close={() => setShowImportModal(false)}
            organization_id={user.organization_id}
            history={history}
            usersList={organizationUsers}
            user={user}
          />
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    user: state.user.data,
    organizationUsers: state.organizationUsers.data,
  };
};

export default connect(mapStateToProps)(Project);

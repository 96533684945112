import React from "react";
import Navbar from "../Components/Navbar/Navbar";
import Analytics from "../Screens/Analytics/Analytics";
import SuperAdminPanel from "../Screens/SuperAdminPanel/SuperAdminPanel";
import Reports from "../Screens/Reports/Reports";
import AddOrganization from "../Screens/AddOrganization/AddOrganization";
import { Route } from "react-router-dom";
import EditOrganization from "../Screens/EditOrganization/EditOrganization";

const SuperAdminRoute = (props: any) => {
  return (
    <>
      <Navbar
        props={props}
        title={"Organizations"}
        path={"/"}
        leadManger={false}
      />
      <Route exact path="/" component={SuperAdminPanel} />
      {/* <Route path="/analytics" component={Analytics} />
      <Route path="/reports" component={Reports} /> */}
      <Route path="/addOrganizations" component={AddOrganization} />
      <Route path="/editOrganizations" component={EditOrganization} />
    </>
  );
};

export default SuperAdminRoute;

import React, { useEffect, useState } from "react";
import commonStyle from "../common.module.css";
import TopBar from "../../Components/TopBar/TopBar";
import { fetchOrganizations } from "../../Services/organizations";
import Loading from "../../Components/Loading/Loading";
import { ORGANIZATION_COLUMNS } from "../../Values/tables";

import { useDispatch } from "react-redux";
import { filterStatus, searchOrganizationItem } from "../../Values/utils";

import CustomTable from "../../Components/CustomTable/CustomTable";

const Organizations = ({ history }: any) => {
  const [organizationData, setOrganizationData] =
    useState<any[] | undefined>(undefined);
  const [filterData, setFilterData] = useState<any[] | undefined>(undefined);

  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const [temporaryData, setTemporaryData] = useState<any[]>([]);
  const [searchQuery, setsearchQuery] = useState("");
  const [status, setStatus] = useState("ALL");
  const [columnModal, setColumnModal] = useState(false);
  const dispatcher = useDispatch();

  useEffect(() => {
    const unSub = fetchOrganizations((data) => setOrganizationData(data));
    return unSub;
  }, []);

  useEffect(() => {
    if (organizationData !== undefined) {
      setFilterData(organizationData);
    }
  }, [organizationData, dispatcher]);

  useEffect(() => {
    if (status === "ALL") {
      if (organizationData) {
        const data = searchOrganizationItem(organizationData, searchQuery);
        setTemporaryData(data);
        setFilterData(data);
      }
    } else {
      const data = searchOrganizationItem(temporaryData, searchQuery);
      setFilterData(data);
    }
    // eslint-disable-next-line
  }, [searchQuery, organizationData]);

  useEffect(() => {
    if (searchQuery === "") {
      if (organizationData) {
        const data = filterStatus(organizationData, status);
        setTemporaryData(data);
        setFilterData(data);
      }
    } else {
      const data = filterStatus(temporaryData, status);
      setFilterData(data);
    }
    // eslint-disable-next-line
  }, [status, organizationData]);

  return (
    <>
      <div className={commonStyle.topBar}>
        <TopBar
          history={history}
          title={"Add Organization"}
          path={"addOrganizations"}
          onChange={(val) => setsearchQuery(val)}
          setStatus={(status) => setStatus(status)}
          filterText="Status"
          status={status}
          setColumnModal={(data) => setColumnModal(data)}
          show={true}
          showStatusBox={true}
        />
      </div>
      <div className={commonStyle.parent}>
        {organizationData === undefined && <Loading logo />}

        <CustomTable
          tableColumns={ORGANIZATION_COLUMNS}
          tableRows={filterData}
          selectedRows={selectedRows}
          setSelectedRows={(data) => setSelectedRows(data)}
          tableType="Super admin"
          showColumnModal={columnModal}
          hideColumnModal={() => setColumnModal(false)}
          setSearchedItem={(val) => setsearchQuery(val)}
        />
      </div>
    </>
  );
};

export default Organizations;

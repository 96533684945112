import React, { FunctionComponent, useEffect, useState } from "react";
import ApiTopBar from "../../Components/TopBar/ApiTopBar";
import commonStyle from "../common.module.css";
import { connect, useDispatch } from "react-redux";
import OwnerModal from "../../Components/Modals/ChangeOwnerModal/ChangeOwnerModal";
import ImportContactsModal from "../../Components/Modals/ImportModal/ImportContactsModal";
import {
  CONTACT_COLUMNS,
  datesField,
  booleanObject,
} from "../../Values/tables";
import Firebase from "firebase/app";
import Loading from "../../Components/Loading/Loading";
import { getDateString, searchContacts } from "../../Values/utils";
import ApiCustomTable from "../../Components/CustomTable/ApiCustomTable";
import BulkEditModal from "../../Components/Modals/BulkEditModal/BulkEditModal";

import { useLocation } from "react-router";

import ImportCallLogsModal from "../../Components/Modals/ImportModal/ImportCallLogsModal";
import ImportNotesModal from "../../Components/Modals/ImportModal/ImportNotesModal";
import ImportTasksModal from "../../Components/Modals/ImportModal/ImportTasksModal";
import axios from "axios";

import moment from "moment";

import { url } from "../../Values/constants";
import {
  setFilterObject,
  setRefreshContacts,
  showSnackbarAction,
} from "../../Redux/actions";
import NewImportContactModal from "../../Components/Modals/ImportModal/NewImportContactModal";
import DeleteSelectedModal from "../../Components/Modals/DeleteSelectedModal/DeleteSelectedModal";
import { db, firestore } from "../../Firebase";

type props = {
  history: any;
  user: any;
  contacts: {
    data: any;
  };
  organizationUsers: any;
  role: any;
  teamLeadUsers: any;
  filterObject: any;
  filterSort: any;
  refreshContacts: boolean;
  searchString: string;
};
let usersList: any[] = [];
let isFinished = false;
const UserPanel: FunctionComponent<props> = ({
  history,
  user,
  contacts,
  organizationUsers,
  role,
  teamLeadUsers,
  filterObject,
  filterSort,
  refreshContacts,
  searchString,
}) => {
  const location: any = useLocation();
  const [showImportModal, setShowImportModal] = useState(false);
  const [callLogsImportModal, setCallLogsImportModal] = useState(false);
  const [notesImportModal, setNotesImportModal] = useState(false);
  const [tasksImportModal, setTasksImportModal] = useState(false);
  const [show, setShow] = useState(false);
  const [deletePopup, setDeletePopop] = useState(false);

  const [showBulkEdit, setShowBulkEdit] = useState(false);
  const [owner, setOwner] = useState(false);
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const [selectedRowsData, setSelectedRowsData] = useState<any[]>([]);
  const [transferStatusTrueRows, setTransferStatusTrueRows] = useState<any[]>(
    []
  );
  const [filterData, setFilterData] = useState<any[] | undefined>(undefined);
  const [searchedItem, setsearchedItem] = useState("");
  const [columnModal, setColumnModal] = useState(false);
  const [lastPage, setLastPage] = useState(-1);
  const [allContacts, setAllContacts] = useState<any[] | undefined>(undefined);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(100);
  const [load, setLoad] = useState(false);
  const [allLeads, setAllLeads] = useState(false);
  const [totalCounts, setTotalCounts] = useState<any>();
  const [userMap, setUserMap] = useState<any>(undefined);
  let dispatcher = useDispatch();
  const createUsersList = (email: string, users: any[], uid: boolean) => {
    users.map((item: any) => {
      if (usersList.includes(item)) {
        return;
      } else {
        if (item.reporting_to === email && item.status === "ACTIVE") {
          if (!usersList.includes(item)) {
            usersList.push(item);
          }

          createUsersList(item.user_email, users, uid);
        }
      }
    });
  };

  useEffect(() => {
    let trueStatusList: any = [];
    selectedRowsData.forEach((row) => {
      Object.keys(row).forEach((item) => {
        if (item === "transfer_status") {
          trueStatusList.push(row[item]);
        }
      });
    });
    setTransferStatusTrueRows(trueStatusList);
  }, [selectedRowsData]);

  useEffect(() => {
    if (organizationUsers) {
      let uids: { [key: string]: string } = {};

      organizationUsers.forEach((user: any) => {
        uids[user.user_email] = user.reporting_to;
      });

      setUserMap(uids);
    }
  }, [organizationUsers]);

  const callApi = async (
    paginate?: boolean,
    localPageSize?: Number,
    localPage?: Number
  ) => {
    if (isFinished === true) {
      return;
    } else {
      // console.log("Helloooooo");
      setLoad(true);
      const filters: { [key: string]: any[] } = {};
      let feild;
      //Note: This is changed from previous version
      Object.keys(filterObject).forEach((item) => {
        // console.log("loop running", filterObject);

        if (filterObject && filterObject[item].length > 0) {
          if (item === "is_button_called") {
            if (filterObject[item].length === 2) {
              filters[item] = [true, false];
            } else {
              filters[item] =
                filterObject[item][0] === "True" ? [true] : [false];
            }
          } else {
            //FIXME: Here contact_no is getting set which is coming from behind
            // console.log("FFFFFilters item", filters);
            console.log("IMPT", filters[item], filterObject[item], item);
            //below code checks if item con_no but val not number, then use con_email instead
            if (item === "contact_no") {
              // let isnum = /^\d+$/.test(filterObject[item]);
              // console.log("contact no saf", isnum);
              // if (isnum) {
              //   filters[item] = filterObject[item];
              // } else {
              //   filters["contact_owner_email"] =
              //     filterObject[item];
              // }
            } else filters[item] = filterObject[item];

            //Normal Funcrtionality
            // filters[item] = filterObject[item];
          }
        }
      });
      // console.log("Finally Object of Filrers", filters);
      if (!allLeads) {
        filters["transfer_status"] = [false];
      }
      if (searchString === "") {
        feild = "customer_name";
      } else {
        if (searchString.match(/^[0-9]+$/) != null) {
          console.log("number found");

          feild = "contact_no";
        } else {
          feild = "customer_name";
        }
      }
      let records = localPageSize ? localPageSize : pageSize;
      const apiData = {
        uid: user.uid,
        page: localPage ? localPage : page + 1,
        searchString: searchString,
        sort:
          Object.keys(filterSort).length === 0
            ? { created_at: "-1" }
            : filterSort,
        pageSize: localPageSize ? localPageSize : pageSize,
        filter: filters,
      };
      // console.log("Api Data - ", apiData);
      try {
        axios.defaults.headers.common["x-access-token"] =
          "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoibGFrc2hheSIsImlhdCI6MTYyOTczOTgzMX0.E0mqgfrDj5su4grI1r8PH973srqJy3k2AG_hvCx-EaA";
        const res = await axios.post(url + "/leads/search", apiData);
        // console.log("Res data:", res);
        let data: any[] = res.data;
        if (data.length < records) {
          isFinished = true;
          setLastPage(page);
        }

        data.forEach((lead) => {
          lead.contactId = lead.Id;
          // console.log("lead owner email", lead);
          lead["reporting_to"] = userMap[lead.contact_owner_email];
          delete lead["Id"];
          Object.keys(lead).forEach((key) => {
            if (datesField.includes(key) && lead[key] !== "") {
              if (lead[key] !== null) {
                lead[key] = Firebase.firestore.Timestamp.fromDate(
                  moment(lead[key]).toDate()
                );
              } else {
                lead[key] = "";
              }
            }
          });
        });
        // console.log("data again", data);
        if (paginate && filterData) {
          let newData = [...filterData, ...data];
          setFilterData(newData);
        } else {
          // console.log(
          //   "filter Data before set ",
          //   filterData
          // );

          setFilterData(data);
          // console.log("filter Data after set ", filterData);
        }
        setLoad(false);
      } catch (e) {
        console.log(e, "errror here");

        // setFilterData([]);
        setLoad(false);
        // dispatcher(
        //   showSnackbarAction("Please Try Again!!", "error")
        // );
      }
    }
  };

  useEffect(() => {
    if (user === undefined || user.uid === undefined) {
      console.log(" ssabfjka ");
      setFilterData([]);
      return;
    }
    if (userMap === undefined) {
      console.log("emopt");

      setFilterData([]);
      return;
    }
    isFinished = false;
    setLastPage(-1);
    if (page !== 0) {
      setPage(0);
    }
    callApi(false, pageSize, 1);
  }, [user, filterObject, filterSort, searchString, allLeads, userMap]);

  useEffect(() => {
    if (
      filterData &&
      filterData.length <= (page + 1) * pageSize &&
      isFinished === false
    ) {
      if (page === 0) {
        callApi(false, pageSize);
      } else {
        callApi(true, pageSize);
      }
    }
  }, [page, pageSize]);

  const callCountApi = async () => {
    axios.defaults.headers.common["x-access-token"] =
      "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoibGFrc2hheSIsImlhdCI6MTYyOTczOTgzMX0.E0mqgfrDj5su4grI1r8PH973srqJy3k2AG_hvCx-EaA";
    const res = await axios.post(url + "/leads/leadCount", {
      uid: user.uid,
    });

    setTotalCounts(res.data.total);
  };

  useEffect(() => {
    if (user === undefined || user.uid === undefined) {
      console.log("empy here");

      setFilterData([]);
      return;
    }
    callCountApi();
  }, [refreshContacts, user.uid]);

  useEffect(() => {
    if (refreshContacts === true) {
      callApi();
      getContactsFilter();
      dispatcher(setRefreshContacts(false));
    }
  }, [refreshContacts]);

  useEffect(() => {
    callApi();
    console.log("filter object", filterObject);
  }, [filterObject]);

  const getContactsFilter = async () => {
    axios.defaults.headers.common["x-access-token"] =
      "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoibGFrc2hheSIsImlhdCI6MTYyOTczOTgzMX0.E0mqgfrDj5su4grI1r8PH973srqJy3k2AG_hvCx-EaA";
    const res = await axios.post(url + "/leads/filterValues", {
      uid: user.uid,
    });

    console.log("Filter data User:", res.data[0]);
    if (res.data[0]) {
      dispatcher(
        setFilterObject({
          ...res.data[0],
          ...booleanObject,
        })
      );
    } else {
      dispatcher(setFilterObject({}));
    }
  };

  useEffect(() => {
    if (user === undefined || user.uid === undefined) {
      return;
    }
    getContactsFilter();
  }, [user]);

  useEffect(() => {
    let contact: any[] = [];
    Object.keys(contacts.data).forEach((key) => {
      contact = [...contact, ...contacts.data[key]];
    });
    setAllContacts(contact);
  }, [contacts.data]);

  useEffect(() => {
    let mapReportingTo: { [key: string]: string } = {};
    if (organizationUsers) {
      organizationUsers.forEach((item: any) => {
        if (mapReportingTo[item.uid] === undefined) {
          mapReportingTo[item.uid] = item.reporting_to;
        }
      });
    }

    if (allContacts) {
      allContacts.forEach((item: any) => {
        if (item.uid === "") {
          item["reporting_to"] = "";
        } else {
          item["reporting_to"] = mapReportingTo[item.uid]
            ? mapReportingTo[item.uid]
            : "";
        }
      });
    }

    // eslint-disable-next-line
  }, [allContacts]);

  const setUsersList = () => {
    if (role === "Lead Manager") {
      organizationUsers.map((item: any) => {
        if (usersList.includes(item)) {
          return;
        } else {
          if (item.status === "ACTIVE") {
            usersList.push(item);
          }
        }
      });
    } else {
      createUsersList(user.user_email, organizationUsers, false);
    }
  };

  useEffect(() => {
    if (selectedRows.length > 0) {
      setOwner(true);
      console.log(selectedRowsData);
    } else if (selectedRows.length === 0) {
      setOwner(false);
    }
  }, [selectedRows]);

  const clearSelectedRowsData = () => {
    console.log("clear selcetioed rows");
    setSelectedRowsData([]);
    setSelectedRows([]);
  };
const [notesList, setNotesList] =  useState<
[] 
>([]);

  const exportFile = () => {
    let data: any[] = [];
    if (selectedRowsData.length === 0) {
    } else {
      selectedRowsData.forEach((item,index) => {
        //Fetching Notes from firebase Code Start
    firestore
        .collection("contactResources")
        .doc(item.contactId)
        .onSnapshot((resources) => {
          if (resources) {
            const rData = resources.data();
            if (rData?.notes) {
              let allNotes:any="";
              rData.notes.map((Mnote:any,noteIndex:any)=>{
               //add multiple notes
               if(Mnote.note){
               let str:any=Mnote.note;
               let updatedNote=str.replace( /[\r\n]+/gm, " " );
               allNotes+=updatedNote+",";
               }else{
                allNotes+="";
               }
              });
              //adding Notes in selectedRowsData array
              item["Notes"]=allNotes;
            }else{
              item["Notes"]="";
            }
          }
        })
        //Fetching Notes from firebase code End
        data.push({
          Id: item.contactId,
          "Notes":item.Notes,
          "Customer Name": item.customer_name,
          "Country Code": item.country_code,
          "Mobile No": item.contact_no,
          "Alternate Mobile No": item.alternate_no,
          "Email ID": item.email,
          Stage: item.stage,
          Owner: item.contact_owner_email,
          "CallBack Reason": item.call_back_reason,
          "Property Type": item.property_type,
          "Property Sub Type": item.property_sub_type,
          "Property Stage": item.property_stage,
          Location: item.location,
          Project: item.project,
          "Not Interested Reason": item.not_int_reason,
          "Lost Reason": item.lost_reason,
          "Other Not Interested Reason": item.other_not_int_reason,
          "Other Lost Reason": item.other_lost_reason,
          "Previous Owner": item.previous_owner,
          "Next follow Up Type": item.next_follow_up_type,
          "Next Follow Up Date & Time": getDateString(
            item.next_follow_up_date_time
          ),
          AddSet: item.addset,
          Campaign: item.campaign,
          "Inventory Type": item.inventory_type,
          Budget: item.budget,
          "Lead Source": item.lead_source,
          "Transfer Status": item.transfer_status,
          "Transfer Reason": item.transfer_reason,
          "Associate Status": item.associate_status,
          "Source Status": item.source_status,
          "Previous Owner-1": item.previous_owner_1,
          "Previous Owner-2": item.previous_owner_2,
          "Previous Stage-1": item.previous_stage_1,
          "Previous Stage-2": item.previous_stage_2,
          "Transfer By-1": item.transfer_by_1,
          "Transfer By-2": item.transfer_by_2,
          "Modified At": getDateString(item.stage_change_at),
          "Stage Change Date and Time": getDateString(item.stage_change_at),
          "Created By": item.created_by,
          "Created At": getDateString(item.created_at),
          "Lead Assign At": getDateString(item.lead_assign_time),
          is_button_called: item.is_button_called,
        });
      });
    }
    return data;
  };
  exportFile();
  return (
    <>
      <div className={commonStyle.topBar}>
        <ApiTopBar
          history={history}
          title={"Add Contacts"}
          path={"/addContacts"}
          onClick={() => {
            setShowImportModal(true);
          }}
          owner={owner}
          deleteSelected={()=>{
            setDeletePopop(true);
          }}
          changeOwner={() => {
            setUsersList();
            setShow(true);
          }}
          onChange={(val) => setsearchedItem(val)}
          filterText={"Status"}
          setColumnModal={(data) => setColumnModal(data)}
          show={true}
          showStatusBox={true}
          onExport={exportFile}
          setBulkEdit={() => {
            setShowBulkEdit(true);
          }}
          onCallLogsImport={() => {
            setCallLogsImportModal(true);
          }}
          onNotesImport={() => {
            setNotesImportModal(true);
          }}
          onTasksImport={() => {
            setTasksImportModal(true);
          }}
          searchedString={searchString}
          setAllLeads={(data) => setAllLeads(data)}
          transferLeads={transferStatusTrueRows}
        />
      </div>
      <div className={commonStyle.parent}>
        {load === true && <Loading />}
        <ApiCustomTable
         
          tableColumns={CONTACT_COLUMNS}
          tableRows={filterData}
          selectedRows={selectedRows}
          setSelectedRows={(data) => setSelectedRows(data)}
          tableType="User"
          showColumnModal={columnModal}
          hideColumnModal={() => setColumnModal(false)}
          selectedRowsData={selectedRowsData}
          setSelectedRowsData={(data) => setSelectedRowsData(data)}
          setPage={(val) => setPage(val)}
          setPageSize={(val) => setPageSize(val)}
          callApi={callApi}
          pageSize={pageSize}
          page={page}
          isFinished={isFinished}
          totalCount={totalCounts}
          lastPage={lastPage}
        />

        {show && (
          <OwnerModal
            open={show}
            close={() => {
              setShow(false);
            }}
            usersList={usersList}
            rowsSelected={selectedRowsData}
            clearSelectedRowsData={clearSelectedRowsData}
            organization_id={user.organization_id}
          />
        )}
        {deletePopup && (
          <DeleteSelectedModal
            data={selectedRowsData}
            clearSelectedRowsData={clearSelectedRowsData}
           open={deletePopup}
          close={()=>{
            setDeletePopop(false);
           
          }}
         
           />
        )}
        {showImportModal && (
          <NewImportContactModal
            open={showImportModal}
            close={() => setShowImportModal(false)}
            organization_id={user.organization_id}
            history={history}
            usersList={organizationUsers}
            user={user}
          />
        )}
        {callLogsImportModal && (
          <ImportCallLogsModal
            open={callLogsImportModal}
            close={() => setCallLogsImportModal(false)}
            organization_id={user.organization_id}
            usersList={organizationUsers}
          />
        )}

        {notesImportModal && (
          <ImportNotesModal
            open={notesImportModal}
            close={() => setNotesImportModal(false)}
          />
        )}
        {tasksImportModal && (
          <ImportTasksModal
            open={tasksImportModal}
            close={() => setTasksImportModal(false)}
            organization_id={user.organization_id}
            usersList={organizationUsers}
            user={user}
          />
        )}
        {showBulkEdit && (
          <BulkEditModal
            open={showBulkEdit}
            organization_id={user.organization_id}
            close={() => {
              setShowBulkEdit(false);
              clearSelectedRowsData();
            }}
            rowsData={selectedRowsData}
          />
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    user: state.user.data,
    role: state.user.role,
    contacts: state.contacts,
    organizationUsers: state.organizationUsers.data,
    teamLeadUsers: state.teamLeadUsers.data,
    filterObject: state.filter,
    filterSort: state.filterSort,
    refreshContacts: state.refresh.refreshContacts,
    searchString: state.searchItem.contactsSearchString,
  };
};

export default connect(mapStateToProps)(UserPanel);

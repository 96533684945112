import React, { useRef, useState, useEffect } from "react";
import TextInput from "../../Components/TextInput/TextInput";
import styles from "./AddContacts.module.css";
import { FaUser } from "react-icons/fa";
import {
  emailValidate,
  phoneValidate,
} from "../../Values/validatorsnew";
import { connect, useDispatch } from "react-redux";

import {
  contactResources,
  createContacts,
  fetchConstants,
  fetchpropertySubType,
} from "../../Services/contacts";
import Loading from "../../Components/Loading/Loading";
import { AiOutlineClose } from "react-icons/ai";
import { showSnackbarAction } from "../../Redux/actions";
import { country_code } from "../../Values/constants";
import Select from "react-select";
import Dropdown from "../../Components/DropDown/Dropdown";
import { useLocation } from "react-router";

const AddContacts = ({
  history,
  user,
  organizationUsers,
  role,
}: {
  history: any;
  user: any;
  organizationUsers: any;
  role: string;
}) => {
  const dispatcher = useDispatch();
  const firstNameRef: any = useRef();
  const lastNameRef: any = useRef();
  const numberRef: any = useRef();
  const emaileRef: any = useRef();
  const alternateRef: any = useRef();
  const [load, setLoad] = useState(false);
  const [countryCodeList, setCountryCodeList] = useState<
    string[]
  >([]);
  const [countryCode, setCountryCode] =
    useState<string>("");
  const [note, setNote] = useState("");

  const noteRef: any = useRef();
  const [projectSelected, setProjectSelected] =
    useState<any>({
      label: "Select",
      value: "Select",
    });

  const [locationSelected, setlocationSelected] =
    useState<any>({
      label: "Select",
      value: "Select",
    });
  const [budgetSelected, setBudgetSelected] = useState<any>(
    {
      label: "Select",
      value: "Select",
    }
  );
  const [propertyTypeSelected, setPropertyTypeSelected] =
    useState<any>({
      label: "Select",
      value: "Select",
    });
  const [propertyStageSelected, setPropertyStageSelected] =
    useState<any>({
      label: "Select",
      value: "Select",
    });
  const [nextFollowSelected, setNextFollowSelected] =
    useState<any>({
      label: "Select",
      value: "Select",
    });
  const [
    propertySubTypeSelected,
    setPropertySubTypeSelected,
  ] = useState<any>({
    label: "Select",
    value: "Select",
  });
  const [leadSourceSelected, setLeadSourceSelected] =
    useState<any>({
      label: "Select",
      value: "Select",
    });
  const [ownerSelected, setOwnerSelected] = useState<any>({
    label: "Select",
    value: "Select",
  });
  const [usersList, setUsersList] = useState([]);

  const [projectsList, setProjectsList] = useState<any[]>(
    []
  );
  const [locationsList, setLocationsList] = useState<any[]>(
    []
  );
  const [budgetsList, setBudgetsList] = useState<any[]>([]);
  const [propertyTypeList, setPropertyTypeList] = useState<
    any[]
  >([]);
  const [propertyStageList, setPropertyStageList] =
    useState<any[]>([]);
  const [nextFollowUpList, setnextFollowUpList] = useState<
    any[]
  >([]);
  const [notIntReasonList, setNotIntReasonList] = useState<
    any[]
  >([]);
  const [lostReasonList, setLostReasonList] = useState<
    any[]
  >([]);
  const [comTypes, setComTypes] = useState<any[]>([]);
  const [resTypes, setResTypes] = useState<any[]>([]);
  const [propertySubType, setPropertySubType] = useState<
    any[]
  >([]);
  const [leadSourceList, setLeadSourceList] = useState<
    any[]
  >([]);
  const [uidList, setUidList] = useState<any>();

  useEffect(() => {
    window.onbeforeunload = function () {
      return "Are you really want to perform the action?";
    };
    if (role === "Lead Manager" && organizationUsers) {
      let uids: { [key: string]: string } = {};
      let data: any = [];
      organizationUsers.map((item: any) => {
        data.push(
          `${item.user_first_name} ${item.user_last_name}-${item.user_email}`
        );
        uids[item.user_email] = item.uid;
      });
      setUsersList(data);
      setUidList(uids);
    }
  }, []);

  useEffect(() => {
    const unsub = contactResources(
      (data) => setLocationsList(data),
      (data) => setBudgetsList(data),
      (data) => setProjectsList(data),
      user.organization_id
    );

    const unsubConst = fetchConstants(
      (data) => setNotIntReasonList(data),
      (data) => setLostReasonList(data),
      (data) => setPropertyStageList(data),
      (data) => setPropertyTypeList(data),
      (data) => setnextFollowUpList(data)
    );

    const unsubProperty = fetchpropertySubType(
      user.organization_id,
      (data) => setComTypes(data),
      (data) => setResTypes(data),
      (data) => setLeadSourceList(data)
    );

    return () => {
      unsub();
      unsubConst();
      unsubProperty();
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (propertyTypeSelected.value === "Commercial") {
      setPropertySubType(comTypes);
    } else if (
      propertyTypeSelected.value === "Residential"
    ) {
      setPropertySubType(resTypes);
    } else {
      setPropertySubType([]);
    }
  }, [propertyTypeSelected]);

  useEffect(() => {
    let data: any[] = [];
    country_code.forEach((item) => {
      data.push(item.dial_code + " " + item.name);
    });
    setCountryCodeList(data);
  }, []);

  useEffect(() => {
    let selected: string[] = [];
    if (user.country) {
      selected = countryCodeList.filter((item) =>
        item
          .toLowerCase()
          .includes(user.country.toLowerCase())
      );
    } else {
      selected = countryCodeList.filter((item) =>
        item.toLowerCase().includes("+91")
      );
    }
    if (selected.length !== 0) {
      setCountryCode(selected[0]);
    }
  }, [user.country, countryCodeList]);

  const choice = () => {
    let data = countryCodeList.map((item: any) => ({
      value: item,
      label: item,
    }));

    return data;
  };
  const location: any = useLocation();
  const create = async () => {
    if (user.organization_id) {
      if (firstNameRef.current.value === "") {
        dispatcher(
          showSnackbarAction(
            "First Name Required!!",
            "error"
          )
        );
        return;
      } else if (lastNameRef.current.value === "") {
        dispatcher(
          showSnackbarAction(
            "Last Name Required!!",
            "error"
          )
        );
        return;
      } else if (numberRef.current.value === "") {
        dispatcher(
          showSnackbarAction(
            "Mobile Number Required!!",
            "error"
          )
        );
        return;
      } else if (
        emaileRef.current.value !== "" &&
        emailValidate(emaileRef.current.value) ===
          "Invalid Email"
      ) {
        dispatcher(
          showSnackbarAction("Invalid Email!!", "error")
        );
        return;
      } else if (countryCode === "") {
        dispatcher(
          showSnackbarAction(
            "Country Code Required!!",
            "error"
          )
        );
        return;
      }
      setLoad(true);
      let l = ownerSelected.value.split("-");
      let email = l[1];

      const country = country_code.find(
        (el: any) =>
          el.dial_code === countryCode.split(" ")[0]
      );

      createContacts(
        user.organization_id,
        emaileRef.current.value,
        firstNameRef.current.value,
        lastNameRef.current.value,
        numberRef.current.value,
        user.user_first_name + " " + user.user_last_name,
        role === "Lead Manager"
          ? uidList[email] !== undefined
            ? uidList[email]
            : user.uid
          : user.uid,
        dispatcher,
        history,
        role === "Lead Manager"
          ? email !== undefined
            ? email
            : user.user_email
          : user.user_email,
        alternateRef.current.value,
        countryCode.split(" ")[0],
        (data: boolean) => setLoad(data),
        country ? country.name : "India",
        propertyTypeSelected.value === "Select"
          ? ""
          : propertyTypeSelected.value,
        propertyStageSelected.value === "Select"
          ? ""
          : propertyStageSelected.value,
        locationSelected.value === "Select"
          ? ""
          : locationSelected.value,
        budgetSelected.value === "Select"
          ? ""
          : budgetSelected.value,
        projectSelected.value === "Select"
          ? ""
          : projectSelected.value,
        propertySubTypeSelected.value === "Select"
          ? ""
          : propertySubTypeSelected.value,
        leadSourceSelected.value === "Select"
          ? "Self Generated"
          : leadSourceSelected.value,
        noteRef.current.value,
        false
      );
    }
  };

  return (
    <div className={styles.parent}>
      {load === true && <Loading />}
      <div className={styles.child}>
        <div className={styles.headerView}>
          <p className={styles.heading}>
            {" "}
            {"Add Contacts Details"}
          </p>
          <AiOutlineClose
            className={styles.closeIcon}
            size={25}
            onClick={() => history.replace("/")}
          />
        </div>
        <div className={styles.box2}>
          <div className={styles.divide}>
            <div className={styles.title}>
              <p className={styles.one}>First Name</p>
              <p className={styles.two}>*</p>
            </div>
            <div>
              <TextInput
                title={"Enter first name"}
                style={{ backgroundColor: "#fff" }}
                ref={firstNameRef}
              >
                <FaUser color={"#808080"} />
              </TextInput>
            </div>
          </div>

          <div className={styles.divide}>
            <div className={styles.title}>
              <p className={styles.one}>Last Name</p>
              <p className={styles.two}>*</p>
            </div>
            <div>
              <TextInput
                title={"Enter last name"}
                style={{ backgroundColor: "#fff" }}
                ref={lastNameRef}
              >
                <FaUser color={"#808080"} />
              </TextInput>
            </div>
          </div>
        </div>

        <div className={styles.box2}>
          <div className={styles.divide}>
            <div className={styles.title}>
              <p className={styles.one}>Mobile No.</p>
              <p className={styles.two}>*</p>
            </div>
            <div>
              <TextInput
                title={"Enter Number"}
                style={{ backgroundColor: "#fff" }}
                ref={numberRef}
              />
            </div>
          </div>

          <div className={styles.divide}>
            <div className={styles.title}>
              <p className={styles.one}>Email ID</p>
            </div>
            <div>
              <TextInput
                title={"Enter Email"}
                style={{ backgroundColor: "#fff" }}
                validator={emailValidate}
                ref={emaileRef}
              />
            </div>
          </div>
        </div>

        <div className={styles.box2}>
          <div className={styles.divide}>
            <div className={styles.title}>
              <p className={styles.one}>Alternate No.</p>
              <p className={styles.two}></p>
            </div>
            <div>
              <TextInput
                title={"Enter Number"}
                style={{ backgroundColor: "#fff" }}
                ref={alternateRef}
              />
            </div>
          </div>

          <div className={styles.divide}>
            <div
              className={styles.title}
              style={{ marginBottom: "3%" }}
            >
              <p className={styles.one}>Country Code</p>
              <p className={styles.two}>*</p>
            </div>
            <div>
              <Select
                options={choice()}
                onChange={(data) => {
                  if (data) {
                    setCountryCode(data.label);
                  }
                }}
                isSearchable={true}
                value={{
                  label: countryCode,
                  value: countryCode,
                }}
              />
            </div>
          </div>
        </div>

        <div className={styles.box2}>
          <div className={styles.divide}>
            <div className={styles.title}>
              <p className={styles.one}>Location</p>
              <p className={styles.two}></p>
            </div>
            <div style={{ marginTop: "7px" }}>
              <Dropdown
                option={locationsList}
                selectedValue={locationSelected}
                setSelectedValue={(value) => {
                  setlocationSelected(value);
                }}
              />
            </div>
          </div>

          <div className={styles.divide}>
            <div className={styles.title}>
              <p className={styles.one}>Project</p>
              <p className={styles.two}></p>
            </div>
            <div style={{ marginTop: "7px" }}>
              <Dropdown
                option={projectsList}
                selectedValue={projectSelected}
                setSelectedValue={(value) => {
                  setProjectSelected(value);
                }}
              />
            </div>
          </div>
        </div>

        <div className={styles.box2}>
          <div className={styles.divide}>
            <div className={styles.title}>
              <p className={styles.one}>Property Type</p>
              <p className={styles.two}></p>
            </div>
            <div style={{ marginTop: "7px" }}>
              <Dropdown
                option={propertyTypeList}
                selectedValue={propertyTypeSelected}
                setSelectedValue={(value) => {
                  setPropertyTypeSelected(value);
                }}
              />
            </div>
          </div>
          <div className={styles.divide}>
            <div className={styles.title}>
              <p className={styles.one}>Property Stage</p>
              <p className={styles.two}></p>
            </div>
            <div style={{ marginTop: "7px" }}>
              <Dropdown
                option={propertyStageList}
                selectedValue={propertyStageSelected}
                setSelectedValue={(value) => {
                  setPropertyStageSelected(value);
                }}
              />
            </div>
          </div>
        </div>

        <div className={styles.box2}>
          <div className={styles.divide}>
            <div className={styles.title}>
              <p className={styles.one}>Budget</p>
              <p className={styles.two}></p>
            </div>
            <div style={{ marginTop: "7px" }}>
              <Dropdown
                option={budgetsList}
                selectedValue={budgetSelected}
                setSelectedValue={(value) => {
                  setBudgetSelected(value);
                }}
              />
            </div>
          </div>
          <div className={styles.divide}>
            <div className={styles.title}>
              <p className={styles.one}>
                Property Sub Type
              </p>
              <p className={styles.two}></p>
            </div>
            <div style={{ marginTop: "7px" }}>
              <Dropdown
                option={propertySubType}
                selectedValue={propertySubTypeSelected}
                setSelectedValue={(value) => {
                  setPropertySubTypeSelected(value);
                }}
              />
            </div>
          </div>
        </div>

        {user.profile === "Lead Manager" && (
          <div className={styles.box2}>
            <div className={styles.divide}>
              <div className={styles.title}>
                <p className={styles.one}>Lead Source</p>
                <p className={styles.two}></p>
              </div>
              <div style={{ marginTop: "7px" }}>
                <Dropdown
                  option={leadSourceList}
                  selectedValue={leadSourceSelected}
                  setSelectedValue={(value) => {
                    setLeadSourceSelected(value);
                  }}
                />
              </div>
            </div>
            <div className={styles.divide}>
              <div className={styles.title}>
                <p className={styles.one}>Owner</p>
                <p className={styles.two}></p>
              </div>
              <div style={{ marginTop: "7px" }}>
                <Dropdown
                  option={usersList}
                  selectedValue={ownerSelected}
                  setSelectedValue={(value) => {
                    setOwnerSelected(value);
                  }}
                />
              </div>
            </div>
          </div>
        )}

        {user.profile === "Team Lead" && (
          <div className={styles.box2}>
            <div className={styles.divide}>
              <div className={styles.title}>
                <p className={styles.one}>Lead Source</p>
                <p className={styles.two}></p>
              </div>
              <div style={{ marginTop: "7px" }}>
                <Dropdown
                  option={leadSourceList}
                  selectedValue={leadSourceSelected}
                  setSelectedValue={(value) => {
                    setLeadSourceSelected(value);
                  }}
                />
              </div>
            </div>
          </div>
        )}

        <div className={styles.box2}>
          <div
            className={styles.divide}
            style={{ width: "100%" }}
          >
            <div className={styles.title}>
              <p className={styles.one}>Add Note</p>
              <p className={styles.two}></p>
            </div>
            <div style={{ marginTop: "7px" }}>
              <textarea
                style={{
                  width: "100%",
                  resize: "none",
                  marginTop: "17px",
                }}
                rows={4}
                cols={10}
                placeholder={"Enter Note"}
                ref={noteRef}
                onChange={(e) => setNote(e.target.value)}
                value={note}
              ></textarea>
            </div>
          </div>
        </div>

        <button
          className={styles.button}
          onClick={() => {
            console.log(user, "user");
            create();
          }}
        >
          Submit
        </button>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    user: state.user.data,
    organizationUsers: state.organizationUsers.data,
    role: state.user.role,
  };
};

export default connect(mapStateToProps)(AddContacts);

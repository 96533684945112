import React, { useState, useEffect, FunctionComponent } from 'react';
import styles from './Analytics.module.css';
import { Line, Chart } from 'react-chartjs-2';
import { connect, useDispatch } from 'react-redux';
import AnalyticsCountContainer from '../../AnalyticsCountContainer/AnalyticsCountContainer';
import AnalyticsSideNav from '../../Components/AnalyticsSideNav/AnalyticsSideNav';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Checkbox } from '@material-ui/core';
import FeedbackGraph from '../../Components/Graphs/FeedbackGraph';
import Trends from '../../Components/Graphs/Trends';
import InterestedBudget from '../../Components/Graphs/InterestedBudget';
import InterestedProject from '../../Components/Graphs/InterestedProject';
import InterestedLocation from '../../Components/Graphs/InterestedLocation';
import InterestedPropertyType from '../../Components/Graphs/InterestedPropertyType';
import InterestedPropertyStage from '../../Components/Graphs/InterestedPropertyStage';
import CompletedTask from '../../Components/Graphs/CompletedTask';
import OverdueTask from '../../Components/Graphs/OverdueTask';
import PendingTask from '../../Components/Graphs/PendingTask';
import NotIntReason from '../../Components/Graphs/NotIntReason';
import LostReason from '../../Components/Graphs/LostReason';
import { FaFilter } from 'react-icons/fa';
import AnalyticsFilter from '../../Components/AnalyticsFilter/AnalyticsFilter';
import axios from 'axios';
import Loading from '../../Components/Loading/Loading';
import moment from 'moment';
import Firebase from 'firebase/app';
import { url } from '../../Values/constants';
import KanbanViewFilter from '../../Components/Modals/KanbanViewModal/KanbanViewModal';

Chart.register(ChartDataLabels);
Chart.defaults.set('plugins.datalabels', {
  color: '#FFFFFF',
});

// all from redux store
type props = {
  user: any;
  organizationUsers: any;
  teamLeadUsers: any;
  role: any;
  history: any;
  branchUsers: any;
};

const LeadManagerAnalytics: FunctionComponent<props> = ({
  user,
  organizationUsers,
  teamLeadUsers,
  role,
  history,
  branchUsers,
}) => {
  //filter show, hide
  const [showFilter, setShowFilter] = useState(false);
  const [callLogAnalytics, setCallLogAnalytics] = useState({});
  const [analyticsData, setAnalyticsData] = useState<any>({});
  const [apiAnalyticsData, setApiAnalyticsData] = useState<any>({});
  const [apiCallLogAnalyticsData, setCallLogApiAnalyticsData] = useState<any>(
    {}
  );
  const [taskAnalyticsData, setTaskAnalyticsData] = useState<any>({});
  // check to show show data team wise or for all team members
  const [checked, setChecked] = useState(false);
  const [load, setLoad] = useState(false);
  // to check if the status is source or associate
  const [source, setSource] = useState(false);
  const [interestedTrendAnalytics, setInterestedTrendAnalytics] = useState({});
  const [analyticsType, setAnalyticsType] = useState('associate');
  // kanban view panels
  const [panel, setPanel] = useState<'Contacts' | 'Tasks' | 'Call Logs'>(
    'Contacts'
  );
  const [clearFilter, setClearFilter] = useState(false);
  const [stage, setStage] = useState<any>({
    INTERESTED: 0,
    FRESH: 0,
    CALLBACK: 0,
    WON: 0,
    LOST: 0,
    Pending: 0,
    Completed: 0,
    'NOT INTERESTED': 0,
  });
  const [taskStage, setTaskStage] = useState<any>({
    Pending: 0,
    Completed: 0,
  });
  const [feedback, setFeedback] = useState<any>({
    INTERESTED: 0,
    FRESH: 0,
    CALLBACK: 0,
    WON: 0,
    LOST: 0,
    'NOT INTERESTED': 0,
  });
  const [total, setTotal] = useState(0);
  const [overdueTrend, setOverdueTrend] = useState<{
    keys: any[];
    values: any[];
  }>({ keys: [], values: [] });
  const [completeTrend, setCompleteTrend] = useState<{
    keys: any[];
    values: any[];
  }>({ keys: [], values: [] });
  const [callLogData, setCallLogData] = useState<{ data: any[]; label: any[] }>(
    { data: [], label: [] }
  );
  const [interestedData, setInterestedData] = useState<{
    data: any[];
    label: any[];
  }>({ data: [], label: [] });
  const [usersList, setUsersList] = useState<any>({});
  const [usersEmail, setUsersEmail] = useState<any[]>([]);
  const [teamsData, setTeamsData] = useState<any>({});
  const [filterType, setFilterType] = useState<any>('All');
  const [contactsFilter, setContactsFilter] = useState<any>({});
  const [tasksFilter, setTasksFilter] = useState<any>({});
  const [callsFilter, setCallsFilter] = useState({});
  const [passOnTaskFilters, setPassOnTaskFilter] = useState<any>({});
  const [passOnContactFilters, setPassOnContactFilters] = useState<any>({});

  // function to call the call logs analytics api
  const callAnalyticsApi = async () => {
    let startDate;
    let endDate;
    setLoad(true);
    // Month till date
    if (filterType === 'MTD') {
      const startOfMonth = moment().startOf('month').toDate();
      startDate = startOfMonth;
      endDate = Firebase.firestore.Timestamp.now().toDate();
    }
    // previous month
    else if (filterType === 'PM') {
      const prevMonth = moment().subtract(1, 'M');
      const prevMonthStart = prevMonth.startOf('month').toDate();
      const prevMonthEnd = prevMonth.endOf('month').toDate();
      startDate = prevMonthStart;
      endDate = prevMonthEnd;
    }
    // today
    else if (filterType === 'T') {
      const todayStart = moment().startOf('day').toDate();
      const todayEnd = moment().endOf('day').toDate();
      startDate = todayStart;
      endDate = todayEnd;
    }
    // yesterday
    else if (filterType === 'Y') {
      const prevDay = moment().subtract(1, 'day');
      const prevDayStart = prevDay.startOf('day').toDate();
      const prevDayEnd = prevDay.endOf('day').toDate();
      startDate = prevDayStart;
      endDate = prevDayEnd;
    }

    // we have three modules - call logs, tasks and contacts
    // if we want filters of other modules in current modules, we need to send the api data such that it includes the module name before the filter attribute
    // as for the same module filter, we can send the filter attribute name as it is
    if (panel === 'Call Logs') {
      let filters: { [key: string]: any } = {};
      Object.keys(contactsFilter).forEach((key) => {
        filters[`leads.${key}`] = contactsFilter[key];
      });
      console.log('final Contacts Filter:', filters);
      setPassOnContactFilters(filters);
      let Tasksfilters: { [key: string]: any } = {};
      Object.keys(tasksFilter).forEach((key) => {
        Tasksfilters[`tasks.${key}`] = tasksFilter[key];
      });
      console.log('final Tasks Filter:', Tasksfilters);
      setPassOnTaskFilter(Tasksfilters);

      // access token for api call
      axios.defaults.headers.common['x-access-token'] =
        'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoibGFrc2hheSIsImlhdCI6MTYyOTczOTgzMX0.E0mqgfrDj5su4grI1r8PH973srqJy3k2AG_hvCx-EaA';

      // interested trend chart api data
      const interestedTrendApiData = {
        uid: user.uid,
        parameter: 'stage_change_at',
        start_date: startDate,
        end_date: endDate,
        taskFilter: Tasksfilters,
        leadFilter: filters,
        callFilter: callsFilter,
      };

      // interested trend chart api call
      const interestedTrendRes = await axios.post(
        url + '/leads/interestedReport/associate',
        interestedTrendApiData
      );
      console.log('calls:', interestedTrendRes.data);
      setInterestedTrendAnalytics(interestedTrendRes.data.ChartCount);

      axios.defaults.headers.common['x-access-token'] =
        'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoibGFrc2hheSIsImlhdCI6MTYyOTczOTgzMX0.E0mqgfrDj5su4grI1r8PH973srqJy3k2AG_hvCx-EaA';

      // call logs report api data
      const callLogsApiData = {
        uid: user.uid,
        start_date: startDate,
        end_date: endDate,
        taskFilter: tasksFilter,
        leadFilter: contactsFilter,
        callFilter: callsFilter,
      };

      // call logs report api call
      const callLogsRes = await axios.post(
        url + '/callLogs/callingReport',
        callLogsApiData
      );

      console.log(callLogsApiData);

      // setting call logs report data in required format for our chart and report
      if (callLogsRes.data) {
        const obj = Object.assign({}, ...callLogsRes.data.ChartCount);
        let callsData: { [key: string]: { callLogAnalytics: {} } } = {};
        callLogsRes.data.report.forEach((item: any) => {
          let data: {
            '0': number;
            '0-30': number;
            '31-60': number;
            '61-120': number;
            '>120': number;
          } = { '0': 0, '0-30': 0, '31-60': 0, '61-120': 0, '>120': 0 };
          item.duration.forEach((val: any) => {
            if (val.duration === 0) {
              data['0'] = val.count;
            } else if (val.duration === 30) {
              data['0-30'] = val.count;
            } else if (val.duration === 60) {
              data['31-60'] = val.count;
            } else if (val.duration === 120) {
              data['61-120'] = val.count;
            } else {
              data['>120'] = val.count;
            }
          });
          callsData[item.owner] = { callLogAnalytics: data };
        });

        setCallLogApiAnalyticsData(callsData);
        setCallLogAnalytics(obj);
      }
    }
    if (panel === 'Tasks' || panel === 'Contacts') {
      let filters: { [key: string]: any } = {};

      Object.keys(contactsFilter).forEach((key) => {
        filters[`leads.${key}`] = contactsFilter[key];
      });

      console.log('final Contacts Filter:', filters);

      setPassOnContactFilters(filters);

      let taskAnalytics: {
        [key: string]: {
          totalTaskCount: { Pending: {}; Overdue: {}; Completed: {} };
        };
      } = {};

      axios.defaults.headers.common['x-access-token'] =
        'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoibGFrc2hheSIsImlhdCI6MTYyOTczOTgzMX0.E0mqgfrDj5su4grI1r8PH973srqJy3k2AG_hvCx-EaA';

      // pending task chart and report api data
      const pendingTaskApiData = {
        uid: user.uid,
        parameter: 'type',
        status: 'Pending',
        start_date: startDate,
        end_date: endDate,
        taskFilter: tasksFilter,
        leadFilter: filters,
        callFilter: callsFilter,
      };

      console.log('pending Api:', pendingTaskApiData);

      // pending task chart and report api call
      const pendingTaskRes = await axios.post(
        url + '/tasks/tasksReport/associate',
        pendingTaskApiData
      );

      console.log('tasks:', pendingTaskRes.data);

      let pendingCount = 0;

      // converting pending task report data in required format for our chart and report
      if (pendingTaskRes.data.report) {
        pendingTaskRes.data.report.forEach((item: any) => {
          let pending: { [key: string]: number } = {};
          item.type.forEach((val: any) => {
            pending[val.type] = val.count;
            if (val.type === 'Meeting' || val.type === 'Site Visit') {
              pendingCount = pendingCount + val.count;
            }
          });
          taskAnalytics[item.owner] = {
            totalTaskCount: {
              Pending: pending,
              Overdue: { Meeting: 0, 'Call Back': 0, 'Site Visit': 0 },
              Completed: { Meeting: 0, 'Call Back': 0, 'Site Visit': 0 },
            },
          };
        });
      }

      axios.defaults.headers.common['x-access-token'] =
        'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoibGFrc2hheSIsImlhdCI6MTYyOTczOTgzMX0.E0mqgfrDj5su4grI1r8PH973srqJy3k2AG_hvCx-EaA';

      // overdue chart and report api data
      const overdueTaskApiData = {
        uid: user.uid,
        parameter: 'type',
        status: 'Overdue',
        start_date: startDate,
        end_date: endDate,
        taskFilter: tasksFilter,
        leadFilter: filters,
        callFilter: callsFilter,
      };

      // overdue chart and report api call
      const overdueTaskRes = await axios.post(
        url + '/tasks/tasksReport/associate',
        overdueTaskApiData
      );

      // converting overdue task report data in required format for our chart and report
      if (overdueTaskRes.data.report) {
        overdueTaskRes.data.report.forEach((item: any) => {
          let overdue: { [key: string]: number } = {};
          item.type.forEach((val: any) => {
            overdue[val.type] = val.count;
            if (val.type === 'Meeting' || val.type === 'Site Visit') {
              pendingCount = pendingCount + val.count;
            }
          });
          if (taskAnalytics[item.owner] === undefined) {
            taskAnalytics[item.owner] = {
              totalTaskCount: {
                Pending: { Meeting: 0, 'Call Back': 0, 'Site Visit': 0 },
                Overdue: overdue,
                Completed: { Meeting: 0, 'Call Back': 0, 'Site Visit': 0 },
              },
            };
          } else {
            taskAnalytics[item.owner] = {
              totalTaskCount: {
                Pending: {
                  ...taskAnalytics[item.owner].totalTaskCount.Pending,
                },
                Overdue: overdue,
                Completed: { Meeting: 0, 'Call Back': 0, 'Site Visit': 0 },
              },
            };
          }
        });
      }

      axios.defaults.headers.common['x-access-token'] =
        'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoibGFrc2hheSIsImlhdCI6MTYyOTczOTgzMX0.E0mqgfrDj5su4grI1r8PH973srqJy3k2AG_hvCx-EaA';

      // completed task api data
      const completedTaskApiData = {
        uid: user.uid,
        parameter: 'type',
        status: 'Completed',
        start_date: startDate,
        end_date: endDate,
        taskFilter: tasksFilter,
        leadFilter: filters,
        callFilter: callsFilter,
      };

      // completed task api call
      const completedTaskRes = await axios.post(
        url + '/tasks/tasksReport/associate',
        completedTaskApiData
      );

      let completedCount = 0;

      // converting completed task report data in required format for our chart and report
      if (completedTaskRes.data.report) {
        completedTaskRes.data.report.forEach((item: any) => {
          let completed: { [key: string]: number } = {};
          item.type.forEach((val: any) => {
            completed[val.type] = val.count;
            if (val.type === 'Meeting' || val.type === 'Site Visit') {
              completedCount = completedCount + val.count;
            }
          });
          if (taskAnalytics[item.owner] === undefined) {
            taskAnalytics[item.owner] = {
              totalTaskCount: {
                Pending: { Meeting: 0, 'Call Back': 0, 'Site Visit': 0 },
                Overdue: { Meeting: 0, 'Call Back': 0, 'Site Visit': 0 },
                Completed: completed,
              },
            };
          } else {
            taskAnalytics[item.owner] = {
              totalTaskCount: {
                Pending: {
                  ...taskAnalytics[item.owner].totalTaskCount.Pending,
                },
                Overdue: {
                  ...taskAnalytics[item.owner].totalTaskCount.Overdue,
                },
                Completed: completed,
              },
            };
          }
        });
      }
      setTaskStage({ Completed: completedCount, Pending: pendingCount });
      setTaskAnalyticsData(taskAnalytics);
    }

    if (panel === 'Contacts') {
      let filters: { [key: string]: any } = {};

      Object.keys(tasksFilter).forEach((key) => {
        filters[`tasks.${key}`] = tasksFilter[key];
      });

      console.log('final tasks Filter:', filters);

      setPassOnTaskFilter(filters);

      let analytics: {
        [key: string]: {
          leadAnalytics: {
            budget: {};
            stage: {};
            location: {};
            project: {};
            propertyType: {};
            propertyStage: {};
            lost_reason: {};
            not_int_reason: {};
          };
        };
      } = {};

      axios.defaults.headers.common['x-access-token'] =
        'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoibGFrc2hheSIsImlhdCI6MTYyOTczOTgzMX0.E0mqgfrDj5su4grI1r8PH973srqJy3k2AG_hvCx-EaA';

      // feedback chart api data
      const feedbackApiData = {
        uid: user.uid,
        start_date: startDate,
        end_date: endDate,
        taskFilter: filters,
        leadFilter: contactsFilter,
        callFilter: callsFilter,
      };

      // feedback chart api call
      const feedbackRes = await axios.post(
        url + '/leads/feedbackReport/associate',
        feedbackApiData
      );

      // converting feedback report data in required format for our chart and report
      if (feedbackRes.data.report) {
        feedbackRes.data.report.forEach((item: any) => {
          let stage: { [key: string]: number } = {};
          item.stage.forEach((val: any) => {
            stage[val.stage] = val.count;
          });
          analytics[item.owner] = {
            leadAnalytics: {
              stage: stage,
              budget: {},
              location: {},
              project: {},
              propertyType: {},
              propertyStage: {},
              lost_reason: {},
              not_int_reason: {},
            },
          };
        });
      }

      axios.defaults.headers.common['x-access-token'] =
        'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoibGFrc2hheSIsImlhdCI6MTYyOTczOTgzMX0.E0mqgfrDj5su4grI1r8PH973srqJy3k2AG_hvCx-EaA';

      // budget chart and report api data
      const budgetApiData = {
        uid: user.uid,
        parameter: 'budget',
        start_date: startDate,
        end_date: endDate,
        taskFilter: filters,
        leadFilter: contactsFilter,
        callFilter: callsFilter,
      };

      // budget chart and report api call
      const budgetRes = await axios.post(
        url + '/leads/interestedReport/associate',
        budgetApiData
      );

      // converting budget report data in required format for our chart and report
      if (budgetRes.data.report) {
        budgetRes.data.report.forEach((item: any) => {
          let budget: { [key: string]: number } = {};
          item.budget.forEach((val: any) => {
            budget[val.budget] = val.count;
          });
          analytics[item.owner] = {
            leadAnalytics: {
              budget: budget,
              stage: { ...analytics[item.owner].leadAnalytics.stage },
              location: {},
              project: {},
              propertyType: {},
              propertyStage: {},
              lost_reason: {},
              not_int_reason: {},
            },
          };
        });
      }

      axios.defaults.headers.common['x-access-token'] =
        'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoibGFrc2hheSIsImlhdCI6MTYyOTczOTgzMX0.E0mqgfrDj5su4grI1r8PH973srqJy3k2AG_hvCx-EaA';

      // location report and chart api data
      const locationApiData = {
        uid: user.uid,
        parameter: 'location',
        start_date: startDate,
        end_date: endDate,
        taskFilter: filters,
        leadFilter: contactsFilter,
        callFilter: callsFilter,
      };

      // location chart and report api call
      const locationRes = await axios.post(
        url + '/leads/interestedReport/associate',
        locationApiData
      );

      // converting location report data in required format for our chart and report
      if (locationRes.data.report) {
        locationRes.data.report.forEach((item: any) => {
          let location: { [key: string]: number } = {};
          item.location.forEach((val: any) => {
            location[val.location] = val.count;
          });
          analytics[item.owner] = {
            leadAnalytics: {
              location: location,
              budget: { ...analytics[item.owner].leadAnalytics.budget },
              stage: { ...analytics[item.owner].leadAnalytics.stage },
              project: {},
              propertyType: {},
              propertyStage: {},
              lost_reason: {},
              not_int_reason: {},
            },
          };
        });
      }

      axios.defaults.headers.common['x-access-token'] =
        'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoibGFrc2hheSIsImlhdCI6MTYyOTczOTgzMX0.E0mqgfrDj5su4grI1r8PH973srqJy3k2AG_hvCx-EaA';

      const projectApiData = {
        uid: user.uid,
        parameter: 'project',
        start_date: startDate,
        end_date: endDate,
        taskFilter: filters,
        leadFilter: contactsFilter,
        callFilter: callsFilter,
      };

      const projectRes = await axios.post(
        url + '/leads/interestedReport/associate',
        projectApiData
      );

      if (projectRes.data.report) {
        projectRes.data.report.forEach((item: any) => {
          let project: { [key: string]: number } = {};
          item.project.forEach((val: any) => {
            project[val.project] = val.count;
          });
          analytics[item.owner] = {
            leadAnalytics: {
              project: project,
              location: { ...analytics[item.owner].leadAnalytics.location },
              budget: { ...analytics[item.owner].leadAnalytics.budget },
              stage: { ...analytics[item.owner].leadAnalytics.stage },
              propertyType: {},
              propertyStage: {},
              lost_reason: {},
              not_int_reason: {},
            },
          };
        });
      }

      axios.defaults.headers.common['x-access-token'] =
        'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoibGFrc2hheSIsImlhdCI6MTYyOTczOTgzMX0.E0mqgfrDj5su4grI1r8PH973srqJy3k2AG_hvCx-EaA';

      const propertyTypeApiData = {
        uid: user.uid,
        parameter: 'property_type',
        start_date: startDate,
        end_date: endDate,
        taskFilter: filters,
        leadFilter: contactsFilter,
        callFilter: callsFilter,
      };

      const propertyTypeRes = await axios.post(
        url + '/leads/interestedReport/associate',
        propertyTypeApiData
      );

      if (propertyTypeRes.data.report) {
        propertyTypeRes.data.report.forEach((item: any) => {
          let propertyType: { [key: string]: number } = {};
          item.property_type.forEach((val: any) => {
            propertyType[val.property_type] = val.count;
          });
          analytics[item.owner] = {
            leadAnalytics: {
              propertyType: propertyType,
              project: { ...analytics[item.owner].leadAnalytics.project },
              location: { ...analytics[item.owner].leadAnalytics.location },
              budget: { ...analytics[item.owner].leadAnalytics.budget },
              stage: { ...analytics[item.owner].leadAnalytics.stage },
              propertyStage: {},
              lost_reason: {},
              not_int_reason: {},
            },
          };
        });
      }

      axios.defaults.headers.common['x-access-token'] =
        'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoibGFrc2hheSIsImlhdCI6MTYyOTczOTgzMX0.E0mqgfrDj5su4grI1r8PH973srqJy3k2AG_hvCx-EaA';

      const propertyStageApiData = {
        uid: user.uid,
        parameter: 'property_stage',
        start_date: startDate,
        end_date: endDate,
        taskFilter: filters,
        leadFilter: contactsFilter,
        callFilter: callsFilter,
      };

      const propertyStageRes = await axios.post(
        url + '/leads/interestedReport/associate',
        propertyStageApiData
      );

      if (propertyStageRes.data.report) {
        propertyStageRes.data.report.forEach((item: any) => {
          let propertyStage: { [key: string]: number } = {};
          item.property_stage.forEach((val: any) => {
            propertyStage[val.property_stage] = val.count;
          });
          analytics[item.owner] = {
            leadAnalytics: {
              propertyStage: propertyStage,
              propertyType: {
                ...analytics[item.owner].leadAnalytics.propertyType,
              },
              project: { ...analytics[item.owner].leadAnalytics.project },
              location: { ...analytics[item.owner].leadAnalytics.location },
              budget: { ...analytics[item.owner].leadAnalytics.budget },
              stage: { ...analytics[item.owner].leadAnalytics.stage },
              lost_reason: {},
              not_int_reason: {},
            },
          };
        });
      }

      axios.defaults.headers.common['x-access-token'] =
        'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoibGFrc2hheSIsImlhdCI6MTYyOTczOTgzMX0.E0mqgfrDj5su4grI1r8PH973srqJy3k2AG_hvCx-EaA';

      const lostReasonApiData = {
        uid: user.uid,
        parameter: 'lost_reason',
        stage: 'LOST',
        start_date: startDate,
        end_date: endDate,
        taskFilter: filters,
        leadFilter: contactsFilter,
        callFilter: callsFilter,
      };

      const lostReasonRes = await axios.post(
        url + '/leads/reasonReport/associate',
        lostReasonApiData
      );

      if (lostReasonRes.data.report) {
        lostReasonRes.data.report.forEach((item: any) => {
          let lost_reason: { [key: string]: number } = {};
          item.lost_reason.forEach((val: any) => {
            lost_reason[val.lost_reason] = val.count;
          });
          analytics[item.owner] = {
            leadAnalytics: {
              lost_reason: lost_reason,
              propertyStage: {
                ...analytics[item.owner].leadAnalytics.propertyStage,
              },
              propertyType: {
                ...analytics[item.owner].leadAnalytics.propertyType,
              },
              project: { ...analytics[item.owner].leadAnalytics.project },
              location: { ...analytics[item.owner].leadAnalytics.location },
              budget: { ...analytics[item.owner].leadAnalytics.budget },
              stage: { ...analytics[item.owner].leadAnalytics.stage },
              not_int_reason: {},
            },
          };
        });
      }

      axios.defaults.headers.common['x-access-token'] =
        'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoibGFrc2hheSIsImlhdCI6MTYyOTczOTgzMX0.E0mqgfrDj5su4grI1r8PH973srqJy3k2AG_hvCx-EaA';

      const notIntApiData = {
        uid: user.uid,
        parameter: 'not_int_reason',
        stage: 'NOT INTERESTED',
        start_date: startDate,
        end_date: endDate,
        taskFilter: filters,
        leadFilter: contactsFilter,
        callFilter: callsFilter,
      };

      const notIntRes = await axios.post(
        url + '/leads/reasonReport/associate',
        notIntApiData
      );

      if (notIntRes.data.report) {
        notIntRes.data.report.forEach((item: any) => {
          let not_int_reason: { [key: string]: number } = {};
          item.not_int_reason.forEach((val: any) => {
            not_int_reason[val.not_int_reason] = val.count;
          });
          analytics[item.owner] = {
            leadAnalytics: {
              not_int_reason: not_int_reason,
              lost_reason: {
                ...analytics[item.owner].leadAnalytics.lost_reason,
              },
              propertyStage: {
                ...analytics[item.owner].leadAnalytics.propertyStage,
              },
              propertyType: {
                ...analytics[item.owner].leadAnalytics.propertyType,
              },
              project: { ...analytics[item.owner].leadAnalytics.project },
              location: { ...analytics[item.owner].leadAnalytics.location },
              budget: { ...analytics[item.owner].leadAnalytics.budget },
              stage: { ...analytics[item.owner].leadAnalytics.stage },
            },
          };
        });
      }

      // delete analytics[""];
      setApiAnalyticsData(analytics);
    }

    setLoad(false);
  };

  // same as above, just for source type
  const callSourceAnalyticsApi = async () => {
    let startDate;
    let endDate;
    setLoad(true);

    if (filterType === 'MTD') {
      const startOfMonth = moment().startOf('month').toDate();
      startDate = startOfMonth;
      endDate = Firebase.firestore.Timestamp.now().toDate();
    } else if (filterType === 'PM') {
      const prevMonth = moment().subtract(1, 'M');
      const prevMonthStart = prevMonth.startOf('month').toDate();
      const prevMonthEnd = prevMonth.endOf('month').toDate();
      startDate = prevMonthStart;
      endDate = prevMonthEnd;
    } else if (filterType === 'T') {
      const todayStart = moment().startOf('day').toDate();
      const todayEnd = moment().endOf('day').toDate();
      startDate = todayStart;
      endDate = todayEnd;
    } else if (filterType === 'Y') {
      const prevDay = moment().subtract(1, 'day');
      const prevDayStart = prevDay.startOf('day').toDate();
      const prevDayEnd = prevDay.endOf('day').toDate();
      startDate = prevDayStart;
      endDate = prevDayEnd;
    }

    if (panel === 'Call Logs') {
      let filters: { [key: string]: any } = {};

      Object.keys(contactsFilter).forEach((key) => {
        filters[`leads.${key}`] = contactsFilter[key];
      });

      console.log('final Contacts Filter:', filters);

      setPassOnContactFilters(filters);

      let Tasksfilters: { [key: string]: any } = {};

      Object.keys(tasksFilter).forEach((key) => {
        Tasksfilters[`tasks.${key}`] = tasksFilter[key];
      });

      console.log('final Tasks Filter:', Tasksfilters);

      setPassOnTaskFilter(Tasksfilters);

      axios.defaults.headers.common['x-access-token'] =
        'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoibGFrc2hheSIsImlhdCI6MTYyOTczOTgzMX0.E0mqgfrDj5su4grI1r8PH973srqJy3k2AG_hvCx-EaA';

      const interestedTrendApiData = {
        uid: user.uid,
        parameter: 'stage_change_at',
        start_date: startDate,
        end_date: endDate,
        taskFilter: Tasksfilters,
        leadFilter: filters,
        callFilter: callsFilter,
      };

      const interestedTrendRes = await axios.post(
        url + '/leads/interestedReport/source',
        interestedTrendApiData
      );

      setInterestedTrendAnalytics(interestedTrendRes.data.ChartCount);

      axios.defaults.headers.common['x-access-token'] =
        'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoibGFrc2hheSIsImlhdCI6MTYyOTczOTgzMX0.E0mqgfrDj5su4grI1r8PH973srqJy3k2AG_hvCx-EaA';

      const callLogsApiData = {
        uid: user.uid,
        start_date: startDate,
        end_date: endDate,
        taskFilter: Tasksfilters,
        leadFilter: filters,
        callFilter: callsFilter,
      };

      console.log(callLogsApiData);

      const callLogsRes = await axios.post(
        url + '/callLogs/callingReport',
        callLogsApiData
      );

      if (callLogsRes.data) {
        const obj = Object.assign({}, ...callLogsRes.data.ChartCount);

        let callsData: { [key: string]: { callLogAnalytics: {} } } = {};
        callLogsRes.data.report.forEach((item: any) => {
          let data: { [key: string]: number } = {};
          item.duration.forEach((val: any) => {
            if (val.duration === 'Other') {
              data['>120'] = val.count;
            } else {
              data[val.duration] = val.count;
            }
          });
          callsData[item.owner] = { callLogAnalytics: data };
        });

        setCallLogApiAnalyticsData(callsData);
        setCallLogAnalytics(obj);
      }
    }

    if (panel === 'Tasks' || panel === 'Contacts') {
      let filters: { [key: string]: any } = {};

      Object.keys(contactsFilter).forEach((key) => {
        filters[`leads.${key}`] = contactsFilter[key];
      });

      console.log('final Contacts Filter:', filters);

      setPassOnContactFilters(filters);

      let taskAnalytics: {
        [key: string]: {
          totalTaskCount: { Pending: {}; Overdue: {}; Completed: {} };
        };
      } = {};

      axios.defaults.headers.common['x-access-token'] =
        'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoibGFrc2hheSIsImlhdCI6MTYyOTczOTgzMX0.E0mqgfrDj5su4grI1r8PH973srqJy3k2AG_hvCx-EaA';

      const pendingTaskApiData = {
        uid: user.uid,
        parameter: 'type',
        status: 'Pending',
        start_date: startDate,
        end_date: endDate,
        taskFilter: tasksFilter,
        leadFilter: filters,
        callFilter: callsFilter,
      };

      let pendingCount = 0;

      const pendingTaskRes = await axios.post(
        url + '/tasks/tasksReport/source',
        pendingTaskApiData
      );

      if (pendingTaskRes.data.report) {
        pendingTaskRes.data.report.forEach((item: any) => {
          let pending: { [key: string]: number } = {};
          item.type.forEach((val: any) => {
            pending[val.type] = val.count;
            if (val.type === 'Meeting' || val.type === 'Site Visit') {
              pendingCount = pendingCount + val.count;
            }
          });
          taskAnalytics[item.owner] = {
            totalTaskCount: {
              Pending: pending,
              Overdue: { Meeting: 0, 'Call Back': 0, 'Site Visit': 0 },
              Completed: { Meeting: 0, 'Call Back': 0, 'Site Visit': 0 },
            },
          };
        });
      }

      axios.defaults.headers.common['x-access-token'] =
        'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoibGFrc2hheSIsImlhdCI6MTYyOTczOTgzMX0.E0mqgfrDj5su4grI1r8PH973srqJy3k2AG_hvCx-EaA';

      const overdueTaskApiData = {
        uid: user.uid,
        parameter: 'type',
        status: 'Overdue',
        start_date: startDate,
        end_date: endDate,
        taskFilter: tasksFilter,
        leadFilter: filters,
        callFilter: callsFilter,
      };

      const overdueTaskRes = await axios.post(
        url + '/tasks/tasksReport/source',
        overdueTaskApiData
      );

      if (overdueTaskRes.data.report) {
        overdueTaskRes.data.report.forEach((item: any) => {
          let overdue: { [key: string]: number } = {};
          item.type.forEach((val: any) => {
            overdue[val.type] = val.count;
            if (val.type === 'Meeting' || val.type === 'Site Visit') {
              pendingCount = pendingCount + val.count;
            }
          });
          if (taskAnalytics[item.owner] === undefined) {
            taskAnalytics[item.owner] = {
              totalTaskCount: {
                Pending: { Meeting: 0, 'Call Back': 0, 'Site Visit': 0 },
                Overdue: overdue,
                Completed: { Meeting: 0, 'Call Back': 0, 'Site Visit': 0 },
              },
            };
          } else {
            taskAnalytics[item.owner] = {
              totalTaskCount: {
                Pending: {
                  ...taskAnalytics[item.owner].totalTaskCount.Pending,
                },
                Overdue: overdue,
                Completed: { Meeting: 0, 'Call Back': 0, 'Site Visit': 0 },
              },
            };
          }
        });
      }

      axios.defaults.headers.common['x-access-token'] =
        'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoibGFrc2hheSIsImlhdCI6MTYyOTczOTgzMX0.E0mqgfrDj5su4grI1r8PH973srqJy3k2AG_hvCx-EaA';

      const completedTaskApiData = {
        uid: user.uid,
        parameter: 'type',
        status: 'Completed',
        start_date: startDate,
        end_date: endDate,
        taskFilter: tasksFilter,
        leadFilter: filters,
        callFilter: callsFilter,
      };

      const completedTaskRes = await axios.post(
        url + '/tasks/tasksReport/source',
        completedTaskApiData
      );

      let completedSum = 0;
      if (completedTaskRes.data.report) {
        completedTaskRes.data.report.forEach((item: any) => {
          let completed: { [key: string]: number } = {};
          item.type.forEach((val: any) => {
            completed[val.type] = val.count;
            if (val.type === 'Meeting' || val.type === 'Site Visit') {
              completedSum = completedSum + val.count;
            }
          });
          if (taskAnalytics[item.owner] === undefined) {
            taskAnalytics[item.owner] = {
              totalTaskCount: {
                Pending: { Meeting: 0, 'Call Back': 0, 'Site Visit': 0 },
                Overdue: { Meeting: 0, 'Call Back': 0, 'Site Visit': 0 },
                Completed: completed,
              },
            };
          } else {
            taskAnalytics[item.owner] = {
              totalTaskCount: {
                Pending: {
                  ...taskAnalytics[item.owner].totalTaskCount.Pending,
                },
                Overdue: {
                  ...taskAnalytics[item.owner].totalTaskCount.Overdue,
                },
                Completed: completed,
              },
            };
          }
        });
      }
      setTaskStage({ Completed: completedSum, Pending: pendingCount });
      setTaskAnalyticsData(taskAnalytics);
    }

    if (panel === 'Contacts') {
      let Tasksfilters: { [key: string]: any } = {};

      Object.keys(tasksFilter).forEach((key) => {
        Tasksfilters[`tasks.${key}`] = tasksFilter[key];
      });

      console.log('final Tasks Filter:', Tasksfilters);

      setPassOnTaskFilter(Tasksfilters);
      let analytics: {
        [key: string]: {
          leadAnalytics: {
            budget: {};
            stage: {};
            location: {};
            project: {};
            propertyType: {};
            propertyStage: {};
            lost_reason: {};
            not_int_reason: {};
          };
        };
      } = {};

      axios.defaults.headers.common['x-access-token'] =
        'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoibGFrc2hheSIsImlhdCI6MTYyOTczOTgzMX0.E0mqgfrDj5su4grI1r8PH973srqJy3k2AG_hvCx-EaA';

      const feedbackApiData = {
        uid: user.uid,
        start_date: startDate,
        end_date: endDate,
        taskFilter: Tasksfilters,
        leadFilter: contactsFilter,
        callFilter: callsFilter,
      };

      const feedbackRes = await axios.post(
        url + '/leads/feedbackReport/source',
        feedbackApiData
      );

      if (feedbackRes.data.report) {
        feedbackRes.data.report.forEach((item: any) => {
          let stage: { [key: string]: number } = {};
          item.stage.forEach((val: any) => {
            stage[val.stage] = val.count;
          });
          analytics[item.lead_source] = {
            leadAnalytics: {
              stage: stage,
              budget: {},
              location: {},
              project: {},
              propertyType: {},
              propertyStage: {},
              lost_reason: {},
              not_int_reason: {},
            },
          };
        });
      }
      axios.defaults.headers.common['x-access-token'] =
        'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoibGFrc2hheSIsImlhdCI6MTYyOTczOTgzMX0.E0mqgfrDj5su4grI1r8PH973srqJy3k2AG_hvCx-EaA';

      const budgetApiData = {
        uid: user.uid,
        parameter: 'budget',
        start_date: startDate,
        end_date: endDate,
        taskFilter: Tasksfilters,
        leadFilter: contactsFilter,
        callFilter: callsFilter,
      };

      const budgetRes = await axios.post(
        url + '/leads/interestedReport/source',
        budgetApiData
      );

      if (budgetRes.data.report) {
        budgetRes.data.report.forEach((item: any) => {
          let budget: { [key: string]: number } = {};
          item.budget.forEach((val: any) => {
            budget[val.budget] = val.count;
          });
          analytics[item.lead_source] = {
            leadAnalytics: {
              budget: budget,
              stage: { ...analytics[item.lead_source].leadAnalytics.stage },
              location: {},
              project: {},
              propertyType: {},
              propertyStage: {},
              lost_reason: {},
              not_int_reason: {},
            },
          };
        });
      }

      axios.defaults.headers.common['x-access-token'] =
        'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoibGFrc2hheSIsImlhdCI6MTYyOTczOTgzMX0.E0mqgfrDj5su4grI1r8PH973srqJy3k2AG_hvCx-EaA';

      const locationApiData = {
        uid: user.uid,
        parameter: 'location',
        start_date: startDate,
        end_date: endDate,
        taskFilter: Tasksfilters,
        leadFilter: contactsFilter,
        callFilter: callsFilter,
      };

      const locationRes = await axios.post(
        url + '/leads/interestedReport/source',
        locationApiData
      );

      if (locationRes.data.report) {
        locationRes.data.report.forEach((item: any) => {
          let location: { [key: string]: number } = {};
          item.location.forEach((val: any) => {
            location[val.location] = val.count;
          });
          analytics[item.lead_source] = {
            leadAnalytics: {
              location: location,
              budget: { ...analytics[item.lead_source].leadAnalytics.budget },
              stage: { ...analytics[item.lead_source].leadAnalytics.stage },
              project: {},
              propertyType: {},
              propertyStage: {},
              lost_reason: {},
              not_int_reason: {},
            },
          };
        });
      }

      axios.defaults.headers.common['x-access-token'] =
        'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoibGFrc2hheSIsImlhdCI6MTYyOTczOTgzMX0.E0mqgfrDj5su4grI1r8PH973srqJy3k2AG_hvCx-EaA';

      const projectApiData = {
        uid: user.uid,
        parameter: 'project',
        start_date: startDate,
        end_date: endDate,
        taskFilter: Tasksfilters,
        leadFilter: contactsFilter,
        callFilter: callsFilter,
      };

      const projectRes = await axios.post(
        url + '/leads/interestedReport/source',
        projectApiData
      );

      if (projectRes.data.report) {
        projectRes.data.report.forEach((item: any) => {
          let project: { [key: string]: number } = {};
          item.project.forEach((val: any) => {
            project[val.project] = val.count;
          });
          analytics[item.lead_source] = {
            leadAnalytics: {
              project: project,
              location: {
                ...analytics[item.lead_source].leadAnalytics.location,
              },
              budget: { ...analytics[item.lead_source].leadAnalytics.budget },
              stage: { ...analytics[item.lead_source].leadAnalytics.stage },
              propertyType: {},
              propertyStage: {},
              lost_reason: {},
              not_int_reason: {},
            },
          };
        });
      }

      axios.defaults.headers.common['x-access-token'] =
        'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoibGFrc2hheSIsImlhdCI6MTYyOTczOTgzMX0.E0mqgfrDj5su4grI1r8PH973srqJy3k2AG_hvCx-EaA';

      const propertyTypeApiData = {
        uid: user.uid,
        parameter: 'property_type',
        start_date: startDate,
        end_date: endDate,
        taskFilter: Tasksfilters,
        leadFilter: contactsFilter,
        callFilter: callsFilter,
      };

      const propertyTypeRes = await axios.post(
        url + '/leads/interestedReport/source',
        propertyTypeApiData
      );

      if (propertyTypeRes.data.report) {
        propertyTypeRes.data.report.forEach((item: any) => {
          let propertyType: { [key: string]: number } = {};
          item.property_type.forEach((val: any) => {
            propertyType[val.property_type] = val.count;
          });
          analytics[item.lead_source] = {
            leadAnalytics: {
              propertyType: propertyType,
              project: { ...analytics[item.lead_source].leadAnalytics.project },
              location: {
                ...analytics[item.lead_source].leadAnalytics.location,
              },
              budget: { ...analytics[item.lead_source].leadAnalytics.budget },
              stage: { ...analytics[item.lead_source].leadAnalytics.stage },
              propertyStage: {},
              lost_reason: {},
              not_int_reason: {},
            },
          };
        });
      }

      axios.defaults.headers.common['x-access-token'] =
        'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoibGFrc2hheSIsImlhdCI6MTYyOTczOTgzMX0.E0mqgfrDj5su4grI1r8PH973srqJy3k2AG_hvCx-EaA';

      const propertyStageApiData = {
        uid: user.uid,
        parameter: 'property_stage',
        start_date: startDate,
        end_date: endDate,
        taskFilter: Tasksfilters,
        leadFilter: contactsFilter,
        callFilter: callsFilter,
      };

      const propertyStageRes = await axios.post(
        url + '/leads/interestedReport/source',
        propertyStageApiData
      );

      if (propertyStageRes.data.report) {
        propertyStageRes.data.report.forEach((item: any) => {
          let propertyStage: { [key: string]: number } = {};
          item.property_stage.forEach((val: any) => {
            propertyStage[val.property_stage] = val.count;
          });
          analytics[item.lead_source] = {
            leadAnalytics: {
              propertyStage: propertyStage,
              propertyType: {
                ...analytics[item.lead_source].leadAnalytics.propertyType,
              },
              project: { ...analytics[item.lead_source].leadAnalytics.project },
              location: {
                ...analytics[item.lead_source].leadAnalytics.location,
              },
              budget: { ...analytics[item.lead_source].leadAnalytics.budget },
              stage: { ...analytics[item.lead_source].leadAnalytics.stage },
              lost_reason: {},
              not_int_reason: {},
            },
          };
        });
      }

      axios.defaults.headers.common['x-access-token'] =
        'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoibGFrc2hheSIsImlhdCI6MTYyOTczOTgzMX0.E0mqgfrDj5su4grI1r8PH973srqJy3k2AG_hvCx-EaA';

      const lostReasonApiData = {
        uid: user.uid,
        parameter: 'lost_reason',
        stage: 'LOST',
        start_date: startDate,
        end_date: endDate,
        taskFilter: Tasksfilters,
        leadFilter: contactsFilter,
        callFilter: callsFilter,
      };

      const lostReasonRes = await axios.post(
        url + '/leads/reasonReport/source',
        lostReasonApiData
      );

      if (lostReasonRes.data.report) {
        lostReasonRes.data.report.forEach((item: any) => {
          let lost_reason: { [key: string]: number } = {};
          item.lost_reason.forEach((val: any) => {
            lost_reason[val.lost_reason] = val.count;
          });
          analytics[item.lead_source] = {
            leadAnalytics: {
              lost_reason: lost_reason,
              propertyStage: {
                ...analytics[item.lead_source].leadAnalytics.propertyStage,
              },
              propertyType: {
                ...analytics[item.lead_source].leadAnalytics.propertyType,
              },
              project: { ...analytics[item.lead_source].leadAnalytics.project },
              location: {
                ...analytics[item.lead_source].leadAnalytics.location,
              },
              budget: { ...analytics[item.lead_source].leadAnalytics.budget },
              stage: { ...analytics[item.lead_source].leadAnalytics.stage },
              not_int_reason: {},
            },
          };
        });
      }

      axios.defaults.headers.common['x-access-token'] =
        'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoibGFrc2hheSIsImlhdCI6MTYyOTczOTgzMX0.E0mqgfrDj5su4grI1r8PH973srqJy3k2AG_hvCx-EaA';

      const notIntApiData = {
        uid: user.uid,
        parameter: 'not_int_reason',
        stage: 'NOT INTERESTED',
        start_date: startDate,
        end_date: endDate,
        taskFilter: Tasksfilters,
        leadFilter: contactsFilter,
        callFilter: callsFilter,
      };

      const notIntRes = await axios.post(
        url + '/leads/reasonReport/source',
        notIntApiData
      );

      if (notIntRes.data.report) {
        notIntRes.data.report.forEach((item: any) => {
          let not_int_reason: { [key: string]: number } = {};
          item.not_int_reason.forEach((val: any) => {
            not_int_reason[val.not_int_reason] = val.count;
          });
          analytics[item.lead_source] = {
            leadAnalytics: {
              not_int_reason: not_int_reason,
              lost_reason: {
                ...analytics[item.lead_source].leadAnalytics.lost_reason,
              },
              propertyStage: {
                ...analytics[item.lead_source].leadAnalytics.propertyStage,
              },
              propertyType: {
                ...analytics[item.lead_source].leadAnalytics.propertyType,
              },
              project: { ...analytics[item.lead_source].leadAnalytics.project },
              location: {
                ...analytics[item.lead_source].leadAnalytics.location,
              },
              budget: { ...analytics[item.lead_source].leadAnalytics.budget },
              stage: { ...analytics[item.lead_source].leadAnalytics.stage },
            },
          };
        });
      }

      setApiAnalyticsData(analytics);
    }
    setLoad(false);
  };

  useEffect(() => {
    if (analyticsType === 'associate') {
      callAnalyticsApi();
    } else if (analyticsType === 'source') {
      callSourceAnalyticsApi();
    }
  }, [
    user.uid,
    organizationUsers,
    analyticsType,
    filterType,
    contactsFilter,
    tasksFilter,
    callsFilter,
    panel,
  ]);

  useEffect(() => {
    setContactsFilter({});
    setTasksFilter({});
    setCallsFilter({});
  }, [panel]);

  useEffect(() => {
    if (organizationUsers) {
      let l: any[] = [];
      const users: { [key: string]: string } = {};

      organizationUsers.map((user: any) => {
        users[user.uid] = user.user_first_name + ' ' + user.user_last_name;
        l.push(user.user_email);
      });

      setUsersList(users);
      setUsersEmail(l);
    }
  }, [organizationUsers]);

  useEffect(() => {
    if (callLogAnalytics) {
      const tempData: any[] = [];
      Object.values(callLogAnalytics).forEach((val: any) => {
        tempData.push(val);
      });
      setCallLogData({ data: tempData, label: Object.keys(callLogAnalytics) });
    }
  }, [callLogAnalytics]);

  useEffect(() => {
    if (interestedTrendAnalytics) {
      setInterestedData({
        data: Object.values(interestedTrendAnalytics),
        label: Object.keys(interestedTrendAnalytics),
      });
    }
  }, [interestedTrendAnalytics]);

  const state = {
    labels: callLogData.label,
    datasets: [
      {
        label: 'Calling Trend',
        backgroundColor: '#4FCE5D',
        borderColor: '#4FCE5D',
        borderWidth: 2,
        data: callLogData.data,
        pointRadius: 6,
      },
    ],
  };

  const interestedState = {
    labels: interestedData.label,
    datasets: [
      {
        label: 'Interested Lead Trend',
        backgroundColor: '#808000',
        borderColor: '#808000',
        borderWidth: 2,
        data: interestedData.data,
        pointRadius: 6,
      },
    ],
  };

  const data = {
    labels: overdueTrend.keys,
    datasets: [
      {
        label: 'Completed Task',
        data: completeTrend.values,
        fill: false,
        backgroundColor: 'rgb(255, 99, 132)',
        borderColor: 'rgba(255, 99, 132, 0.2)',
        yAxisID: 'y-axis-1',
        pointRadius: 6,
      },
      {
        label: 'Overdue Task',
        data: overdueTrend.values,
        fill: false,
        backgroundColor: 'rgb(54, 162, 235)',
        borderColor: 'rgba(54, 162, 235, 0.2)',
        yAxisID: 'y-axis-2',
        pointRadius: 6,
      },
    ],
  };

  useEffect(() => {
    if (apiAnalyticsData) {
      let total = 0;
      let tempCounts: { [key: string]: number } = {
        FRESH: 0,
        INTERESTED: 0,
        CALLBACK: 0,
        WON: 0,
        'NOT INTERESTED': 0,
        LOST: 0,
      };
      Object.values(apiAnalyticsData).forEach((userAnalytics: any) => {
        Object.keys(userAnalytics.leadAnalytics.stage).forEach((key) => {
          if (key !== 'Pending' && key !== 'Completed') {
            total += userAnalytics.leadAnalytics.stage[key];
          }
        });
        const analyticsCount = userAnalytics.leadAnalytics.stage;
        Object.keys(tempCounts).forEach((key) => {
          if (analyticsCount[key]) {
            tempCounts[key] += analyticsCount[key];
          }
        });
      });

      setStage(tempCounts);
      setTotal(total);
    }
  }, [apiAnalyticsData]);

  useEffect(() => {
    console.log('apply Contacts', contactsFilter);
    console.log('apply tasks:', tasksFilter);
    console.log('apply calls:', callsFilter);
  }, [contactsFilter, tasksFilter, callsFilter]);

  useEffect(() => {
    if (clearFilter === true) {
      setContactsFilter({});
      setTasksFilter({});
      setCallsFilter({});
      setClearFilter(false);
    }
  }, [clearFilter]);
  return (
    <div className={styles.parent}>
      {load && <Loading />}
      <div className={styles.navContainer}>
        <AnalyticsSideNav
          panel={panel}
          setClearFilter={(data) => setClearFilter(data)}
        />
      </div>

      <div className={styles.container}>
        <div className={styles.panel}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <button
              className={styles.contactsButton}
              onClick={() => setPanel('Contacts')}
            >
              Contacts Analytics
            </button>
            {panel === 'Contacts' && <div className={styles.line}></div>}
          </div>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <button
              className={styles.tasksButton}
              onClick={() => setPanel('Tasks')}
            >
              Tasks Analytics
            </button>
            {panel === 'Tasks' && <div className={styles.line}></div>}
          </div>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <button
              className={styles.callsButton}
              onClick={() => setPanel('Call Logs')}
            >
              Call Logs Analytics
            </button>
            {panel === 'Call Logs' && <div className={styles.line}></div>}
          </div>
        </div>
        <div className={styles.type}>
          <button
            className={styles.associate}
            style={
              source
                ? {
                    backgroundColor: '#fff',
                    color: '#279f9f',
                    borderColor: '#279f9f',
                    border: 'solid',
                    borderWidth: '1px',
                  }
                : {}
            }
            onClick={() => {
              setAnalyticsType('associate');
              setSource(false);
            }}
          >
            Associate
          </button>
          <button
            className={styles.source}
            style={
              source
                ? {}
                : {
                    backgroundColor: '#fff',
                    color: '#279f9f',
                    borderColor: '#279f9f',
                    border: 'solid',
                    borderWidth: '1px',
                  }
            }
            onClick={() => {
              setAnalyticsType('source');
              setSource(true);
            }}
          >
            Source
          </button>
          <div className={styles.filters}>
            <KanbanViewFilter
              panel={panel}
              setCallsFilter={(data) => setCallsFilter(data)}
              setContactsFilter={(data) => setContactsFilter(data)}
              setTaskFilters={(data) => setTasksFilter(data)}
              clearFilter={clearFilter}
              usersList={usersEmail}
            />
          </div>
        </div>
        <div className={styles.topContainer}>
          <div className={styles.countContainer}>
            <p className={styles.contHeading}>Total No. of Leads</p>
            <p className={styles.countText}>{total}</p>
          </div>

          <AnalyticsCountContainer
            stage={stage}
            history={history}
            filter={filterType}
            taskStage={taskStage}
            taskFilter={passOnTaskFilters}
            leadFilter={contactsFilter}
            otherContactFilter={passOnContactFilters}
            otherTaskFilter={tasksFilter}
          />

          <div className={styles.countContainer}>
            <p className={styles.contHeading}>Team Wise</p>

            <Checkbox
              onChange={(e) => {
                setChecked(!checked);
              }}
            />
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <button
                className={styles.filterBox}
                onClick={() => setShowFilter(!showFilter)}
              >
                <FaFilter
                  color={'#279F9F'}
                  size="10"
                  style={{ marginRight: '10px' }}
                />
                Date Filter
              </button>
              {showFilter === true && (
                <div className={styles.filter}>
                  <AnalyticsFilter
                    setFilterState={(data) => setFilterType(data)}
                    filterUsed={filterType}
                    showFilter={(data) => setShowFilter(data)}
                  />
                </div>
              )}
            </div>
          </div>
        </div>

        <div className={styles.graphs}>
          {panel === 'Contacts' && (
            <FeedbackGraph
              analyticsData={apiAnalyticsData}
              usersList={usersList}
              checked={checked}
              teamsData={teamsData}
              history={history}
              filter={filterType}
              source={source}
              taskFilter={passOnTaskFilters}
              leadFilter={contactsFilter}
            />
          )}

          {panel === 'Call Logs' && (
            <Trends
              state={state}
              interestedState={interestedState}
              history={history}
              filter={filterType}
              analyticsData={apiCallLogAnalyticsData}
              usersList={usersList}
              checked={checked}
              teamsData={teamsData}
              source={source}
              taskFilters={passOnTaskFilters}
              leadFilters={passOnContactFilters}
              callFilters={callsFilter}
            />
          )}

          {panel === 'Contacts' && (
            <>
              <InterestedBudget
                analyticsData={apiAnalyticsData}
                usersList={usersList}
                checked={checked}
                teamsData={teamsData}
                history={history}
                filter={filterType}
                source={source}
                taskFilter={passOnTaskFilters}
                leadFilter={contactsFilter}
              />

              <InterestedProject
                analyticsData={apiAnalyticsData}
                usersList={usersList}
                checked={checked}
                teamsData={teamsData}
                history={history}
                filter={filterType}
                source={source}
                taskFilter={passOnTaskFilters}
                leadFilter={contactsFilter}
              />
              <InterestedLocation
                analyticsData={apiAnalyticsData}
                usersList={usersList}
                checked={checked}
                teamsData={teamsData}
                history={history}
                filter={filterType}
                source={source}
                taskFilter={passOnTaskFilters}
                leadFilter={contactsFilter}
              />

              <InterestedPropertyType
                analyticsData={apiAnalyticsData}
                usersList={usersList}
                checked={checked}
                teamsData={teamsData}
                history={history}
                filter={filterType}
                source={source}
                taskFilter={passOnTaskFilters}
                leadFilter={contactsFilter}
              />

              <InterestedPropertyStage
                analyticsData={apiAnalyticsData}
                usersList={usersList}
                checked={checked}
                teamsData={teamsData}
                history={history}
                filter={filterType}
                source={source}
                taskFilter={passOnTaskFilters}
                leadFilter={contactsFilter}
              />
              <NotIntReason
                analyticsData={apiAnalyticsData}
                usersList={usersList}
                checked={checked}
                teamsData={teamsData}
                history={history}
                filter={filterType}
                source={source}
                taskFilter={passOnTaskFilters}
                leadFilter={contactsFilter}
              />

              <LostReason
                analyticsData={apiAnalyticsData}
                usersList={usersList}
                checked={checked}
                teamsData={teamsData}
                history={history}
                filter={filterType}
                source={source}
                taskFilter={passOnTaskFilters}
                leadFilter={contactsFilter}
              />
            </>
          )}

          {panel === 'Tasks' && (
            <>
              {' '}
              <CompletedTask
                analyticsData={taskAnalyticsData}
                usersList={usersList}
                checked={checked}
                teamsData={teamsData}
                history={history}
                filter={filterType}
                source={source}
                passOnFilters={passOnContactFilters}
                tasksFilters={tasksFilter}
              />
              <OverdueTask
                analyticsData={taskAnalyticsData}
                usersList={usersList}
                checked={checked}
                teamsData={teamsData}
                history={history}
                filter={filterType}
                source={source}
                passOnFilters={passOnContactFilters}
                tasksFilters={tasksFilter}
              />
              <PendingTask
                analyticsData={taskAnalyticsData}
                usersList={usersList}
                checked={checked}
                teamsData={teamsData}
                history={history}
                filter={filterType}
                source={source}
                passOnFilters={passOnContactFilters}
                tasksFilters={tasksFilter}
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    user: state.user.data,
    organizationUsers: state.organizationUsers.data,
    teamLeadUsers: state.teamLeadUsers.data,
    role: state.user.role,
    branchUsers: state.branchUsers.data,
  };
};
export default connect(mapStateToProps)(LeadManagerAnalytics);

import React, { useEffect, useState, useRef, FunctionComponent } from "react";
import styles from "./AddUsers.module.css";
import TextInput from "../../Components/TextInput/TextInput";
import { FaUser } from "react-icons/fa";
import Dropdown from "../../Components/DropDown/Dropdown";
import AddModal from "../../Components/Modals/AddModal/AddModal";
import { emailValidate } from "../../Values/validatorsnew";
import { connect, useDispatch } from "react-redux";
import {
  updateTeams,
  updateDesignations,
  createUser,
} from "../../Services/organizations";
import Loading from "../../Components/Loading/Loading";
import { showSnackbarAction } from "../../Redux/actions";
import { AiOutlineClose } from "react-icons/ai";
import {
  updateTeamsAction,
  updateDesignationsAction,
} from "../../Redux/actions";
import { useLocation } from "react-router-dom";
import { EditUserDetails, fetchProfilesData } from "../../Services/users";
import { getFilterBranch } from "../../Values/utils";

type props = {
  user: any;
  history: any;
  organization: any;
  organizationId: string;
  organizationUsers: any[];
  branchList: any[];
};

const AddUsers: FunctionComponent<props> = ({
  history,
  organization,
  organizationId,
  organizationUsers,
  branchList,
}) => {
  const location: any = useLocation();

  const firstNameRef: any = useRef();
  const lastNameRef: any = useRef();
  const numberRef: any = useRef();
  const emaileRef: any = useRef();
  const branchRef: any = useRef();
  const [teamSelected, setTeamSelected] = useState<any>({
    label: "Select",
    value: "Select",
  });
  const [designationSelected, setDesignationSelected] = useState<any>({
    label: "Select",
    value: "Select",
  });
  const [reportingTo, setReportingTo] = useState<any>({
    label: "Select",
    value: "Select",
  });
  const [profileSelected, setProfileSelected] = useState<any>({
    label: "Select",
    value: "Select",
  });
  const [brancheSelected, setBranchSelected] = useState<any>({
    label: "Select",
    value: "Select",
  });

  const [teams, setTeams] = useState<any[]>([]);
  const [branches, setBranches] = useState<any[]>([]);
  const [designations, setDesignations] = useState<any[]>([]);
  const [profile, setProfile] = useState<string[]>([]);
  const [reporting, setReporting] = useState<any>({
    label: "",
    value: "",
  });

  const [load, setLoad] = useState(false);
  const [showTeamModal, setShowTeamModal] = useState(false);
  const [showBranchModal, setShowBranchModal] = useState(false);
  const [showDesigModal, setShowDesigModal] = useState(false);
  const [reportingToProfile, setReportingToProfile] = useState<any>({
    label: "",
    value: "",
  });

  const dispatcher = useDispatch();
  useEffect(() => {
    setTeams(organization.teams);
    setDesignations(organization.designations);
    setBranches(branchList);

    const unSubProfile = fetchProfilesData((data: string[]) =>
      setProfile(data)
    );

    return () => {
      unSubProfile();
    };
    // eslint-disable-next-line
  }, [organization]);

  useEffect(() => {
    if (location.state !== undefined) {
      setDesignationSelected({
        label: location.state.detail.designation,
        value: location.state.detail.designation,
      });
      setProfileSelected({
        label: location.state.detail.profile,
        value: location.state.detail.profile,
      });
    }
    // eslint-disable-next-line
  }, [designations, profile]);

  useEffect(() => {
    let data: any = [{}];
    let items: any = [{}];
    if (organizationUsers) {
      organizationUsers.forEach((item: any, index: number) => {
        data.push({
          label: `${item.user_first_name} ${item.user_last_name} (${item.user_email})`,
          value: item.user_email,
        });
        items.push({
          label: item.profile,
          value: item.user_email,
        });
      });

      setReporting(data);
      setReportingToProfile(items);
    }
  }, [organizationUsers]);

  useEffect(() => {
    window.onbeforeunload = function () {
      return "Are you really want to perform the action?";
    };
  }, []);

  const handleCheck = () => {
    if (firstNameRef.current.value === "") {
      dispatcher(showSnackbarAction("Enter First Name!!", "error"));
      return false;
    } else if (lastNameRef.current.value === "") {
      dispatcher(showSnackbarAction("Enter Last Name!!", "error"));
      return false;
    } else if (numberRef.current.value === "") {
      dispatcher(showSnackbarAction("Enter Phone Number!!", "error"));
      return false;
    } else if (emaileRef.current.value === "") {
      dispatcher(showSnackbarAction("Enter Email!!", "error"));
      return false;
    } else if (
      teamSelected.label === "Select" ||
      teamSelected.label === "+ New Team"
    ) {
      dispatcher(showSnackbarAction("Select Team!!", "error"));
      return false;
    } else if (
      designationSelected.label === "Select" ||
      designationSelected.label === "+ New Designation"
    ) {
      dispatcher(showSnackbarAction("Select Designation!!", "error"));
      return false;
    } else if (profileSelected.label === "Select") {
      dispatcher(showSnackbarAction("Select Profile!!", "error"));
      return false;
    } else if (reporting.length === 0) {
      return true;
    } else if (reportingTo.value === "Select") {
      dispatcher(showSnackbarAction("Select Reporting to!!", "error"));
      return false;
    } else if (
      brancheSelected.label === "Select" ||
      brancheSelected.label === "+ New Branch"
    ) {
      dispatcher(showSnackbarAction("Enter Branch!!", "error"));
      return false;
    } else {
      return true;
    }
  };

  const create = () => {
    const val = handleCheck();
    let activeUsers = organizationUsers.filter(
      (item) => item.status === "ACTIVE"
    );
    const reporting_to = reporting.length === 0 ? "" : reportingTo.value;
    if (val) {
      if (!profile.includes(profileSelected.value)) {
        dispatcher(showSnackbarAction("Invalid Profile!!", "error"));
      } else {
        setLoad(true);
        createUser(
          emaileRef.current.value,
          firstNameRef.current.value,
          lastNameRef.current.value,
          teamSelected.label,
          reporting_to,
          numberRef.current.value,
          designationSelected.label,
          brancheSelected.label === "Select" ||
            brancheSelected.label === "+ New Branch"
            ? ""
            : brancheSelected.value,
          activeUsers.length < Number(organization.no_of_employees)
            ? "ACTIVE"
            : "INACTIVE",
          profileSelected.value,
          dispatcher,
          history,
          organizationId,
          organization.admin_first_name + " " + organization.admin_last_name,
          organization.country,
          organization.state,
          false,
          (data: boolean) => setLoad(data),
          reportingToProfile
        );
      }
    }
  };

  const EditCheck = () => {
    if (firstNameRef.current.value === "") {
      firstNameRef.current.value = location.state.detail.user_first_name;
    }
    if (lastNameRef.current.value === "") {
      lastNameRef.current.value = location.state.detail.user_last_name;
    }
    if (numberRef.current.value === "") {
      numberRef.current.value = location.state.detail.contact_no;
    }
    if (
      designationSelected.label === "Select" ||
      teamSelected.label === "+ New Designation"
    ) {
      designationSelected.label = location.state.detail.designation;
    }
    if (profileSelected.label === "Select") {
      profileSelected.label = location.state.detail.profile;
    }
    if (reportingTo.value === "Select") {
      reportingTo.value = location.state.detail.reporting_to;
    }
    if (
      teamSelected.label === "Select" ||
      teamSelected.label === "+ New Team"
    ) {
      teamSelected.label = location.state.detail.team;
    }

    return true;
  };

  const edit = () => {
    const val = EditCheck();
    const reporting_to = reporting.length === 0 ? "" : reportingTo.value;
    if (val) {
      if (!profile.includes(profileSelected.value)) {
        dispatcher(showSnackbarAction("Invalid Profile!!", "error"));
      } else {
        console.log(profileSelected.value);
        setLoad(true);
        EditUserDetails(
          location.state.detail.uid,
          firstNameRef.current.value,
          lastNameRef.current.value,
          numberRef.current.value,
          designationSelected.label,
          profileSelected.value,
          reporting_to,
          teamSelected.label,
          dispatcher,
          numberRef.current.value !== location.state.detail.contact_no
            ? true
            : false,
          (data: boolean) => setLoad(data),
          history,
          reportingToProfile,
          brancheSelected.label === "Select" ||
            brancheSelected.label === "+ New Branch"
            ? location.state.detail.branch
              ? location.state.detail.branch
              : ""
            : brancheSelected.label
        );
      }
    }
  };

  return (
    <>
      <div className={styles.parent}>
        {organizationUsers === undefined && <Loading />}
        {load && <Loading />}
        <div className={styles.child}>
          <div className={styles.headerView}>
            <p className={styles.heading}>
              {location.state !== undefined
                ? "Edit User Details"
                : "Add User Details"}
            </p>
            <AiOutlineClose
              className={styles.closeIcon}
              size={25}
              onClick={() => history.replace("/")}
            />
          </div>
          <div className={styles.box2}>
            <div className={styles.divide}>
              <div className={styles.title}>
                <p className={styles.one}>FIRST NAME</p>
                <p className={styles.two}>*</p>
              </div>
              <div>
                <TextInput
                  title={"Enter first name"}
                  style={{ backgroundColor: "#fff" }}
                  ref={firstNameRef}
                  disabled={false}
                  value={
                    location.state ? location.state.detail.user_first_name : ""
                  }
                >
                  <FaUser color={"#808080"} />
                </TextInput>
              </div>
            </div>

            <div className={styles.divide}>
              <div className={styles.title}>
                <p className={styles.one}>LAST NAME</p>
                <p className={styles.two}>*</p>
              </div>
              <div>
                <TextInput
                  title={"Enter last name"}
                  style={{ backgroundColor: "#fff" }}
                  ref={lastNameRef}
                  disabled={false}
                  value={
                    location.state ? location.state.detail.user_last_name : ""
                  }
                >
                  <FaUser color={"#808080"} />
                </TextInput>
              </div>
            </div>
          </div>

          <div className={styles.box2}>
            <div className={styles.divide}>
              <div className={styles.title}>
                <p className={styles.one}>MOBILE NUMBER</p>
                <p className={styles.two}>*</p>
              </div>
              <div>
                <TextInput
                  title={"Enter Number"}
                  style={{ backgroundColor: "#fff" }}
                  ref={numberRef}
                  disabled={false}
                  value={location.state ? location.state.detail.contact_no : ""}
                />
              </div>
            </div>

            <div className={styles.divide}>
              <div className={styles.title}>
                <p className={styles.one}>EMAIL ID</p>
                <p className={styles.two}>*</p>
              </div>
              <div>
                <TextInput
                  title={"Enter Email"}
                  style={
                    location.state !== undefined
                      ? { backgroundColor: "#f5f5f5" }
                      : { backgroundColor: "#fff" }
                  }
                  validator={emailValidate}
                  ref={emaileRef}
                  disabled={location.state !== undefined ? true : false}
                  value={location.state ? location.state.detail.user_email : ""}
                />
              </div>
            </div>
          </div>

          <div className={styles.box2}>
            <div className={styles.divide}>
              <div className={styles.title}>
                <p className={styles.one}>TEAM</p>
                <p className={styles.two}>*</p>
              </div>
              <div style={{ marginTop: "7px" }}>
                <Dropdown
                  title={" + New Team"}
                  option={teams}
                  setDetails={() => setShowTeamModal(true)}
                  selectedValue={teamSelected}
                  setSelectedValue={(value) => {
                    setTeamSelected(value);
                  }}
                  disable={false}
                />
              </div>
            </div>

            <div className={styles.divide}>
              <div className={styles.title}>
                <p className={styles.one}>REPORTING TO</p>
                <p className={styles.two}>*</p>
              </div>
              <div style={{ marginTop: "7px" }}>
                <Dropdown
                  choices={reporting}
                  selectedValue={reportingTo}
                  setSelectedValue={(value) => setReportingTo(value)}
                  disable={false}
                />
              </div>
            </div>
          </div>

          <div className={styles.box2}>
            <div className={styles.divide}>
              <div className={styles.title}>
                <p className={styles.one}>DESIGNATION</p>
                <p className={styles.two}>*</p>
              </div>
              <div style={{ marginTop: "7px" }}>
                <Dropdown
                  title={" + New Designation"}
                  option={designations}
                  setDetails={() => setShowDesigModal(true)}
                  selectedValue={designationSelected}
                  setSelectedValue={(value) => setDesignationSelected(value)}
                />
              </div>
            </div>
            <div className={styles.divide}>
              <div className={styles.title}>
                <p className={styles.one}>PROFILE</p>
                <p className={styles.two}>*</p>
              </div>
              <div style={{ marginTop: "7px" }}>
                <Dropdown
                  option={profile}
                  selectedValue={profileSelected}
                  setSelectedValue={(value) => setProfileSelected(value)}
                />
              </div>
            </div>
          </div>

          <div className={styles.box2}>
            <div className={styles.divide}>
              <div className={styles.title}>
                <p className={styles.one}>BRANCH</p>
                <p className={styles.two}>*</p>
              </div>
              <div>
                <div style={{ marginTop: "7px" }}>
                  <Dropdown
                    title={" + New Branch"}
                    option={branches}
                    setDetails={() => setShowBranchModal(true)}
                    selectedValue={brancheSelected}
                    setSelectedValue={(value) => {
                      setBranchSelected(value);
                    }}
                    disable={false}
                  />
                </div>
              </div>
            </div>
          </div>
          {location.state !== undefined ? (
            <button className={styles.button} onClick={() => edit()}>
              Update
            </button>
          ) : (
            <button className={styles.button} onClick={() => create()}>
              Submit
            </button>
          )}
        </div>
      </div>

      {showTeamModal && (
        <>
          <AddModal
            show={showTeamModal}
            heading={"Create Team"}
            subHeading={"Enter Name"}
            close={() => setShowTeamModal(false)}
            onSave={(value: string) => {
              updateTeams(
                value,
                setLoad,
                organizationId,
                (data: boolean) => {
                  dispatcher(updateTeamsAction(value));
                  setShowTeamModal(data);
                  setTeamSelected({ label: value, value });
                },
                dispatcher
              );
            }}
          />
        </>
      )}
      {showBranchModal && (
        <>
          <AddModal
            show={showBranchModal}
            heading={"Create Branch"}
            subHeading={"Enter Name"}
            close={() => setShowBranchModal(false)}
            onSave={(value: string) => {
              setBranchSelected({ label: value, value });
              setShowBranchModal(false);
            }}
          />
        </>
      )}
      {showDesigModal && (
        <>
          <AddModal
            show={showDesigModal}
            heading={"Create Designation"}
            subHeading={"Enter designation"}
            close={() => setShowDesigModal(false)}
            onSave={(value) => {
              updateDesignations(
                value,
                setLoad,
                organizationId,
                (data: boolean) => {
                  dispatcher(updateDesignationsAction(value));
                  setShowDesigModal(data);
                  setDesignationSelected({ label: value, value });
                },
                dispatcher
              );
            }}
          />
        </>
      )}
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    organization: state.organization,
    organizationId: state.organization.id,
    organizationUsers: state.organizationUsers.data,
    branchList: state.branchList.data,
  };
};

export default connect(mapStateToProps)(AddUsers);

import React, { useState, useEffect, FunctionComponent } from "react";
import Topbar from "../../Components/TopBar/TopBar";
import commonStyle from "../common.module.css";
import { connect, useDispatch } from "react-redux";
import { APIDATA_COLUMNS } from "../../Values/tables";

import { filterStatus, searchAPIItem } from "../../Values/utils";
import moment from "moment";

import CustomTable from "../../Components/CustomTable/CustomTable";

import ApiModal from "../../Components/Modals/ApiModal/ApiModal";
import { fetchApiData, fetchApiFilterData } from "../../Services/organizations";

type props = {
  history: any;
  organizationId: any;
  user: any;
};

const ApiDataPanel: FunctionComponent<props> = ({
  history,
  organizationId,
  user,
}) => {
  const [searchQuery, setsearchQuery] = useState("");
  const [columnModal, setColumnModal] = useState(false);
  const [filterData, setFilterData] = useState<any[] | undefined>(undefined);
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const [selectedRowsData, setSelectedRowsData] = useState<any[]>([]);
  const [apiModal, setApiModal] = useState(false);
  const [API, setAPI] = useState<any[] | undefined>(undefined);
  const [status, setStatus] = useState("ALL");
  const [temporaryData, setTemporaryData] = useState<any[]>([]);
  const [apiFilter, setApiFilter] = useState("7");

  useEffect(() => {
    if (user.organization_id) {
      if (apiFilter === "7") {
        let prevDate = moment().subtract(7, "day").toDate();
        let currentDate = moment().toDate();
        fetchApiFilterData(
          user.organization_id,
          (val) => setAPI(val),
          prevDate,
          currentDate
        );
      } else if (apiFilter === "30") {
        let prevDate = moment().subtract(30, "day").toDate();
        let currentDate = moment().toDate();
        fetchApiFilterData(
          user.organization_id,
          (val) => setAPI(val),
          prevDate,
          currentDate
        );
      } else {
        fetchApiData(user.organization_id, (val) => setAPI(val));
      }
    }
  }, [user, apiFilter]);

  useEffect(() => {
    if (API) {
      let data: any[] = [];
      API.forEach((item) => {
        data.push(item);
      });
      setFilterData(data);
    }
  }, [API]);

  //   useEffect(() => {
  //     if (status === "ALL") {
  //       if (API) {
  //         const data = searchAPIItem(API, searchQuery);
  //         setTemporaryData(data);
  //         setFilterData(data);
  //       }
  //     } else {
  //       const data = searchAPIItem(temporaryData, searchQuery);
  //       setFilterData(data);
  //     }
  //     // eslint-disable-next-line
  //   }, [searchQuery, API]);

  //   useEffect(() => {
  //     if (searchQuery === "") {
  //       if (API) {
  //         const data = filterStatus(API, status);
  //         setTemporaryData(data);
  //         setFilterData(data);
  //       }
  //     } else {
  //       const data = filterStatus(temporaryData, status);
  //       setFilterData(data);
  //     }
  //     // eslint-disable-next-line
  //   }, [status, API]);
  return (
    <>
      <div className={commonStyle.topBar}>
        <Topbar
          history={history}
          title={"Add Api Data"}
          path={"/addUsers"}
          onChange={(val) => setsearchQuery(val)}
          filterText="Status"
          setColumnModal={(data) => setColumnModal(data)}
          show={false}
          showStatusBox={true}
          setApiModal={(data) => setApiModal(data)}
          setStatus={(status) => setStatus(status)}
          status={status}
          setApiFilter={(data) => setApiFilter(data)}
          apiFilterData={apiFilter}
        />
      </div>
      <div className={commonStyle.parent}>
        <CustomTable
          tableColumns={APIDATA_COLUMNS}
          tableRows={filterData}
          selectedRows={selectedRows}
          setSelectedRows={(data) => setSelectedRows(data)}
          tableType="API"
          showColumnModal={columnModal}
          hideColumnModal={() => setColumnModal(false)}
          selectedRowsData={selectedRowsData}
          setSelectedRowsData={(data) => setSelectedRowsData(data)}
          setSearchedItem={(val) => setsearchQuery(val)}
        />
      </div>

      

      {apiModal === true && (
        <ApiModal open={apiModal} close={() => setApiModal(false)} />
      )}
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    organizationId: state.organization.id,
    organizationUsers: state.organizationUsers.data,
    user: state.user.data,
  };
};
export default connect(mapStateToProps)(ApiDataPanel);

import React, {
  FunctionComponent,
  useEffect,
  useState,
  useRef,
} from "react";
import styles from "../BulkEditModal/BulkEditModal.module.css";
import Modal from "react-modal";
import { IoIosClose } from "react-icons/io";

import Dropdown from "../../DropDown/Dropdown";
import {
  addNoteFirebase,
  changeLeadStage,
  fetchConstants,
} from "../../../Services/contacts";

import Loading from "../../Loading/Loading";
import { useDispatch } from "react-redux";
import { showSnackbarAction } from "../../../Redux/actions";
import { useHistory } from "react-router-dom";

type props = {
  notes: any[];
  contactDetail: any;
  close: () => void;
  open: boolean;
  userEmail?: string;
};

const NotesModal: FunctionComponent<props> = ({
  notes,
  contactDetail,
  close,
  open,
  userEmail,
}) => {
  const [load, setLoad] = useState(false);
  const [note, setNote] = useState("");

  const noteRef: any = useRef();
  const dispatcher = useDispatch();
  const history = useHistory();
  const onSubmit = () => {
    if (noteRef.current.value === "") {
      dispatcher(
        showSnackbarAction("Please Add Note!!", "error")
      );
    } else {
      addNoteFirebase(
        contactDetail.contactId,
        notes,
        noteRef.current.value,
        (data) => setLoad(data),
        dispatcher,
        close,
        userEmail
      );
    }
  };
  return (
    <Modal
      className={styles.parent}
      isOpen={open}
      shouldCloseOnOverlayClick={true}
      overlayClassName={styles.overlay}
      shouldCloseOnEsc={true}
      onRequestClose={close}
    >
      {load === true && <Loading />}
      <div className={styles.firstDiv}>
        <p className={styles.contactForm}>
          Create New Note
        </p>
        <div className={styles.cross} onClick={close}>
          <IoIosClose size={35} color={"#808080"} />
        </div>
      </div>
      <div className={styles.line}></div>

      <div className={styles.box2}>
        <div
          className={styles.divide}
          style={{ width: "100%" }}
        >
          <div className={styles.title}>
            <p className={styles.one}>Add Note</p>
            <p className={styles.two}></p>
          </div>
          <div style={{ marginTop: "7px" }}>
            <textarea
              style={{
                width: "100%",
                resize: "none",
                marginTop: "17px",
              }}
              rows={4}
              cols={10}
              placeholder={"Enter Note"}
              ref={noteRef}
              onChange={(e) => setNote(e.target.value)}
              value={note}
            ></textarea>
          </div>
        </div>
      </div>

      <button
        className={styles.apply}
        style={{ marginTop: "auto" }}
        onClick={onSubmit}
      >
        Save
      </button>
    </Modal>
  );
};

export default NotesModal;

import React from "react";
import CustomSnackbar from "./Components/Snackbar";
import GlobalState from "./Redux/GlobalState";
import AppRoutes from "./Routes";
import "./themes.css";

function App() {
  return (
    <GlobalState>
      <>
        <AppRoutes />
        <CustomSnackbar />
      </>
    </GlobalState>
  );
}

export default App;

import React, { FunctionComponent } from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import styles from '../../CommonStyles/CommonGraphStyles.module.css';
import { setClearFilter, updateStage } from '../../Redux/actions';
import {
  apiTaskDrillDown,
  taskDrillDownTableOrg,
} from '../../Services/analytics';
import { changeDateForRoute } from '../../Services/contacts';
import { sortAnalytics } from '../../Values/utils';
import * as ReactBootStrap from 'react-bootstrap';
type props = {
  data: any;
  type: 'Pending' | 'Completed' | 'Overdue';
  heading: string;
  usersList: any;
  checked: boolean;
  teamsData: any;
  style?: any;
  contacts: any;
  organizationUsers: any;
  filter: 'MTD' | 'YTD' | 'PM' | 'All' | 'CUSTOM';
  history: any;
  teamLeadUsers: any;
  user: any;
  source: boolean;
  taskFilter: any;
  leadFilter: any;
};

const TaskBarTable: FunctionComponent<props> = ({
  data,
  type,
  heading,
  usersList,
  checked,
  teamsData,
  style,
  contacts,
  organizationUsers,
  filter,
  history,
  teamLeadUsers,
  user,
  source,
  taskFilter,
  leadFilter,
}) => {
  const [table, setTable] = useState<any>({});
  const [tableData, setTableData] = useState<any[]>([]);
  const [allContacts, setAllContacts] = useState<any[] | undefined>(undefined);

  useEffect(() => {
    let contact: any[] = [];
    Object.keys(contacts.data).forEach((key) => {
      contact = [...contact, ...contacts.data[key]];
    });
    setAllContacts(contact);
  }, [contacts.data]);
  useEffect(() => {
    let temp: { [key: string]: {} } = {};

    Object.keys(data).forEach((key: any) => {
      temp[key] = {
        Meeting: data[key].totalTaskCount[type]['Meeting']
          ? data[key].totalTaskCount[type]['Meeting']
          : 0,
        'Call Back': data[key].totalTaskCount[type]['Call Back']
          ? data[key].totalTaskCount[type]['Call Back']
          : 0,
        'Site Visit': data[key].totalTaskCount[type]['Site Visit']
          ? data[key].totalTaskCount[type]['Site Visit']
          : 0,
      };
    });

    setTable(temp);
  }, [data]);

  useEffect(() => {
    const tempList: any[] = [];

    Object.keys(table).forEach((key) => {
      tempList.push({
        name: checked ? key : source ? key : usersList[key],
        uid: key,
        meeting: table[key]['Meeting'],
        siteVisit: table[key]['Site Visit'],
        callBack: table[key]['Call Back'],
        Total:
          table[key]['Meeting'] +
          table[key]['Site Visit'] +
          table[key]['Call Back'],
      });
    });
    let teamTempData: any[] = [];
    if (checked) {
      tempList.forEach((user) => {
        const team = teamsData[user.name];
        let existing = teamTempData.filter((item) => item.name === team);
        if (existing.length !== 0) {
          Object.keys(user).forEach((key) => {
            if (key !== 'name') {
              if (existing[0][key]) {
                existing[0][key] += user[key];
              } else {
                existing[0][key] = user[key];
              }
            }
          });
        } else {
          teamTempData.push({ ...user, name: team });
        }
      });
    }
    const finalData = checked ? teamTempData : source ? tempList : tempList;
    let gt: { [key: string]: any } = {};
    finalData.forEach((item) => {
      Object.keys(item).forEach((key) => {
        if (key !== 'name') {
          if (gt[key]) {
            gt[key] += item[key];
          } else {
            gt[key] = item[key];
          }
        }
      });
    });

    finalData.sort(sortAnalytics);
    finalData.unshift({ name: 'Grand Total', ...gt });
    setTableData(finalData.filter((item) => item.name !== undefined));
  }, [table, checked, source]);
  const dispatcher = useDispatch();

  // when a user clicks on the table data which is not zero, it opens up a drill down page, the api data for which is stored in the local storage and the necessary call is made from that page
  const getDrillDownData = async (key: string, item: any) => {
    var keyF: any;
    for (var i = 0; i < localStorage.length; i++) {
      keyF = localStorage.key(i);
      if (keyF !== 'columns') {
        localStorage.removeItem(key);
      }
    }
    dispatcher(setClearFilter(true));
    if (allContacts) {
      let mapReportingTo: { [key: string]: string } = {};
      if (organizationUsers) {
        organizationUsers.forEach((item: any) => {
          if (mapReportingTo[item.uid] === undefined) {
            mapReportingTo[item.uid] = item.reporting_to;
          }
        });
      }
      const drillDownData = await apiTaskDrillDown(
        filter,
        item.name === 'Grand Total' ? user.uid : item.uid,
        type,
        key,
        taskFilter,
        leadFilter,
        item.name === 'Grand Total' ? true : false
      );
      localStorage.setItem('taskDrilldownData', JSON.stringify(drillDownData));
      window.open('/taskDrilldownData', '_blank');
    }
  };
  return (
    <>
      <div className={styles.detailsBox} style={style}>
        <p className={styles.graphHeading}>{heading}</p>
        <ReactBootStrap.Table>
          {/* <div className={styles.typeBox}> */}
          <thead>
            <tr>
              <th scope="col">User Name</th>

              <th scope="col">Total</th>

              <th scope="col">Meeting</th>

              <th scope="col">Call Back</th>
              <th scope="col">Site Visit</th>
            </tr>
          </thead>
          <tr></tr>
          <tr></tr>
          <tr></tr>
          <tr></tr>
          <tr></tr>
          <tbody>
            {/* </div> */}
            {/* <div className={styles.graphLine} style={{ width: 530 }} /> */}
            {/* <div className={styles.scrollPart}> */}
            {tableData.map((item, index) => (
              <>
                <tr key={index}>
                  {/* <div className={styles.typeBox}> */}
                  <th
                    style={{
                      fontSize: '0.8rem',
                      fontWeight: 'bold',
                      width: '130px',
                    }}
                  >
                    {item.name}
                  </th>

                  <th
                    style={{
                      fontSize: '0.8rem',
                      fontWeight: 'normal',
                      width: '130px',
                      cursor: item.Total !== 0 ? 'pointer' : 'not-allowed',
                    }}
                    onClick={() =>
                      item.Total !== 0 && getDrillDownData('Total', item)
                    }
                  >
                    {item.Total}
                  </th>
                  <th
                    style={{
                      fontSize: '0.8rem',
                      fontWeight: 'normal',
                      width: '130px',
                      cursor: item.meeting !== 0 ? 'pointer' : 'not-allowed',
                    }}
                    onClick={() =>
                      item.meeting !== 0 && getDrillDownData('Meeting', item)
                    }
                  >
                    {item.meeting}
                  </th>
                  <th
                    style={{
                      fontSize: '0.8rem',
                      fontWeight: 'normal',
                      width: '130px',
                      cursor: item.callBack !== 0 ? 'pointer' : 'not-allowed',
                    }}
                    onClick={() =>
                      item.callBack !== 0 && getDrillDownData('Call Back', item)
                    }
                  >
                    {item.callBack}
                  </th>
                  <th
                    style={{
                      fontSize: '0.8rem',
                      fontWeight: 'normal',
                      width: '130px',
                      cursor: item.siteVisit !== 0 ? 'pointer' : 'not-allowed',
                    }}
                    onClick={() =>
                      item.siteVisit !== 0 &&
                      getDrillDownData('Site Visit', item)
                    }
                  >
                    {item.siteVisit}
                  </th>
                  {/* </div> */}
                  {/* <div className={styles.graphLine} style={{ width: 530 }} />  */}
                </tr>
                <tr></tr>
                <tr></tr>
                <tr></tr>
                <tr></tr>
              </>
            ))}

            {/* </div> */}
          </tbody>
        </ReactBootStrap.Table>
      </div>
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    contacts: state.contacts,
    organizationUsers: state.organizationUsers.data,
    teamLeadUsers: state.teamLeadUsers.data,
    user: state.user.data,
  };
};
export default connect(mapStateToProps)(TaskBarTable);

import React, { FunctionComponent, useState, useRef, useEffect } from "react";
import styles from "./AttachmentModal.module.css";
import Modal from "react-modal";
import { IoMdDocument } from "react-icons/io";
import { connect, useDispatch } from "react-redux";
import { FaUserCircle } from "react-icons/fa";
import { uploadUserImage } from "../../../Services/users";
import Loading from "../../Loading/Loading";

import { uploadAttachment } from "../../../Services/contacts";
type props = {
  open: boolean;
  close: () => void;
  title: string;
  contactDetail: any;
  type: string;
  attachmentsList: any[];
  user: any;
};
const AttachmentModal: FunctionComponent<props> = ({
  user,
  open,
  close,
  title,
  contactDetail,
  type,
  attachmentsList,
}) => {
  const dispatcher = useDispatch();
  const attachmentRef: any = useRef();
  const [load, setLoad] = useState(false);
  const [file, setFile] = useState<any>();

  const upload = async (data: FileList | null) => {
    if (data) {
      setLoad(true);
      uploadAttachment(
        contactDetail.contactId,
        data[0],
        attachmentsList,
        type,
        data[0]?.name,
        dispatcher,
        (data) => setLoad(data),
        close
      );
    } else {
      console.log("data is not uploaded");
    }
  };

  return (
    <Modal
      isOpen={open}
      className={styles.parent}
      overlayClassName={styles.overlay}
      onRequestClose={close}
      shouldCloseOnOverlayClick={true}
      shouldCloseOnEsc={true}
    >
      {load && <Loading />}
      <div className={styles.headingContainer}>
        <p className={styles.heading}>{title}</p>
      </div>
      <div
        className={styles.dragBox}
        onDragOver={(e) => {
          e.preventDefault();
        }}
        onDrop={(e) => {
          e.preventDefault();
          upload(e.dataTransfer.files);
          setLoad(true);
        }}
      >
        <IoMdDocument size={80} color={"#808080"} />
        <p>Drag And Drop</p>
      </div>

      <div className={styles.uploadContainer}>
        <div className={styles.selectBox}>
          <label htmlFor="file-input" className={styles.uploadButton}>
            {title}
          </label>
          <input
            id="file-input"
            className={styles.select}
            type="file"
            accept=".png,.jpg,.jpeg,.pdf,.docs"
            onChange={(e) => upload(e.target.files)}
          />
        </div>
        <div className={styles.buttonContainer}>
          <button className={styles.button} onClick={() => close()}>
            Cancel
          </button>
        </div>
      </div>
    </Modal>
  );
};

const mapStateToProps = (state: any) => {
  return {
    user: state.user.data,
  };
};

export default connect(mapStateToProps)(AttachmentModal);

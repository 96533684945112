import React, { FunctionComponent } from "react";
import styles from "./AnalyticsFilter.module.css";

import AnalyticsFilterValue from "./AnalyticsFilterValue";

type props = {
  setFilterState: (data: "All" | "YTD" | "PM" | "MTD" | "T" | "Y") => void;
  filterUsed: string;
  showFilter: (data: boolean) => void;
};
const AnalyticsFilter: FunctionComponent<props> = ({
  setFilterState,
  filterUsed,
  showFilter,
}) => {
  return (
    <div className={styles.parent}>
      <div className={styles.headContainer}>
        <p className={styles.heading}>Filter Value</p>
        <p className={styles.resetText} onClick={() => setFilterState("All")}>
          Reset
        </p>
      </div>

      <AnalyticsFilterValue
        title="Month Till Date"
        setFilterState={(data) => setFilterState(data)}
        filterType="MTD"
        filterUsed={filterUsed}
        showFilter={(data) => showFilter(data)}
      />
      <AnalyticsFilterValue
        title="Previous Month"
        setFilterState={(data) => setFilterState(data)}
        filterType="PM"
        filterUsed={filterUsed}
        showFilter={(data) => showFilter(data)}
      />

      <AnalyticsFilterValue
        title="All"
        setFilterState={(data) => setFilterState(data)}
        filterType="All"
        filterUsed={filterUsed}
        showFilter={(data) => showFilter(data)}
      />

      <AnalyticsFilterValue
        title="Today"
        setFilterState={(data) => setFilterState(data)}
        filterType="T"
        filterUsed={filterUsed}
        showFilter={(data) => showFilter(data)}
      />

      <AnalyticsFilterValue
        title="Yesterday"
        setFilterState={(data) => setFilterState(data)}
        filterType="Y"
        filterUsed={filterUsed}
        showFilter={(data) => showFilter(data)}
      />

      {/* <div className={styles.box}>
        <p className={styles.filterText}>Custom</p>

        <input type="radio" />
      </div>
      <div className={styles.line}></div>

      <div className={styles.box}>
        <p className={styles.filterText}>Today</p>
        <input type="radio" />
      </div>
      <div className={styles.line}></div>

      <div className={styles.box}>
        <p className={styles.filterText}>Yesterday</p>
        <input type="radio" />
      </div>
      <div className={styles.line}></div>

      <div className={styles.box}>
        <p className={styles.filterText}>This Week</p>
        <input type="radio" />
      </div>
      <div className={styles.line}></div>

      <div className={styles.box}>
        <p className={styles.filterText}>Last Week</p>
        <input type="radio" />
      </div>
      <div className={styles.line}></div>

      <div className={styles.box}>
        <p className={styles.filterText}>This Month</p>
        <input type="radio" />
      </div>
      <div className={styles.line}></div>

      <div className={styles.box}>
        <p className={styles.filterText}>Last Month</p>
        <input type="radio" />
      </div>
      <div className={styles.line}></div>

      <div className={styles.box}>
        <p className={styles.filterText}>Last 90 days</p>
        <input type="radio" />
      </div>
      <div className={styles.line}></div>

      <div className={styles.box}>
        <p className={styles.filterText}>Last 6 Months</p>
        <input type="radio" />
      </div>
      <div className={styles.line}></div>

      <div className={styles.box}>
        <p className={styles.filterText}>This Year</p>
        <input type="radio" />
      </div>
      <div className={styles.line}></div>

      <div className={styles.box}>
        <p className={styles.filterText}>Last Year</p>
        <input type="radio" />
      </div>
      <div className={styles.line}></div>

      <div className={styles.customFilterContainer}>
        <p className={styles.heading}>Custom Filter Option</p>
      </div>

      <div className={styles.box}>
        <p className={styles.filterText}>Assigned From Date</p>
        <GoCalendar color="#279f9f" />
      </div>
      <div className={styles.line}></div>

      <div className={styles.box}>
        <p className={styles.filterText}>Assigned To Date</p>
        <GoCalendar color="#279f9f" />
      </div>
      <div className={styles.line}></div> */}
    </div>
  );
};

export default AnalyticsFilter;

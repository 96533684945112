import React, { FunctionComponent, useEffect } from "react";
import { useState } from "react";
import { Pie } from "react-chartjs-2";
import { connect, useDispatch } from "react-redux";
import styles from "../../CommonStyles/CommonGraphStyles.module.css";
import { setClearFilter, updateStage } from "../../Redux/actions";
import { getDrillDownChartOrg } from "../../Services/analytics";
import {
  changeDateForRoute,
  fetchDrillDownAllContacts,
} from "../../Services/contacts";
import { properFormat } from "../../Values/utils";

type props = {
  analyticsData: any;
  heading: string;
  type:
    | "project"
    | "location"
    | "budget"
    | "stage"
    | "propertyType"
    | "propertyStage";
  color: string[];
  GraphType: any;
  style?: any;
  role: any;
  history: any;
  filter: "MTD" | "YTD" | "PM" | "All" | "CUSTOM";
  contacts: any;
  user: any;
  organizationUsers: any[];
  teamLeadUsers: any;
  branchUsers: any;
};

const IntBarChartOrg: FunctionComponent<props> = ({
  analyticsData,
  heading,
  type,
  color,
  GraphType,
  style,
  role,
  history,
  filter,
  contacts,
  user,
  organizationUsers,
  teamLeadUsers,
  branchUsers,
}) => {
  const [data, setData] = useState<any>({});
  const [allContacts, setAllContacts] = useState<any[] | undefined>(undefined);

  useEffect(() => {
    let contact: any[] = [];
    Object.keys(contacts.data).forEach((key) => {
      contact = [...contact, ...contacts.data[key]];
    });
    setAllContacts(contact);
  }, [contacts.data]);

  useEffect(() => {
    const temp: { [key: string]: number } = {};
    Object.values(analyticsData).forEach((analytics: any) => {
      Object.keys(analytics.leadAnalytics[type]).forEach((key) => {
        if (temp[key]) {
          temp[key] += analytics.leadAnalytics[type][key];
        } else {
          temp[key] = analytics.leadAnalytics[type][key];
        }
      });
    });
    delete temp["Pending"];
    delete temp["Completed"];
    setData(temp);
  }, [analyticsData]);

  const chartData = {
    labels: Object.keys(data).map((item) => properFormat(item)),
    datasets: [
      {
        label: `Interested ${type}s Summary`,
        backgroundColor: color,
        borderColor: color,
        barThickness: 25,
        borderWidth: 2,
        data: Object.values(data),
      },
    ],
  };
  const dispatcher = useDispatch();

  const barOptions = {
    title: {
      display: false,
      fontSize: 20,
    },
    responsive: true,
    maintainAspectRatio: false,
    onClick: async (e: any, item: any) => {
      if (allContacts) {
        let mapReportingTo: { [key: string]: string } = {};
        if (organizationUsers) {
          organizationUsers.forEach((item: any) => {
            if (mapReportingTo[item.uid] === undefined) {
              mapReportingTo[item.uid] = item.reporting_to;
            }
          });
        }
        if (user.profile === "Team Lead") {
          let drillDownData = await getDrillDownChartOrg(
            allContacts.filter((item) => teamLeadUsers.includes(item.uid)),
            filter,
            type === "propertyStage"
              ? "property_stage"
              : type === "propertyType"
              ? "property_type"
              : type,
            Object.keys(data)[item[0].index],
            user.organization_id
          );

          drillDownData.forEach((item: any) => {
            if (item.uid === "") {
              item["reporting_to"] = "";
            } else {
              item["reporting_to"] = mapReportingTo[item.uid]
                ? mapReportingTo[item.uid]
                : "";
            }
          });
          localStorage.setItem(
            "drilldownData",
            changeDateForRoute(drillDownData)
          );
          window.open("/drilldownData", "_blank");
        } else {
          let drillDownData = await getDrillDownChartOrg(
            allContacts,
            filter,
            type === "propertyStage"
              ? "property_stage"
              : type === "propertyType"
              ? "property_type"
              : type,
            Object.keys(data)[item[0].index],
            user.organization_id
          );

          drillDownData.forEach((item: any) => {
            if (item.uid === "") {
              item["reporting_to"] = "";
            } else {
              item["reporting_to"] = mapReportingTo[item.uid]
                ? mapReportingTo[item.uid]
                : "";
            }
          });
          localStorage.setItem(
            "drilldownData",
            changeDateForRoute(drillDownData)
          );
          window.open("/drilldownData", "_blank");
        }
      }
    },
  };

  const pieOptions = {
    title: {
      display: false,
      fontSize: 20,
    },
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      datalabels: {
        formatter: (value: any, ctx: any) => {
          let sum = 0;
          let dataArr = ctx.chart.data.datasets[0].data;
          dataArr.map((data: any) => {
            sum += data;
          });
          let percentage = Math.floor((value * 100) / sum) + "%";
          return percentage;
        },
        color: "#fff",
      },
    },
    onClick: async (e: any, item: any) => {
      var key: any;
      for (var i = 0; i < localStorage.length; i++) {
        key = localStorage.key(i);

        if (key !== "columns") {
          localStorage.removeItem(key);
        }
      }

      dispatcher(setClearFilter(true));
      if (allContacts) {
        let mapReportingTo: { [key: string]: string } = {};
        if (organizationUsers) {
          organizationUsers.forEach((item: any) => {
            if (mapReportingTo[item.uid] === undefined) {
              mapReportingTo[item.uid] = item.reporting_to;
            }
          });
        }
        if (user.profile === "Team Lead") {
          let drillDownData = await getDrillDownChartOrg(
            allContacts.filter((item) => teamLeadUsers.includes(item.uid)),
            filter,
            type === "propertyStage"
              ? "property_stage"
              : type === "propertyType"
              ? "property_type"
              : type,
            Object.keys(data)[item[0].index],
            user.organization_id,
            teamLeadUsers
          );

          drillDownData.forEach((item: any) => {
            if (item.uid === "") {
              item["reporting_to"] = "";
            } else {
              item["reporting_to"] = mapReportingTo[item.uid]
                ? mapReportingTo[item.uid]
                : "";
            }
          });

          localStorage.setItem(
            "drilldownData",
            changeDateForRoute(drillDownData)
          );
          window.open("/drilldownData", "_blank");
        } else {
          let drillDownData = await getDrillDownChartOrg(
            allContacts,
            filter,
            type === "propertyStage"
              ? "property_stage"
              : type === "propertyType"
              ? "property_type"
              : type,
            Object.keys(data)[item[0].index],
            user.organization_id,
            [],
            user.branchPermission ? branchUsers : []
          );

          drillDownData.forEach((item: any) => {
            if (item.uid === "") {
              item["reporting_to"] = "";
            } else {
              item["reporting_to"] = mapReportingTo[item.uid]
                ? mapReportingTo[item.uid]
                : "";
            }
          });

          localStorage.setItem(
            "drilldownData",
            changeDateForRoute(drillDownData)
          );
          window.open("/drilldownData", "_blank");
        }
      }
    },
  };

  return (
    <div className={styles.graphBox} style={style}>
      <GraphType
        data={chartData}
        options={GraphType === Pie ? pieOptions : barOptions}
        type="line"
      />
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    user: state.user.data,
    role: state.user.role,
    contacts: state.contacts,
    organizationUsers: state.organizationUsers.data,
    teamLeadUsers: state.teamLeadUsers.data,
    branchUsers: state.branchUsers.data,
  };
};
export default connect(mapStateToProps)(IntBarChartOrg);

import React, { useState, useEffect, FunctionComponent } from "react";
import Topbar from "../../Components/TopBar/TopBar";
import commonStyle from "../common.module.css";
import { connect, useDispatch } from "react-redux";
import { NEWS_COLUMNS } from "../../Values/tables";

import { filterStatus, searchAPIItem } from "../../Values/utils";

import CustomTable from "../../Components/CustomTable/CustomTable";

import { fetchNews } from "../../Services/organizations";
import NewsModal from "../../Components/Modals/NewsModal/NewsModal";

type props = {
  history: any;
  organizationId: any;
};

const NewsPanel: FunctionComponent<props> = ({ history, organizationId }) => {
  const [searchQuery, setsearchQuery] = useState("");
  const [columnModal, setColumnModal] = useState(false);
  const [filterData, setFilterData] = useState<any[] | undefined>(undefined);
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const [selectedRowsData, setSelectedRowsData] = useState<any[]>([]);
  const [newsModal, setNewsModal] = useState(false);
  const [news, setNews] = useState<any[] | undefined>(undefined);
  const [status, setStatus] = useState("ALL");
  const [temporaryData, setTemporaryData] = useState<any[]>([]);

  useEffect(() => {
    if (organizationId) {
      fetchNews(organizationId, (val) => setNews(val));
    }
  }, [organizationId]);

  useEffect(() => {
    if (news) {
      let data: any[] = [];
      news.forEach((item) => {
        data.push(item);
      });
      setFilterData(data);
    }
  }, [news]);
  return (
    <>
      <div className={commonStyle.topBar}>
        <Topbar
          history={history}
          title={"Add News Link"}
          path={"/addUsers"}
          onChange={(val) => setsearchQuery(val)}
          filterText="Status"
          setColumnModal={(data) => setColumnModal(data)}
          show={false}
          showStatusBox={true}
          setApiModal={(data) => setNewsModal(data)}
          setStatus={(status) => setStatus(status)}
          status={status}
        />
      </div>
      <div className={commonStyle.parent}>
        <CustomTable
          tableColumns={NEWS_COLUMNS}
          tableRows={filterData}
          selectedRows={selectedRows}
          setSelectedRows={(data) => setSelectedRows(data)}
          tableType="NEWS"
          showColumnModal={columnModal}
          hideColumnModal={() => setColumnModal(false)}
          selectedRowsData={selectedRowsData}
          setSelectedRowsData={(data) => setSelectedRowsData(data)}
          setSearchedItem={(val) => setsearchQuery(val)}
        />
      </div>

      {newsModal === true && (
        <NewsModal open={newsModal} close={() => setNewsModal(false)} />
      )}
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    organizationId: state.organization.id,
  };
};
export default connect(mapStateToProps)(NewsPanel);

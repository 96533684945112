import React, { useState, useEffect, FunctionComponent } from "react";
import Topbar from "../../Components/TopBar/TopBar";
import commonStyle from "../common.module.css";
import { connect, useDispatch } from "react-redux";
import { API_COLUMNS } from "../../Values/tables";

import { filterStatus, searchAPIItem } from "../../Values/utils";

import CustomTable from "../../Components/CustomTable/CustomTable";
import ApiModal from "../../Components/Modals/ApiModal/ApiModal";
import { fetchApi } from "../../Services/organizations";

type props = {
  history: any;
  organizationId: any;
};

const ApiPanel: FunctionComponent<props> = ({ history, organizationId }) => {
  const [searchQuery, setsearchQuery] = useState("");
  const [columnModal, setColumnModal] = useState(false);
  const [filterData, setFilterData] = useState<any[] | undefined>(undefined);
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const [selectedRowsData, setSelectedRowsData] = useState<any[]>([]);
  const [apiModal, setApiModal] = useState(false);
  const [API, setAPI] = useState<any[] | undefined>(undefined);
  const [status, setStatus] = useState("ALL");
  const [temporaryData, setTemporaryData] = useState<any[]>([]);
  const [edit, setEdit] = useState(false);
  const [editData, setEditData] = useState();

  useEffect(() => {
    if (organizationId) {
      fetchApi(organizationId, (val) => setAPI(val));
    }
  }, [organizationId]);

  useEffect(() => {
    if (API) {
      let data: any[] = [];
      API.forEach((item) => {
        data.push(item);
      });
      setFilterData(data);
    }
  }, [API]);

  useEffect(() => {
    if (status === "ALL") {
      if (API) {
        const data = searchAPIItem(API, searchQuery);
        setTemporaryData(data);
        setFilterData(data);
      }
    } else {
      const data = searchAPIItem(temporaryData, searchQuery);
      setFilterData(data);
    }
    // eslint-disable-next-line
  }, [searchQuery, API]);

  useEffect(() => {
    if (searchQuery === "") {
      if (API) {
        const data = filterStatus(API, status);
        setTemporaryData(data);
        setFilterData(data);
      }
    } else {
      const data = filterStatus(temporaryData, status);
      setFilterData(data);
    }
    // eslint-disable-next-line
  }, [status, API]);
  return (
    <>
      <div className={commonStyle.topBar}>
        <Topbar
          history={history}
          title={"Add Api"}
          path={"/addUsers"}
          onChange={(val) => setsearchQuery(val)}
          filterText="Status"
          setColumnModal={(data) => setColumnModal(data)}
          show={false}
          showStatusBox={true}
          setApiModal={(data) => setApiModal(data)}
          setStatus={(status) => setStatus(status)}
          status={status}
        />
      </div>
      <div className={commonStyle.parent}>
        <CustomTable
          tableColumns={API_COLUMNS}
          tableRows={filterData}
          selectedRows={selectedRows}
          setSelectedRows={(data) => setSelectedRows(data)}
          tableType="API"
          showColumnModal={columnModal}
          hideColumnModal={() => setColumnModal(false)}
          selectedRowsData={selectedRowsData}
          setSelectedRowsData={(data) => setSelectedRowsData(data)}
          setSearchedItem={(val) => setsearchQuery(val)}
          setApiModal={(data) => setApiModal(data)}
          setApiEdit={(data) => setEdit(data)}
          setApiData={(data) => setEditData(data)}
        />
      </div>

      {apiModal === true && (
        <ApiModal open={apiModal} close={() => setApiModal(false)} />
      )}
      {apiModal === true && edit === true && (
        <ApiModal
          open={apiModal}
          close={() => setApiModal(false)}
          edit={edit}
          editData={editData}
          editClose={() => setEdit(false)}
        />
      )}
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    organizationId: state.organization.id,
    organizationUsers: state.organizationUsers.data,
  };
};
export default connect(mapStateToProps)(ApiPanel);

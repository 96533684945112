import moment from 'moment';
import { firestore } from '../Firebase';
import { datesField } from '../Values/tables';

export const sortDates = (a: string, b: string) => {
  if (moment(a, 'DD-MM-YYYY') > moment(b, 'DD-MM-YYYY')) {
    return 1;
  } else {
    return -1;
  }
};

const convertFeildName = (name: string) => {
  let convertedName = '';
  if (name === 'Call Back') {
    convertedName = 'CALLBACK';
  } else {
    convertedName = name.toUpperCase();
  }
  return convertedName;
};
export const getDrillDownChartOrg = async (
  contacts: any[],
  filter: 'MTD' | 'PM' | 'All' | 'YTD' | 'CUSTOM' | 'Y' | 'T',
  feild:
    | 'stage'
    | 'budget'
    | 'location'
    | 'project'
    | 'property_type'
    | 'property_stage'
    | 'not_int_reason'
    | 'lost_reason'
    | 'callLogs',
  feildValue: string,
  organization_id: string,
  teamUsers?: any[],
  branchUsers?: any[]
) => {
  let drillDownData: any[] = [];
  if (filter === 'MTD') {
    const startDate = moment().startOf('month').toDate();
    const endDate = new Date();
    if (feild === 'stage') {
      feildValue = convertFeildName(feildValue);
      if (feildValue === 'FRESH') {
        drillDownData = contacts.filter(
          (contact) => contact[feild] === feildValue
        );
      } else if (
        feildValue === 'NOT INTERESTED' ||
        feildValue === 'WON' ||
        feildValue === 'LOST'
      ) {
        drillDownData = [];
        const data = await firestore
          .collection('contacts')
          .where('organization_id', '==', organization_id)
          .where(feild, '==', feildValue)
          .where('stage_change_at', '>', startDate)
          .where('stage_change_at', '<', endDate)
          .get();
        data.docs.forEach((doc) => {
          drillDownData.push(doc.data());
        });
        if (teamUsers && teamUsers.length !== 0) {
          drillDownData = drillDownData.filter((item) =>
            teamUsers.includes(item.uid)
          );
        }
        if (branchUsers && branchUsers.length !== 0) {
          drillDownData = drillDownData.filter((item) =>
            branchUsers.includes(item.uid)
          );
        }
      } else {
        drillDownData = contacts.filter(
          (contact) =>
            contact.stage_change_at?.toDate &&
            contact[feild] === feildValue &&
            contact.stage_change_at.toDate() > startDate &&
            contact.stage_change_at.toDate() < endDate
        );
      }
    } else if (feild === 'not_int_reason' || feild === 'lost_reason') {
      drillDownData = [];
      const data = await firestore
        .collection('contacts')
        .where('organization_id', '==', organization_id)
        .where(feild, '==', feildValue)
        .where('stage_change_at', '>', startDate)
        .where('stage_change_at', '<', endDate)
        .get();
      data.docs.forEach((doc) => {
        drillDownData.push(doc.data());
      });
      if (teamUsers && teamUsers.length !== 0) {
        drillDownData = drillDownData.filter((item) =>
          teamUsers.includes(item.uid)
        );
      }
      if (branchUsers && branchUsers.length !== 0) {
        drillDownData = drillDownData.filter((item) =>
          branchUsers.includes(item.uid)
        );
      }
    } else {
      drillDownData = contacts.filter(
        (contact) =>
          contact.stage_change_at?.toDate &&
          contact.stage === 'INTERESTED' &&
          contact[feild] === feildValue &&
          contact.stage_change_at.toDate() > startDate &&
          contact.stage_change_at.toDate() < endDate
      );
    }
  } else if (filter === 'PM') {
    const prevMonth = moment().subtract(1, 'M');
    const prevMonthStart = moment(prevMonth).startOf('month').toDate();
    const prevMonthEnd = moment(prevMonth).endOf('month').toDate();

    if (feild === 'stage') {
      feildValue = convertFeildName(feildValue);
      if (feildValue === 'FRESH') {
        drillDownData = contacts.filter(
          (contact) => contact[feild] === feildValue
        );
      } else if (
        feildValue === 'NOT INTERESTED' ||
        feildValue === 'WON' ||
        feildValue === 'LOST'
      ) {
        drillDownData = [];
        const data = await firestore
          .collection('contacts')
          .where('organization_id', '==', organization_id)
          .where(feild, '==', feildValue)
          .where('stage_change_at', '>', prevMonthStart)
          .where('stage_change_at', '<', prevMonthEnd)
          .get();
        data.docs.forEach((doc) => {
          drillDownData.push(doc.data());
        });
        if (teamUsers && teamUsers.length !== 0) {
          drillDownData = drillDownData.filter((item) =>
            teamUsers.includes(item.uid)
          );
        }
        if (branchUsers && branchUsers.length !== 0) {
          drillDownData = drillDownData.filter((item) =>
            branchUsers.includes(item.uid)
          );
        }
      } else {
        drillDownData = contacts.filter(
          (contact) =>
            contact.stage_change_at?.toDate &&
            contact[feild] === feildValue &&
            contact.stage_change_at.toDate() > prevMonthStart &&
            contact.stage_change_at.toDate() < prevMonthEnd
        );
      }
    } else if (feild === 'not_int_reason' || feild === 'lost_reason') {
      drillDownData = [];
      const data = await firestore
        .collection('contacts')
        .where('organization_id', '==', organization_id)
        .where(feild, '==', feildValue)
        .where('stage_change_at', '>', prevMonthStart)
        .where('stage_change_at', '<', prevMonthEnd)
        .get();
      data.docs.forEach((doc) => {
        drillDownData.push(doc.data());
      });
      if (teamUsers && teamUsers.length !== 0) {
        drillDownData = drillDownData.filter((item) =>
          teamUsers.includes(item.uid)
        );
      }
      if (branchUsers && branchUsers.length !== 0) {
        drillDownData = drillDownData.filter((item) =>
          branchUsers.includes(item.uid)
        );
      }
    } else {
      drillDownData = contacts.filter(
        (contact) =>
          contact.stage_change_at?.toDate &&
          contact.stage === 'INTERESTED' &&
          contact[feild] === feildValue &&
          contact.stage_change_at.toDate() > prevMonthStart &&
          contact.stage_change_at.toDate() < prevMonthEnd
      );
    }
  } else if (filter === 'Y') {
    console.log(filter);
    const prevDay = moment().subtract(1, 'day');
    const prevDayStart = moment(prevDay).startOf('day').toDate();
    const prevDayEnd = moment(prevDay).endOf('day').toDate();

    if (feild === 'stage') {
      feildValue = convertFeildName(feildValue);
      if (feildValue === 'FRESH') {
        drillDownData = contacts.filter(
          (contact) => contact[feild] === feildValue
        );
      } else if (
        feildValue === 'NOT INTERESTED' ||
        feildValue === 'WON' ||
        feildValue === 'LOST'
      ) {
        drillDownData = [];
        const data = await firestore
          .collection('contacts')
          .where('organization_id', '==', organization_id)
          .where(feild, '==', feildValue)
          .where('stage_change_at', '>', prevDayStart)
          .where('stage_change_at', '<', prevDayEnd)
          .get();
        data.docs.forEach((doc) => {
          drillDownData.push(doc.data());
        });
        if (teamUsers && teamUsers.length !== 0) {
          drillDownData = drillDownData.filter((item) =>
            teamUsers.includes(item.uid)
          );
        }
        if (branchUsers && branchUsers.length !== 0) {
          drillDownData = drillDownData.filter((item) =>
            branchUsers.includes(item.uid)
          );
        }
      } else {
        drillDownData = contacts.filter(
          (contact) =>
            contact.stage_change_at?.toDate &&
            contact[feild] === feildValue &&
            contact.stage_change_at.toDate() > prevDayStart &&
            contact.stage_change_at.toDate() < prevDayEnd
        );
      }
    } else if (feild === 'not_int_reason' || feild === 'lost_reason') {
      drillDownData = [];
      const data = await firestore
        .collection('contacts')
        .where('organization_id', '==', organization_id)
        .where(feild, '==', feildValue)
        .where('stage_change_at', '>', prevDayStart)
        .where('stage_change_at', '<', prevDayEnd)
        .get();
      data.docs.forEach((doc) => {
        drillDownData.push(doc.data());
      });
      if (teamUsers && teamUsers.length !== 0) {
        drillDownData = drillDownData.filter((item) =>
          teamUsers.includes(item.uid)
        );
      }
      if (branchUsers && branchUsers.length !== 0) {
        drillDownData = drillDownData.filter((item) =>
          branchUsers.includes(item.uid)
        );
      }
    } else {
      drillDownData = contacts.filter(
        (contact) =>
          contact.stage_change_at?.toDate &&
          contact.stage === 'INTERESTED' &&
          contact[feild] === feildValue &&
          contact.stage_change_at.toDate() > prevDayStart &&
          contact.stage_change_at.toDate() < prevDayEnd
      );
    }
  } else if (filter === 'T') {
    const startDate = moment().startOf('day').toDate();
    const endDate = moment().endOf('day').toDate();
    if (feild === 'stage') {
      feildValue = convertFeildName(feildValue);
      if (feildValue === 'FRESH') {
        drillDownData = contacts.filter(
          (contact) => contact[feild] === feildValue
        );
      } else if (
        feildValue === 'NOT INTERESTED' ||
        feildValue === 'WON' ||
        feildValue === 'LOST'
      ) {
        drillDownData = [];
        const data = await firestore
          .collection('contacts')
          .where('organization_id', '==', organization_id)
          .where(feild, '==', feildValue)
          .where('stage_change_at', '>', startDate)
          .where('stage_change_at', '<', endDate)
          .get();
        data.docs.forEach((doc) => {
          drillDownData.push(doc.data());
        });
        if (teamUsers && teamUsers.length !== 0) {
          drillDownData = drillDownData.filter((item) =>
            teamUsers.includes(item.uid)
          );
        }
        if (branchUsers && branchUsers.length !== 0) {
          drillDownData = drillDownData.filter((item) =>
            branchUsers.includes(item.uid)
          );
        }
      } else {
        drillDownData = contacts.filter(
          (contact) =>
            contact.stage_change_at?.toDate &&
            contact[feild] === feildValue &&
            contact.stage_change_at.toDate() > startDate &&
            contact.stage_change_at.toDate() < endDate
        );
      }
    } else if (feild === 'not_int_reason' || feild === 'lost_reason') {
      drillDownData = [];
      const data = await firestore
        .collection('contacts')
        .where('organization_id', '==', organization_id)
        .where(feild, '==', feildValue)
        .where('stage_change_at', '>', startDate)
        .where('stage_change_at', '<', endDate)
        .get();
      data.docs.forEach((doc) => {
        drillDownData.push(doc.data());
      });
      if (teamUsers && teamUsers.length !== 0) {
        drillDownData = drillDownData.filter((item) =>
          teamUsers.includes(item.uid)
        );
      }
      if (branchUsers && branchUsers.length !== 0) {
        drillDownData = drillDownData.filter((item) =>
          branchUsers.includes(item.uid)
        );
      }
    } else {
      drillDownData = contacts.filter(
        (contact) =>
          contact.stage_change_at?.toDate &&
          contact.stage === 'INTERESTED' &&
          contact[feild] === feildValue &&
          contact.stage_change_at.toDate() > startDate &&
          contact.stage_change_at.toDate() < endDate
      );
    }
  } else if (filter === 'YTD') {
  } else {
    if (feild === 'stage') {
      if (
        feildValue === 'NOT INTERESTED' ||
        feildValue === 'WON' ||
        feildValue === 'LOST'
      ) {
        drillDownData = [];
        const data = await firestore
          .collection('contacts')
          .where('organization_id', '==', organization_id)
          .where(feild, '==', feildValue)
          .get();
        data.docs.forEach((doc) => {
          drillDownData.push(doc.data());
        });
        if (teamUsers && teamUsers.length !== 0) {
          drillDownData = drillDownData.filter((item) =>
            teamUsers.includes(item.uid)
          );
        }
        if (branchUsers && branchUsers.length !== 0) {
          drillDownData = drillDownData.filter((item) =>
            branchUsers.includes(item.uid)
          );
        }
      } else {
        feildValue = convertFeildName(feildValue);
        drillDownData = contacts.filter(
          (contact) => contact[feild] === feildValue
        );
      }
    } else if (feild === 'not_int_reason' || feild === 'lost_reason') {
      drillDownData = [];
      const data = await firestore
        .collection('contacts')
        .where('organization_id', '==', organization_id)
        .where(feild, '==', feildValue)
        .get();
      data.docs.forEach((doc) => {
        drillDownData.push(doc.data());
      });
      if (teamUsers && teamUsers.length !== 0) {
        drillDownData = drillDownData.filter((item) =>
          teamUsers.includes(item.uid)
        );
      }
      if (branchUsers && branchUsers.length !== 0) {
        drillDownData = drillDownData.filter((item) =>
          branchUsers.includes(item.uid)
        );
      }
    } else {
      drillDownData = contacts.filter(
        (contact) =>
          contact.stage === 'INTERESTED' && contact[feild] === feildValue
      );
    }
  }
  console.log(drillDownData);
  return drillDownData;
};

export const totalDrillDownData = async (
  contacts: any[],
  filter: 'MTD' | 'PM' | 'All' | 'YTD' | 'CUSTOM' | 'Y' | 'T',
  feild:
    | 'stage'
    | 'budget'
    | 'location'
    | 'project'
    | 'property_type'
    | 'property_stage'
    | 'not_int_reason'
    | 'lost_reason'
    | 'callLogs',
  organization_id: string
) => {
  let drillDownData: any[] = [];
  if (filter === 'MTD') {
    const startDate = moment().startOf('month').toDate();
    const endDate = new Date();
    if (feild === 'stage') {
      contacts.map((contact) => {
        if (contact.stage === 'FRESH') {
          drillDownData.push(contact);
        } else {
          if (
            contact.stage_change_at?.toDate &&
            contact.stage_change_at.toDate() > startDate &&
            contact.stage_change_at.toDate() < endDate
          ) {
            drillDownData.push(contact);
          }
        }
      });
    } else if (feild === 'not_int_reason' || feild === 'lost_reason') {
      let stage = '';
      if (feild === 'not_int_reason') {
        stage = 'NOT INTERESTED';
      } else {
        stage = 'LOST';
      }
      drillDownData = [];
      const data = await firestore
        .collection('contacts')
        .where('organization_id', '==', organization_id)
        .where('stage', '==', stage)
        .where('stage_change_at', '>', startDate)
        .where('stage_change_at', '<', endDate)
        .get();
      data.docs.forEach((doc) => {
        drillDownData.push(doc.data());
      });
    } else {
      drillDownData = contacts.filter(
        (contact) =>
          contact.stage_change_at?.toDate &&
          contact.stage === 'INTERESTED' &&
          contact[feild] &&
          contact.stage_change_at.toDate() > startDate &&
          contact.stage_change_at.toDate() < endDate
      );
    }
  } else if (filter === 'T') {
    const startDate = moment().startOf('day').toDate();
    const endDate = moment().endOf('day').toDate();

    if (feild === 'stage') {
      contacts.map((contact) => {
        if (contact.stage === 'FRESH') {
          drillDownData.push(contact);
        } else {
          if (
            contact.stage_change_at?.toDate &&
            contact.stage_change_at.toDate() > startDate &&
            contact.stage_change_at.toDate() < endDate
          ) {
            drillDownData.push(contact);
          }
        }
      });
    } else if (feild === 'not_int_reason' || feild === 'lost_reason') {
      let stage = '';
      if (feild === 'not_int_reason') {
        stage = 'NOT INTERESTED';
      } else {
        stage = 'LOST';
      }
      drillDownData = [];
      const data = await firestore
        .collection('contacts')
        .where('organization_id', '==', organization_id)
        .where('stage', '==', stage)
        .where('stage_change_at', '>', startDate)
        .where('stage_change_at', '<', endDate)
        .get();
      data.docs.forEach((doc) => {
        drillDownData.push(doc.data());
      });
    } else {
      drillDownData = contacts.filter(
        (contact) =>
          contact.stage_change_at?.toDate &&
          contact.stage === 'INTERESTED' &&
          contact[feild] &&
          contact.stage_change_at.toDate() > startDate &&
          contact.stage_change_at.toDate() < endDate
      );
    }
  } else if (filter === 'PM') {
    const prevMonth = moment().subtract(1, 'M');
    const prevMonthStart = moment(prevMonth).startOf('month').toDate();
    const prevMonthEnd = moment(prevMonth).endOf('month').toDate();
    if (feild === 'stage') {
      contacts.map((contact) => {
        if (contact.stage === 'FRESH') {
          drillDownData.push(contact);
        } else {
          if (
            contact.stage_change_at?.toDate &&
            contact.stage_change_at.toDate() > prevMonthStart &&
            contact.stage_change_at.toDate() < prevMonthEnd
          ) {
            drillDownData.push(contact);
          }
        }
      });
    } else if (feild === 'not_int_reason' || feild === 'lost_reason') {
      let stage = '';
      if (feild === 'not_int_reason') {
        stage = 'NOT INTERESTED';
      } else {
        stage = 'LOST';
      }
      drillDownData = [];
      const data = await firestore
        .collection('contacts')
        .where('organization_id', '==', organization_id)
        .where('stage', '==', stage)
        .where('stage_change_at', '>', prevMonthStart)
        .where('stage_change_at', '<', prevMonthEnd)
        .get();
      data.docs.forEach((doc) => {
        drillDownData.push(doc.data());
      });
    } else {
      drillDownData = contacts.filter(
        (contact) =>
          contact.stage_change_at?.toDate &&
          contact.stage === 'INTERESTED' &&
          contact[feild] &&
          contact.stage_change_at.toDate() > prevMonthStart &&
          contact.stage_change_at.toDate() < prevMonthEnd
      );
    }
  } else if (filter === 'Y') {
    const prevDay = moment().subtract(1, 'day');
    const prevDayStart = moment(prevDay).startOf('day').toDate();
    const prevDayEnd = moment(prevDay).endOf('day').toDate();

    if (feild === 'stage') {
      contacts.map((contact) => {
        if (contact.stage === 'FRESH') {
          drillDownData.push(contact);
        } else {
          if (
            contact.stage_change_at?.toDate &&
            contact.stage_change_at.toDate() > prevDayStart &&
            contact.stage_change_at.toDate() < prevDayEnd
          ) {
            drillDownData.push(contact);
          }
        }
      });
    } else if (feild === 'not_int_reason' || feild === 'lost_reason') {
      let stage = '';
      if (feild === 'not_int_reason') {
        stage = 'NOT INTERESTED';
      } else {
        stage = 'LOST';
      }
      drillDownData = [];
      const data = await firestore
        .collection('contacts')
        .where('organization_id', '==', organization_id)
        .where('stage', '==', stage)
        .where('stage_change_at', '>', prevDayStart)
        .where('stage_change_at', '<', prevDayEnd)
        .get();
      data.docs.forEach((doc) => {
        drillDownData.push(doc.data());
      });
    } else {
      drillDownData = contacts.filter(
        (contact) =>
          contact.stage_change_at?.toDate &&
          contact.stage === 'INTERESTED' &&
          contact[feild] &&
          contact.stage_change_at.toDate() > prevDayStart &&
          contact.stage_change_at.toDate() < prevDayEnd
      );
    }
  } else if (filter === 'YTD') {
  } else {
    if (feild === 'stage') {
      drillDownData = contacts.filter((contact) => contact[feild]);
    } else if (feild === 'not_int_reason' || feild === 'lost_reason') {
      let stage = '';
      if (feild === 'not_int_reason') {
        stage = 'NOT INTERESTED';
      } else {
        stage = 'LOST';
      }
      drillDownData = [];
      const data = await firestore
        .collection('contacts')
        .where('organization_id', '==', organization_id)
        .where('stage', '==', stage)
        .get();
      data.docs.forEach((doc) => {
        drillDownData.push(doc.data());
      });
      drillDownData = contacts.filter((contact) => contact[feild]);
    } else {
      drillDownData = contacts.filter(
        (contact) => contact.stage === 'INTERESTED' && contact[feild]
      );
    }
  }

  return drillDownData;
};

export const drillDownTableOrg = async (
  contacts: any[],
  filter: 'MTD' | 'PM' | 'All' | 'YTD' | 'CUSTOM' | 'Y' | 'T',
  feild:
    | 'stage'
    | 'budget'
    | 'location'
    | 'project'
    | 'property_type'
    | 'property_stage'
    | 'not_int_reason'
    | 'lost_reason'
    | 'callLogs',
  feildValue: string,
  teamWise: boolean,
  type: 'SuperTotal' | 'Total' | 'Normal',
  sales: boolean,
  name: string,
  organizationUsers: any,
  organization_id: string,
  uid?: string,
  teamUsers?: any[],
  branchUsers?: any[]
) => {
  console.log(feild, feildValue, type);
  let drillDownData;
  let uidList: string[] = [];
  if (type === 'Normal') {
    if (teamWise) {
      organizationUsers.map((user: any) => {
        if (user.team === name) {
          uidList.push(user.uid);
        }
        if (name === 'Grand Total') {
          uidList.push(user.uid);
        }
      });
      if (feild === 'not_int_reason') {
        contacts = [];
        const data = await firestore
          .collection('contacts')
          .where('organization_id', '==', organization_id)
          .where('stage', '==', 'NOT INTERESTED')
          .get();
        data.docs.forEach((doc) => {
          contacts.push(doc.data());
        });
      } else if (feild === 'lost_reason') {
        contacts = [];
        const data = await firestore
          .collection('contacts')
          .where('organization_id', '==', organization_id)
          .where('stage', '==', 'LOST')
          .get();
        data.docs.forEach((doc) => {
          contacts.push(doc.data());
        });
      }
      if (name === 'Grand Total') {
        if (branchUsers && branchUsers.length === 0) {
          contacts = contacts.filter((contact) =>
            uidList.includes(contact.uid)
          );
        } else {
          contacts = contacts.filter((contact) =>
            branchUsers?.includes(contact.uid)
          );
        }
      } else {
        contacts = contacts.filter((contact) => uidList.includes(contact.uid));
      }

      drillDownData = getDrillDownChartOrg(
        contacts,
        filter,
        feild,
        feildValue,
        organization_id
      );
      if (feild === 'lost_reason' || feild === 'not_int_reason') {
        // drillDownData = (await drillDownData).filter((data) =>
        //   uidList.includes(data.uid)
        // );

        if (name === 'Grand Total') {
          if (branchUsers && branchUsers.length === 0) {
            drillDownData = (await drillDownData).filter((data) =>
              uidList.includes(data.uid)
            );
          } else {
            drillDownData = (await drillDownData).filter((data) =>
              branchUsers?.includes(data.uid)
            );
          }
        } else {
          drillDownData = (await drillDownData).filter((data) =>
            uidList.includes(data.uid)
          );
        }
      }
      if (feild === 'stage') {
        if (
          feildValue === 'NOT INTERESTED' ||
          feildValue === 'WON' ||
          feildValue === 'LOST'
        ) {
          if (name === 'Grand Total') {
            if (branchUsers && branchUsers.length === 0) {
              drillDownData = (await drillDownData).filter((data) =>
                uidList.includes(data.uid)
              );
            } else {
              drillDownData = (await drillDownData).filter((data) =>
                branchUsers?.includes(data.uid)
              );
            }
          } else {
            drillDownData = (await drillDownData).filter((data) =>
              uidList.includes(data.uid)
            );
          }
        }
      }
    } else {
      if (sales && uid && uid !== '') {
        uidList.push(uid);
      } else {
        if (teamUsers && teamUsers.length !== 0) {
          if (name !== 'Grand Total') {
            organizationUsers.map((user: any) => {
              if (user.user_first_name + ' ' + user.user_last_name === name) {
                uidList.push(user.uid);
              }
              if (name === 'Grand Total') {
                uidList.push(user.uid);
              }
            });
          } else {
            teamUsers.map((item) => {
              uidList.push(item);
            });
          }
        } else {
          if (name === 'Grand Total') {
            uidList.push('');
          }
          organizationUsers.map((user: any) => {
            if (user.user_first_name + ' ' + user.user_last_name === name) {
              uidList.push(user.uid);
            }
            if (name === 'Grand Total') {
              uidList.push(user.uid);
            }
          });
        }
      }

      if (feild === 'not_int_reason') {
        contacts = [];
        const data = await firestore
          .collection('contacts')
          .where('organization_id', '==', organization_id)
          .where('stage', '==', 'NOT INTERESTED')
          .get();
        data.docs.forEach((doc) => {
          contacts.push(doc.data());
        });
      } else if (feild === 'lost_reason') {
        contacts = [];
        const data = await firestore
          .collection('contacts')
          .where('organization_id', '==', organization_id)
          .where('stage', '==', 'LOST')
          .get();
        data.docs.forEach((doc) => {
          contacts.push(doc.data());
        });
      }

      if (name === 'Grand Total') {
        if (branchUsers && branchUsers.length === 0) {
          contacts = contacts.filter((contact) =>
            uidList.includes(contact.uid)
          );
        } else {
          contacts = contacts.filter((contact) =>
            branchUsers?.includes(contact.uid)
          );
        }
      } else {
        contacts = contacts.filter((contact) => uidList.includes(contact.uid));
      }

      drillDownData = getDrillDownChartOrg(
        contacts,
        filter,
        feild,
        feildValue,
        organization_id
      );

      if (feild === 'lost_reason' || feild === 'not_int_reason') {
        // drillDownData = (await drillDownData).filter((data) =>
        //   uidList.includes(data.uid)
        // );

        if (name === 'Grand Total') {
          if (branchUsers && branchUsers.length === 0) {
            drillDownData = (await drillDownData).filter((data) =>
              uidList.includes(data.uid)
            );
          } else {
            drillDownData = (await drillDownData).filter((data) =>
              branchUsers?.includes(data.uid)
            );
          }
        } else {
          drillDownData = (await drillDownData).filter((data) =>
            uidList.includes(data.uid)
          );
        }
      }
      if (feild === 'stage') {
        if (
          feildValue === 'NOT INTERESTED' ||
          feildValue === 'WON' ||
          feildValue === 'LOST'
        ) {
          if (name === 'Grand Total') {
            if (branchUsers && branchUsers.length === 0) {
              drillDownData = (await drillDownData).filter((data) =>
                uidList.includes(data.uid)
              );
            } else {
              drillDownData = (await drillDownData).filter((data) =>
                branchUsers?.includes(data.uid)
              );
            }
          } else {
            drillDownData = (await drillDownData).filter((data) =>
              uidList.includes(data.uid)
            );
          }
        }
      }
    }
  } else if (type === 'SuperTotal') {
    if (sales && uid && uid !== '') {
      uidList.push(uid);
    } else {
      if (teamUsers && teamUsers.length !== 0) {
        teamUsers.map((item) => {
          uidList.push(item);
        });
      } else {
        if (name === 'Grand Total') {
          uidList.push('');
        }
        organizationUsers.map((user: any) => {
          uidList.push(user.uid);
        });
      }
    }

    if (feild === 'not_int_reason') {
      contacts = [];
      const data = await firestore
        .collection('contacts')
        .where('organization_id', '==', organization_id)
        .where('stage', '==', 'NOT INTERESTED')
        .get();
      data.docs.forEach((doc) => {
        contacts.push(doc.data());
      });
    } else if (feild === 'lost_reason') {
      contacts = [];
      const data = await firestore
        .collection('contacts')
        .where('organization_id', '==', organization_id)
        .where('stage', '==', 'LOST')
        .get();
      data.docs.forEach((doc) => {
        contacts.push(doc.data());
      });
    } else if (feild === 'stage') {
      contacts = [];
      const data = await firestore
        .collection('contacts')
        .where('organization_id', '==', organization_id)

        .get();
      data.docs.forEach((doc) => {
        contacts.push(doc.data());
      });
    }
    if (name === 'Grand Total') {
      if (branchUsers && branchUsers.length === 0) {
        contacts = contacts.filter((contact) => uidList.includes(contact.uid));
      } else {
        contacts = contacts.filter((contact) =>
          branchUsers?.includes(contact.uid)
        );
      }
    } else {
      contacts = contacts.filter((contact) => uidList.includes(contact.uid));
    }
    drillDownData = totalDrillDownData(
      contacts,
      filter,
      feild,
      organization_id
    );
  } else {
    if (teamWise) {
      organizationUsers.map((user: any) => {
        if (user.team === name) {
          uidList.push(user.uid);
        }
      });
      if (feild === 'not_int_reason') {
        contacts = [];
        const data = await firestore
          .collection('contacts')
          .where('organization_id', '==', organization_id)
          .where('stage', '==', 'NOT INTERESTED')
          .get();
        data.docs.forEach((doc) => {
          contacts.push(doc.data());
        });
      } else if (feild === 'lost_reason') {
        contacts = [];
        const data = await firestore
          .collection('contacts')
          .where('organization_id', '==', organization_id)
          .where('stage', '==', 'LOST')
          .get();
        data.docs.forEach((doc) => {
          contacts.push(doc.data());
        });
      } else if (feild === 'stage') {
        contacts = [];
        const data = await firestore
          .collection('contacts')
          .where('organization_id', '==', organization_id)

          .get();
        data.docs.forEach((doc) => {
          contacts.push(doc.data());
        });
      }
      if (name === 'Grand Total') {
        if (branchUsers && branchUsers.length === 0) {
          contacts = contacts.filter((contact) =>
            uidList.includes(contact.uid)
          );
        } else {
          contacts = contacts.filter((contact) =>
            branchUsers?.includes(contact.uid)
          );
        }
      } else {
        contacts = contacts.filter((contact) => uidList.includes(contact.uid));
      }
      drillDownData = totalDrillDownData(
        contacts,
        filter,
        feild,
        organization_id
      );
    } else {
      if (name === 'Grand Total') {
        uidList.push('');
      }

      organizationUsers.map((user: any) => {
        if (user.user_first_name + ' ' + user.user_last_name === name) {
          uidList.push(user.uid);
        }
      });
      if (feild === 'not_int_reason') {
        contacts = [];
        const data = await firestore
          .collection('contacts')
          .where('organization_id', '==', organization_id)
          .where('stage', '==', 'NOT INTERESTED')
          .get();
        data.docs.forEach((doc) => {
          contacts.push(doc.data());
        });
      } else if (feild === 'lost_reason') {
        contacts = [];
        const data = await firestore
          .collection('contacts')
          .where('organization_id', '==', organization_id)
          .where('stage', '==', 'LOST')
          .get();
        data.docs.forEach((doc) => {
          contacts.push(doc.data());
        });
      } else if (feild === 'stage') {
        contacts = [];
        const data = await firestore
          .collection('contacts')
          .where('organization_id', '==', organization_id)

          .get();
        data.docs.forEach((doc) => {
          contacts.push(doc.data());
        });
      }
      if (name === 'Grand Total') {
        if (branchUsers && branchUsers.length === 0) {
          contacts = contacts.filter((contact) =>
            uidList.includes(contact.uid)
          );
        } else {
          contacts = contacts.filter((contact) =>
            branchUsers?.includes(contact.uid)
          );
        }
      } else {
        contacts = contacts.filter((contact) => uidList.includes(contact.uid));
      }
      drillDownData = totalDrillDownData(
        contacts,
        filter,
        feild,
        organization_id
      );
    }
  }

  return drillDownData;
};

export const getTaskDrillDownChartOrg = (
  contacts: any[],
  filter: 'MTD' | 'PM' | 'All' | 'YTD' | 'CUSTOM' | 'Y' | 'T',
  feild: 'Overdue' | 'Pending' | 'Completed',
  feildValue: string
) => {
  let drillDownData = [];
  if (filter === 'MTD') {
    const currentDate = new Date();
    const monthEnd = moment().endOf('month').toDate();
    if (feild === 'Pending') {
      drillDownData = contacts.filter(
        (contact) =>
          (contact.stage === 'INTERESTED' || contact.stage === 'CALLBACK') &&
          contact.next_follow_up_date_time?.toDate &&
          contact.next_follow_up_type === feildValue &&
          contact.next_follow_up_date_time.toDate() > currentDate &&
          contact.next_follow_up_date_time.toDate() <= monthEnd
      );
    } else if (feild === 'Overdue') {
      drillDownData = contacts.filter(
        (contact) =>
          (contact.stage === 'INTERESTED' || contact.stage === 'CALLBACK') &&
          contact.next_follow_up_date_time?.toDate &&
          contact.next_follow_up_type === feildValue &&
          contact.next_follow_up_date_time.toDate() < currentDate
      );
    } else {
      drillDownData = [];
    }
  } else if (filter === 'T') {
    const startDate = moment().startOf('day').toDate();
    const endDate = moment().endOf('day').toDate();
    if (feild === 'Pending') {
      drillDownData = contacts.filter(
        (contact) =>
          (contact.stage === 'INTERESTED' || contact.stage === 'CALLBACK') &&
          contact.next_follow_up_date_time?.toDate &&
          contact.next_follow_up_type === feildValue &&
          contact.next_follow_up_date_time.toDate() > startDate &&
          contact.next_follow_up_date_time.toDate() <= endDate
      );
    } else if (feild === 'Overdue') {
      drillDownData = contacts.filter(
        (contact) =>
          (contact.stage === 'INTERESTED' || contact.stage === 'CALLBACK') &&
          contact.next_follow_up_date_time?.toDate &&
          contact.next_follow_up_type === feildValue &&
          contact.next_follow_up_date_time.toDate() < endDate &&
          contact.next_follow_up_date_time.toDate() > startDate
      );
    } else {
      drillDownData = [];
    }
  } else if (filter === 'PM') {
    const prevMonth = moment().subtract(1, 'M');
    const prevMonthEnd = moment(prevMonth).endOf('month').toDate();
    if (feild === 'Pending') {
      drillDownData = [];
    } else if (feild === 'Overdue') {
      drillDownData = contacts.filter(
        (contact) =>
          (contact.stage === 'INTERESTED' || contact.stage === 'CALLBACK') &&
          contact.next_follow_up_date_time?.toDate &&
          contact.next_follow_up_type === feildValue &&
          contact.next_follow_up_date_time.toDate() < prevMonthEnd
      );
    } else {
      drillDownData = [];
    }
  } else if (filter === 'Y') {
    const prevDay = moment().subtract(1, 'day');
    const prevDayStart = moment(prevDay).startOf('day').toDate();
    const prevDayEnd = moment(prevDay).endOf('day').toDate();

    if (feild === 'Pending') {
      drillDownData = [];
    } else if (feild === 'Overdue') {
      drillDownData = contacts.filter(
        (contact) =>
          (contact.stage === 'INTERESTED' || contact.stage === 'CALLBACK') &&
          contact.next_follow_up_date_time?.toDate &&
          contact.next_follow_up_type === feildValue &&
          contact.next_follow_up_date_time.toDate() > prevDayStart &&
          contact.next_follow_up_date_time.toDate() < prevDayEnd
      );
    } else {
      drillDownData = [];
    }
  } else if (filter === 'YTD') {
  } else {
    const currentDate = new Date();
    if (feild === 'Pending') {
      drillDownData = contacts.filter(
        (contact) =>
          (contact.stage === 'INTERESTED' || contact.stage === 'CALLBACK') &&
          contact.next_follow_up_date_time?.toDate &&
          contact.next_follow_up_type === feildValue &&
          contact.next_follow_up_date_time.toDate() > currentDate
      );
    } else if (feild === 'Overdue') {
      drillDownData = contacts.filter(
        (contact) =>
          (contact.stage === 'INTERESTED' || contact.stage === 'CALLBACK') &&
          contact.next_follow_up_date_time?.toDate &&
          contact.next_follow_up_type === feildValue &&
          contact.next_follow_up_date_time.toDate() < currentDate
      );
    } else {
      drillDownData = [];
    }
  }

  return drillDownData;
};

export const totalTaskDrillDownData = (
  contacts: any[],
  filter: 'MTD' | 'PM' | 'All' | 'YTD' | 'CUSTOM' | 'Y' | 'T',
  feild: 'Overdue' | 'Pending' | 'Completed'
) => {
  let drillDownData = [];
  if (filter === 'MTD') {
    const currentDate = new Date();
    const monthEnd = moment().endOf('month').toDate();
    if (feild === 'Pending') {
      drillDownData = contacts.filter(
        (contact) =>
          (contact.stage === 'INTERESTED' || contact.stage === 'CALLBACK') &&
          contact.next_follow_up_date_time?.toDate &&
          contact.next_follow_up_type &&
          contact.next_follow_up_date_time.toDate() > currentDate &&
          contact.next_follow_up_date_time.toDate() <= monthEnd
      );
    } else if (feild === 'Overdue') {
      drillDownData = contacts.filter(
        (contact) =>
          (contact.stage === 'INTERESTED' || contact.stage === 'CALLBACK') &&
          contact.next_follow_up_date_time?.toDate &&
          contact.next_follow_up_type &&
          contact.next_follow_up_date_time.toDate() < currentDate
      );
    } else {
      drillDownData = [];
    }
  } else if (filter === 'T') {
    const startDate = moment().startOf('day').toDate();
    const endDate = moment().endOf('day').toDate();
    if (feild === 'Pending') {
      drillDownData = contacts.filter(
        (contact) =>
          (contact.stage === 'INTERESTED' || contact.stage === 'CALLBACK') &&
          contact.next_follow_up_date_time?.toDate &&
          contact.next_follow_up_type &&
          contact.next_follow_up_date_time.toDate() > startDate &&
          contact.next_follow_up_date_time.toDate() <= endDate
      );
    } else if (feild === 'Overdue') {
      drillDownData = contacts.filter(
        (contact) =>
          (contact.stage === 'INTERESTED' || contact.stage === 'CALLBACK') &&
          contact.next_follow_up_date_time?.toDate &&
          contact.next_follow_up_type &&
          contact.next_follow_up_date_time.toDate() < endDate &&
          contact.next_follow_up_date_time.toDate() > startDate
      );
    } else {
      drillDownData = [];
    }
  } else if (filter === 'PM') {
    const prevMonth = moment().subtract(1, 'M');
    const prevMonthEnd = moment(prevMonth).endOf('month').toDate();
    if (feild === 'Pending') {
      drillDownData = [];
    } else if (feild === 'Overdue') {
      drillDownData = contacts.filter(
        (contact) =>
          (contact.stage === 'INTERESTED' || contact.stage === 'CALLBACK') &&
          contact.next_follow_up_date_time?.toDate &&
          contact.next_follow_up_type &&
          contact.next_follow_up_date_time.toDate() < prevMonthEnd
      );
    } else {
      drillDownData = [];
    }
  } else if (filter === 'Y') {
    const prevDay = moment().subtract(1, 'day');
    const prevDayStart = moment(prevDay).startOf('day').toDate();
    const prevDayEnd = moment(prevDay).endOf('day').toDate();

    if (feild === 'Pending') {
      drillDownData = [];
    } else if (feild === 'Overdue') {
      drillDownData = contacts.filter(
        (contact) =>
          (contact.stage === 'INTERESTED' || contact.stage === 'CALLBACK') &&
          contact.next_follow_up_date_time?.toDate &&
          contact.next_follow_up_type &&
          contact.next_follow_up_date_time.toDate() > prevDayStart &&
          contact.next_follow_up_date_time.toDate() < prevDayEnd
      );
    } else {
      drillDownData = [];
    }
  } else if (filter === 'YTD') {
  } else {
    const currentDate = new Date();
    if (feild === 'Pending') {
      drillDownData = contacts.filter(
        (contact) =>
          (contact.stage === 'INTERESTED' || contact.stage === 'CALLBACK') &&
          contact.next_follow_up_date_time?.toDate &&
          contact.next_follow_up_type &&
          contact.next_follow_up_date_time.toDate() > currentDate
      );
    } else if (feild === 'Overdue') {
      drillDownData = contacts.filter(
        (contact) =>
          (contact.stage === 'INTERESTED' || contact.stage === 'CALLBACK') &&
          contact.next_follow_up_date_time?.toDate &&
          contact.next_follow_up_type &&
          contact.next_follow_up_date_time.toDate() < currentDate
      );
    } else {
      drillDownData = [];
    }
  }

  return drillDownData;
};

export const taskDrillDownTableOrg = (
  contacts: any[],
  filter: 'MTD' | 'PM' | 'All' | 'YTD' | 'CUSTOM' | 'Y' | 'T',
  feild: 'Overdue' | 'Pending' | 'Completed',
  feildValue: string,
  teamWise: boolean,
  type: 'SuperTotal' | 'Total' | 'Normal',
  name: string,
  organizationUsers: any,
  teamUsers?: any[]
) => {
  let drillDownData;
  let uidList: string[] = [];
  if (type === 'Normal') {
    if (teamWise) {
      organizationUsers.map((user: any) => {
        if (user.team === name) {
          uidList.push(user.uid);
        }
        if (name === 'Grand Total') {
          uidList.push(user.uid);
        }
      });
      contacts = contacts.filter((contact) => uidList.includes(contact.uid));
      drillDownData = getTaskDrillDownChartOrg(
        contacts,
        filter,
        feild,
        feildValue
      );
    } else {
      if (teamUsers && teamUsers.length !== 0) {
        if (name !== 'Grand Total') {
          organizationUsers.map((user: any) => {
            if (user.user_first_name + ' ' + user.user_last_name === name) {
              uidList.push(user.uid);
            }
            if (name === 'Grand Total') {
              uidList.push(user.uid);
            }
          });
        } else {
          teamUsers.map((item) => {
            uidList.push(item);
          });
        }
      } else {
        organizationUsers.map((user: any) => {
          if (user.user_first_name + ' ' + user.user_last_name === name) {
            uidList.push(user.uid);
          }
          if (name === 'Grand Total') {
            uidList.push(user.uid);
          }
        });
      }

      contacts = contacts.filter((contact) => uidList.includes(contact.uid));
      drillDownData = getTaskDrillDownChartOrg(
        contacts,
        filter,
        feild,
        feildValue
      );
    }
  } else if (type === 'SuperTotal') {
    if (teamUsers && teamUsers.length !== 0) {
      teamUsers.map((item) => {
        uidList.push(item);
      });
    } else {
      organizationUsers.map((user: any) => {
        uidList.push(user.uid);
      });
    }

    contacts = contacts.filter((contact) => uidList.includes(contact.uid));
    drillDownData = totalTaskDrillDownData(contacts, filter, feild);
  } else {
    if (teamWise) {
      organizationUsers.map((user: any) => {
        if (user.team === name) {
          uidList.push(user.uid);
        }
      });
      contacts = contacts.filter((contact) => uidList.includes(contact.uid));
      drillDownData = totalTaskDrillDownData(contacts, filter, feild);
    } else {
      organizationUsers.map((user: any) => {
        if (user.user_first_name + ' ' + user.user_last_name === name) {
          uidList.push(user.uid);
        }
      });
      contacts = contacts.filter((contact) => uidList.includes(contact.uid));
      drillDownData = totalTaskDrillDownData(contacts, filter, feild);
    }
  }
  return drillDownData;
};

export const getDate = (date: Date) => {
  const utcDate = moment.utc(date);
  return utcDate.utcOffset('+05:30').format('DD-MM-YYYY');
};
export const getTaskDrillDownChartSales = (
  contacts: any[],
  filter: 'MTD' | 'PM' | 'All' | 'YTD' | 'CUSTOM' | 'Y' | 'T',
  feild: 'Overdue' | 'Pending' | 'Completed',
  feildValue: string
) => {
  let drillDownData = [];
  if (filter === 'MTD') {
    const currentDate = new Date();
    const monthEnd = moment().endOf('month').toDate();
    if (feild === 'Pending') {
      drillDownData = contacts.filter(
        (contact) =>
          (contact.stage === 'INTERESTED' || contact.stage === 'CALLBACK') &&
          contact.next_follow_up_date_time?.toDate &&
          contact.next_follow_up_date_time.toDate() > currentDate &&
          contact.next_follow_up_date_time.toDate() <= monthEnd &&
          getDate(contact.next_follow_up_date_time.toDate()) === feildValue
      );
    } else if (feild === 'Overdue') {
      drillDownData = contacts.filter(
        (contact) =>
          (contact.stage === 'INTERESTED' || contact.stage === 'CALLBACK') &&
          contact.next_follow_up_date_time?.toDate &&
          getDate(contact.next_follow_up_date_time.toDate()) === feildValue &&
          contact.next_follow_up_date_time.toDate() < currentDate
      );
    } else {
      drillDownData = [];
    }
  } else if (filter === 'T') {
    const startDate = moment().startOf('day').toDate();
    const endDate = moment().endOf('day').toDate();
    if (feild === 'Pending') {
      drillDownData = contacts.filter(
        (contact) =>
          (contact.stage === 'INTERESTED' || contact.stage === 'CALLBACK') &&
          contact.next_follow_up_date_time?.toDate &&
          contact.next_follow_up_date_time.toDate() > startDate &&
          contact.next_follow_up_date_time.toDate() <= endDate &&
          getDate(contact.next_follow_up_date_time.toDate()) === feildValue
      );
    } else if (feild === 'Overdue') {
      drillDownData = contacts.filter(
        (contact) =>
          (contact.stage === 'INTERESTED' || contact.stage === 'CALLBACK') &&
          contact.next_follow_up_date_time?.toDate &&
          getDate(contact.next_follow_up_date_time.toDate()) === feildValue &&
          contact.next_follow_up_date_time.toDate() < endDate &&
          contact.next_follow_up_date_time.toDate() > startDate
      );
    } else {
      drillDownData = [];
    }
  } else if (filter === 'PM') {
    const prevMonth = moment().subtract(1, 'M');
    const prevMonthEnd = moment(prevMonth).endOf('month').toDate();
    if (feild === 'Pending') {
      drillDownData = [];
    } else if (feild === 'Overdue') {
      drillDownData = contacts.filter(
        (contact) =>
          (contact.stage === 'INTERESTED' || contact.stage === 'CALLBACK') &&
          contact.next_follow_up_date_time?.toDate &&
          getDate(contact.next_follow_up_date_time.toDate()) === feildValue &&
          contact.next_follow_up_date_time.toDate() < prevMonthEnd
      );
    } else {
      drillDownData = [];
    }
  } else if (filter === 'Y') {
    const prevDay = moment().subtract(1, 'day');
    const prevDayStart = moment(prevDay).startOf('day').toDate();
    const prevDayEnd = moment(prevDay).endOf('day').toDate();
    if (feild === 'Pending') {
      drillDownData = [];
    } else if (feild === 'Overdue') {
      drillDownData = contacts.filter(
        (contact) =>
          (contact.stage === 'INTERESTED' || contact.stage === 'CALLBACK') &&
          contact.next_follow_up_date_time?.toDate &&
          getDate(contact.next_follow_up_date_time.toDate()) === feildValue &&
          contact.next_follow_up_date_time.toDate() > prevDayStart &&
          contact.next_follow_up_date_time.toDate() < prevDayEnd
      );
    } else {
      drillDownData = [];
    }
  } else if (filter === 'YTD') {
  } else {
    const currentDate = new Date();
    if (feild === 'Pending') {
      drillDownData = contacts.filter(
        (contact) =>
          (contact.stage === 'INTERESTED' || contact.stage === 'CALLBACK') &&
          contact.next_follow_up_date_time?.toDate &&
          getDate(contact.next_follow_up_date_time.toDate()) === feildValue &&
          contact.next_follow_up_date_time.toDate() > currentDate
      );
    } else if (feild === 'Overdue') {
      drillDownData = contacts.filter(
        (contact) =>
          (contact.stage === 'INTERESTED' || contact.stage === 'CALLBACK') &&
          contact.next_follow_up_date_time?.toDate &&
          getDate(contact.next_follow_up_date_time.toDate()) === feildValue &&
          contact.next_follow_up_date_time.toDate() < currentDate
      );
    } else {
      drillDownData = [];
    }
  }

  return drillDownData;
};

export const getTaskDrillDownTableSales = (
  contacts: any[],
  filter: 'MTD' | 'PM' | 'All' | 'YTD' | 'CUSTOM' | 'Y' | 'T',
  feild: 'Overdue' | 'Pending' | 'Completed',
  feildValue: string,
  type: 'Meeting' | 'Call Back' | 'Site Visit'
) => {
  let drillDownData = [];
  if (filter === 'MTD') {
    const currentDate = new Date();
    const monthEnd = moment().endOf('month').toDate();
    if (feild === 'Pending') {
      drillDownData = contacts.filter(
        (contact) =>
          (contact.stage === 'INTERESTED' || contact.stage === 'CALLBACK') &&
          contact.next_follow_up_date_time?.toDate &&
          contact.next_follow_up_date_time.toDate() > currentDate &&
          contact.next_follow_up_date_time.toDate() <= monthEnd &&
          getDate(contact.next_follow_up_date_time.toDate()) === feildValue &&
          contact.next_follow_up_type === type
      );
    } else if (feild === 'Overdue') {
      drillDownData = contacts.filter(
        (contact) =>
          (contact.stage === 'INTERESTED' || contact.stage === 'CALLBACK') &&
          contact.next_follow_up_date_time?.toDate &&
          getDate(contact.next_follow_up_date_time.toDate()) === feildValue &&
          contact.next_follow_up_date_time.toDate() < currentDate &&
          contact.next_follow_up_type === type
      );
    } else {
      drillDownData = [];
    }
  } else if (filter === 'T') {
    const startDate = moment().startOf('day').toDate();
    const endDate = moment().endOf('day').toDate();
    if (feild === 'Pending') {
      drillDownData = contacts.filter(
        (contact) =>
          (contact.stage === 'INTERESTED' || contact.stage === 'CALLBACK') &&
          contact.next_follow_up_date_time?.toDate &&
          contact.next_follow_up_date_time.toDate() > startDate &&
          contact.next_follow_up_date_time.toDate() <= endDate &&
          getDate(contact.next_follow_up_date_time.toDate()) === feildValue &&
          contact.next_follow_up_type === type
      );
    } else if (feild === 'Overdue') {
      drillDownData = contacts.filter(
        (contact) =>
          (contact.stage === 'INTERESTED' || contact.stage === 'CALLBACK') &&
          contact.next_follow_up_date_time?.toDate &&
          getDate(contact.next_follow_up_date_time.toDate()) === feildValue &&
          contact.next_follow_up_date_time.toDate() < endDate &&
          contact.next_follow_up_type === type
      );
    } else {
      drillDownData = [];
    }
  } else if (filter === 'PM') {
    const prevMonth = moment().subtract(1, 'M');
    const prevMonthEnd = moment(prevMonth).endOf('month').toDate();
    if (feild === 'Pending') {
      drillDownData = [];
    } else if (feild === 'Overdue') {
      drillDownData = contacts.filter(
        (contact) =>
          (contact.stage === 'INTERESTED' || contact.stage === 'CALLBACK') &&
          contact.next_follow_up_date_time?.toDate &&
          getDate(contact.next_follow_up_date_time.toDate()) === feildValue &&
          contact.next_follow_up_date_time.toDate() < prevMonthEnd &&
          contact.next_follow_up_type === type
      );
    } else {
      drillDownData = [];
    }
  } else if (filter === 'Y') {
    const prevDay = moment().subtract(1, 'day');
    const prevDayStart = moment(prevDay).startOf('day').toDate();
    const prevDayEnd = moment(prevDay).endOf('day').toDate();

    if (feild === 'Pending') {
      drillDownData = [];
    } else if (feild === 'Overdue') {
      drillDownData = contacts.filter(
        (contact) =>
          (contact.stage === 'INTERESTED' || contact.stage === 'CALLBACK') &&
          contact.next_follow_up_date_time?.toDate &&
          getDate(contact.next_follow_up_date_time.toDate()) === feildValue &&
          contact.next_follow_up_date_time.toDate() < prevDayEnd &&
          contact.next_follow_up_date_time.toDate() > prevDayStart &&
          contact.next_follow_up_type === type
      );
    } else {
      drillDownData = [];
    }
  } else if (filter === 'YTD') {
  } else {
    const currentDate = new Date();
    if (feild === 'Pending') {
      drillDownData = contacts.filter(
        (contact) =>
          (contact.stage === 'INTERESTED' || contact.stage === 'CALLBACK') &&
          contact.next_follow_up_date_time?.toDate &&
          getDate(contact.next_follow_up_date_time.toDate()) === feildValue &&
          contact.next_follow_up_date_time.toDate() > currentDate &&
          contact.next_follow_up_type === type
      );
    } else if (feild === 'Overdue') {
      drillDownData = contacts.filter(
        (contact) =>
          (contact.stage === 'INTERESTED' || contact.stage === 'CALLBACK') &&
          contact.next_follow_up_date_time?.toDate &&
          getDate(contact.next_follow_up_date_time.toDate()) === feildValue &&
          contact.next_follow_up_date_time.toDate() < currentDate &&
          contact.next_follow_up_type === type
      );
    } else {
      drillDownData = [];
    }
  }

  return drillDownData;
};

export const getPendingTaskDrillDown = (
  contacts: any[],
  filter: 'MTD' | 'PM' | 'All' | 'YTD' | 'CUSTOM' | 'Y' | 'T',
  feild: 'Pending' | 'Completed'
) => {
  let drillDownData = [];
  if (filter === 'MTD') {
    const currentDate = new Date();
    const monthEnd = moment().endOf('month').toDate();
    if (feild === 'Pending') {
      drillDownData = contacts.filter(
        (contact) =>
          ((contact.stage === 'INTERESTED' || contact.stage === 'CALLBACK') &&
            contact.next_follow_up_date_time?.toDate &&
            (contact.next_follow_up_type === 'Meeting' ||
              contact.next_follow_up_type === 'Site Visit') &&
            contact.next_follow_up_date_time.toDate() > currentDate &&
            contact.next_follow_up_date_time.toDate() <= monthEnd) ||
          contact.next_follow_up_date_time.toDate() < currentDate
      );
    } else {
      drillDownData = [];
    }
  } else if (filter === 'T') {
    const startDate = moment().startOf('day').toDate();
    const endDate = moment().endOf('day').toDate();
    if (feild === 'Pending') {
      drillDownData = contacts.filter(
        (contact) =>
          ((contact.stage === 'INTERESTED' || contact.stage === 'CALLBACK') &&
            contact.next_follow_up_date_time?.toDate &&
            (contact.next_follow_up_type === 'Meeting' ||
              contact.next_follow_up_type === 'Site Visit') &&
            contact.next_follow_up_date_time.toDate() > startDate &&
            contact.next_follow_up_date_time.toDate() <= endDate) ||
          contact.next_follow_up_date_time.toDate() < startDate
      );
    } else {
      drillDownData = [];
    }
  } else if (filter === 'PM') {
    const prevMonth = moment().subtract(1, 'M');
    const prevMonthEnd = moment(prevMonth).endOf('month').toDate();
    if (feild === 'Pending') {
      drillDownData = [];
      drillDownData = contacts.filter(
        (contact) =>
          (contact.stage === 'INTERESTED' || contact.stage === 'CALLBACK') &&
          contact.next_follow_up_date_time?.toDate &&
          (contact.next_follow_up_type === 'Meeting' ||
            contact.next_follow_up_type === 'Site Visit') &&
          contact.next_follow_up_date_time.toDate() < prevMonthEnd
      );
    } else {
      drillDownData = [];
    }
  } else if (filter === 'Y') {
    const prevDay = moment().subtract(1, 'day');
    const prevDayStart = moment(prevDay).startOf('day').toDate();
    const prevDayEnd = moment(prevDay).endOf('day').toDate();

    if (feild === 'Pending') {
      drillDownData = [];
      drillDownData = contacts.filter(
        (contact) =>
          (contact.stage === 'INTERESTED' || contact.stage === 'CALLBACK') &&
          contact.next_follow_up_date_time?.toDate &&
          (contact.next_follow_up_type === 'Meeting' ||
            contact.next_follow_up_type === 'Site Visit') &&
          contact.next_follow_up_date_time.toDate() > prevDayStart &&
          contact.next_follow_up_date_time.toDate() < prevDayEnd
      );
    } else {
      drillDownData = [];
    }
  } else if (filter === 'YTD') {
  } else {
    const currentDate = new Date();
    if (feild === 'Pending') {
      drillDownData = contacts.filter(
        (contact) =>
          (contact.stage === 'INTERESTED' || contact.stage === 'CALLBACK') &&
          contact.next_follow_up_date_time?.toDate &&
          (contact.next_follow_up_type === 'Meeting' ||
            contact.next_follow_up_type === 'Site Visit') &&
          (contact.next_follow_up_date_time.toDate() > currentDate ||
            contact.next_follow_up_date_time.toDate() < currentDate)
      );
    } else {
      drillDownData = [];
    }
  }

  return drillDownData;
};

export const getInterestedLeadsDrillDown = (
  contacts: any[],
  filter: 'MTD' | 'PM' | 'All' | 'YTD' | 'CUSTOM' | 'Y' | 'T',
  feildValue: string
) => {
  let drillDownData: any[] = [];
  if (filter === 'MTD') {
    const startDate = moment().startOf('month').toDate();
    const endDate = new Date();

    drillDownData = contacts.filter(
      (contact) =>
        contact.stage_change_at?.toDate &&
        contact.stage === 'INTERESTED' &&
        contact.stage_change_at.toDate() > startDate &&
        contact.stage_change_at.toDate() < endDate &&
        getDate(contact.stage_change_at.toDate()) === feildValue
    );
  } else if (filter === 'T') {
    const startDate = moment().startOf('day').toDate();
    const endDate = moment().endOf('day').toDate();
    drillDownData = contacts.filter(
      (contact) =>
        contact.stage_change_at?.toDate &&
        contact.stage === 'INTERESTED' &&
        contact.stage_change_at.toDate() > startDate &&
        contact.stage_change_at.toDate() < endDate &&
        getDate(contact.stage_change_at.toDate()) === feildValue
    );
  } else if (filter === 'PM') {
    const prevMonth = moment().subtract(1, 'M');
    const prevMonthStart = moment(prevMonth).startOf('month').toDate();
    const prevMonthEnd = moment(prevMonth).endOf('month').toDate();

    drillDownData = contacts.filter(
      (contact) =>
        contact.stage_change_at?.toDate &&
        contact.stage === 'INTERESTED' &&
        contact.stage_change_at.toDate() > prevMonthStart &&
        contact.stage_change_at.toDate() < prevMonthEnd &&
        getDate(contact.stage_change_at.toDate()) === feildValue
    );
  } else if (filter === 'Y') {
    const prevDay = moment().subtract(1, 'day');
    const prevDayStart = moment(prevDay).startOf('day').toDate();
    const prevDayEnd = moment(prevDay).endOf('day').toDate();

    drillDownData = contacts.filter(
      (contact) =>
        contact.stage_change_at?.toDate &&
        contact.stage === 'INTERESTED' &&
        contact.stage_change_at.toDate() > prevDayStart &&
        contact.stage_change_at.toDate() < prevDayEnd &&
        getDate(contact.stage_change_at.toDate()) === feildValue
    );
  } else if (filter === 'YTD') {
  } else {
    drillDownData = contacts.filter(
      (contact) =>
        contact.stage_change_at?.toDate &&
        contact.stage === 'INTERESTED' &&
        getDate(contact.stage_change_at.toDate()) === feildValue
    );
  }

  return drillDownData;
};

export const apiContactsDrilldown = async (
  uid: string,
  type: string,
  value: string,
  stage: string,
  filter: string,
  taskFilter: any,
  leadsFilter: any,
  role: boolean
  
) => {
  console.log("uid"+uid);
  let allLeadsFilter = {};
  if (filter === 'MTD') {
    const startDate = moment().startOf('month').toDate();
    const endDate = new Date();
    if (value === 'Total' && type !== 'stage') {
      allLeadsFilter = {
        ...leadsFilter,
        stage: [stage],
        lead_assign_time: [startDate, endDate],
      };
    } else if (
      value === 'FRESH' ||
      value === 'INTERESTED' ||
      value === 'NOT INTERESTED' ||
      value === 'WON' ||
      value === 'LOST' ||
      value === 'CALL BACK' ||
      value === 'CALLBACK'
    ) {
      allLeadsFilter = {
        ...leadsFilter,
        stage: [value],
        lead_assign_time: [startDate, endDate],
      };
    } else if (type === 'stage' && value === 'Total') {
      allLeadsFilter = {
        ...leadsFilter,
        lead_assign_time: [startDate, endDate],
      };
    } else {
      allLeadsFilter = {
        ...leadsFilter,
        [type]: [value],
        stage: [stage],
        lead_assign_time: [startDate, endDate],
      };
    }
  } else if (filter === 'T') {
    const startDate = moment().startOf('day').toDate().toISOString();
    const endDate = moment().endOf('day').toDate().toISOString();
    if (value === 'Total' && type !== 'stage') {
      allLeadsFilter = {
        ...leadsFilter,
        stage: [stage],
        lead_assign_time: [startDate, endDate],
      };
    } else if (
      value === 'FRESH' ||
      value === 'INTERESTED' ||
      value === 'NOT INTERESTED' ||
      value === 'WON' ||
      value === 'LOST' ||
      value === 'CALL BACK' ||
      value === 'CALLBACK'
    ) {
      allLeadsFilter = {
        ...leadsFilter,
        stage: [value],
        lead_assign_time: [startDate, endDate],
      };
    } else if (type === 'stage' && value === 'Total') {
      allLeadsFilter = {
        ...leadsFilter,
        lead_assign_time: [startDate, endDate],
      };
    } else {
      allLeadsFilter = {
        ...leadsFilter,
        [type]: [value],
        stage: [stage],
        lead_assign_time: [startDate, endDate],
      };
    }
  } else if (filter === 'PM') {
    const prevMonth = moment().subtract(1, 'M');
    const prevMonthStart = moment(prevMonth).startOf('month').toDate();
    const prevMonthEnd = moment(prevMonth).endOf('month').toDate();
    console.log([prevMonthStart, prevMonthEnd]);
    if (value === 'Total' && type !== 'stage') {
      allLeadsFilter = {
        ...leadsFilter,
        stage: [stage],
        lead_assign_time: [prevMonthStart, prevMonthEnd],
      };
    } else if (
      value === 'FRESH' ||
      value === 'INTERESTED' ||
      value === 'NOT INTERESTED' ||
      value === 'WON' ||
      value === 'LOST' ||
      value === 'CALL BACK' ||
      value === 'CALLBACK'
    ) {
      allLeadsFilter = {
        ...leadsFilter,
        stage: [value],
        lead_assign_time: [prevMonthStart, prevMonthEnd],
      };
    } else if (type === 'stage' && value === 'Total') {
      allLeadsFilter = {
        ...leadsFilter,
        lead_assign_time: [prevMonthStart, prevMonthEnd],
      };
    } else {
      allLeadsFilter = {
        ...leadsFilter,
        [type]: [value],
        stage: [stage],
        lead_assign_time: [prevMonthStart, prevMonthEnd],
      };
    }
  } else if (filter === 'Y') {
    const prevDay = moment().subtract(1, 'day');
    const prevDayStart = moment(prevDay).startOf('day').toDate().toISOString();
    const prevDayEnd = moment(prevDay).endOf('day').toDate().toISOString();
    if (value === 'Total' && type !== 'stage') {
      allLeadsFilter = {
        ...leadsFilter,
        stage: [stage],
        lead_assign_time: [prevDayStart, prevDayEnd],
      };
    } else if (
      value === 'FRESH' ||
      value === 'INTERESTED' ||
      value === 'NOT INTERESTED' ||
      value === 'WON' ||
      value === 'LOST' ||
      value === 'CALL BACK' ||
      value === 'CALLBACK'
    ) {
      allLeadsFilter = {
        ...leadsFilter,
        stage: [value],
        lead_assign_time: [prevDayStart, prevDayEnd],
      };
    } else if (type === 'stage' && value === 'Total') {
      allLeadsFilter = {
        ...leadsFilter,
        lead_assign_time: [prevDayStart, prevDayEnd],
      };
    } else {
      allLeadsFilter = {
        ...leadsFilter,
        [type]: [value],
        stage: [stage],
        lead_assign_time: [prevDayStart, prevDayEnd],
      };
    }
  } else {
    console.log(value);
    if (value === 'Total' && type !== 'stage') {
      allLeadsFilter = { ...leadsFilter, stage: [stage] };
    } else if (
      value === 'FRESH' ||
      value === 'INTERESTED' ||
      value === 'NOT INTERESTED' ||
      value === 'WON' ||
      value === 'LOST' ||
      value === 'CALL BACK' ||
      value === 'CALLBACK'
    ) {
      allLeadsFilter = { ...leadsFilter, stage: [value] };
    } else if (type === 'stage' && value === 'Total') {
      allLeadsFilter = { ...leadsFilter };
    } else {
      allLeadsFilter = { ...leadsFilter, [type]: [value], stage: [stage] };
    }
  }

  let filterValue = {
    uid: uid,
    taskFilter: taskFilter,
    leadFilter: allLeadsFilter,
    role: role,
  };

  console.log('final Data:', filterValue);
  return filterValue;
};

export const apiTaskDrillDown = async (
  filter: string,
  uid: string,
  task: string,
  value: string,
  taskFilter: any,
  leadFilter: any,
  role: boolean
) => {
  let allTaskFilter = {};
  if (filter === 'MTD') {
    const startDate = moment().startOf('month').toDate();
    const endDate = new Date();

    if (task === 'Completed') {
      if (value === 'Total') {
        allTaskFilter = {
          ...taskFilter,
          status: [task],

          completed_at: [startDate, endDate],
        };
      } else {
        allTaskFilter = {
          ...taskFilter,
          status: [task],
          type: [value],
          completed_at: [startDate, endDate],
        };
      }
    } else {
      if (value === 'Total') {
        allTaskFilter = {
          ...taskFilter,
          status: [task],

          due_date: [startDate, endDate],
        };
      } else {
        allTaskFilter = {
          ...taskFilter,
          status: [task],
          type: [value],
          due_date: [startDate, endDate],
        };
      }
    }
  } else if (filter === 'T') {
    const startDate = moment().startOf('day').toDate().toISOString();
    const endDate = moment().endOf('day').toDate().toISOString();

    if (task === 'Completed') {
      if (value === 'Total') {
        allTaskFilter = {
          ...taskFilter,
          status: [task],

          completed_at: [startDate, endDate],
        };
      } else {
        allTaskFilter = {
          ...taskFilter,
          status: [task],
          type: [value],
          completed_at: [startDate, endDate],
        };
      }
    } else {
      if (value === 'Total') {
        allTaskFilter = {
          ...taskFilter,
          status: [task],

          due_date: [startDate, endDate],
        };
      } else {
        allTaskFilter = {
          ...taskFilter,
          status: [task],
          type: [value],
          due_date: [startDate, endDate],
        };
      }
    }
  } else if (filter === 'PM') {
    const prevMonth = moment().subtract(1, 'M');
    const prevMonthStart = moment(prevMonth).startOf('month').toDate();
    const prevMonthEnd = moment(prevMonth).endOf('month').toDate();

    if (task === 'Completed') {
      if (value === 'Total') {
        allTaskFilter = {
          ...taskFilter,
          status: [task],

          completed_at: [prevMonthStart, prevMonthEnd],
        };
      } else {
        allTaskFilter = {
          ...taskFilter,
          status: [task],
          type: [value],
          completed_at: [prevMonthStart, prevMonthEnd],
        };
      }
    } else {
      if (value === 'Total') {
        allTaskFilter = {
          ...taskFilter,
          status: [task],

          due_date: [prevMonthStart, prevMonthEnd],
        };
      } else {
        allTaskFilter = {
          ...taskFilter,
          status: [task],
          type: [value],
          due_date: [prevMonthStart, prevMonthEnd],
        };
      }
    }
  } else if (filter === 'Y') {
    const prevDay = moment().subtract(1, 'day');
    const prevDayStart = moment(prevDay).startOf('day').toDate().toISOString();
    const prevDayEnd = moment(prevDay).endOf('day').toDate().toISOString();

    if (task === 'Completed') {
      if (value === 'Total') {
        allTaskFilter = {
          ...taskFilter,
          status: [task],

          completed_at: [prevDayStart, prevDayEnd],
        };
      } else {
        allTaskFilter = {
          ...taskFilter,
          status: [task],
          type: [value],
          completed_at: [prevDayStart, prevDayEnd],
        };
      }
    } else {
      if (value === 'Total') {
        allTaskFilter = {
          ...taskFilter,
          status: [task],

          due_date: [prevDayStart, prevDayEnd],
        };
      } else {
        allTaskFilter = {
          ...taskFilter,
          status: [task],
          type: [value],
          due_date: [prevDayStart, prevDayEnd],
        };
      }
    }
  } else {
    if (value === 'Total') {
      allTaskFilter = { ...taskFilter, status: [task] };
    } else {
      allTaskFilter = { ...taskFilter, status: [task], type: [value] };
    }
  }
  let finalData = {
    uid: uid,
    taskFilter: allTaskFilter,
    leadFilter: { ...leadFilter, 'leads.associate_status': ['True'] },
    role,
  };

  console.log('Tasks Drilldown:', finalData);
  return finalData;
};

export const apiCallDrilldown = async (
  filter: string,
  uid: string,
  value: string,
  taskFilter: any,
  leadFilter: any,
  callFilter: any,
  role: boolean
) => {
  console.log("uid:"+uid);
  let allCallFilter = {};
  if (filter === 'MTD') {
    const startDate = moment().startOf('month').toDate();
    const endDate = new Date();
    if (value === 'Total') {
      allCallFilter = { ...callFilter, created_at: [startDate, endDate] };
    } else if (value === '0') {
      allCallFilter = {
        ...callFilter,
        duration: [0],
        created_at: [startDate, endDate],
      };
    } else if (value === '0-30') {
      allCallFilter = {
        ...callFilter,
        duration: [30],
        created_at: [startDate, endDate],
      };
    } else if (value === '31-60') {
      allCallFilter = {
        ...callFilter,
        duration: [60],
        created_at: [startDate, endDate],
      };
    } else if (value === '61-120') {
      allCallFilter = {
        ...callFilter,
        duration: [120],
        created_at: [startDate, endDate],
      };
    } else {
      allCallFilter = {
        ...callFilter,
        duration: [121],
        created_at: [startDate, endDate],
      };
    }
  } else if (filter === 'T') {
    const startDate = moment().startOf('day').toDate().toISOString();
    const endDate = moment().endOf('day').toDate().toISOString();
    if (value === 'Total') {
      allCallFilter = { ...callFilter, created_at: [startDate, endDate] };
    } else if (value === '0') {
      allCallFilter = {
        ...callFilter,
        duration: [0],
        created_at: [startDate, endDate],
      };
    } else if (value === '0-30') {
      allCallFilter = {
        ...callFilter,
        duration: [30],
        created_at: [startDate, endDate],
      };
    } else if (value === '31-60') {
      allCallFilter = {
        ...callFilter,
        duration: [60],
        created_at: [startDate, endDate],
      };
    } else if (value === '61-120') {
      allCallFilter = {
        ...callFilter,
        duration: [120],
        created_at: [startDate, endDate],
      };
    } else {
      allCallFilter = {
        ...callFilter,
        duration: [121],
        created_at: [startDate, endDate],
      };
    }
  } else if (filter === 'PM') {
    const prevMonth = moment().subtract(1, 'M');
    const prevMonthStart = moment(prevMonth).startOf('month').toDate();
    const prevMonthEnd = moment(prevMonth).endOf('month').toDate();

    if (value === 'Total') {
      allCallFilter = {
        ...callFilter,
        created_at: [prevMonthStart, prevMonthEnd],
      };
    } else if (value === '0') {
      allCallFilter = {
        ...callFilter,
        duration: [0],
        created_at: [prevMonthStart, prevMonthEnd],
      };
    } else if (value === '0-30') {
      allCallFilter = {
        ...callFilter,
        duration: [30],
        created_at: [prevMonthStart, prevMonthEnd],
      };
    } else if (value === '31-60') {
      allCallFilter = {
        ...callFilter,
        duration: [60],
        created_at: [prevMonthStart, prevMonthEnd],
      };
    } else if (value === '61-120') {
      allCallFilter = {
        ...callFilter,
        duration: [120],
        created_at: [prevMonthStart, prevMonthEnd],
      };
    } else {
      allCallFilter = {
        ...callFilter,
        duration: [121],
        created_at: [prevMonthStart, prevMonthEnd],
      };
    }
  } else if (filter === 'Y') {
    const prevDay = moment().subtract(1, 'day');
    const prevDayStart = moment(prevDay).startOf('day').toDate().toISOString();
    const prevDayEnd = moment(prevDay).endOf('day').toDate().toISOString();

    if (value === 'Total') {
      allCallFilter = { ...callFilter, created_at: [prevDayStart, prevDayEnd] };
    } else if (value === '0') {
      allCallFilter = {
        ...callFilter,
        duration: [0],
        created_at: [prevDayStart, prevDayEnd],
      };
    } else if (value === '0-30') {
      allCallFilter = {
        ...callFilter,
        duration: [30],
        created_at: [prevDayStart, prevDayEnd],
      };
    } else if (value === '31-60') {
      allCallFilter = {
        ...callFilter,
        duration: [60],
        created_at: [prevDayStart, prevDayEnd],
      };
    } else if (value === '61-120') {
      allCallFilter = {
        ...callFilter,
        duration: [120],
        created_at: [prevDayStart, prevDayEnd],
      };
    } else {
      allCallFilter = {
        ...callFilter,
        duration: [121],
        created_at: [prevDayStart, prevDayEnd],
      };
    }
  } else {
    if (value === 'Total') {
      allCallFilter = { ...callFilter };
    } else if (value === '0') {
      allCallFilter = { ...callFilter, duration: [0] };
    } else if (value === '0-30') {
      allCallFilter = { ...callFilter, duration: [30] };
    } else if (value === '31-60') {
      allCallFilter = { ...callFilter, duration: [60] };
    } else if (value === '61-120') {
      allCallFilter = { ...callFilter, duration: [120] };
    } else {
      allCallFilter = { ...callFilter, duration: [121] };
    }
  }
  let finalData = {
    uid: uid,
    taskFilter: taskFilter,
    leadFilter: leadFilter,
    callFilter: allCallFilter,
    role,
  };

  console.log('Calls Drilldown:', finalData);
  return finalData;
};

export const kpiLeadsDrilldownData = async (
  uid: string,
  filter: any,
  value: any,
  taskFilter: any,
  leadFilter: any,
  role: boolean
) => {
  let allLeadsFilter = {};
  if (filter === 'MTD') {
    const startDate = moment().startOf('month').toDate();
    const endDate = new Date();
    allLeadsFilter = {
      ...leadFilter,
      stage: [value],
      lead_assign_time: [startDate, endDate],
    };
  } else if (filter === 'PM') {
    const prevMonth = moment().subtract(1, 'M');
    const prevMonthStart = moment(prevMonth).startOf('month').toDate();
    const prevMonthEnd = moment(prevMonth).endOf('month').toDate();
    allLeadsFilter = {
      ...leadFilter,
      stage: [value],
      lead_assign_time: [prevMonthStart, prevMonthEnd],
    };
  } else if (filter === 'T') {
    const startDate = moment().startOf('day').toDate().toISOString();
    const endDate = moment().endOf('day').toDate().toISOString();
    allLeadsFilter = {
      ...leadFilter,
      stage: [value],
      lead_assign_time: [startDate, endDate],
    };
  } else if (filter === 'Y') {
    const prevDay = moment().subtract(1, 'day');
    const prevDayStart = moment(prevDay).startOf('day').toDate().toISOString();
    const prevDayEnd = moment(prevDay).endOf('day').toDate().toISOString();
    allLeadsFilter = {
      ...leadFilter,
      stage: [value],
      lead_assign_time: [prevDayStart, prevDayEnd],
    };
  } else {
    allLeadsFilter = { ...leadFilter, stage: [value] };
  }

  let finalData = {
    uid: uid,
    leadFilter: allLeadsFilter,
    taskFilter: taskFilter,
    role,
  };
  console.log('Kpi data:', finalData);
  return finalData;
};

export const kpiTaskDrilldownData = async (
  filter: any,
  uid: string,
  value: string,
  taskFilter: any,
  leadFilter: any,
  role: boolean
) => {
  let allTaskFilter = {};
  if (filter === 'MTD') {
    const startDate = moment().startOf('month').toDate();
    const endDate = new Date();
    if (value === 'Completed') {
      allTaskFilter = {
        ...taskFilter,
        status: [value],
        type: ['Meeting', 'Site Visit'],
        completed_at: [startDate, endDate],
      };
    } else {
      allTaskFilter = {
        ...taskFilter,
        status: [value],
        type: ['Meeting', 'Site Visit'],
        due_date: [startDate, endDate],
      };
    }
  } else if (filter === 'PM') {
    const prevMonth = moment().subtract(1, 'M');
    const prevMonthStart = moment(prevMonth).startOf('month').toDate();
    const prevMonthEnd = moment(prevMonth).endOf('month').toDate();
    if (value === 'Completed') {
      allTaskFilter = {
        ...taskFilter,
        status: [value],
        type: ['Meeting', 'Site Visit'],
        completed_at: [prevMonthStart, prevMonthEnd],
      };
    } else {
      allTaskFilter = {
        ...taskFilter,
        status: [value],
        type: ['Meeting', 'Site Visit'],
        due_date: [prevMonthStart, prevMonthEnd],
      };
    }
  } else if (filter === 'T') {
    const startDate = moment().startOf('day').toDate().toISOString();
    const endDate = moment().endOf('day').toDate().toISOString();
    if (value === 'Completed') {
      allTaskFilter = {
        ...taskFilter,
        status: [value],
        type: ['Meeting', 'Site Visit'],
        completed_at: [startDate, endDate],
      };
    } else {
      allTaskFilter = {
        ...taskFilter,
        status: [value],
        type: ['Meeting', 'Site Visit'],
        due_date: [startDate, endDate],
      };
    }
  } else if (filter === 'Y') {
    const prevDay = moment().subtract(1, 'day');
    const prevDayStart = moment(prevDay).startOf('day').toDate().toISOString();
    const prevDayEnd = moment(prevDay).endOf('day').toDate().toISOString();
    if (value === 'Completed') {
      allTaskFilter = {
        ...taskFilter,
        status: [value],
        type: ['Meeting', 'Site Visit'],
        completed_at: [prevDayStart, prevDayEnd],
      };
    } else {
      allTaskFilter = {
        ...taskFilter,
        status: [value],
        type: ['Meeting', 'Site Visit'],
        due_date: [prevDayStart, prevDayEnd],
      };
    }
  } else {
    if (value === 'Completed') {
      allTaskFilter = {
        ...taskFilter,
        status: [value],
        type: ['Meeting', 'Site Visit'],
      };
    } else {
      allTaskFilter = {
        ...taskFilter,
        status: [value],
        type: ['Meeting', 'Site Visit'],
      };
    }
  }
  let finalData = {
    uid: uid,
    leadFilter: leadFilter,
    taskFilter: allTaskFilter,
    role,
  };
  console.log('Kpi task filter:', finalData);

  return finalData;
};

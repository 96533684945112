import React, { FunctionComponent, useEffect, useState } from 'react';
import TopBar from '../../Components/TopBar/TopBar';
import commonStyle from '../common.module.css';

import CustomTable from '../../Components/CustomTable/CustomTable';
import { getDataFromRoute } from '../../Services/contacts';
import { connect, useDispatch } from 'react-redux';
import {
  setClearFilter,
  showSnackbarAction,
  setFilterObject,
  setRefreshContacts,
} from '../../Redux/actions';
import axios from 'axios';

import moment from 'moment';

import { url } from '../../Values/constants';
import {
  CONTACT_COLUMNS,
  datesField,
  booleanObject,
} from '../../Values/tables';
import Firebase from 'firebase/app';
import ApiTopBar from '../../Components/TopBar/ApiTopBar';
import ApiCustomTable from '../../Components/CustomTable/ApiCustomTable';
import Loading from '../../Components/Loading/Loading';

type props = {
  history: any;
  user: any;
  searchString: string;
  filterSort: any;
  filterObject: any;
  role: string;
  organizationUsers: any[];
  refreshContacts: any;
  organizationId:string;
};
let usersList: any[] = [];
let isFinished = false;
const ApiDrilldownPanel: FunctionComponent<props> = ({
  history,
  user,
  searchString,
  filterSort,
  filterObject,
  role,
  organizationUsers,
  refreshContacts,
  organizationId
}) => {
  const [searchedItem, setsearchedItem] = useState('');
  const [columnModal, setColumnModal] = useState(false);
  const [filterData, setFilterData] = useState<any[] | undefined>(undefined);
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const [selectedRowsData, setSelectedRowsData] = useState<any[]>([]);

  const [lastPage, setLastPage] = useState(-1);
  const [allContacts, setAllContacts] = useState<any[] | undefined>(undefined);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(100);
  const [load, setLoad] = useState(false);
  const [allLeads, setAllLeads] = useState(false);
  const [totalCounts, setTotalCounts] = useState<any>();
  const [userMap, setUserMap] = useState<any>(undefined);
  const [localStorageData, setLocalStorageData] = useState<any>({});
  const dispatcher = useDispatch();
  const createUsersList = (email: string, users: any[], uid: boolean) => {
    users.map((item: any) => {
      if (usersList.includes(item)) {
        return;
      } else {
        if (item.reporting_to === email && item.status === 'ACTIVE') {
          if (!usersList.includes(item)) {
            usersList.push(item);
          }

          createUsersList(item.user_email, users, uid);
        }
      }
    });
  };

  useEffect(() => {
   
    console.log(organizationUsers);
    if (organizationUsers) {
      console.log(organizationUsers);
      let uids: { [key: string]: string } = {};

      organizationUsers.forEach((user: any) => {
        uids[user.user_email] = user.reporting_to;
      });
      console.log(uids);
      setUserMap(uids);
    }
  }, [organizationUsers]);
  useEffect(() => {
    var key: any;
    for (var i = 0; i < localStorage.length; i++) {
      key = localStorage.key(i);

      if (key !== 'columns' && key !== 'drilldownData') {
        localStorage.removeItem(key);
      }
    }
    dispatcher(setClearFilter(true));
    const savedData = localStorage.getItem('drilldownData');
    if (savedData) {
      //   setFilterData(getDataFromRoute(JSON.parse(savedData)));
      console.log('data:', JSON.parse(savedData));
      setLocalStorageData(JSON.parse(savedData));
    }
  }, []);

  const callApi = async (
    paginate?: boolean,
    localPageSize?: Number,
    localPage?: Number
  ) => {
    console.log(user);
    if (isFinished === true) {
      return;
    } else {
      console.log('callApi');
      setLoad(true);
      const filters: { [key: string]: any[] } = localStorageData.leadFilter;
      let feild;
      //   Object.keys(filterObject).forEach((item) => {
      //     if (filterObject[item].length > 0) {
      //       filters[item] = filterObject[item];
      //     }
      //   });
      if (!allLeads) {
        filters['transfer_status'] = ['False'];
        filters['associate_status'] = ['True'];
      } else {
        filters['transfer_status'] = ['False', 'True'];
        filters['associate_status'] = ['True'];
      }
      if (searchString === '') {
        feild = 'contact_no';
      } else {
        if (searchString.match(/^[0-9]+$/) != null) {
          feild = 'contact_no';
        } else {
          feild = 'customer_name';
        }
      }
      let records = localPageSize ? localPageSize : pageSize;
      const apiData = {
        uid: localStorageData.uid,
        organizationid:localStorageData.organizationid,
        page: localPage ? localPage : page + 1,
        searchString: searchString,
        sort:
          Object.keys(filterSort).length === 0
            ? { created_at: '-1' }
            : filterSort,
        pageSize: localPageSize ? localPageSize : pageSize,
        taskFilter: localStorageData.taskFilter,
        leadFilter: filters,
        role: localStorageData.role,
        
      };
      console.log('Api Data - ', apiData);
      try {
        axios.defaults.headers.common['x-access-token'] =
          'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoibGFrc2hheSIsImlhdCI6MTYyOTczOTgzMX0.E0mqgfrDj5su4grI1r8PH973srqJy3k2AG_hvCx-EaA';

        const res = await axios.post(url + '/leads/drillDownSearch', apiData);

        let data: any[] = res.data;
        if (data.length < records) {
          isFinished = true;
          setLastPage(page);
        }

        data.forEach((lead) => {
          lead.contactId = lead.Id;

          delete lead['Id'];
          Object.keys(lead).forEach((key) => {
            if (datesField.includes(key) && lead[key] !== '') {
              if (lead[key] !== null) {
                lead[key] = Firebase.firestore.Timestamp.fromDate(
                  moment(lead[key]).toDate()
                );
              } else {
                lead[key] = '';
              }
            }
          });
        });
        console.log(data);
        if (paginate && filterData) {
          let newData = [...filterData, ...data];
          setFilterData(newData);
        } else {
          setFilterData(data);
        }
        setLoad(false);
      } catch (error) {
        setFilterData([]);
        setLoad(false);
        console.log('error', error);
        dispatcher(showSnackbarAction('Please Try Again!!', 'error'));
      }
    }
  };

  useEffect(() => {
    if (localStorageData.uid === undefined) {
      setFilterData([]);
      return;
    }
    // if (userMap === undefined) {
    //   setFilterData([]);
    //   return;
    // }
    isFinished = false;
    setLastPage(-1);
    if (page !== 0) {
      setPage(0);
    }
    callApi(false, pageSize, 1);
  }, [allLeads, localStorageData]);

  useEffect(() => {
    if (
      filterData &&
      filterData.length <= (page + 1) * pageSize &&
      isFinished === false
    ) {
      if (page === 0) {
        callApi(false, pageSize);
      } else {
        callApi(true, pageSize);
      }
    }
  }, [page, pageSize]);

  const callCountApi = async () => {
    axios.defaults.headers.common['x-access-token'] =
      'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoibGFrc2hheSIsImlhdCI6MTYyOTczOTgzMX0.E0mqgfrDj5su4grI1r8PH973srqJy3k2AG_hvCx-EaA';
    const res = await axios.post(url + '/leads/leadCount', {
      uid: localStorageData.uid,
    });

    setTotalCounts(res.data.total);
  };

  useEffect(() => {
    if (localStorageData.uid === undefined) {
      setFilterData([]);
      return;
    }
    callCountApi();
  }, [refreshContacts, localStorageData.uid]);

  useEffect(() => {
    if (refreshContacts === true) {
      callApi();
      getContactsFilter();
      dispatcher(setRefreshContacts(false));
    }
  }, [refreshContacts]);

  const getContactsFilter = async () => {
    axios.defaults.headers.common['x-access-token'] =
      'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoibGFrc2hheSIsImlhdCI6MTYyOTczOTgzMX0.E0mqgfrDj5su4grI1r8PH973srqJy3k2AG_hvCx-EaA';
    const res = await axios.post(url + '/leads/filterValues', {
      uid: localStorageData.uid,
    });

    console.log('Filter data:', res.data[0]);
    if (res.data[0]) {
      dispatcher(setFilterObject({ ...res.data[0], ...booleanObject }));
    } else {
      dispatcher(setFilterObject({}));
    }
  };

  useEffect(() => {
    if (localStorageData.uid === undefined) {
      return;
    }
    getContactsFilter();
  }, [localStorageData.uid]);

  const setUsersList = () => {
    if (role === 'Lead Manager') {
      organizationUsers.map((item: any) => {
        if (usersList.includes(item)) {
          return;
        } else {
          if (item.status === 'ACTIVE') {
            usersList.push(item);
          }
        }
      });
    } else {
      createUsersList(user.user_email, organizationUsers, false);
    }
  };
  return (
    <>
      <div className={commonStyle.topBar}>
        <ApiTopBar
          history={history}
          title={'Add Contacts'}
          path={'/addContacts'}
          changeOwner={() => {
            setUsersList();
          }}
          onChange={(val) => setsearchedItem(val)}
          filterText={'Status'}
          setColumnModal={(data) => setColumnModal(data)}
          show={true}
          showStatusBox={true}
          searchedString={searchString}
          setAllLeads={(data) => setAllLeads(data)}
        />
      </div>

      <div className={commonStyle.parent}>
        {load === true && <Loading />}
        <ApiCustomTable
          tableColumns={CONTACT_COLUMNS}
          tableRows={filterData}
          selectedRows={selectedRows}
          setSelectedRows={(data) => setSelectedRows(data)}
          tableType="User"
          showColumnModal={columnModal}
          hideColumnModal={() => setColumnModal(false)}
          selectedRowsData={selectedRowsData}
          setSelectedRowsData={(data) => setSelectedRowsData(data)}
          setPage={(val) => setPage(val)}
          setPageSize={(val) => setPageSize(val)}
          callApi={callApi}
          pageSize={pageSize}
          page={page}
          isFinished={isFinished}
          totalCount={totalCounts}
          lastPage={lastPage}
        />
      </div>
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    user: state.user.data,
    role: state.user.role,
    filterSort: state.filterSort,
    refreshContacts: state.refresh.refreshContacts,
    searchString: state.searchItem.contactsSearchString,
    filterObject: state.filter,
    organizationUsers: state.organizationUsers.data,
    organizationId: state.organization.id,
  };
};
export default connect(mapStateToProps)(ApiDrilldownPanel);

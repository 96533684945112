import React, {
  FunctionComponent,
  useEffect,
  useRef,
  useState,
} from "react";
import styles from "./ContactDetails.module.css";
import { IoIosAddCircleOutline } from "react-icons/io";
import { BiPencil } from "react-icons/bi";
import ContactButton from "../../Components/Button/ContactButton";
import { useLocation } from "react-router";
import {
  changeLeadStage,
  fetchContactResources,
  fetchLeadTasks,
  toggleis_button_called,
} from "../../Services/contacts";
import {
  getDateAndTime,
  properFormat,
} from "../../Values/utils";
import {
  HiVideoCamera,
  HiOutlinePhotograph,
  HiOutlineMicrophone,
  HiPhone,
} from "react-icons/hi";
import { ImFileText2 } from "react-icons/im";
import InterestedLeadModal from "../../Components/Modals/InterestedLeadModal/InterestedLeadModal";
import NotInterestedLeadModal from "../../Components/Modals/NotInterestedModal/NotInterestedModal";
import CallBackModal from "../../Components/Modals/CallBackModal/CallBackModal";
import LostModal from "../../Components/Modals/LostModal/LostModal";
import CreateModal from "../../Components/Modals/CreateModal/CreateModal";
import NotesModal from "../../Components/Modals/NotesModal/NotesModal";
import { connect, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import RescheduleModal from "../../Components/Modals/RescheduleModal/RescheduleModal";
import ImportModal from "../../Components/Modals/ImportModal/ImportContactsModal";
import ProfileImageModal from "../../Components/Modals/ProfileImageModal/ProfileImageModal";
import AttachmentModal from "../../Components/Modals/AttachmentModal/AttachmentModal";
import Loading from "../../Components/Loading/Loading";
import OwnerModal from "../../Components/Modals/ChangeOwnerModal/ChangeOwnerModal";
import axios from "axios";
import { fetchCustomButtons } from "../../Services/organizations";
import { showSnackbarAction } from "../../Redux/actions";

type props = {
  user: any;
  organizationUsers: any;
};

let usersList: any[] = [];
const ContactDetails: FunctionComponent<props> = ({
                                                    user,
                                                    organizationUsers,
                                                  }) => {
  const location: any = useLocation();
  if(location.state == undefined){
    window.location.href = "https://login1.read-pro.com/";
  }
  const [tasksList, setTasksList] = useState<
      any[] | undefined
      >(undefined);
  const [notesList, setNotesList] = useState<
      any[] | undefined
      >(undefined);
  const [attachmentsList, setAttachmentsList] = useState<
      any[] | undefined
      >(undefined);
  const [load, setLoad] = useState(false);

  // to open the custom button dropdwon if there are more than 2 custom buttoms
  const [open, setOpen] = useState(false);
  const drop = useRef(null);

  // top css property for dropdown custom button
  const top = 30;
  // margin top css property for dropdown custom button
  const topMargin = 5;

  const [callLogsList, setCallLogsList] = useState<
      any[] | undefined
      >(undefined);
  const [customButtons, setCustomButtons] = useState<
      any[] | undefined
      >(undefined);
  const [title, setTitle] = useState("");
  const [interestedModal, setInterestedModal] =
      useState(false);
  const [callbackModal, setCallbackModal] = useState(false);
  const [notInterestedModal, setNotInterestedModal] =
      useState(false);
  const [lostModal, setLostModal] = useState(false);
  const [createModal, setCreateModal] = useState(false);
  const [rescheduleModal, setRescheduleModal] =
      useState(false);
  const [noteModal, setNoteModal] = useState(false);
  const [picModal, setPicModal] = useState(false);
  const [vidModal, setVidModal] = useState(false);
  const [fileModal, setFileModal] = useState(false);
  const [ownerButton, setOwnerButton] = useState(false);

  const createUsersList = (
      email: string,
      users: any[],
      uid: boolean
  ) => {
    users.map((item: any) => {
      if (usersList.includes(item)) {
        return;
      } else {
        if (
            item.reporting_to === email &&
            item.status === "ACTIVE"
        ) {
          if (!usersList.includes(item)) {
            usersList.push(item);
          }

          createUsersList(item.user_email, users, uid);
        }
      }
    });
  };

  useEffect(() => {
    const subuser = fetchLeadTasks(
        (data) => setTasksList(data),
        location.state.detail.contactId
    );
    const subcontacts = fetchContactResources(
        (data) => setNotesList(data),
        (data) => setAttachmentsList(data),
        (data) => setCallLogsList(data),
        location.state.detail.contactId
    );
    const subToggleButtons = fetchCustomButtons(
        user.organization_id,
        (data) => setCustomButtons(data)
    );
    sessionStorage.setItem("org",user.organization_id)
    return () => {
      subuser();
      subcontacts();
      subToggleButtons();
    };
  }, [location.state,user]);
  const dispatcher = useDispatch();
  const history = useHistory();

  const callCustomButton = async (
      url: string,
      parameters: any,
      headers: any,
      type: string
  ) => {
    // console.log(url, parameters, headers, type);

    const isObjectEmpty = (object: any) => {
      var isEmpty = true;
      for (let keys in object) {
        isEmpty = false;
        break; // exiting since we found that the object is not empty
      }
      return isEmpty;
    };

    let res;
    if (type.toLowerCase() === "post") {
      for (let key in parameters) {
        let wildcard = parameters[key].split("$");
        if (wildcard.length > 1) {
          parameters[key] =
              location.state.detail[wildcard[1]];
        }
      }

      if (!isObjectEmpty(headers)) {
        const config = {
          headers: headers,
        };
        res = await axios.post(url, parameters, config);
      } else {
        res = await axios.post(url, parameters);
      }
      dispatcher(
          showSnackbarAction(
              "Custom Button Action Successful",
              "success"
          )
      );
    } else if (type.toLowerCase() === "get") {
      res = await axios.get(url);
    }

    toggleis_button_called(location.state.detail.contactId);
  };

  return (
      <div className={styles.parent}>
        <div className={styles.child}>
          <div className={styles.detailsContainer}>
            <div className={styles.contactDetails}>
              <div className={styles.firstContainer}>
                <div className={styles.nameDiv}>
                  <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                  >
                    <div>
                      <button
                          className={styles.dropdownButton}
                          onClick={() => {
                            setOwnerButton(true);
                            createUsersList(
                                user.user_email,
                                organizationUsers,
                                false
                            );
                          }}
                      >
                        Change Owner
                      </button>
                    </div>
                    {customButtons &&
                    customButtons.length === 1 ? (
                        <button
                            className={styles.ownerButton}
                            onClick={() => {
                              callCustomButton(
                                  customButtons[0]?.url,
                                  customButtons[0]?.parameters,
                                  customButtons[0]?.headers,
                                  customButtons[0]?.type
                              );
                            }}
                        >
                          {customButtons[0]?.name}
                        </button>
                    ) : (
                        <div
                            className="dropdown"
                            ref={drop}
                            style={{
                              position: "relative",
                              // margin: '16px',
                              // width: 'auto',
                              display: "inline-block",
                            }}
                        >
                          <button
                              className={styles.dropdownButton}
                              onClick={() =>
                                  setOpen((open) => !open)
                              }
                          >
                            {!open ? "Open" : "Close"} Custom
                            buttons dropdown
                          </button>
                          {open && (
                              <>
                                {customButtons &&
                                    customButtons.map(
                                        (tb: any, index: any) => (
                                            <button
                                                style={{
                                                  position: "absolute",
                                                  top: top * (index + 1),
                                                  marginTop:
                                                      topMargin *
                                                      (2 * (index + 1) - 1),
                                                }}
                                                className={
                                                  styles.dropdownButton
                                                }
                                                onClick={() => {
                                                  callCustomButton(
                                                      tb.url,
                                                      tb.parameters,
                                                      tb.headers,
                                                      tb.type
                                                  );
                                                  setOpen(false);
                                                }}
                                            >
                                              {tb.name}
                                            </button>
                                        )
                                    )}
                              </>
                          )}
                          {/* {open && (
                      <>
                        <button
                          style={{
                            position: 'absolute',
                            top: '30px',
                            marginTop: '5px',
                          }}
                          className={styles.dropdownButton}
                        >
                          Send SMS
                        </button>
                        <button
                          style={{
                            position: 'absolute',
                            top: '60px',
                            marginTop: '15px',
                          }}
                          className={styles.dropdownButton}
                        >
                          Send SMS
                        </button>
                        <button
                          style={{
                            position: 'absolute',
                            top: '90px',
                            marginTop: '25px',
                          }}
                          className={styles.dropdownButton}
                        >
                          Send SMS
                        </button>
                      </>
                    )} */}
                        </div>
                    )}
                  </div>

                  <p className={styles.name}>
                    {location.state.detail.customer_name
                        .toLowerCase()
                        .split(" ")
                        .map(function (word: any) {
                          return (
                              word.charAt(0).toUpperCase() +
                              word.slice(1)
                          );
                        })
                        .join(" ")}
                  </p>
                  <p className={styles.customerEmail}>
                    {location.state.detail.email}
                  </p>
                </div>

                <div className={styles.buttonContainer}>
                  {properFormat(
                      location.state.detail.stage
                  ) === "Fresh" ? (
                      <>
                        <ContactButton
                            color="#008000"
                            title="Interested"
                            clicked={() => {
                              setInterestedModal(true);
                            }}
                        />
                        <ContactButton
                            color="#0000ff"
                            title="Call Back"
                            clicked={() => {
                              setCallbackModal(true);
                            }}
                        />
                        <ContactButton
                            color="#ff0000"
                            title="Not-Interested"
                            clicked={() => {
                              setNotInterestedModal(true);
                            }}
                        />
                      </>
                  ) : properFormat(
                      location.state.detail.stage
                  ) === "Call Back" ? (
                      <>
                        <ContactButton
                            color="#008000"
                            title="Interested"
                            clicked={() => {
                              setInterestedModal(true);
                            }}
                        />
                        <ContactButton
                            color="#0000ff"
                            title="Re-Callback"
                            clicked={() => {
                              setCallbackModal(true);
                            }}
                        />
                        <ContactButton
                            color="#ff0000"
                            title="Not-Interested"
                            clicked={() => {
                              setNotInterestedModal(true);
                            }}
                        />
                      </>
                  ) : properFormat(
                      location.state.detail.stage
                  ) === "Interested" ? (
                      <>
                        <ContactButton
                            color="#008000"
                            title="Won"
                            clicked={() => {
                              changeLeadStage(
                                  location.state.detail.contactId,
                                  { stage: "WON" },
                                  () => {},
                                  dispatcher,
                                  () => {},
                                  history,
                                  tasksList
                              );
                            }}
                        />
                        <ContactButton
                            color="#ff0000"
                            title="Lost"
                            clicked={() => {
                              setLostModal(true);
                            }}
                        />
                        <ContactButton
                            color="#0000ff"
                            title="Re Schedule"
                            clicked={() => {
                              setRescheduleModal(true);
                            }}
                        />
                        <ContactButton
                            color="#0000ff"
                            title="Create"
                            clicked={() => {
                              setCreateModal(true);
                            }}
                        />
                      </>
                  ) : (
                      <></>
                  )}
                </div>

                <div className={styles.data}>
                  <p className={styles.heading}>
                    Alternate No.
                  </p>
                  <p className={styles.text}>
                    {location.state.detail.alternate_no}
                  </p>
                </div>
                <div className={styles.data}>
                  <p className={styles.heading}>Location</p>
                  <p className={styles.text}>
                    {location.state.detail.location}
                  </p>
                </div>
                <div className={styles.data}>
                  <p className={styles.heading}>
                    Property Type
                  </p>
                  <p className={styles.text}>
                    {location.state.detail.property_type}
                  </p>
                </div>
                <div className={styles.data}>
                  <p className={styles.heading}>
                    Property Stage
                  </p>
                  <p className={styles.text}>
                    {location.state.detail.property_stage}
                  </p>
                </div>
                <div className={styles.data}>
                  <p className={styles.heading}>Budget</p>
                  <p className={styles.text}>
                    {location.state.detail.budget}
                  </p>
                </div>
                <div className={styles.data}>
                  <p className={styles.heading}>Project</p>
                  <p className={styles.text}>
                    {location.state.detail.project}
                  </p>
                </div>
                <div className={styles.data}>
                  <p className={styles.heading}>
                    Not Interested Reason
                  </p>
                  <p className={styles.text}>
                    {location.state.detail.not_int_reason}
                  </p>
                </div>
                <div className={styles.data}>
                  <p className={styles.heading}>
                    Lost Reason
                  </p>
                  <p className={styles.text}>
                    {location.state.detail.lost_reason}
                  </p>
                </div>
                <div className={styles.data}>
                  <p className={styles.heading}>
                    Other Not Interested Reason
                  </p>
                  <p className={styles.text}>
                    {
                      location.state.detail
                          .other_not_init_reason
                    }
                  </p>
                </div>
                <div className={styles.data}>
                  <p className={styles.heading}>Addset</p>
                  <p className={styles.text}>
                    {location.state.detail.addset}
                  </p>
                </div>
                <div className={styles.data}>
                  <p className={styles.heading}>
                    Stage Change Date & Time
                  </p>
                  <p className={styles.text}>
                    {location.state.detail.stage_change_at !==
                    ""
                        ? getDateAndTime(
                            location.state.detail
                                .stage_change_at,
                            "LLL"
                        )
                        : ""}
                  </p>
                </div>
              </div>

              <div className={styles.secondContainer}>
                <div className={styles.emailContainer}>
                  <p className={styles.email}>
                    {
                      location.state.detail
                          .contact_owner_email
                    }
                  </p>
                  <p className={styles.email}>
                    {location.state.detail.contact_no}
                  </p>
                </div>
                <div className={styles.data}>
                  <p className={styles.heading}>
                    Next Follow Up Type
                  </p>
                  <p className={styles.text}>
                    {
                      location.state.detail
                          .next_follow_up_type
                    }
                  </p>
                </div>
                <div className={styles.data}>
                  <p className={styles.heading}>
                    Next Follow Up Date & Time
                  </p>
                  <p className={styles.text}>
                    {location.state.detail
                        .next_follow_up_date_time !== ""
                        ? getDateAndTime(
                            location.state.detail
                                .next_follow_up_date_time,
                            "LLL"
                        )
                        : ""}
                  </p>
                </div>
                <div className={styles.data}>
                  <p className={styles.heading}>
                    Lead Source
                  </p>
                  <p className={styles.text}>
                    {location.state.detail.lead_source}
                  </p>
                </div>
                <div className={styles.data}>
                  <p className={styles.heading}>
                    Reporting To
                  </p>
                  <p className={styles.text}>
                    {location.state.detail.reporting_to}
                  </p>
                </div>
                <div className={styles.data}>
                  <p className={styles.heading}>
                    Property Sub Type
                  </p>
                  <p className={styles.text}>
                    {location.state.detail.property_sub_type}
                  </p>
                </div>
                <div className={styles.data}>
                  <p className={styles.heading}>
                    Call Back Reason
                  </p>
                  <p className={styles.text}>
                    {location.state.detail.call_back_reason}
                  </p>
                </div>
                <div className={styles.data}>
                  <p className={styles.heading}>
                    Other Lost Reason
                  </p>
                  <p className={styles.text}>
                    {location.state.detail.other_lost_reason}
                  </p>
                </div>
                <div className={styles.data}>
                  <p className={styles.heading}>Created By</p>
                  <p className={styles.text}>
                    {location.state.detail.created_by}
                  </p>
                </div>
                <div className={styles.data}>
                  <p className={styles.heading}>
                    Created Date & Time
                  </p>
                  <p className={styles.text}>
                    {getDateAndTime(
                        location.state.detail.created_at,
                        "LLL"
                    )}
                  </p>
                </div>
                <div className={styles.data}>
                  <p className={styles.heading}>
                    Lead Assign Date & Time
                  </p>
                  <p className={styles.text}>
                    {getDateAndTime(
                        location.state.detail.lead_assign_time,
                        "LLL"
                    )}
                  </p>
                </div>
                <div className={styles.data}>
                  <p className={styles.heading}>Campaign</p>
                  <p className={styles.text}>
                    {location.state.detail.campaign}
                  </p>
                </div>
                <div className={styles.data}>
                  <p className={styles.heading}>
                    Modified Date & Time
                  </p>
                  <p className={styles.text}>
                    {location.state.detail.modified_at !== ""
                        ? getDateAndTime(
                            location.state.detail.modified_at,
                            "LLL"
                        )
                        : ""}
                  </p>
                </div>
              </div>
            </div>

            <div className={styles.taskDetails}>
              <p className={styles.task}>Tasks</p>
              <div className={styles.tableContainer}>
                <table className={styles.table}>
                  <thead className={styles.head}>
                  <tr>
                    <th className={styles.th}>Tasks</th>
                    <th className={styles.th}>Date</th>
                    <th className={styles.th}>Time</th>
                    <th className={styles.th}>
                      Status Type
                    </th>
                  </tr>
                  </thead>
                  <tbody>
                  {tasksList?.map(
                      (item: any, index: number) => (
                          <tr key={index}>
                            <td className={styles.td}>
                              {item.type}
                            </td>
                            <td className={styles.td}>
                              {item.due_date.toDate
                                  ? getDateAndTime(
                                      item.due_date.toDate(),
                                      "DD MMM YY"
                                  )
                                  : ""}
                            </td>
                            <td className={styles.td}>
                              {item.due_date.toDate
                                  ? getDateAndTime(
                                      item.due_date.toDate(),
                                      "LT"
                                  )
                                  : ""}
                            </td>
                            <td className={styles.td}>
                              {item.status}
                            </td>
                          </tr>
                      )
                  )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className={styles.notesContainer}>
            <div className={styles.childNotes}>
              <div className={styles.notesSection}>
                <div className={styles.notesHead}>
                  <p className={styles.notes}>Notes</p>
                  <IoIosAddCircleOutline
                      color="#279ea0"
                      size="20"
                      onClick={() => setNoteModal(true)}
                  />
                </div>
                <div className={styles.container}>
                  {notesList?.map(
                      (item: any, index: number) => (
                          <div className={styles.noteDisplay}>
                            <div className={styles.box}>
                              <div className={styles.innerBox}>
                                <div
                                    className={
                                      styles.dateAndCreatedByBox
                                    }
                                >
                                  <p className={styles.date}>
                                    {getDateAndTime(
                                        item.created_at.toDate(),
                                        "LLL"
                                    )}
                                  </p>
                                  <p className={styles.createdBy}>
                                    {item.userEmail}
                                  </p>
                                  {/* <BiPencil size="15" color="#279ea0" /> */}
                                </div>
                                <p className={styles.note}>
                                  {item.note}
                                </p>
                              </div>
                            </div>
                          </div>
                      )
                  )}
                  {notesList?.length === 0 && (
                      <p className={styles.noContent}>
                        No Notes{" "}
                      </p>
                  )}
                </div>
              </div>
              <div className={styles.attachmentSection}>
                <div className={styles.notesHead}>
                  <p className={styles.notes}>Attachments</p>
                  {/* <IoIosAddCircleOutline color="#279ea0" size="20" /> */}
                  <div className={styles.attachmentItems}>
                    <div style={{ padding: "0 10px" }}>
                      <HiVideoCamera
                          size={15}
                          color="#279ea0"
                          onClick={() => {
                            setVidModal(true);
                            setTitle("Upload Video");
                          }}
                      />
                    </div>
                    <div style={{ padding: "0 10px" }}>
                      <HiOutlinePhotograph
                          size={15}
                          color="#279ea0"
                          onClick={() => {
                            console.log("clicked");
                            setPicModal(true);
                            setTitle("Upload Image");
                          }}
                      />
                    </div>
                    <div style={{ padding: "0 10px" }}>
                      <ImFileText2
                          size={15}
                          color="#279ea0"
                          onClick={() => {
                            setFileModal(true);
                            setTitle("Upload Files");
                          }}
                      />
                    </div>
                  </div>
                </div>
                <div className={styles.attachmentContainer}>
                  {load && <Loading />}
                  {attachmentsList?.map(
                      (item: any, index: number) => (
                          <div
                              className={styles.attachmentBox}
                              key={index}
                          >
                            {item.type === "video" && (
                                <HiVideoCamera
                                    size={15}
                                    color="#279ea0"
                                />
                            )}
                            {item.type === "photo" && (
                                <HiOutlinePhotograph
                                    size={15}
                                    color="#279ea0"
                                />
                            )}
                            {item.type === "audio" && (
                                <HiOutlineMicrophone
                                    size={15}
                                    color="#279ea0"
                                />
                            )}
                            {item.type === "file" && (
                                <ImFileText2
                                    size={15}
                                    color="#279ea0"
                                />
                            )}
                            <p className={styles.note}>
                              <a href={item.url}>{item.name}</a>
                            </p>
                          </div>
                      )
                  )}
                  {attachmentsList?.length === 0 && (
                      <p className={styles.noContent}>
                        No Attachments{" "}
                      </p>
                  )}
                </div>
              </div>

              <div className={styles.notesSection}>
                <div className={styles.notesHead}>
                  <p className={styles.notes}>Call Logs</p>
                  <IoIosAddCircleOutline
                      color="#279ea0"
                      size="20"
                  />
                </div>
                <div className={styles.attachmentContainer}>
                  {callLogsList?.map(
                      (item: any, index: number) => (
                          <div
                              className={styles.attachmentBox}
                              key={index}
                          >
                            <HiPhone size={15} color="#279ea0" />

                            <p className={styles.note}>
                              {item.callTime}
                            </p>
                            <p
                                className={styles.note}
                                style={{ marginLeft: "auto" }}
                            >
                              {getDateAndTime(
                                  item.created_at.toDate(),
                                  "LLL"
                              )}
                            </p>
                          </div>
                      )
                  )}
                  {callLogsList?.length === 0 && (
                      <p className={styles.noContent}>
                        No Call Logs{" "}
                      </p>
                  )}
                </div>
              </div>
            </div>
          </div>

          {ownerButton && (
              <OwnerModal
                  open={ownerButton}
                  close={() => {
                    setOwnerButton(false);
                    history.push("/");
                  }}
                  usersList={usersList}
                  rowsSelected={[location.state.detail]}
                  organization_id={user.organization_id}
              />
          )}

          {picModal && (
              <AttachmentModal
                  open={picModal}
                  close={() => setPicModal(false)}
                  title={title}
                  type={"photo"}
                  contactDetail={location.state.detail}
                  attachmentsList={
                    attachmentsList ? attachmentsList : []
                  }
              />
          )}
          {/* {console.log(location.state.detail)}  */}
          {vidModal && (
              <AttachmentModal
                  open={vidModal}
                  close={() => setVidModal(false)}
                  title={title}
                  type={"video"}
                  contactDetail={location.state.detail}
                  attachmentsList={
                    attachmentsList ? attachmentsList : []
                  }
              />
          )}
          {fileModal && (
              <AttachmentModal
                  open={fileModal}
                  close={() => setFileModal(false)}
                  title={title}
                  type={"file"}
                  contactDetail={location.state.detail}
                  attachmentsList={
                    attachmentsList ? attachmentsList : []
                  }
              />
          )}
          {interestedModal === true && (
              <InterestedLeadModal
                  contactDetail={location.state.detail}
                  close={() => setInterestedModal(false)}
                  open={interestedModal}
                  notes={notesList ? notesList : []}
                  tasksData={tasksList ? tasksList : []}
              />
          )}
          {notInterestedModal === true && (
              <NotInterestedLeadModal
                  contactDetail={location.state.detail}
                  close={() => setNotInterestedModal(false)}
                  open={notInterestedModal}
                  tasksData={tasksList ? tasksList : []}
                  notes={notesList ? notesList : []}
              />
          )}
          {callbackModal === true && (
              <CallBackModal
                  contactDetail={location.state.detail}
                  close={() => setCallbackModal(false)}
                  open={callbackModal}
                  tasksData={tasksList ? tasksList : []}
                  notes={notesList ? notesList : []}
              />
          )}
          {lostModal === true && (
              <LostModal
                  contactDetail={location.state.detail}
                  close={() => setLostModal(false)}
                  open={lostModal}
                  tasksData={tasksList ? tasksList : []}
                  notes={notesList ? notesList : []}
              />
          )}
          {createModal === true && (
              <CreateModal
                  contactDetail={location.state.detail}
                  close={() => setCreateModal(false)}
                  open={createModal}
                  tasksData={tasksList ? tasksList : []}
                  notes={notesList ? notesList : []}
              />
          )}
          {rescheduleModal === true && (
              <RescheduleModal
                  contactDetail={location.state.detail}
                  close={() => setRescheduleModal(false)}
                  open={rescheduleModal}
                  tasksData={tasksList ? tasksList : []}
                  notes={notesList ? notesList : []}
              />
          )}
          {noteModal === true && (
              <NotesModal
                  notes={notesList ? notesList : []}
                  contactDetail={location.state.detail}
                  close={() => setNoteModal(false)}
                  open={noteModal}
                  userEmail={user.user_email}
              />
          )}
        </div>
      </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    user: state.user.data,
    role: state.user.role,
    contacts: state.contacts,
    organizationUsers: state.organizationUsers.data,
    teamLeadUsers: state.teamLeadUsers.data,
  };
};

export default connect(mapStateToProps)(ContactDetails);
import React, { FunctionComponent } from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import styles from '../../CommonStyles/CommonGraphStyles.module.css';
import { Bar } from 'react-chartjs-2';
import { connect, useDispatch } from 'react-redux';
import { getTaskDrillDownChartOrg } from '../../Services/analytics';
import { setClearFilter, updateStage } from '../../Redux/actions';
import moment from 'moment';
import { changeDateForRoute } from '../../Services/contacts';

type props = {
  analyticsData: any;
  type: 'Pending' | 'Completed' | 'Overdue';
  color: string[];
  style?: any;
  role: any;
  history: any;
  contacts: any;
  filter: 'MTD' | 'YTD' | 'PM' | 'All' | 'CUSTOM';
  organizationUsers: any[];
  user: any;
  teamLeadUsers: any[];
};

const TaskBarChartOrg: FunctionComponent<props> = ({
  analyticsData,
  type,
  color,
  style,
  role,
  history,
  contacts,
  filter,
  organizationUsers,
  user,
  teamLeadUsers,
}) => {
  const [data, setData] = useState<any>({});
  const [allContacts, setAllContacts] = useState<any[] | undefined>(undefined);

  useEffect(() => {
    let contact: any[] = [];
    Object.keys(contacts.data).forEach((key) => {
      contact = [...contact, ...contacts.data[key]];
    });
    setAllContacts(contact);
  }, [contacts.data]);
  useEffect(() => {
    const temp: { [key: string]: number } = {};

    Object.values(analyticsData).forEach((analytics: any) => {
      Object.keys(analytics.totalTaskCount[type]).forEach((tasks) => {
        if (temp[tasks]) {
          temp[tasks] += analytics.totalTaskCount[type][tasks];
        } else {
          temp[tasks] = analytics.totalTaskCount[type][tasks];
        }
      });
    });

    setData(temp);
  }, [analyticsData]);

  const chartData = {
    labels: Object.keys(data),
    datasets: [
      {
        label: `${type} Task Summary`,
        backgroundColor: color,
        borderColor: color,
        barThickness: 40,
        borderWidth: 2,
        data: Object.values(data),
      },
    ],
  };

  const dispatcher = useDispatch();

  const getDrillDownData = (feildValue: string) => {
    if (type === 'Completed') {
    } else {
      var key: any;
      for (var i = 0; i < localStorage.length; i++) {
        key = localStorage.key(i);

        if (key !== 'columns') {
          localStorage.removeItem(key);
        }
      }
      dispatcher(setClearFilter(true));

      if (allContacts) {
        let mapReportingTo: { [key: string]: string } = {};
        if (organizationUsers) {
          organizationUsers.forEach((item: any) => {
            if (mapReportingTo[item.uid] === undefined) {
              mapReportingTo[item.uid] = item.reporting_to;
            }
          });
        }
        if (user.profile === 'Team Lead') {
          let drillDownData = getTaskDrillDownChartOrg(
            allContacts.filter((item) => teamLeadUsers.includes(item.uid)),
            filter,
            type,
            feildValue
          );
          drillDownData.forEach((item: any) => {
            if (item.uid === '') {
              item['reporting_to'] = '';
            } else {
              item['reporting_to'] = mapReportingTo[item.uid]
                ? mapReportingTo[item.uid]
                : '';
            }
          });
          localStorage.setItem(
            'drilldownData',
            changeDateForRoute(drillDownData)
          );
          window.open('/drilldownData', '_blank');
        } else {
          let drillDownData = getTaskDrillDownChartOrg(
            allContacts,
            filter,
            type,
            feildValue
          );
          drillDownData.forEach((item: any) => {
            if (item.uid === '') {
              item['reporting_to'] = '';
            } else {
              item['reporting_to'] = mapReportingTo[item.uid]
                ? mapReportingTo[item.uid]
                : '';
            }
          });
          localStorage.setItem(
            'drilldownData',
            changeDateForRoute(drillDownData)
          );
          window.open('/drilldownData', '_blank');
        }
      }
    }
  };

  return (
    <div className={styles.graphBox} style={style}>
      <Bar
        data={chartData}
        options={{
          title: {
            display: false,
            fontSize: 20,
          },
          responsive: true,
          maintainAspectRatio: false,
          onClick: (e: any, item: any) => {
            getDrillDownData(Object.keys(data)[item[0]?.index]);
          },
        }}
        type="line"
      />
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    role: state.user.role,
    contacts: state.contacts,
    organizationUsers: state.organizationUsers.data,
    user: state.user.data,
    teamLeadUsers: state.teamLeadUsers.data,
  };
};
export default connect(mapStateToProps)(TaskBarChartOrg);

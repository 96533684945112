import React, { FunctionComponent, useState, useRef, useEffect } from "react";
import styles from "./DeleteRecordsModal.module.css";
import Modal from "react-modal";
import { connect, useDispatch } from "react-redux";

import Loading from "../../Loading/Loading";
import Dropdown from "../../DropDown/Dropdown";
import { showSnackbarAction } from "../../../Redux/actions";
import { DeleteRecords } from "../../../Services/users";

type props = {
  open: boolean;
  close: () => void;
  filterData: any[] | undefined;
  organizationUsers: any;
  organizationId: string;
};

const DeleteRecordsModal: FunctionComponent<props> = ({
  open,
  close,
  filterData,
  organizationUsers,
  organizationId,
}) => {
  const leadsRef: any = useRef();
  const dispatcher = useDispatch();
  const [load, setLoad] = useState(false);
  const [emailSelected, setEmailSelected] = useState<any>({
    label: "Select",
    value: "Select",
  });
  const [uid, setUid] = useState<any>();
  const [User_Email, setEmail] = useState<any[]>();

  useEffect(() => {
    let uids: { [key: string]: string } = {};
    let emails: any[] = [];
    organizationUsers.forEach((item: any) => {
      emails.push(item.user_email);
      uids[item.user_email] = item.uid;
    });
    setEmail(emails);
    setUid(uids);
  }, [organizationUsers]);

  const onSave = () => {
    if (emailSelected.value === "" || emailSelected.value === "Select") {
      dispatcher(showSnackbarAction("Please Select A User!!", "error"));
    } else {
      console.log(organizationId);
      console.log(uid[emailSelected.value]);
      setLoad(true);
      DeleteRecords(
        uid[emailSelected.value],
        organizationId,
        dispatcher,
        (data) => setLoad(data),
        close
      );
    }
  };

  return (
    <Modal
      isOpen={open}
      className={styles.parent}
      overlayClassName={styles.overlay}
      onRequestClose={close}
      shouldCloseOnOverlayClick={true}
      shouldCloseOnEsc={true}
    >
      {load && <Loading />}
      <div className={styles.headingContainer}>
        <p className={styles.heading}>Delete Records</p>
      </div>

      <div style={{ marginTop: "50px", width: "100%" }}>
        <Dropdown
          option={User_Email}
          selectedValue={emailSelected}
          setSelectedValue={(value) => setEmailSelected(value)}
        />
      </div>
      <div className={styles.buttonView}>
        <button className={styles.cancelButton} onClick={close}>
          Cancel
        </button>
        <button className={styles.saveButton} onClick={onSave}>
          Save
        </button>
      </div>
    </Modal>
  );
};

const mapStateToProps = (state: any) => {
  return {
    organizationId: state.organization.id,
    organizationUsers: state.organizationUsers.data,
  };
};

export default connect(mapStateToProps)(DeleteRecordsModal);

import Modal from "react-modal";
import React, { FunctionComponent, useEffect, useState } from "react";
import styles from "./ImportModal.module.css";
import { IoIosClose } from "react-icons/io";
import {
  fetchContactsFile,
  uploadContactsFile,
} from "../../../Services/contacts";
import { useDispatch } from "react-redux";
import Loading from "../../Loading/Loading";
import moment from "moment";

type props = {
  open: boolean;
  close: () => void;
  organization_id: string;
  history: any;
  usersList: any[];
  user: any;
};

const NewImportContactModal: FunctionComponent<props> = ({
  open,
  close,
  organization_id,
  history,
  usersList,
  user,
}) => {
  const [load, setLoad] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState<any[]>([]);
  const dispatcher = useDispatch();

  useEffect(() => {
    if (organization_id) {
      const unsub = fetchContactsFile(
        organization_id,
        (data: any) => setUploadedFiles(data),
        dispatcher
      );
      return () => {
        unsub();
      };
    }
  }, [organization_id]);
  const upload = async (file: any) => {
    setLoad(true);
    uploadContactsFile(
      file,
      organization_id,
      dispatcher,
      (data: boolean) => setLoad(data),
      user.user_first_name + " " + user.user_last_name
    );
  };

  return (
    <Modal
      style={{ overlay: { zIndex: 100 } }}
      className={styles.parent}
      isOpen={open}
      onRequestClose={close}
      shouldCloseOnOverlayClick={true}
      shouldCloseOnEsc={true}
    >
      {load === true && <Loading />}
      <div className={styles.child}>
        {load === true && <Loading />}
        <div className={styles.cross} onClick={close}>
          <IoIosClose size={35} color={"#808080"} />
        </div>
        <p className={styles.contactForm}>Import Data for "Contact Form"</p>
        <div className={styles.tableBox}>
          <table className={styles.table}>
            <thead className={styles.head}>
              <tr>
                <th className={styles.th}>Sno.</th>
                <th className={styles.th}>Request Id</th>
                <th className={styles.th}>Created At</th>
                <th className={styles.th}>Status</th>
                <th className={styles.th}>Upload Count</th>
                <th className={styles.th}>Uploaded File</th>
                <th className={styles.th}>Processed File</th>
              </tr>
            </thead>
            <tbody>
              {uploadedFiles.map((item: any, index: number) => (
                <tr key={index} className={styles.no}>
                  <td className={styles.td}>{index + 1}</td>
                  <td className={styles.td}>{item.request_id}</td>
                  <td className={styles.td}>
                    {moment(item.created_at.toDate()).format("MM/DD/YY HH:mm")}
                  </td>
                  <td className={styles.td}>{item.status}</td>
                  <td className={styles.td}>{item.uploadCount}</td>
                  <td className={styles.td}>
                    <a href={item.fileUrl}>Uploaded File</a>
                  </td>

                  <td className={styles.td}>
                    {item.responseURL !== "" && (
                      <a href={item.responseURL}>Processed File</a>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className={styles.selectBox}>
          <div className={styles.uploadButton}>
            <a
              style={{ textDecoration: "none", color: "#fff" }}
              href="https://firebasestorage.googleapis.com/v0/b/read-pro.appspot.com/o/contactsUploadData%2Ftemplate.csv?alt=media&token=f8bcacfe-639d-4caf-ba36-3021ab9f3d2d"
            >
              Download Template
            </a>
          </div>
          <label htmlFor="file-input" className={styles.uploadButton}>
            Upload File
          </label>
          <input
            id="file-input"
            className={styles.select}
            type="file"
            accept=".csv,.xlsx,.xls"
            onChange={(e: any) => upload(e.target.files[0])}
          />
        </div>
      </div>
    </Modal>
  );
};

export default NewImportContactModal;

import React, { FunctionComponent, useState, useRef, useEffect } from "react";
import styles from "./DeleteSelectedModal.module.css";
import Modal from "react-modal";
import { connect, useDispatch } from "react-redux";
import Loading from "../../Loading/Loading";
import { showSnackbarAction } from "../../../Redux/actions";
import { db, firestore, functions, storage } from "../../../Firebase";
import Firebase from "firebase/app";
import { deleteRecords } from "../../../Services/contacts";
import axios from "axios";

import { url } from "../../../Values/constants";


type props = {
  open: boolean;
  close: () => void;
  data: any;
  clearSelectedRowsData?: () => void;
};

const DeleteSelectedModal: FunctionComponent<props> = ({
  open,
  close,
  clearSelectedRowsData,
  data,
}) => {
  const [load, setLoad] = useState(false);
  const dispatcher = useDispatch();
  const deleteselectedRecord = async () => {
       setLoad(true);
   let outcome= deleteRecords(data);

   if(outcome){
    try {
    
      for (const element of data) {
        console.log(element);
        axios.defaults.headers.common['x-access-token'] =
        'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoibGFrc2hheSIsImlhdCI6MTYyOTczOTgzMX0.E0mqgfrDj5su4grI1r8PH973srqJy3k2AG_hvCx-EaA';
        const callLogs=await axios.delete(url+"/callLogs/deleteCallLogs",{data:{leadId:element.contactId}});
        axios.defaults.headers.common['x-access-token'] =
        'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoibGFrc2hheSIsImlhdCI6MTYyOTczOTgzMX0.E0mqgfrDj5su4grI1r8PH973srqJy3k2AG_hvCx-EaA';
        const task=await axios.delete(url+"/tasks/deleteTask",{data:{leadId:element.contactId}});
        console.log(callLogs.data);
        console.log(task.data);
      
    
      
      }
    } catch (err) {
      console.log(err);
    }
       setTimeout(() => {
        close();
        setLoad(false);
        dispatcher(showSnackbarAction("Record Delted","success"));
        clearSelectedRowsData && clearSelectedRowsData();
        window.location.reload();
      }, 2500);
      
   }else{
     setTimeout(() => {
       close();
       setLoad(false);
       dispatcher(showSnackbarAction("Failed","error"));
      
      
     }, 2500);
   }
   
          
      
   
  };
  return (
    <>
      <Modal
        isOpen={open}
        className={styles.parent}
        overlayClassName={styles.overlay}
        onRequestClose={close}
        shouldCloseOnOverlayClick={true}
        shouldCloseOnEsc={true}
      >
        {load && <Loading />}
        <div className={styles.headingContainer}>
          <p className={styles.heading}>Delete Selected Records</p>
        </div>
        <div className={styles.container}>
          <div className={styles.subheading}>
            <p className={styles.optionHeading}>Selected Options</p>
          </div>
          <ol>
            {data.map((dat: any) => (
              <li>{dat.customer_name}</li>
            ))}
          </ol>
        </div>

        <div className={styles.buttonView}>
          <button className={styles.cancelButton} onClick={close}>
            Cancel
          </button>
          <button className={styles.saveButton} onClick={deleteselectedRecord}>
            Save
          </button>
        </div>
      </Modal>
    </>
  );
};
// const mapStateToProps = (state: any) => {
//     return {
//       organizationId: state.organization.id,
//       organizationUsers: state.organizationUsers.data,
//     };
//   };
export default DeleteSelectedModal;

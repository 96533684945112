import React, { useRef, useState } from "react";
import styles from "./ForgotPassword.module.css";
import Logo from "../../Assets/Images/readPro_logo.png";
import Logo2 from "../../Assets/Images/ify_logo.png";
import TextInput from "../../Components/TextInput/TextInput";
import { MdEmail } from "react-icons/md";
import background from "../../Assets/Images/passwordBackground.svg";
import { auth } from "../../Firebase";
import { emailValidate } from "../../Values/validatorsnew";
import { useDispatch } from "react-redux";
import { showSnackbarAction } from "../../Redux/actions";
import Loading from "../../Components/Loading/Loading";

const ForgotPassword = (props: any) => {
  const emailRef: any = useRef();
  const dispatcher = useDispatch();
  const [load, setLoad] = useState(false);

  const send = async (email: string) => {
    console.log(email);
    if (email !== "") {
      try {
        if (email) {
          setLoad(true);
          await auth.sendPasswordResetEmail(email);
          setLoad(false);
          dispatcher(
            showSnackbarAction(
              "Password reset information have been sent on you registered email id",
              "success"
            )
          );
          props.history.push("/");
        }
      } catch (error) {
        console.log("reset error", error);
      }
    } else {
      dispatcher(showSnackbarAction("Please Enter Your Email!", "error"));
    }
  };
  return (
    <div
      style={{
        backgroundImage: `url(${background})`,
        backgroundRepeat: "no-repeat",

        backgroundSize: "center",
        backgroundPositionY: "center",
        backgroundPositionX: "center",
        backgroundColor: "rgba(0, 0, 0, 0.02)",
      }}
      className={styles.parent}
    >
      {load === true && <Loading />}
      <div className={styles.child}>
        <img src={Logo} alt={"top"} className={styles.image} height={"45vh"} />
        <p className={styles.forget}>Forget Password?</p>
        <div style={{ marginTop: "5%" }}>
          <p className={styles.text}>Enter your registerd email below to</p>
          <p className={styles.text}>receive password reset instructions</p>
        </div>
        <div className={styles.form}>
          <div className={styles.input}>
            <TextInput title={"Email"} ref={emailRef} validator={emailValidate}>
              <MdEmail color={"#808080"} />
            </TextInput>
          </div>
          <div
            className={styles.forgetBox}
            onClick={() => props.history.push("/")}
          >
            <p className={styles.forgetText}>Remember Password?</p>
            <p className={styles.loginText}>Login</p>
          </div>
        </div>
        <div className={styles.buttonView}>
          <button
            className={styles.button}
            onClick={() => send(emailRef.current?.value)}
          >
            Send
          </button>
        </div>
        <div className={styles.bottomView}>
          <p className={styles.bottomText}>POWERED BY</p>
          <img
            src={Logo2}
            alt={"bottom"}
            className={styles.image}
            height={"45vh"}
          />
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;

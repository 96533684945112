import React, { FunctionComponent, useEffect } from "react";
import { Line } from "react-chartjs-2";
import AnalyticsGraph from "../AnalyticsGraph/AnalyticsGraph";
import IntBarTable from "../IntBarTable/IntBarTable";
import commonStyles from "../../CommonStyles/CommonGraphStyles.module.css";

type props = {
  state: any;
  interestedState: any;
  history: any;
  filter: "MTD" | "YTD" | "PM" | "All" | "CUSTOM";

  analyticsData: any;
  usersList: any;
  checked: boolean;
  teamsData: any;

  source: boolean;
  taskFilters?: any;
  leadFilters?: any;
  callFilters?: any;
};

const Trends: FunctionComponent<props> = ({
  state,
  interestedState,
  history,
  filter,
  analyticsData,
  usersList,
  checked,
  teamsData,
  source,
  taskFilters,
  leadFilters,
  callFilters,
}) => {
  return (
    <>
      <div id="trends">
        <AnalyticsGraph
          GraphType={Line}
          data={state}
          Heading={"Call Log Trends Summary"}
          title={"Customer Name"}
          value={"Follow Up Type"}
          filter={filter}
          type="other"
          history={history}
        />
      </div>
      {source === false && (
        <div id="trends" className={commonStyles.graphContainer}>
          <IntBarTable
            type={"callLogs"}
            data={analyticsData}
            heading={"Call Logs Summary"}
            usersList={usersList}
            checked={checked}
            teamsData={teamsData}
            style={{ width: "100%" }}
            show={false}
            filter={filter}
            history={history}
            source={source}
            taskFilter={taskFilters ? taskFilters : {}}
            leadFilter={leadFilters ? leadFilters : {}}
            callFilter={callFilters ? callFilters : {}}
          />
        </div>
      )}

      <div id="trends">
        <AnalyticsGraph
          GraphType={Line}
          data={interestedState}
          Heading={"Interested Lead Trends Summary"}
          title={"Customer Name"}
          value={"Follow Up Type"}
          filter={filter}
          type="other"
          history={history}
        />
      </div>
    </>
  );
};

export default Trends;

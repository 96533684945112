import React, {
  FunctionComponent,
  useEffect,
  useState,
  useRef,
} from "react";
import styles from "../BulkEditModal/BulkEditModal.module.css";
import Modal from "react-modal";
import { IoIosClose } from "react-icons/io";

import Dropdown from "../../DropDown/Dropdown";
import {
  addNoteFirebase,
  changeLeadStage,
  fetchConstants,
  reScheduleTaskFirebase,
} from "../../../Services/contacts";

import Loading from "../../Loading/Loading";
import { connect, useDispatch } from "react-redux";
import { showSnackbarAction } from "../../../Redux/actions";
import { useHistory } from "react-router-dom";
import moment from "moment";
import TextInput from "../../TextInput/TextInput";
type props = {
  contactDetail: any;
  close: () => void;
  open: boolean;
  tasksData: any[];
  notes: any[];
  user: any;
};

const RescheduleModal: FunctionComponent<props> = ({
  contactDetail,
  close,
  open,
  tasksData,
  notes,
  user,
}) => {
  const dateRef: any = useRef();
  const noteRef: any = useRef();
  const [load, setLoad] = useState(false);
  const [nextFollowSelected, setNextFollowSelected] =
    useState<any>({
      label: contactDetail.next_follow_up_type
        ? contactDetail.next_follow_up_type
        : "Select",
      value: contactDetail.next_follow_up_type
        ? contactDetail.next_follow_up_type
        : "Select",
    });

  const dispatcher = useDispatch();
  const history = useHistory();
  const onSubmit = () => {
    if (
      dateRef.current.value === "" ||
      dateRef.current.value < new Date()
    ) {
      dispatcher(
        showSnackbarAction("Select A Valid Date!!", "error")
      );
    } else {
      setLoad(true);
      if (noteRef.current.value.length !== 0) {
        addNoteFirebase(
          contactDetail.contactId,
          notes,
          noteRef.current.value,
          () => {},
          dispatcher,
          () => {},
          user.user_email
        );
      }
      reScheduleTaskFirebase(
        moment(dateRef.current.value).toDate(),
        tasksData,
        contactDetail.contactId,
        dispatcher,
        history
      );
    }
  };
  return (
    <Modal
      className={styles.parent}
      isOpen={open}
      shouldCloseOnOverlayClick={true}
      overlayClassName={styles.overlay}
      shouldCloseOnEsc={true}
      onRequestClose={close}
    >
      {load === true && <Loading />}
      <div className={styles.firstDiv}>
        <p className={styles.contactForm}>
          Reschedule Task
        </p>
        <div className={styles.cross} onClick={close}>
          <IoIosClose size={35} color={"#808080"} />
        </div>
      </div>
      <div className={styles.line}></div>

      <div
        className={styles.divide}
        style={{ marginTop: "20px", width: "100%" }}
      >
        <div className={styles.title}>
          <p className={styles.one}>
            Next Follow Up Date & Time
          </p>
          <p className={styles.two}></p>
        </div>
        <div>
          <input
            type="datetime-local"
            placeholder="Enter Date"
            ref={dateRef}
          />
        </div>
      </div>

      <div className={styles.box2}>
        <div
          className={styles.divide}
          style={{ width: "100%" }}
        >
          <div className={styles.title}>
            <p className={styles.one}>Note</p>
            <p className={styles.two}></p>
          </div>
          <div>
            <TextInput
              title={"Enter Note"}
              style={{ backgroundColor: "#fff" }}
              ref={noteRef}
            ></TextInput>
          </div>
        </div>
      </div>

      <button
        className={styles.apply}
        style={{ marginTop: "auto" }}
        onClick={onSubmit}
      >
        Save
      </button>
    </Modal>
  );
};

const mapStateToProps = (state: any) => {
  return {
    user: state.user.data,
  };
};

export default connect(mapStateToProps)(RescheduleModal);

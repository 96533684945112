import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import commonStyle from '../../Screens/common.module.css';
import { useTable, useColumnOrder } from 'react-table';

import { connect, useDispatch } from 'react-redux';
import TableHeader from '../TableHeader/TableHeader';
import CustomToggle from '../CustomToggle';
import {
  updateUserImport,
  updateUserStatus,
  EditUserTableHeader,
} from '../../Services/users';

import {
  updateAPIStatus,
  updateOrganizationStatus,
} from '../../Services/organizations';
import ColumnManagerModal from '../Modals/ColumnManagerModal/ColumnManager';
import {
  setClearFilter,
  setContactsSearchString,
  setTasksSearchString,
  setCallsSearchString,
  showSnackbarAction,
} from '../../Redux/actions';
import { useHistory } from 'react-router';
import { FiEdit3 } from 'react-icons/fi';
import Loading from '../Loading/Loading';

import InfiniteScroll from 'react-infinite-scroll-component';
import { useLocation } from 'react-router-dom';

import DistributionModel from '../Modals/DistributionModel/DistributionModel';

type props = {
  tableColumns: any[];
  tableRows: any[] | undefined;
  selectedRows: any[];
  setSelectedRows: (data: any[]) => void;
  tableType:
    | 'Organization'
    | 'Super admin'
    | 'User'
    | 'Task'
    | 'CallLogs'
    | 'API'
    | 'NEWS'
    | 'LeadDistributor'
    | 'DrillDown';
  showColumnModal: boolean;
  hideColumnModal: () => void;
  selectedRowsData?: any[];
  setSelectedRowsData?: (data: any[]) => void;

  organization: any;
  organizationUsers: any[];
  role: any;
  page: number;
  pageSize: number;
  setPage: (data: any) => void;
  setPageSize: (data: any) => void;
  isFinished: boolean;
  lastPage: number;
  totalCount: any;
  setBranch?: (text: boolean) => void;
  setUid?: (data: string) => void;
  user: any;
  setApiModal?: (data: boolean) => void;
  setApiEdit?: (data: boolean) => void;
  setApiData?: (data: any) => void;

  callApi?: (data: boolean, data2: Number | undefined) => Promise<void>;
};

const ApiCustomTable: FunctionComponent<props> = ({
  tableColumns,
  tableRows,
  selectedRows,
  setSelectedRows,
  tableType,
  showColumnModal,
  hideColumnModal,
  selectedRowsData,
  setSelectedRowsData,

  organization,
  organizationUsers,
  role,
  setBranch,
  setUid,
  user,
  setApiModal,
  setApiEdit,
  setApiData,
  page,
  pageSize,
  setPage,
  setPageSize,
  callApi,
  isFinished,
  lastPage,
  totalCount,
}) => {
  const dispatcher = useDispatch();
  const history = useHistory();
  const pageSizeOptions = [10, 25, 50, 100, 200, 500];
  const [checked, setChecked] = useState(false);
  const [showDistributionModal, setShowDistributionModal] = useState(false);
  const [load, setLoad] = useState(false);
  const [distributionData, setDistributionData] = useState();
  const [indexs, setIndex] = useState(0);
  const [pageData, setPageData] = useState<any[]>([]);

  useEffect(() => {
    if (tableRows) {
      const data = tableRows.slice(pageSize * page, pageSize * page + pageSize);
      setPageData(data);
    }
  }, [page, pageSize, tableRows]);

  const selectAll = (checked: boolean) => {
    if (
      tableType === 'User' ||
      tableType === 'Organization' ||
      tableType === 'CallLogs' ||
      tableType === 'Task'
    ) {
      if (checked === true) {
        if (tableRows) {
          let selectRowsIndex: any[] = [...selectedRows];

          let newRowIndex = pageData.map((lead) => lead.contactId);
          setSelectedRows([...newRowIndex, ...selectRowsIndex]);
        }

        if (tableRows) {
          if (selectedRowsData) {
            let selectRows: any[] = [...selectedRowsData];
            pageData.forEach((item: any) => {
              selectRows.push(item);
            });
            setSelectedRowsData && setSelectedRowsData(selectRows);
          } else {
            let selectRows: any[] = [];
            pageData.forEach((item: any) => {
              selectRows.push(item);
            });
            setSelectedRowsData && setSelectedRowsData(selectRows);
          }
        }
      } else {
        setSelectedRows([]);
        setSelectedRowsData && setSelectedRowsData([]);
      }
    } else {
      if (checked === true) {
        setSelectedRows(Array.from(Array(pageData.length).keys()));
      } else {
        setSelectedRows([]);
      }
    }
  };

  const columns = useMemo(() => tableColumns, [tableColumns]);
  const data = useMemo(() => pageData, [pageData]);
  const [currentLimit, setCurrentLimit] = useState(20);
  const [active, setActiveUsers] = useState(0);
  const tableInstance: any = useTable(
    {
      columns,
      data,
    },
    useColumnOrder
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    allColumns,
    setColumnOrder,
    toggleHideAllColumns,
  } = tableInstance;

  useEffect(() => {
    const savedColumns = localStorage.getItem('columns');
    if (savedColumns) {
      setColumnOrder(JSON.parse(savedColumns));
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    let storageColumn;
    if (
      tableType === 'CallLogs' &&
      user.calllogsColumn &&
      user.calllogsColumn.length > 0
    ) {
      storageColumn = user.calllogsColumn;
      setColumnOrder(storageColumn);
    } else if (
      tableType === 'User' &&
      user.userColumn &&
      user.userColumn.length > 0
    ) {
      storageColumn = user.userColumn;
      setColumnOrder(storageColumn);
    } else if (
      tableType === 'Task' &&
      user.taskColumn &&
      user.taskColumn.length > 0
    ) {
      storageColumn = user.taskColumn;
      setColumnOrder(storageColumn);
    }
  }, [user, tableType]);

  useEffect(() => {
    if (organizationUsers) {
      let activeUsers = organizationUsers.filter(
        (item) => item.status === 'ACTIVE'
      );
      setActiveUsers(activeUsers.length);
    }
  }, [organizationUsers]);

  const setColumns = (columnList: any) => {
    setColumnOrder(columnList);
    if (
      tableType === 'User' ||
      tableType === 'CallLogs' ||
      tableType === 'Task'
    ) {
      EditUserTableHeader(user.uid, tableType.toLowerCase(), columnList);
    }
    localStorage.setItem('columns', JSON.stringify(columnList));
  };

  const checkStatus = (email: string) => {
    const l = data.filter((item) => item.reporting_to === email);
    if (l.length > 0) {
      return false;
    } else {
      return true;
    }
  };

  const onToggle = (event: any, row: any) => {
    if (tableType === 'Organization') {
      setLoad(true);
      if (event.target.checked) {
        let check = checkStatus(row.original.user_email);
        if (check === true) {
          updateUserStatus(row.original.uid, 'INACTIVE', (data) =>
            setLoad(data)
          );
        } else {
          dispatcher(
            showSnackbarAction(
              'Some Users Are Reporting To This User!!',
              'warning'
            )
          );
          setLoad(false);
        }
      } else {
        let activeUsers = organizationUsers.filter(
          (item) => item.status === 'ACTIVE'
        );

        if (activeUsers.length === Number(organization.no_of_employees)) {
          dispatcher(
            showSnackbarAction(
              'Maximum active users limit reached!!',
              'warning'
            )
          );
          setLoad(false);
        } else {
          updateUserStatus(row.original.uid, 'ACTIVE', (data) => setLoad(data));
        }
      }
    }
    if (tableType === 'Super admin') {
      setLoad(true);
      if (event.target.checked) {
        updateOrganizationStatus(
          row.original.organization_id,
          'INACTIVE',
          (data) => setLoad(data)
        );
      } else {
        updateOrganizationStatus(
          row.original.organization_id,
          'ACTIVE',
          (data) => setLoad(data)
        );
      }
    }
    if (tableType === 'API') {
      setLoad(true);
      if (event.target.checked) {
        updateAPIStatus(row.original.api_key, 'INACTIVE', (data) =>
          setLoad(data)
        );
      } else {
        updateAPIStatus(row.original.api_key, 'ACTIVE', (data) =>
          setLoad(data)
        );
      }
    }
  };

  const changeImport = (event: any, row: any) => {
    setLoad(true);
    if (event.target.checked) {
      updateUserImport(
        row.original.organization_id,
        row.original.user_email,
        true,
        dispatcher,
        (data) => setLoad(data)
      );
    } else {
      updateUserImport(
        row.original.organization_id,
        row.original.user_email,
        false,
        dispatcher,
        (data) => setLoad(data)
      );
    }
  };

  const onCheck = (event: any, row: any) => {
    if (
      tableType === 'User' ||
      tableType === 'Organization' ||
      tableType === 'Task'
    ) {
      if (event.target.checked) {
        let data = [...selectedRows];
        data.push(row.contactId);
        setSelectedRows(data);
        if (selectedRowsData) {
          let rowData = [...selectedRowsData];
          rowData.push(row);
          setSelectedRowsData && setSelectedRowsData(rowData);
        }
      } else if (!event.target.checked) {
        let data = [...selectedRows];
        let item = selectedRows.indexOf(row.contactId);
        if (item > -1) {
          data.splice(item, 1);
        }
        setSelectedRows(data);
        if (selectedRowsData) {
          let rowData = [...selectedRowsData];
          let val = selectedRowsData.indexOf(row);
          if (val > -1) {
            rowData.splice(val, 1);
          }
          setSelectedRowsData && setSelectedRowsData(rowData);
        }
      }
    }

    if (event.target.checked) {
      let data = [...selectedRows];
      data.push(row.contactId);
      setSelectedRows(data);
    } else if (!event.target.checked) {
      let data = [...selectedRows];
      let item = selectedRows.indexOf(row.contactId);
      if (item > -1) {
        data.splice(item, 1);
      }
      setSelectedRows(data);
    }
  };

  const clearFilter = () => {
    if (tableType === 'User') {
      dispatcher(setContactsSearchString(''));
    }
    if (tableType === 'Task') {
      dispatcher(setTasksSearchString(''));
    }
    if (tableType === 'CallLogs') {
      dispatcher(setCallsSearchString(''));
    }
    var key: any;
    for (var i = 0; i < localStorage.length; i++) {
      key = localStorage.key(i);

      if (key !== 'columns') {
        localStorage.removeItem(key);
      }
    }
    dispatcher(setClearFilter(true));
    if (tableType === 'User') {
      history.push('/');
    }
  };
  const location: any = useLocation();

  return (
    <>
      <div className={commonStyle.child} id="scrollable">
        {load === true && <Loading />}
        <div style={{ width: 'max-content' }} {...getTableProps()}>
          <TableHeader
            headerGroups={headerGroups}
            selectAll={(data) => {
              selectAll(data);
              setChecked(data);
            }}
            panel={tableType}
            allCheck={checked}
          />
          <InfiniteScroll
            style={{ overflow: 'visible' }}
            dataLength={currentLimit}
            next={() => setCurrentLimit(currentLimit + 20)}
            hasMore={rows.length > currentLimit}
            loader={''}
            scrollableTarget="scrollable"
            className={commonStyle.infiniteScroll}
          >
            <div {...getTableBodyProps()}>
              {rows.slice(0, currentLimit).map((row: any, index: number) => {
                prepareRow(row);
                return (
                  <div
                    {...row.getRowProps()}
                    style={
                      row.original.status === 'INACTIVE'
                        ? {
                            backgroundColor: '#D3D3D3',
                            borderWidth: 2,
                            display: 'flex',
                            flexDirection: 'row',
                          }
                        : {
                            backgroundColor: '#ffffff',
                            display: 'flex',
                            flexDirection: 'row',
                          }
                    }
                  >
                    <div className={commonStyle.sticky}>
                      <input
                        type="checkbox"
                        onChange={(e) => {
                          onCheck(e, row.original);
                        }}
                        checked={selectedRows.includes(row.original.contactId)}
                      />
                      {tableType !== 'Task' && (
                        <FiEdit3
                          size={15}
                          color="#279EA0"
                          style={{ marginRight: '10px' }}
                          onClick={() => {
                            if (tableType === 'Organization') {
                              history.push({
                                pathname: '/addUsers',

                                state: {
                                  detail: row.original,
                                  edit: true,
                                },
                              });
                            } else if (tableType === 'User') {
                              if (row.original.transfer_status === true) {
                                dispatcher(
                                  showSnackbarAction(
                                    'Lead With Transfer Status TRUE Cannot be Edited!!',
                                    'error'
                                  )
                                );
                              } else {
                                let data = { ...row.original };

                                // Object.keys(data).forEach((key) => {
                                //   if (data[key].toDate) {
                                //     delete data[key];
                                //   }
                                // });

                                history.push({
                                  pathname: '/editContacts',
                                  state: {
                                    detail: data,
                                  },
                                });
                              }
                            } else if (tableType === 'Super admin') {
                              history.push({
                                pathname: '/editOrganizations',
                                state: { detail: row.original },
                              });
                            } else if (tableType === 'LeadDistributor') {
                              setShowDistributionModal(true);
                              setDistributionData(row.original);
                              setIndex(index);
                            } else if (tableType === 'API') {
                              setApiEdit && setApiEdit(true);
                              setApiModal && setApiModal(true);
                              setApiData && setApiData(row.original);
                            }
                          }}
                        />
                      )}
                      {showDistributionModal && (
                        <DistributionModel
                          open={showDistributionModal}
                          organization_id={user.organization_id}
                          close={() => {
                            setShowDistributionModal(false);
                          }}
                          data={distributionData}
                          index={indexs}
                        />
                      )}
                      {pageSize * page + index + 1}
                    </div>

                    {row.cells.map((cell: any) => {
                      return (
                        <div
                          className={commonStyle.td}
                          {...cell.getCellProps()}
                          style={
                            cell.column.Header === 'News Link'
                              ? { minWidth: '490px' }
                              : {}
                          }
                          onClick={() => {
                            if (
                              (cell.column.Header === 'Customer Name' &&
                                tableType === 'User') ||
                              (cell.column.Header === 'Customer Name' &&
                                tableType === 'Task') ||
                              (cell.column.Header === 'Customer Name' &&
                                tableType === 'CallLogs')
                            ) {
                              if (cell.row.original.transfer_status === true) {
                                dispatcher(
                                  showSnackbarAction(
                                    'This is a Transfered Lead!!',
                                    'error'
                                  )
                                );
                              } else {
                                history.push({
                                  pathname: '/contactDetails',
                                  state: {
                                    detail: {
                                      ...cell.row.original,
                                      created_at:
                                        cell.row.original[
                                          'created_at'
                                        ].toDate(),
                                      lead_assign_time: cell.row.original[
                                        'lead_assign_time'
                                      ]
                                        ? cell.row.original[
                                            'lead_assign_time'
                                          ].toDate()
                                        : '',
                                      next_follow_up_date_time: cell.row
                                        .original['next_follow_up_date_time']
                                        ? cell.row.original[
                                            'next_follow_up_date_time'
                                          ].toDate()
                                        : '',
                                      modified_at: cell.row.original[
                                        'modified_at'
                                      ]
                                        ? cell.row.original[
                                            'modified_at'
                                          ].toDate()
                                        : '',
                                      stage_change_at: cell.row.original[
                                        'stage_change_at'
                                      ]
                                        ? cell.row.original[
                                            'stage_change_at'
                                          ].toDate()
                                        : '',
                                      feedback_time: cell.row.original[
                                        'feedback_time'
                                      ]
                                        ? cell.row.original[
                                            'feedback_time'
                                          ].toDate()
                                        : '',
                                      due_date: cell.row.original['due_date']
                                        ? cell.row.original['due_date'].toDate
                                          ? cell.row.original[
                                              'due_date'
                                            ].toDate()
                                          : ''
                                        : '',
                                    },
                                  },
                                });
                              }
                            }
                          }}
                        >
                          {cell.render('Cell')}
                        </div>
                      );
                    })}

                    {tableType === 'Organization' && (
                      <div
                        className={commonStyle.td}
                        // style={{ minWidth: "317px" }}
                      >
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            paddingRight: '10px',
                            justifyContent: 'space-between',
                          }}
                        >
                          {(tableType === 'Organization' ||
                            tableType === 'Super admin' ||
                            tableType === 'API') && (
                            <CustomToggle
                              size="small"
                              onChange={(e) => onToggle(e, row)}
                              checked={
                                row.original.status === 'INACTIVE'
                                  ? true
                                  : false
                              }
                            />
                          )}
                        </div>
                      </div>
                    )}

                    {tableType === 'Organization' ? (
                      <div className={commonStyle.tdSmall}>
                        {row.original.profile === 'Lead Manager' ? (
                          <input
                            type="checkbox"
                            onChange={(e) => changeImport(e, row)}
                            checked={
                              row.original.import === true ? true : false
                            }
                          />
                        ) : null}
                      </div>
                    ) : null}

                    {tableType === 'Organization' ? (
                      <div
                        className={commonStyle.tdSmall}
                        style={{ paddingLeft: '30px' }}
                      >
                        {row.original.profile === 'Lead Manager' ? (
                          <button
                            className={commonStyle.branchButton}
                            onClick={() => {
                              setBranch && setBranch(true);
                              setUid && setUid(row.original.uid);
                            }}
                          >
                            Branch
                          </button>
                        ) : null}
                      </div>
                    ) : null}
                  </div>
                );
              })}
            </div>
          </InfiniteScroll>
        </div>
      </div>

      <div style={{ marginBottom: '23px' }}>
        <span>
          Page <strong>{page + 1 + '  '}</strong>
        </span>

        <select
          value={pageSize}
          onChange={(e) => {
            setPageSize(Number(e.target.value));
          }}
        >
          {pageSizeOptions.map((pagesize) => (
            <option key={pagesize} value={pagesize}>
              Show {pagesize}
            </option>
          ))}
        </select>

        <button
          onClick={() => {
            setChecked(false);
            if (page !== 0) {
              setPage(page - 1);
            }
          }}
          disabled={page === 0 ? true : false}
        >
          Previous
        </button>
        <button
          onClick={async () => {
            setChecked(false);
            setPage(page + 1);
          }}
          disabled={page === lastPage ? true : false}
        >
          Next
        </button>
      </div>
      <div className={commonStyle.count}>
        <button
          className={commonStyle.clearFilter}
          style={
            location.state?.detail || localStorage.length > 0
              ? { color: '#fff', borderColor: '#fff' }
              : { color: '#555', borderColor: '#555' }
          }
          onClick={() => clearFilter()}
        >
          Clear Filter
        </button>
        {tableType === 'Organization' && (
          <>
            <div className={commonStyle.selectContainer}>
              <p className={commonStyle.countHead}>Total Licence:</p>
              <p className={commonStyle.number}>
                {organization.no_of_employees}
              </p>
            </div>
            <div className={commonStyle.selectContainer}>
              <p className={commonStyle.countHead}>Active Licence:</p>
              <p className={commonStyle.number}>{active}</p>
            </div>
          </>
        )}
        <div className={commonStyle.selectContainer}>
          <p className={commonStyle.countHead}>Selected Records:</p>
          <p className={commonStyle.number}>{selectedRows.length}</p>
        </div>
        <div className={commonStyle.countContainer}>
          <p className={commonStyle.countHead}>Total Records:</p>
          <p className={commonStyle.number}>{totalCount}</p>
        </div>
      </div>

      {showColumnModal === true && (
        <ColumnManagerModal
          columnsList={allColumns}
          open={showColumnModal}
          close={hideColumnModal}
          onChangeColumns={(data: any[]) => setColumns(data)}
          toggleAllColumns={toggleHideAllColumns}
        />
      )}
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    user: state.user.data,
    organizationUsers: state.organizationUsers.data,
    role: state.user.role,
    organization: state.organization,
  };
};

export default connect(mapStateToProps)(ApiCustomTable);

import React, { FunctionComponent, useState } from 'react';
import styles from './TopBar.module.css';
import { AiFillCaretDown } from 'react-icons/ai';
import { AiOutlineSearch } from 'react-icons/ai';
import { BsPlus } from 'react-icons/bs';
import { FaChevronDown } from 'react-icons/fa';

import StatusFilter from '../StatusFilter/StatusFilter';
import Import from '../../Components/Import/Import';
import { BsColumnsGap } from 'react-icons/bs';
import { connect, useDispatch } from 'react-redux';
import { IoClose } from 'react-icons/io5';
import {
  setFilter,
  setRefreshCallLogs,
  setRefreshContacts,
  setRefreshTasks,
  setContactsSearchString,
  setTasksSearchString,
  setCallsSearchString,
  showSnackbarAction,
  updateStage,
} from '../../Redux/actions';
import { useEffect } from 'react';
import { correctLeadCount } from '../../Services/organizations';
import DistributionModel from '../../Components/Modals/DistributionModel/DistributionModel';
import { IoIosRefresh } from 'react-icons/io';

type props = {
  user: any;
  history: any;
  title: string;
  path: string;
  // on selected leads, if we click on change owner, modal opens condition
  onClick?: () => void;
  // to show the change owner text condition
  owner?: boolean;
  // function from user panel
  changeOwner?: () => void;
  deleteSelected?:()=>void;
  onChange?: (text: any) => void;
  setStatus?: (status: string) => void;
  filterText: string;
  status?: string;
  // sets condition for statusFilter component and there it is handled accordingly
  taskFilter?: boolean;
  // sets condition for statusFilter component and there it is handled accordingly
  projectFilter?: boolean;
  setColumnModal?: (data: boolean) => void;
  show: boolean;
  userRole: any;
  showStatusBox?: boolean;
  onExport?: () => void;
  // dropdown rights from organization panel shown in leads panel
  onCallLogsImport?: () => void;
  // dropdown rights from organization panel shown in leads panel
  onNotesImport?: () => void;
  // dropdown rights from organization panel shown in leads panel
  onTasksImport?: () => void;
  // edit records option on selecting a lead, opens a modal to update every selected lead
  setBulkEdit?: () => void;
  leadsStage: any;
  setAllLeads?: (data: boolean) => void;
  setListViewModal?: (data: boolean) => void;
  setApiModal?: (data: boolean) => void;
  setApiFilter?: (data: string) => void;
  apiFilterData?: string;
  organizationId: string;
  // to refresh
  setLoad?: (data: boolean) => void;
  setChangeStageModal?: (data: boolean) => void;
  setDeleteRecordsModal?: (data: boolean) => void;
  // for maintaining search after switiching panels, stored globally
  searchedString?: string;
  // if we select a lead with transfer status true, we won't allow to open change owner and edit bulk modals
  transferLeads?: any[];
};

const ApiTopBar: FunctionComponent<props> = ({
  user,
  history,
  title,
  path,
  onClick,
  owner,
  changeOwner,
  deleteSelected,
  onChange,
  setStatus,
  filterText,
  status,
  taskFilter,
  projectFilter,
  setColumnModal,
  show,
  userRole,
  showStatusBox,
  onExport,
  onCallLogsImport,
  onNotesImport,
  onTasksImport,
  setBulkEdit,
  leadsStage,
  setListViewModal,
  setApiModal,
  setApiFilter,
  apiFilterData,
  organizationId,
  setLoad,
  setChangeStageModal,
  setDeleteRecordsModal,
  searchedString,
  setAllLeads,
  transferLeads,
}) => {
  const [expand, setExpand] = useState(false);
  const [expandImport, setExpandImport] = useState(false);
  const [value, setValue] = useState('');
  const [showDistributionModal, setShowDistributionModal] = useState(false);
  const [isSearched, setIsSearched] = useState(false);
  const dispatcher = useDispatch();

  const apiFilter = (event: any, filter: string) => {
    if (event.target.checked) {
      if (setApiFilter) {
        setApiFilter(filter);
      }
    } else {
      if (setApiFilter) {
        setApiFilter('');
      }
    }
  };

  // include transfer leads in contacts panel
  const transferLead = (event: any) => {
    if (setAllLeads && event.target.checked) {
      setAllLeads(true);
    } else {
      setAllLeads && setAllLeads(false);
    }
  };

  // on panel change, search pertains
  useEffect(() => {
    if (value !== searchedString) {
      setIsSearched(false);
    }
  }, [value]);

  return (
    <div className={styles.topBar}>
      {showStatusBox === true && (
        <>
          {userRole !== 'Sales' && (
            <div>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <div
                  className={styles.statusBox}
                  style={
                    title === 'Add Contacts' || title === 'drilldown'
                      ? { borderWidth: '0px' }
                      : {}
                  }
                >
                  {owner ? (
                    <p
                      className={styles.owner}
                      onClick={() => {
                        transferLeads && transferLeads.includes(true)
                          ? dispatcher(
                              showSnackbarAction(
                                'Leads With Transfer Status TRUE Cannot Be Transfered!!',
                                'error'
                              )
                            )
                          : changeOwner && changeOwner();
                      }}
                    >
                      Change Owner
                    </p>
                  ) : (
                    title !== 'Add Contacts' &&
                    title !== 'drilldown' && (
                      <div className={styles.innerChild}>
                        <p
                          className={styles.statusText}
                          style={
                            filterText === 'userPanel'
                              ? { cursor: 'pointer' }
                              : {}
                          }
                        >
                          {filterText}
                        </p>
                        {filterText !== 'userPanel' && (
                          <>
                            <div className={styles.line}></div>
                            <AiFillCaretDown
                              onClick={() => setExpand(!expand)}
                              className={styles.icon}
                            />
                          </>
                        )}
                      </div>
                    )
                  )}
                </div>
                {owner && (
                  <div
                    className={styles.statusBox}
                    style={{ marginLeft: '10px', borderWidth: '0px' }}
                  >
                    <p
                      className={styles.owner}
                      onClick={() => {
                        transferLeads && transferLeads.includes(true)
                          ? dispatcher(
                              showSnackbarAction(
                                'Leads With Transfer Status TRUE Cannot Be Edited!!',
                                'error'
                              )
                            )
                          : setBulkEdit && setBulkEdit();
                      }}
                    >
                      Edit Records
                    </p>
                  </div>
                )}
               {owner && (userRole==='Lead Manager' &&(
                   <div
                   className={styles.statusBox}
                   style={{ marginLeft: '10px', borderWidth: '0px' }}
                 >
                   <p
                     className={styles.owner}
                     onClick={() => {
                       deleteSelected && deleteSelected();
                     }}
                   >
                     Delete Records
                   </p>
                 </div>
                 ) )}
              </div>
              {expand === true && (
                <div
                  style={{
                    position: 'relative',
                    display: 'flex',
                    alignItems: 'center',
                    paddingLeft: '22px',
                    justifyContent: 'center',
                  }}
                >
                  <StatusFilter
                    setStatus={(status) => setStatus && setStatus(status)}
                    status={status}
                    taskFilter={taskFilter}
                    projectFilter={projectFilter}
                    setExpand={(data) => setExpand(data)}
                    expand={expand}
                  />
                </div>
              )}
            </div>
          )}
        </>
      )}

      {title === 'Add User' && (
        <>
          <button
            style={{ marginLeft: '20px' }}
            className={styles.addBox}
            onClick={() => {
              setListViewModal && setListViewModal(true);
            }}
          >
            Set List View
          </button>
          <button
            style={{ marginLeft: '20px' }}
            className={styles.addBox}
            onClick={() => {
              setLoad && setLoad(true);
              if (setLoad) {
                correctLeadCount(
                  organizationId,
                  (data) => setLoad(data),
                  dispatcher
                );
              }
            }}
          >
            Correct Count
          </button>
          <button
            style={{ marginLeft: '20px' }}
            className={styles.addBox}
            onClick={() => {
              setChangeStageModal && setChangeStageModal(true);
            }}
          >
            Change Stage
          </button>
          <button
            style={{ marginLeft: '20px' }}
            className={styles.addBox}
            onClick={() => {
              setDeleteRecordsModal && setDeleteRecordsModal(true);
            }}
          >
            Delete Records
          </button>
        </>
      )}

      {title === 'Add Api' && (
        <button
          style={{ marginLeft: '20px' }}
          className={styles.addBox}
          onClick={() => {
            setApiModal && setApiModal(true);
          }}
        >
          Add API
        </button>
      )}

      {title === 'Add News Link' && (
        <button
          style={{ marginLeft: '20px' }}
          className={styles.addBox}
          onClick={() => {
            setApiModal && setApiModal(true);
          }}
        >
          Add News Link
        </button>
      )}

      {title === 'Add Api Data' && (
        <>
          <div className={styles.leadContainer}>
            <div className={styles.leadBox}>
              <p className={styles.leadText}>Last 7 Days</p>
              <input
                type="checkbox"
                onChange={(e) => {
                  apiFilter(e, '7');
                }}
                checked={apiFilterData === '7' ? true : false}
              />
            </div>
            <div className={styles.leadBox}>
              <p className={styles.leadText}>Last 30 Days</p>
              <input
                type="checkbox"
                onChange={(e) => {
                  apiFilter(e, '30');
                }}
                checked={apiFilterData === '30' ? true : false}
              />
            </div>
            <div className={styles.leadBox}>
              <p className={styles.leadText}>All</p>
              <input
                type="checkbox"
                onChange={(e) => {
                  apiFilter(e, 'all');
                }}
                checked={apiFilterData === 'all' ? true : false}
              />
            </div>
          </div>
        </>
      )}

      {title === 'Add Contacts' && userRole === 'Lead Manager' && (
        <div className={styles.leadContainer}>
          <div className={styles.leadBox} style={{ marginLeft: 'auto' }}>
            <p className={styles.leadText}>Include Transfer Leads</p>
            <input
              type="checkbox"
              onChange={(e) => {
                transferLead(e);
              }}
            />
          </div>
        </div>
      )}

      <div className={styles.box}>
        {title !== 'drilldown' && title !== 'Lead Distribution' && (
          <div className={styles.searchBox}>
            <input
              className={styles.searchText}
              placeholder="Search"
              value={value}
              onChange={(val) => {
                setValue(val.target.value);
              }}
              onKeyDown={(e) => {
                if (e.code === 'Enter') {
                  // onChange && onChange(value);
                  if (title === 'Add Task') {
                    dispatcher(setTasksSearchString(value));
                  } else if (title === 'Add Contacts') {
                    dispatcher(setContactsSearchString(value));
                  } else if (title === 'Add Call Logs') {
                    dispatcher(setCallsSearchString(value));
                  }

                  setIsSearched(true);
                }
              }}
            />
            {isSearched === false ? (
              <AiOutlineSearch
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  // onChange && onChange(value);
                  if (title === 'Add Task') {
                    dispatcher(setTasksSearchString(value));
                  } else if (title === 'Add Contacts') {
                    dispatcher(setContactsSearchString(value));
                  } else if (title === 'Add Call Logs') {
                    dispatcher(setCallsSearchString(value));
                  }
                  setIsSearched(true);
                }}
              />
            ) : (
              <IoClose
                color={'ff0000'}
                onClick={() => {
                  // onChange && onChange("");
                  if (title === 'Add Task') {
                    dispatcher(setTasksSearchString(''));
                  } else if (title === 'Add Contacts') {
                    dispatcher(setContactsSearchString(''));
                  } else if (title === 'Add Call Logs') {
                    dispatcher(setCallsSearchString(''));
                  }
                  setValue('');
                  setIsSearched(false);
                }}
              />
            )}
          </div>
        )}

        {title !== 'Add Task' &&
          title !== 'drilldown' &&
          title !== 'Add Api' &&
          title !== 'Add News Link' &&
          title !== 'Add Api Data' && (
            <button
              className={styles.addBox}
              onClick={() => {
                title === 'Lead Distribution'
                  ? setShowDistributionModal(true)
                  : history.push(path);
              }}
            >
              <BsPlus size={22} color={'#ffffff'} />
              {title}
            </button>
          )}

        {showDistributionModal && (
          <DistributionModel
            open={showDistributionModal}
            organization_id={user.organization_id}
            close={() => {
              setShowDistributionModal(false);
            }}
          />
        )}

        {show === true && title !== 'drilldown' && (
          <>
            {(userRole === 'Lead Manager' ||
              userRole === 'organization' ||
              userRole === 'Team Lead') && (
              <div
                className={styles.arrowBox}
                onClick={() => setExpandImport(!expandImport)}
              >
                <FaChevronDown />
                {expandImport === true && (
                  <div
                    style={{
                      position: 'relative',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      marginTop: '37px',
                    }}
                  >
                    <Import
                      onClick={onClick}
                      setExpandImport={(data) => setExpandImport(data)}
                      onExport={onExport}
                      expand={expandImport}
                      close={() => setExpandImport(false)}
                      onCallLogsImport={onCallLogsImport}
                      onNotesImport={onNotesImport}
                      onTasksImport={onTasksImport}
                    />
                  </div>
                )}
              </div>
            )}
          </>
        )}
        <div className={styles.columnManage}>
          <BsColumnsGap
            size={20}
            onClick={() => {
              setColumnModal && setColumnModal(true);
            }}
          />
          <span className={styles.tooltiptext}>Column Manager</span>
        </div>

        <div className={styles.refreshIcon}>
          <IoIosRefresh
            size={15}
            style={{ cursor: 'pointer', color: '#fff' }}
            onClick={() => {
              if (title === 'Add Task') {
                dispatcher(setRefreshTasks(true));
              } else if (title === 'Add Contacts') {
                dispatcher(setRefreshContacts(true));
              } else if (title === 'Add Call Logs') {
                dispatcher(setRefreshCallLogs(true));
              }
            }}
          />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    user: state.user.data,
    userRole: state.user.role,
    leadsStage: state.leadsStage.stage,
    organizationId: state.organization.id,
  };
};

export default connect(mapStateToProps)(ApiTopBar);

import React, { FunctionComponent, useEffect } from "react";
import Navbar from "../Components/Navbar/Navbar";
import Reports from "../Screens/Reports/Reports";
import { Route } from "react-router-dom";
import UserPanel from "../Screens/UserPanel/UserPanel";
import AddContacts from "../Screens/AddContacts/AddContacts";
import Task from "../Screens/TasksPanel/TaskPanel";
import Project from "../Screens/ProjectsPanel/ProjectsPanel";
import AddProject from "../Screens/AddProject/AddProject";
import AddTasks from "../Screens/AddTasks/AddTasks";
import Resources from "../Screens/Resources/Resources";
import ContactDetails from "../Screens/ContactDetails/ContactDetails";
import { connect, useDispatch } from "react-redux";
import { fetchOrganizationsUsers } from "../Services/users";
import EditContact from "../Screens/EditContact/EditContact";

import CallLogsPanel from "../Screens/CallLogsPanel/CallLogsPanel";
import ProjectDetails from "../Screens/ProjectDetails/ProjectDetails";
import ProjectEditModal from "../Components/Modals/ProjectEditModal/ProjectEditModal";
import AddFAQ from "../Screens/AddFAQ/AddFAQ";
import FAQPanel from "../Screens/FAQPanel/FAQPanel";
import LeadManagerAnalytics from "../Screens/Analytics/LeadManagerAnalytics";
import ApiDataPanel from "../Screens/ApiDataPanel/ApiDataPanel";
import LeadDistributor from "../Screens/LeadDistributor/LeadDistributor";
import "axios";
import axios from "axios";
import { setBranchUsersList } from "../Redux/actions";

type props = {
  user: any;
  history: any;
  leadsStage: any;
  organizationUsers: any;
};

const LeadManagerRoute: FunctionComponent<props> = ({
  history,
  user,
  leadsStage,
  organizationUsers,
}) => {
  const dispatcher = useDispatch();

  useEffect(() => {
    if (user.organization_id) {
      const unSub = fetchOrganizationsUsers(
        dispatcher,
        user.organization_id,
        user
      );

      return () => {
        unSub();
      };
    }
  }, [user, dispatcher]);

  useEffect(() => {
    if (user && organizationUsers) {
      let uidList: any[] = [];

      if (user.branchPermission && !user.branchPermission.includes("All")) {
        uidList.push(user.uid);
        organizationUsers.forEach((users: any) => {
          if (users.branch) {
            if (user.branchPermission.includes(users.branch)) {
              uidList.push(users.uid);
            }
          }
        });

        dispatcher(setBranchUsersList(uidList));
      }
      if (user.branchPermission && user.branchPermission.includes("All")) {
        uidList.push(user.uid);
        organizationUsers.forEach((users: any) => {
          uidList.push(users.uid);
        });
        dispatcher(setBranchUsersList(uidList));
      }
    }
  }, [user, organizationUsers]);

  return (
    <>
      <Navbar
        props={{ history }}
        title={"Contacts"}
        path={"/"}
        leadManger={true}
        project={"/projects"}
        task={"/tasks"}
        resources={"/resources"}
        callLogs={"/callLogs"}
        faq={"/faq"}
        apiData={"/apiData"}
        leadDistributor={"/leadDistributor"}
      />

      <Route exact path="/" component={UserPanel} />
      <Route path="/analytics" component={LeadManagerAnalytics} />
      <Route path="/reports" component={Reports} />
      <Route path="/addProjects" component={AddProject} />
      <Route path="/addTasks" component={AddTasks} />
      <Route path="/contactDetails" component={ContactDetails} />
      <Route path="/tasks" component={Task} />
      <Route path="/projects" component={Project} />
      <Route path="/resources" component={Resources} />
      <Route path="/addContacts" component={AddContacts} />
      <Route path="/editContacts" component={EditContact} />
      <Route path="/callLogs" component={CallLogsPanel} />
      <Route path="/projectDetails" component={ProjectDetails} />
      <Route path="/editProjects" component={ProjectEditModal} />
      <Route path="/addFAQ" component={AddFAQ} />
      <Route path="/faq" component={FAQPanel} />
      <Route path="/leadDistributor" component={LeadDistributor} />
      <Route path="/apiData" component={ApiDataPanel} />
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    user: state.user.data,
    leadsStage: state.leadsStage.stage,
    organizationUsers: state.organizationUsers.data,
  };
};
export default connect(mapStateToProps)(LeadManagerRoute);

import React, { FunctionComponent } from "react";
import commonStyles from "../../CommonStyles/CommonGraphStyles.module.css";
import IntBarChartOrg from "../IntBarChartOrg/IntBarChartOrg";
import IntBarTable from "../IntBarTable/IntBarTable";
import { Pie } from "react-chartjs-2";

type props = {
  analyticsData: any;
  usersList: any;
  checked: boolean;
  teamsData: any;
  history: any;
  filter: "MTD" | "YTD" | "PM" | "All" | "CUSTOM";
  source: boolean;
  taskFilter?: any;
  leadFilter?: any;
};

const FeedbackGraph: FunctionComponent<props> = ({
  analyticsData,
  usersList,
  checked,
  teamsData,
  history,
  filter,
  source,
  taskFilter,
  leadFilter,
}) => {
  return (
    <>
      <div id="feedback" className={commonStyles.graphContainer}>
        <IntBarChartOrg
          analyticsData={analyticsData}
          heading={"Feedback"}
          type={"stage"}
          color={[
            "#173F5F",
            "#1F639C",
            "#ED563B",
            "#3CAEA3",
            "#8B9D7D",
            "#F6D65D",
          ]}
          GraphType={Pie}
          style={{ width: "100%", height: "350px" }}
          history={history}
          filter={filter}
        />
      </div>
      <div id="feedbackBar" className={commonStyles.graphContainer}>
        <IntBarTable
          type={"stage"}
          data={analyticsData}
          heading={"Feedback Summary"}
          usersList={usersList}
          checked={checked}
          teamsData={teamsData}
          style={{ width: "100%" }}
          filter={filter}
          history={history}
          source={source}
          taskFilter={taskFilter}
          leadFilter={leadFilter}
        />
      </div>
    </>
  );
};

export default FeedbackGraph;

import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { FunctionComponent } from 'react';
import { connect, useDispatch } from 'react-redux';
import styles from '../../CommonStyles/CommonGraphStyles.module.css';
import { setClearFilter, updateStage } from '../../Redux/actions';
import {
  apiCallDrilldown,
  apiContactsDrilldown,
  drillDownTableOrg,
} from '../../Services/analytics';
import { changeDateForRoute } from '../../Services/contacts';
import { properFormat, sortAnalytics } from '../../Values/utils';
import * as ReactBootStrap from 'react-bootstrap';

type props = {
  data: any;
  type:
    | 'project'
    | 'location'
    | 'budget'
    | 'stage'
    | 'lost_reason'
    | 'not_int_reason'
    | 'propertyType'
    | 'propertyStage'
    | 'callLogs';
  heading: string;
  usersList: any;
  checked: boolean;
  teamsData: any;
  style?: any;
  show?: boolean;
  contacts: any;
  filter: 'MTD' | 'YTD' | 'PM' | 'All' | 'CUSTOM' | 'T' | 'Y';
  organizationUsers: any;
  history: any;
  user: any;
  teamLeadUsers: any;
  branchUsers: any;
  taskFilter?: any;
  leadFilter?: any;
  callFilter?: any;
  source?: boolean;
};

const IntBarTable: FunctionComponent<props> = ({
  data,
  heading,
  type,
  usersList,
  checked,
  teamsData,
  style,
  show,
  contacts,
  filter,
  organizationUsers,
  history,
  user,
  teamLeadUsers,
  branchUsers,
  taskFilter,
  leadFilter,
  callFilter,
  source,
}) => {
  const [tableData, setTableData] = useState<any[]>([]);
  const [header, setHeader] = useState<any[]>([]);
  const [allContacts, setAllContacts] = useState<any[] | undefined>(undefined);

  useEffect(() => {
    let contact: any[] = [];
    Object.keys(contacts.data).forEach((key) => {
      contact = [...contact, ...contacts.data[key]];
    });
    setAllContacts(contact);
  }, [contacts.data]);

  useEffect(() => {
    const tempHeader: string[] = ['User Name', 'Total'];
    const tempData: any[] = [];
    if (type === 'callLogs') {
      Object.keys(data).forEach((key) => {
        const analytics = data[key].callLogAnalytics;
        let sum = 0;
        Object.keys(analytics).forEach((key) => {
          if (
            !tempHeader.includes(key) &&
            key !== 'Pending' &&
            key !== 'Completed'
          ) {
            tempHeader.push(key);
          }
        });
        Object.keys(analytics).forEach((key) => {
          if (key !== 'Pending' && key !== 'Completed') {
            sum += analytics[key];
          }
        });
        tempData.push({
          name: checked ? key : source ? key : usersList[key],
          uid: key,
          Total: sum,
          ...analytics,
        });
      });
    } else {
      Object.keys(data).forEach((key) => {
        const analytics = data[key].leadAnalytics[type];
        let sum = 0;
        Object.keys(analytics).forEach((key) => {
          if (
            !tempHeader.includes(key) &&
            key !== 'Pending' &&
            key !== 'Completed'
          ) {
            tempHeader.push(key);
          }
        });
        Object.keys(analytics).forEach((key) => {
          if (key !== 'Pending' && key !== 'Completed') {
            sum += analytics[key];
          }
        });
        tempData.push({
          name: checked ? key : source ? key : usersList[key],
          uid: key,
          Total: sum,
          ...analytics,
        });
      });
    }
    let teamTempData: any[] = [];
    if (checked) {
      tempData.forEach((user) => {
        const team = teamsData[user.name];
        let existing = teamTempData.filter((item) => item.name === team);
        if (existing.length !== 0) {
          Object.keys(user).forEach((key) => {
            if (key !== 'name') {
              if (existing[0][key]) {
                existing[0][key] += user[key];
              } else {
                existing[0][key] = user[key];
              }
            }
          });
        } else {
          teamTempData.push({ ...user, name: team });
        }
      });
    }
    let gt: { [key: string]: any } = {};
    const finalData = checked ? teamTempData : source ? tempData : tempData;
    finalData.forEach((item) => {
      Object.keys(item).forEach((key) => {
        if (key !== 'name') {
          if (gt[key]) {
            gt[key] += item[key];
          } else {
            gt[key] = item[key];
          }
        }
      });
    });

    finalData.sort(sortAnalytics);
    finalData.unshift({ name: 'Grand Total', ...gt });
    if (type !== 'callLogs') {
      tempHeader.forEach((item) => {
        if (item === 'Other') {
          const i = tempHeader.indexOf('Other');
          tempHeader.splice(i, 1);
          tempHeader.push('Other');
        }
      });
    }

    if (type === 'stage') {
      tempHeader.forEach((item) => {
        setHeader([
          'User Name',
          'Total',
          'FRESH',
          'CALLBACK',
          'INTERESTED',
          'WON',
          'NOT INTERESTED',
          'LOST',
        ]);
      });
    } else {
      setHeader(tempHeader);
    }

    setTableData(finalData.filter((item) => item.name !== undefined));
  }, [data, checked]);
  const dispatcher = useDispatch();

  // when a user clicks on the table data which is not zero, it opens up a drill down page, the api data for which is stored in the local storage and the necessary call is made from that page
  const getDrillDownData = async (key: string, item: any) => {
    if (type === 'callLogs') {
      console.log("anyltitcal");
      const drillDownData = await apiCallDrilldown(
        filter,
        item.name === 'Grand Total' ? user.uid : item.uid,
        key,
        taskFilter,
        leadFilter,
        callFilter,
        item.name === 'Grand Total' ? true : false
      );
     
     
        const newDrollDown={
          ...drillDownData,
          organizationid:user.organization_id
        }
        console.log(newDrollDown);
        localStorage.setItem('callDrilldownData', JSON.stringify(newDrollDown));
        window.open('/callDrilldownData', '_blank');
     
     
    } else {
      console.log("source");
      const drillDownData = await apiContactsDrilldown(
        item.name === 'Grand Total' ? user.uid : item.uid,
        type === 'propertyStage'
          ? 'property_stage'
          : type === 'propertyType'
          ? 'property_type'
          : type,
        key,
        type === 'lost_reason'
          ? 'LOST'
          : type === 'not_int_reason'
          ? 'NOT INTERESTED'
          : 'INTERESTED', 
        filter,
        taskFilter,
        leadFilter,
        item.name === 'Grand Total' ? true : false
      );
        if(item.name==='Grand Total'){
          console.log(user);
      const newDrollDown={
        uid:"Grand Total",
        leadFilter:drillDownData.leadFilter,
        taskFilter:drillDownData.taskFilter,
        role:drillDownData.role,
        organizationid:user.organization_id
      }
      console.log('loacalStorage:', newDrollDown);
      localStorage.setItem('drilldownData', JSON.stringify(newDrollDown));
      window.open('/drilldownData', '_blank');
        }else{
          console.log(user);
      const newDrollDown={
        ...drillDownData,
        organizationid:user.organization_id
      }
      console.log('loacalStorage:', newDrollDown);
      localStorage.setItem('drilldownData', JSON.stringify(newDrollDown));
      window.open('/drilldownData', '_blank');
        }
      
    }
  };

  return (
    <div className={styles.detailsBox} style={style}>
      <p className={styles.graphHeading}>{heading}</p>

      <ReactBootStrap.Table>
        <thead>
          <tr>
            {header.map((item, index) => (
              <>
                <th
                  scope="col"
                  key={index}
                  style={{
                    fontSize: '0.8rem',
                    fontWeight: 'bold',
                    width: '130px',
                  }}
                >
                  {properFormat(item)}
                </th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
              </>
            ))}
          </tr>
        </thead>
        <tr></tr>
        <tr></tr>
        <tr></tr>
        <tr></tr>
        <tr></tr>
        <tbody>
          {tableData.map((item, index) => (
            <>
              <tr key={index}>
                <th
                  scope="col"
                  style={{
                    fontSize: '0.8rem',
                    fontWeight: 'bold',
                    width: '130px',
                  }}
                >
                  {properFormat(item.name)}
                </th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>

                {header.slice(1).map((key: string, sIndex) => (
                  <>
                    <th
                      key={String(index) + String(sIndex)}
                      style={{
                        fontSize: '0.8rem',
                        fontWeight: 'normal',
                        width: '130px',
                        cursor: item[key] ? 'pointer' : 'not-allowed',
                      }}
                      onClick={() => {
                        item[key] && getDrillDownData(key, item);
                      }}
                    >
                      {item[key] ? properFormat(item[key]) : 0}
                    </th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                  </>
                ))}
              </tr>
              <tr></tr>
              <tr></tr>
              <tr></tr>
              <tr></tr>
            </>
          ))}
        </tbody>
      </ReactBootStrap.Table>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    contacts: state.contacts,
    organizationUsers: state.organizationUsers.data,
    user: state.user.data,
    teamLeadUsers: state.teamLeadUsers.data,
    branchUsers: state.branchUsers.data,
  };
};
export default connect(mapStateToProps)(IntBarTable);

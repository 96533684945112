import React, { FunctionComponent } from "react";
import styles from "../CommonStyles/CommonGraphStyles.module.css";
import CountBox from "../Components/CountBox/CountBox";

type props = {
  stage: any;
  history: any;
  filter: "MTD" | "YTD" | "PM" | "All" | "CUSTOM";
  taskStage: any;
  taskFilter?: any;
  leadFilter?: any;
  otherTaskFilter?: any;
  otherContactFilter?: any;
};

const AnalyticsCountContainer: FunctionComponent<props> = ({
  stage,
  history,
  filter,
  taskStage,
  taskFilter,
  leadFilter,
  otherTaskFilter,
  otherContactFilter,
}) => {
  return (
    <>
      <CountBox
        count={stage.FRESH}
        text="Fresh"
        style={{ color: "#ff9933" }}
        history={history}
        filterText="FRESH"
        filter={filter}
        taskFilter={taskFilter}
        leadFilter={leadFilter}
      />
      <CountBox
        count={stage.CALLBACK}
        text="Call Back"
        style={{ color: "#2FA2D3" }}
        history={history}
        filterText="CALLBACK"
        filter={filter}
        taskFilter={taskFilter}
        leadFilter={leadFilter}
      />
      <CountBox
        count={stage.INTERESTED}
        text="Interested"
        style={{ color: "#279F9F" }}
        history={history}
        filterText="INTERESTED"
        filter={filter}
        taskFilter={taskFilter}
        leadFilter={leadFilter}
      />
      <CountBox
        count={stage.WON}
        text="Closed Won"
        style={{ color: "#D51E1E" }}
        history={history}
        filterText="WON"
        filter={filter}
        taskFilter={taskFilter}
        leadFilter={leadFilter}
      />
      <CountBox
        count={stage["NOT INTERESTED"]}
        text="Not Interested"
        style={{ color: "#279EA0" }}
        history={history}
        filterText="NOT INTERESTED"
        filter={filter}
        taskFilter={taskFilter}
        leadFilter={leadFilter}
      />
      <CountBox
        count={stage.LOST}
        text="Closed Lost"
        style={{ color: "#4FCE5D" }}
        history={history}
        filterText="LOST"
        filter={filter}
        taskFilter={taskFilter}
        leadFilter={leadFilter}
      />

      <CountBox
        count={taskStage["Completed"]}
        text="Completed Visits"
        style={{ color: "#B00020" }}
        history={history}
        filterText="Completed"
        filter={filter}
        taskFilter={otherTaskFilter}
        leadFilter={otherContactFilter}
      />
      <CountBox
        count={taskStage["Pending"]}
        text="Scheduled Visits"
        style={{ color: "#279F9F" }}
        history={history}
        filterText="Pending"
        filter={filter}
        taskFilter={otherTaskFilter}
        leadFilter={otherContactFilter}
      />
    </>
  );
};

export default AnalyticsCountContainer;

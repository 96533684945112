import React, { FunctionComponent, useEffect } from 'react';
import Navbar from '../Components/Navbar/Navbar';
import Analytics from '../Screens/Analytics/Analytics';
import Reports from '../Screens/Reports/Reports';
import { Route } from 'react-router-dom';
import UserPanel from '../Screens/UserPanel/UserPanel';
import AddContacts from '../Screens/AddContacts/AddContacts';
import ContactDetails from '../Screens/ContactDetails/ContactDetails';
import EditContact from '../Screens/EditContact/EditContact';
import { connect, useDispatch } from 'react-redux';
import { fetchOrganizationsUsers } from '../Services/users';
import CallLogsPanel from '../Screens/CallLogsPanel/CallLogsPanel';
import LeadManagerAnalytics from '../Screens/Analytics/LeadManagerAnalytics';
import Task from '../Screens/TasksPanel/TaskPanel';

type props = {
  user: any;

  history: any;
  leadsStage: any;
};

const UserRoute: FunctionComponent<props> = ({ history, user, leadsStage }) => {
  const dispatcher = useDispatch();

  useEffect(() => {
    if (user.organization_id) {
      const unSub = fetchOrganizationsUsers(
        dispatcher,
        user.organization_id,
        user
      );

      return () => {
        unSub();
      };
    }

    // eslint-disable-next-line
  }, [user]);

  return (
    <>
      <Navbar
        props={{ history }}
        title={'Contacts'}
        path={'/'}
        leadManger={false}
        callLogs={'/callLogs'}
        task={'/tasks'}
      />
      <Route exact path="/" component={UserPanel} />
      <Route
        path="/analytics"
        component={user.profile !== 'Sales' ? LeadManagerAnalytics : Analytics}
      />
      <Route path="/reports" component={Reports} />
      <Route path="/addContacts" component={AddContacts} />
      <Route path="/contactDetails" component={ContactDetails} />
      <Route path="/editContacts" component={EditContact} />
      <Route path="/callLogs" component={CallLogsPanel} />
      <Route path="/tasks" component={Task} />
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    user: state.user.data,
    leadsStage: state.leadsStage.stage,
  };
};

export default connect(mapStateToProps)(UserRoute);

import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";
import "firebase/storage";
import "firebase/database";

const app = firebase.initializeApp({
  apiKey: "AIzaSyD-imDQKQinOEbHnLia8IIAawsZ8h8U4UU",
  authDomain: "read-pro.firebaseapp.com",
  databaseURL: "https://read-pro-default-rtdb.firebaseio.com",
  projectId: "read-pro",
  storageBucket: "read-pro.appspot.com",
  messagingSenderId: "181749393848",
  appId: "1:181749393848:web:7b6f446c116ef72165474d",
  measurementId: "G-LKK64PEBB7",
});

export const auth = app.auth();
export const firestore = app.firestore();
export const functions = app.functions();
export const storage = app.storage();
export const db = app.database();
export default app;

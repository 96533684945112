import React, { FunctionComponent, useState } from "react";
import { useEffect } from "react";

import { connect } from "react-redux";
import Loading from "../../Components/Loading/Loading";
import { fetchFAQ } from "../../Services/resources";
import styles from "./FAQPanel.module.css";

type props = { history: any; user: any };
const FAQPanel: FunctionComponent<props> = ({ history, user }) => {
  const [faqList, setfaqList] = useState<any[] | undefined>(undefined);
  useEffect(() => {
    if (user.organization_id) {
      fetchFAQ(user.organization_id, (data) => setfaqList(data));
    }
  }, [user]);

  return (
    <div className={styles.parent}>
      {faqList === undefined && <Loading />}
      <div className={styles.child}>
        <div className={styles.topBar}>
          <p className={styles.title}>FAQ Section</p>
          <button
            className={styles.addButton}
            onClick={() => history.push("/addFAQ")}
          >
            Add FAQ
          </button>
        </div>
        <div className={styles.subChild}>
          {faqList?.map((item, index) => (
            <div className={styles.FAQContainer} key={index}>
              <p className={styles.heading}>{`Question ${index + 1}`}</p>
              <div className={styles.container}>{item.question}</div>

              <div className={styles.line}></div>
              <p className={styles.heading}>{`Answer ${index + 1}`}</p>
              <div className={styles.container}>{item.answer}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    user: state.user.data,
  };
};

export default connect(mapStateToProps)(FAQPanel);

import React, {
  FunctionComponent,
  useCallback,
  useRef,
  useEffect,
} from "react";
import styles from "./UserDetails.module.css";
import { auth } from "../../Firebase";
import { FaUserCircle } from "react-icons/fa";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import { TransitionProps } from "@material-ui/core/transitions";
import Button from "@material-ui/core/Button";

type props = {
  user: any;
  showUserDetail: boolean;
  close: () => void;
  openModal: (data: boolean) => void;
  contacts: {
    data: any;
  };
};

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const UserDetails: FunctionComponent<props> = ({
  user,
  showUserDetail,
  close,
  openModal,
  contacts,
}) => {
  const detailsRef = useRef(null);
  const [open, setOpen] = React.useState(false);
  const history = useHistory();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const logOut = async () => {
    Object.keys(contacts.data).forEach((key) => {
      contacts.data[key] = [];
    });
    await auth.signOut();
    history.replace("/", {});
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOutside = useCallback((event: any) => {
    const element: any = detailsRef.current;
    if (element && !element.contains(event.target)) {
      close();
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (showUserDetail === true) {
      setTimeout(
        () => document.addEventListener("click", handleClickOutside),
        100
      );
    } else {
      document.removeEventListener("click", handleClickOutside);
    }

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
    // eslint-disable-next-line
  }, [showUserDetail]);

  return (
    <>
      <div className={styles.parent} ref={detailsRef}>
        <div className={styles.detailsContainer}>
          {user.user_image && user.user_image !== "" ? (
            <img
              alt="user"
              src={user.user_image}
              className={styles.image}
              onClick={() => {
                openModal(true);
                close();
              }}
            />
          ) : (
            <FaUserCircle
              color="#C0C0C0"
              size={50}
              onClick={() => {
                openModal(true);
                close();
              }}
            />
          )}

          <div className={styles.nameContainer}>
            <p
              className={styles.name}
            >{`${user.user_first_name} ${user.user_last_name}`}</p>
            <p className={styles.email}>{user.user_email}</p>
          </div>
        </div>
        <div className={styles.buttonBox}>
          <button className={styles.logOut} onClick={handleClickOpen}>
            Log Out
          </button>
        </div>
      </div>

      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          {"Confirmation"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Are you sure you want to Logout?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Cancel
          </Button>
          <Button onClick={logOut} color="primary">
            Logout
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    user: state.user.data,
    contacts: state.contacts,
  };
};

export default connect(mapStateToProps)(UserDetails);

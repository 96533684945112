import React, { FunctionComponent, useRef, useEffect } from "react";
import styles from "../../../Screens/AddUsers/AddUsers.module.css";
import Modal from "react-modal";
import { useState } from "react";
import Loading from "../../Loading/Loading";
import { IoIosClose } from "react-icons/io";
import TextInput from "../../TextInput/TextInput";
import Dropdown from "../../DropDown/Dropdown";
import { useLocation } from "react-router-dom";
import { AiOutlineClose } from "react-icons/ai";
import { editProjectDetails } from "../../../Services/resources";
import { connect, useDispatch } from "react-redux";
import { showSnackbarAction } from "../../../Redux/actions";
import { fetchProjects } from "../../../Services/contacts";

type props = { history: any; user: any };
const ProjectEditModal: FunctionComponent<props> = ({ history, user }) => {
  const location: any = useLocation();
  const dispatcher = useDispatch();
  const [load, setLoad] = useState(false);
  const [propertyTypeSelected, setPropertyTypeSelected] = useState<any>({
    label: "Select",
    value: "Select",
  });
  const [projectsList, setProjectsList] = useState<any[] | undefined>(
    undefined
  );
  const [propertyTypes, setPropertyTypes] = useState<string[]>([]);
  const projectNameRef: any = useRef();
  const developerNameRef: any = useRef();
  const addressRef: any = useRef();
  const reraRef: any = useRef();
  const walkthroughRef: any = useRef();

  useEffect(() => {
    let data = ["Commercial", "Residential"];
    setPropertyTypes(data);
    if (user.organization_id) {
      const unsub = fetchProjects(
        (data) => setProjectsList(data),
        user.organization_id
      );

      return () => {
        unsub();
      };
    }
  }, []);

  const handleCheck = () => {
    if (projectNameRef.current.value === "") {
      projectNameRef.current.value = location.state.detail.project_name;
    }
    if (developerNameRef.current.value === "") {
      developerNameRef.current.value = location.state.detail.developer_name;
    }
    if (addressRef.current.value === "") {
      addressRef.current.value = location.state.detail.address;
    }
    if (walkthroughRef.current.value === "") {
      walkthroughRef.current.value = location.state.detail.walkthrough_link;
      dispatcher(showSnackbarAction("Please Enter Walkthrough Link", "error"));
      return false;
    }
    if (propertyTypeSelected.label === "Select") {
      propertyTypeSelected.label = location.state.detail.property_type;
    }

    return true;
  };

  const edit = () => {
    const val = handleCheck();
    if (val && projectsList) {
      setLoad(true);
      editProjectDetails(
        user.organization_id,
        projectsList,
        location.state.detail.project_name,
        projectNameRef.current.value,
        developerNameRef.current.value,
        addressRef.current.value,
        propertyTypeSelected.label,
        reraRef.current.value,
        walkthroughRef.current.value,
        dispatcher,
        (data) => setLoad(data)
      );
    }
  };

  return (
    <div className={styles.parent}>
      {load === true && <Loading />}
      <div className={styles.child}>
        <div className={styles.headerView}>
          <p className={styles.heading}> Edit Project Details</p>
          <AiOutlineClose
            className={styles.closeIcon}
            size={25}
            onClick={() => history.replace("/projects")}
          />
        </div>
        <div className={styles.box2}>
          <div className={styles.divide}>
            <div className={styles.title}>
              <p className={styles.one}>Developer Name</p>
              <p className={styles.two}></p>
            </div>
            <div>
              <TextInput
                title={"Enter developer name"}
                style={{ backgroundColor: "#fff" }}
                ref={developerNameRef}
                value={location.state.detail.developer_name}
              ></TextInput>
            </div>
          </div>
          <div className={styles.divide}>
            <div className={styles.title}>
              <p className={styles.one}>Project Name</p>
              <p className={styles.two}></p>
            </div>
            <div>
              <TextInput
                title={"Enter project name"}
                style={{ backgroundColor: "#fff" }}
                ref={projectNameRef}
                value={location.state.detail.project_name}
              ></TextInput>
            </div>
          </div>
        </div>

        <div className={styles.box2}>
          <div className={styles.divide}>
            <div className={styles.title}>
              <p className={styles.one}>Property Type</p>
              <p className={styles.two}></p>
            </div>
            <div style={{ marginTop: "7px" }}>
              <Dropdown
                option={propertyTypes}
                selectedValue={propertyTypeSelected}
                setSelectedValue={(value) => setPropertyTypeSelected(value)}
              />
            </div>
          </div>

          <div className={styles.divide}>
            <div className={styles.title}>
              <p className={styles.one}>Address</p>
              <p className={styles.two}></p>
            </div>
            <div>
              <TextInput
                title={"Enter address"}
                style={{ backgroundColor: "#fff" }}
                ref={addressRef}
                value={location.state.detail.address}
              />
            </div>
          </div>
        </div>

        <div className={styles.box2}>
          <div className={styles.divide}>
            <div className={styles.title}>
              <p className={styles.one}>Rera Link</p>
            </div>
            <div>
              <TextInput
                title={"Enter rera link"}
                style={{ backgroundColor: "#fff" }}
                ref={reraRef}
                value={location.state.detail.rera_link}
              />
            </div>
          </div>

          <div className={styles.divide}>
            <div className={styles.title}>
              <p className={styles.one}>Walkthrough Link</p>
              <p className={styles.two}></p>
            </div>
            <div>
              <TextInput
                title={"Enter walkthrough link"}
                style={{ backgroundColor: "#fff" }}
                ref={walkthroughRef}
                value={location.state.detail.walkthrough_link}
              />
            </div>
          </div>
        </div>

        <button className={styles.button} onClick={() => edit()}>
          Submit
        </button>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    user: state.user.data,
  };
};

export default connect(mapStateToProps)(ProjectEditModal);

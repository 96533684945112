import React, { FunctionComponent, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import Loading from "../../Components/Loading/Loading";
import styles from "../AddOrganization/AddOrganization.module.css";
import { AiOutlineClose } from "react-icons/ai";
import TextInput from "../../Components/TextInput/TextInput";
import { emailValidate, phoneValidate } from "../../Values/validatorsnew";
import { useLocation } from "react-router-dom";
import Select from "react-select";
import { countryData } from "../../Values/constants";
import { useEffect } from "react";
import { editOrganization } from "../../Services/organizations";

type props = {
  history: any;
};

const EditOrganization: FunctionComponent<props> = ({ history }) => {
  const dispatcher = useDispatch();
  const location: any = useLocation();
  const [load, setLoad] = useState(false);

  const organizationNameRef: any = useRef();
  const mobileNumberRef: any = useRef();
  const emailRef: any = useRef();
  const cityRef: any = useRef();
  const addressRef: any = useRef();
  const pincodeRef: any = useRef();

  const employeesRef: any = useRef();

  const editOrganizationDetails = () => {
    setLoad(true);
    editOrganization(
      location.state.detail.organization_id,
      organizationNameRef.current.value,
      mobileNumberRef.current.value,
      emailRef.current.value,
      addressRef.current.value,
      location.state.detail.country,
      location.state.detail.state,
      cityRef.current.value,
      pincodeRef.current.value,
      location.state.detail.admin_first_name,
      location.state.detail.admin_last_name,
      location.state.detail.admin_contact_number,
      location.state.detail.admin_email_id,
      employeesRef.current.value,
      location.state.detail.auth_id,
      history,
      dispatcher,
      (data) => setLoad(data)
    );
  };

  return (
    <div className={styles.parent}>
      {load === true && <Loading />}
      <div className={styles.child}>
        <div className={styles.headerView}>
          <p className={styles.heading}> Edit Organization Details</p>
          <AiOutlineClose
            className={styles.closeIcon}
            size={25}
            onClick={() => history.replace("/")}
          />
        </div>
        <div className={styles.title}>
          <p className={styles.one}>Organization Name</p>
          <p className={styles.two}></p>
        </div>
        <div className={styles.box}>
          <TextInput
            title={"Enter Name"}
            style={{ backgroundColor: "#fff" }}
            ref={organizationNameRef}
            value={location.state.detail.organization_name}
          ></TextInput>
        </div>

        <div className={styles.box2}>
          <div className={styles.divide}>
            <div className={styles.title}>
              <p className={styles.one}>Mobile Number</p>
              <p className={styles.two}></p>
            </div>
            <div>
              <TextInput
                title={"Enter Number"}
                style={{ backgroundColor: "#fff" }}
                ref={mobileNumberRef}
                validator={phoneValidate}
                length={true}
                value={location.state.detail.mobile_number}
              />
            </div>
          </div>

          <div className={styles.divide}>
            <div className={styles.title}>
              <p className={styles.one}>Email Id</p>
              <p className={styles.two}></p>
            </div>
            <div>
              <TextInput
                title={"Enter Email"}
                style={{ backgroundColor: "#fff" }}
                ref={emailRef}
                validator={emailValidate}
                value={location.state.detail.email_id}
              />
            </div>
          </div>
        </div>

        <div style={{ marginTop: "22px" }} className={styles.title}>
          <p className={styles.one}>Address</p>
          <p className={styles.two}></p>
        </div>
        <div className={styles.box}>
          <TextInput
            title={"Enter Address"}
            style={{ backgroundColor: "#fff" }}
            ref={addressRef}
            value={location.state.detail.address}
          />
        </div>

        <div className={styles.box2}>
          <div className={styles.divide}>
            <div className={styles.title}>
              <p className={styles.one}>CITY</p>
              <p className={styles.two}></p>
            </div>
            <div>
              <TextInput
                title={"Enter City"}
                style={{ backgroundColor: "#fff" }}
                ref={cityRef}
                value={location.state.detail.city}
              />
            </div>
          </div>

          <div className={styles.divide}>
            <div className={styles.title}>
              <p className={styles.one}>PINCODE</p>
              <p className={styles.two}></p>
            </div>
            <div>
              <TextInput
                title={"Enter Pincode"}
                style={{ backgroundColor: "#fff" }}
                ref={pincodeRef}
                value={location.state.detail.pincode}
              />
            </div>
          </div>
        </div>

        <div className={styles.box2}>
          <div className={styles.divide}>
            <div className={styles.title}>
              <p className={styles.one}>NUMBER OF EMPLOYEES</p>
              <p className={styles.two}>*</p>
            </div>
            <div>
              <TextInput
                title={"Enter Number Of Employees"}
                style={{ backgroundColor: "#fff" }}
                ref={employeesRef}
                value={location.state.detail.no_of_employees}
              />
            </div>
          </div>
        </div>

        <button
          className={styles.button}
          onClick={() => {
            editOrganizationDetails();
          }}
        >
          Submit
        </button>
      </div>
    </div>
  );
};

export default EditOrganization;

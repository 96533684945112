import React, { FunctionComponent, useState } from "react";
import styles from "./TopBar.module.css";
import { AiFillCaretDown } from "react-icons/ai";
import { AiOutlineSearch } from "react-icons/ai";
import { BsPlus } from "react-icons/bs";
import { FaChevronDown } from "react-icons/fa";

import StatusFilter from "../StatusFilter/StatusFilter";
import Import from "../../Components/Import/Import";
import { BsColumnsGap } from "react-icons/bs";
import { connect, useDispatch } from "react-redux";
import { IoClose } from "react-icons/io5";
import { updateStage } from "../../Redux/actions";
import { useEffect } from "react";
import { correctLeadCount } from "../../Services/organizations";
import DistributionModel from "../../Components/Modals/DistributionModel/DistributionModel";

type props = {
  user: any;
  history: any;
  //button title // some design functions are implemented based on differnet titles, like in API panel
  title: string;
  // button screen path
  path: string;
  onClick?: () => void;
  owner?: boolean;
  changeOwner?: () => void;
  // search value for panels
  onChange?: (text: any) => void;
  // status box
  setStatus?: (status: string) => void;
  // left button text change
  filterText: string;
  // for left most button and add button
  status?: string;
  taskFilter?: boolean;
  projectFilter?: boolean;
  // rightmost table column setter
  setColumnModal?: (data: boolean) => void;
  // setSmsModal?: (data: boolean) => void;
  // left button show and unshow condition
  show: boolean;
  // globally setted user role
  userRole: any;
  // only for leftmost button
  showStatusBox?: boolean;
  // dropdown exports only for contacts and users
  onExport?: () => void;
  onCallLogsImport?: () => void;
  onNotesImport?: () => void;
  onTasksImport?: () => void;
  setBulkEdit?: () => void;
  leadsStage: any;
  // setting fields to view for users with different profile
  setListViewModal?: (data: boolean) => void;
  // in api panel, news panel, to add api
  setApiModal?: (data: boolean) => void;
  // not required, just for status types in left most button
  setApiFilter?: (data: string) => void;
  // not required
  apiFilterData?: string;
  // global state
  organizationId: string;
  setLoad?: (data: boolean) => void;
  // to enter lead ids for their state to be changed to fresh
  setChangeStageModal?: (data: boolean) => void;
  // select contact whose leads are to be deleted
  setDeleteRecordsModal?: (data: boolean) => void;
  // in custom button panel in admin panel
  setCustomButtonModal?: (data: boolean) => void;
};

const TopBar: FunctionComponent<props> = ({
  user,
  history,
  title,
  path,
  onClick,
  owner,
  changeOwner,
  onChange,
  setStatus,
  filterText,
  status,
  taskFilter,
  projectFilter,
  setColumnModal,
  show,
  userRole,
  showStatusBox,
  onExport,
  onCallLogsImport,
  onNotesImport,
  onTasksImport,
  setBulkEdit,
  leadsStage,
  setListViewModal,
  setApiModal,
  setApiFilter,
  apiFilterData,
  organizationId,
  setLoad,
  setChangeStageModal,
  setDeleteRecordsModal,
  setCustomButtonModal,
}) => {
  // left most box expand condition
  const [expand, setExpand] = useState(false);
  // dropdown expand condition
  const [expandImport, setExpandImport] = useState(false);
  // search icon on search bar change to cross on change
  const [value, setValue] = useState("");
  //  lead distribution panel open and close
  const [showDistributionModal, setShowDistributionModal] =
    useState(false);
  const dispatcher = useDispatch();
  const onStageChange = (event: any, stage: string) => {
    if (event.target.checked) {
      dispatcher(updateStage({ [stage]: true }));
    } else if (!event.target.checked) {
      if (
        stage !== "FRESH" &&
        stage !== "INTERESTED" &&
        stage !== "CALLBACK"
      ) {
        dispatcher(updateStage({ [stage]: false }));
      }
    }
  };

  // api panel, 7 days , .....
  const apiFilter = (event: any, filter: string) => {
    if (event.target.checked) {
      if (setApiFilter) {
        setApiFilter(filter);
      }
    } else {
      if (setApiFilter) {
        setApiFilter("");
      }
    }
  };

  return (
    <div className={styles.topBar}>
      {showStatusBox === true && (
        <>
          {userRole !== "Sales" && (
            <div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <div
                  className={styles.statusBox}
                  style={
                    title === "Add Contacts" ||
                    title === "drilldown"
                      ? { borderWidth: "0px" }
                      : {}
                  }
                >
                  {owner ? (
                    <p
                      className={styles.owner}
                      onClick={changeOwner}
                    >
                      Change Owner
                    </p>
                  ) : (
                    title !== "Add Contacts" &&
                    title !== "drilldown" && (
                      <div className={styles.innerChild}>
                        <p
                          className={styles.statusText}
                          style={
                            filterText === "userPanel"
                              ? { cursor: "pointer" }
                              : {}
                          }
                        >
                          {filterText}
                        </p>
                        {filterText !== "userPanel" && (
                          <>
                            <div
                              className={styles.line}
                            ></div>
                            <AiFillCaretDown
                              onClick={() =>
                                setExpand(!expand)
                              }
                              className={styles.icon}
                            />
                          </>
                        )}
                      </div>
                    )
                  )}
                </div>
                {owner && (
                  <div
                    className={styles.statusBox}
                    style={{
                      marginLeft: "10px",
                      borderWidth: "0px",
                    }}
                  >
                    <p
                      className={styles.owner}
                      onClick={setBulkEdit}
                    >
                      Edit Records
                    </p>
                  </div>
                )}
              </div>
              {expand === true && (
                <div
                  style={{
                    position: "relative",
                    display: "flex",
                    alignItems: "center",
                    paddingLeft: "22px",
                    justifyContent: "center",
                  }}
                >
                  <StatusFilter
                    setStatus={(status) =>
                      setStatus && setStatus(status)
                    }
                    status={status}
                    taskFilter={taskFilter}
                    projectFilter={projectFilter}
                    setExpand={(data) => setExpand(data)}
                    expand={expand}
                  />
                </div>
              )}
            </div>
          )}
        </>
      )}

      {title === "Add User" && (
        <>
          <button
            style={{ marginLeft: "20px" }}
            className={styles.addBox}
            onClick={() => {
              setListViewModal && setListViewModal(true);
            }}
          >
            Set List View
          </button>
          <button
            style={{ marginLeft: "20px" }}
            className={styles.addBox}
            onClick={() => {
              setLoad && setLoad(true);
              if (setLoad) {
                correctLeadCount(
                  organizationId,
                  (data) => setLoad(data),
                  dispatcher
                );
              }
            }}
          >
            Correct Count
          </button>
          <button
            style={{ marginLeft: "20px" }}
            className={styles.addBox}
            onClick={() => {
              setChangeStageModal &&
                setChangeStageModal(true);
            }}
          >
            Change Stage
          </button>
          <button
            style={{ marginLeft: "20px" }}
            className={styles.addBox}
            onClick={() => {
              setDeleteRecordsModal &&
                setDeleteRecordsModal(true);
            }}
          >
            Delete Records
          </button>
        </>
      )}

      {title === "Add Api" && (
        <button
          style={{ marginLeft: "20px" }}
          className={styles.addBox}
          onClick={() => {
            setApiModal && setApiModal(true);
          }}
        >
          Add API
        </button>
      )}

      {title === "Add News Link" && (
        <button
          style={{ marginLeft: "20px" }}
          className={styles.addBox}
          onClick={() => {
            setApiModal && setApiModal(true);
          }}
        >
          Add News Link
        </button>
      )}

      {title === "Add Custom Button" && (
        <button
          style={{ marginLeft: "20px" }}
          className={styles.addBox}
          onClick={() => {
            setCustomButtonModal &&
              setCustomButtonModal(true);
          }}
        >
          Add Custom Buttom
        </button>
      )}

      {userRole !== "oganization" &&
        userRole !== "superAdmin" &&
        title === "Add Contacts" && (
          <>
            <div className={styles.leadContainer}>
              <div className={styles.leadBox}>
                <p className={styles.leadText}>Fresh</p>
                <input
                  type="checkbox"
                  onChange={(e) => {
                    onStageChange(e, "FRESH");
                  }}
                  checked={leadsStage.FRESH}
                />
              </div>
              <div className={styles.leadBox}>
                <p className={styles.leadText}>
                  Interested
                </p>
                <input
                  type="checkbox"
                  onChange={(e) => {
                    onStageChange(e, "INTERESTED");
                  }}
                  checked={leadsStage.INTERESTED}
                />
              </div>
              <div className={styles.leadBox}>
                <p className={styles.leadText}>Call Back</p>
                <input
                  type="checkbox"
                  onChange={(e) => {
                    onStageChange(e, "CALLBACK");
                  }}
                  checked={leadsStage["CALLBACK"]}
                />
              </div>
              <div className={styles.leadBox}>
                <p className={styles.leadText}>Won</p>
                <input
                  type="checkbox"
                  onChange={(e) => {
                    onStageChange(e, "WON");
                  }}
                  onClick={() => {
                    onChange && onChange("");
                    setValue("");
                  }}
                  checked={leadsStage.WON}
                />
              </div>
              <div className={styles.leadBox}>
                <p className={styles.leadText}>Lost</p>
                <input
                  type="checkbox"
                  onChange={(e) => {
                    onStageChange(e, "LOST");
                  }}
                  onClick={() => {
                    onChange && onChange("");
                    setValue("");
                  }}
                  checked={leadsStage.LOST}
                />
              </div>
              <div className={styles.leadBox}>
                <p className={styles.leadText}>
                  Not Interested
                </p>
                <input
                  type="checkbox"
                  onChange={(e) => {
                    onStageChange(e, "NOT INTERESTED");
                  }}
                  onClick={() => {
                    onChange && onChange("");
                    setValue("");
                  }}
                  checked={leadsStage["NOT INTERESTED"]}
                />
              </div>
              <div className={styles.leadBox}>
                <p className={styles.leadText}>
                  Transfer Leads
                </p>
                <input
                  type="checkbox"
                  onChange={(e) => {
                    onStageChange(e, "ALL");
                  }}
                  onClick={() => {
                    onChange && onChange("");
                    setValue("");
                  }}
                  checked={leadsStage["ALL"]}
                />
              </div>
            </div>
          </>
        )}

      {title === "Add Api Data" && (
        <>
          <div className={styles.leadContainer}>
            <div className={styles.leadBox}>
              <p className={styles.leadText}>Last 7 Days</p>
              <input
                type="checkbox"
                onChange={(e) => {
                  apiFilter(e, "7");
                }}
                checked={
                  apiFilterData === "7" ? true : false
                }
              />
            </div>
            <div className={styles.leadBox}>
              <p className={styles.leadText}>
                Last 30 Days
              </p>
              <input
                type="checkbox"
                onChange={(e) => {
                  apiFilter(e, "30");
                }}
                checked={
                  apiFilterData === "30" ? true : false
                }
              />
            </div>
            <div className={styles.leadBox}>
              <p className={styles.leadText}>All</p>
              <input
                type="checkbox"
                onChange={(e) => {
                  apiFilter(e, "all");
                }}
                checked={
                  apiFilterData === "all" ? true : false
                }
              />
            </div>
          </div>
        </>
      )}

      <div className={styles.box}>
        {title !== "drilldown" &&
          title !== "Lead Distribution" &&
          title !== "Add Custom Button" && (
            <div className={styles.searchBox}>
              <input
                className={styles.searchText}
                placeholder="Search"
                value={value}
                onChange={(val) => {
                  onChange && onChange(val.target.value);
                  setValue(val.target.value);
                }}
              />
              {value.length === 0 ? (
                <AiOutlineSearch />
              ) : (
                <IoClose
                  color={"ff0000"}
                  onClick={() => {
                    onChange && onChange("");
                    setValue("");
                  }}
                />
              )}
            </div>
          )}

        {title !== "Add Task" &&
          title !== "drilldown" &&
          title !== "Add Api" &&
          title !== "Add News Link" &&
          title !== "Add Api Data" &&
          title !== "Add Custom Button" && (
            <button
              className={styles.addBox}
              onClick={() => {
                title === "Lead Distribution"
                  ? setShowDistributionModal(true)
                  : history.push(path);
              }}
            >
              <BsPlus size={22} color={"#ffffff"} />
              {title}
            </button>
          )}

        {showDistributionModal && (
          <DistributionModel
            open={showDistributionModal}
            organization_id={user.organization_id}
            close={() => {
              setShowDistributionModal(false);
            }}
          />
        )}
       
        {show === true && title !== "drilldown" && (
          <>
            {(userRole === "Lead Manager" ||
              userRole === "organization" ||
              userRole === "Team Lead") && (
              <div
                className={styles.arrowBox}
                onClick={() =>
                  setExpandImport(!expandImport)
                }
              >
                <FaChevronDown />
                {expandImport === true && (
                  <div
                    style={{
                      position: "relative",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginTop: "37px",
                    }}
                  >
                    <Import
                      onClick={onClick}
                      setExpandImport={(data) =>
                        setExpandImport(data)
                      }
                      onExport={onExport}
                      expand={expandImport}
                      close={() => setExpandImport(false)}
                      onCallLogsImport={onCallLogsImport}
                      onNotesImport={onNotesImport}
                      onTasksImport={onTasksImport}
                    />
                  </div>
                )}
              </div>
            )}
          </>
        )}
        <div  style={{
                      position: 'relative',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      marginTop: '37px',
                    }}>hi</div>
        {true && (
            <div  
            style={{
              position: 'relative',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              marginTop: '37px',
            }}
          ></div>
        )}
        {title !== "Add Custom Button" && (
          <div className={styles.columnManage}>
            <BsColumnsGap
              size={20}
              onClick={() => {
                setColumnModal && setColumnModal(true);
              }}
            />
            <span className={styles.tooltiptext}>
              Column Manager
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    user: state.user.data,
    userRole: state.user.role,
    leadsStage: state.leadsStage.stage,
    organizationId: state.organization.id,
  };
};

export default connect(mapStateToProps)(TopBar);

import React, { useState, useEffect } from "react";
import styles from "./Resources.module.css";
import ImportImageModal from "../../Components/Modals/ImportModal/ImportImageModal";
import { connect, useDispatch } from "react-redux";
import AddResources from "../../Components/Modals/AddResourcesModal/AddResourceModal";
import {
  fetchResources,
  createLocation,
  createBudget,
  deleteCarouselImage,
  createLeadSource,
  deleteLocation,
  deleteBudget,
  deleteLeadSource,
  editLocation,
  editLeadSource,
  editBudget,
  createCommercialType,
  createResidentialType,
  editResType,
  editCommType,
  deleteResidential,
  deleteComType,
  createTransferReason,
} from "../../Services/resources";
import { getDateString } from "../../Values/utils";
import { showSnackbarAction } from "../../Redux/actions";
import Loading from "../../Components/Loading/Loading";
import { MdDelete } from "react-icons/md";

import { FiEdit3 } from "react-icons/fi";
import EditResources from "../../Components/Modals/AddResourcesModal/EditResourceModal";

const Resources = ({ history, user }: any) => {
  const [openLocation, setOpenLocation] = useState(false);
  const [openBudget, setOpenBudget] = useState(false);
  const [openLeadSource, setOpenLeadSource] = useState(false);
  const [openCommercial, setOpenCommercial] = useState(false);
  const [openResidential, setOpenResidential] = useState(false);
  const [openTransferReason, setOpenTransferReason] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [editValue, setEditValue] = useState("");
  const [editModalType, setEditModalType] = useState<
    "Location" | "Budget" | "Lead Source" | "Residential" | "Commercial"
  >("Location");
  const [importImageModal, setImportImageModal] = useState(false);
  const [locationsList, setLocationsList] = useState<any[] | undefined>(
    undefined
  );
  const [budgetsList, setBudgetsList] = useState<any[] | undefined>(undefined);
  const [leadSourceList, setLeadSourceList] = useState<any[] | undefined>(
    undefined
  );
  const [carouselList, setCarouselList] = useState<any[] | undefined>(
    undefined
  );
  const [comTypes, setComTypes] = useState<any[] | undefined>(undefined);
  const [resTypes, setResTypes] = useState<any[] | undefined>(undefined);
  const [transferReasons, setTransferReasons] = useState<any[] | undefined>(
    undefined
  );
  const [load, setLoad] = useState(false);
  const [locationNames, setLocationNames] = useState<any[]>([]);

  useEffect(() => {
    if (user.organization_id) {
      const unsub = fetchResources(
        (data) => setLocationsList(data),
        (data) => setBudgetsList(data),
        (data) => setCarouselList(data),
        (data) => setLeadSourceList(data),
        (data) => setComTypes(data),
        (data) => setResTypes(data),

        user.organization_id,
        (data) => setTransferReasons(data)
      );

      return () => {
        unsub();
      };
    }
  }, [user]);
  const dispatcher = useDispatch();
  const deleteImage = async (index: number, image_name: string) => {
    if (carouselList) {
      setLoad(true);
      await deleteCarouselImage(
        user.organization_id,
        image_name,
        carouselList,
        index,
        dispatcher
      );
      setLoad(false);
    }
  };

  const deleteLocationFirebase = (location: string, index: number) => {
    if (locationsList) {
      setLoad(true);
      deleteLocation(
        location,
        user.organization_id,
        locationsList,
        index,
        dispatcher,
        (data) => setLoad(data)
      );
    }
  };

  const deleteBudgetFirebase = (budget: string, index: number) => {
    if (budgetsList) {
      setLoad(true);

      deleteBudget(
        budget,
        user.organization_id,
        budgetsList,
        index,
        dispatcher,
        (data) => setLoad(data)
      );
    }
  };

  const deleteLeadSourceFirebase = (leadSource: string, index: number) => {
    if (leadSourceList) {
      setLoad(true);
      deleteLeadSource(
        leadSource,
        user.organization_id,
        leadSourceList,
        index,
        dispatcher,
        (data) => setLoad(data)
      );
    }
  };

  const deleteResidentialType = (residential: string, index: number) => {
    if (resTypes) {
      setLoad(true);
      deleteResidential(
        residential,
        user.organization_id,
        resTypes,
        index,
        dispatcher,
        (data) => setLoad(data)
      );
    }
  };

  const deleteCommercialType = (commercial: string, index: number) => {
    if (comTypes) {
      setLoad(true);
      deleteComType(
        commercial,
        user.organization_id,
        comTypes,
        index,
        dispatcher,
        (data) => setLoad(data)
      );
    }
  };
  const onEdit = (
    type: "Location" | "Budget" | "Lead Source" | "Residential" | "Commercial",
    value: string
  ) => {
    setEditModalType(type);
    setEditModal(true);
    setEditValue(value);
  };

  useEffect(() => {
    let l: any = [];
    locationsList?.forEach((item) => {
      l.push(item.location_name);
    });
    setLocationNames(l);
  }, [locationsList]);
  return (
    <>
      {carouselList === undefined && <Loading />}
      {load && <Loading />}

      <div className={styles.parent}>
        <div className={styles.child}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "55%",
              justifyContent: "space-between",
            }}
          >
            <div className={styles.locationContainer}>
              <div className={styles.imageTable}>
                <table className={styles.table}>
                  <thead className={styles.head}>
                    <tr>
                      <th className={styles.th}>IMAGE NAME</th>
                      <th className={styles.th}>IMAGE</th>
                      <th className={styles.th}>Created At</th>
                      <th className={styles.th}></th>
                    </tr>
                  </thead>
                  <tbody>
                    {carouselList?.map((item: any, index: number) => (
                      <tr key={index}>
                        <td className={styles.td}>{item.image_name}</td>
                        <td className={styles.td}>
                          <img
                            width={"65%"}
                            height={"90px"}
                            src={item.url}
                            alt="carousel"
                          />
                        </td>
                        <td className={styles.td}>
                          {getDateString(item.created_at)}
                        </td>
                        <td className={styles.td} style={{ width: "40px" }}>
                          <MdDelete
                            color={"#ff0000"}
                            size={20}
                            onClick={() => deleteImage(index, item.image_name)}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <button
                className={styles.addImageButton}
                onClick={() => setImportImageModal(true)}
              >
                ADD IMAGE
              </button>
            </div>

            <div className={styles.budgetContainer}>
              <div className={styles.tableContainer}>
                <table className={styles.table}>
                  <thead className={styles.head}>
                    <tr>
                      <th className={styles.th}></th>
                      <th className={styles.th}>Lead Source</th>
                      <th className={styles.th}>Created At</th>
                      <th className={styles.th}></th>
                    </tr>
                  </thead>
                  <tbody>
                    {leadSourceList?.map((item: any, index: number) => (
                      <tr key={index}>
                        <td className={styles.td}>
                          <FiEdit3
                            size={15}
                            color="#279EA0"
                            onClick={() =>
                              onEdit("Lead Source", item.leadSource)
                            }
                          />
                        </td>
                        <td className={styles.td}>{item.leadSource}</td>
                        <td className={styles.td}>
                          {getDateString(item.created_at)}
                        </td>
                        <td className={styles.td} style={{ width: "40px" }}>
                          <MdDelete
                            color={"#ff0000"}
                            size={20}
                            onClick={() =>
                              deleteLeadSourceFirebase(item.leadSource, index)
                            }
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className={styles.addLocationContainer}>
                <button
                  className={styles.addButton}
                  onClick={() => setOpenLeadSource(true)}
                >
                  Add Lead Source
                </button>
              </div>
            </div>

            <div className={styles.budgetContainer}>
              <div className={styles.tableContainer}>
                <table className={styles.table}>
                  <thead className={styles.head}>
                    <tr>
                      <th className={styles.th}></th>
                      <th className={styles.th}>Commercial Type</th>

                      <th className={styles.th}></th>
                    </tr>
                  </thead>
                  <tbody>
                    {comTypes?.map((item: any, index: number) => (
                      <tr key={index}>
                        <td className={styles.td}>
                          <FiEdit3
                            size={15}
                            color="#279EA0"
                            onClick={() => onEdit("Commercial", item)}
                          />
                        </td>
                        <td className={styles.td}>{item}</td>

                        <td className={styles.td} style={{ width: "40px" }}>
                          <MdDelete
                            color={"#ff0000"}
                            size={20}
                            onClick={() => {
                              deleteCommercialType(item, index);
                            }}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>

              <button
                className={styles.addButton}
                onClick={() => setOpenCommercial(true)}
              >
                Add Commercial Type
              </button>
            </div>
          </div>

          <div className={styles.location}>
            <div className={styles.locationContainer}>
              <div className={styles.tableContainer}>
                <table className={styles.table}>
                  <thead className={styles.head}>
                    <tr>
                      <th className={styles.th}></th>
                      <th className={styles.th}>Locations</th>
                      <th className={styles.th}>Created At</th>
                      <th className={styles.th}></th>
                    </tr>
                  </thead>
                  <tbody>
                    {locationsList?.map((item: any, index: number) => (
                      <>
                        <tr key={index}>
                          <td className={styles.td}>
                            <FiEdit3
                              size={15}
                              color="#279EA0"
                              onClick={() =>
                                onEdit("Location", item.location_name)
                              }
                            />
                          </td>
                          <td className={styles.td}>{item.location_name}</td>
                          <td className={styles.td}>
                            {getDateString(item.created_at)}
                          </td>
                          <td className={styles.td} style={{ width: "40px" }}>
                            <MdDelete
                              color={"#ff0000"}
                              size={20}
                              onClick={() =>
                                deleteLocationFirebase(
                                  item.location_name,
                                  index
                                )
                              }
                            />
                          </td>
                        </tr>
                      </>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className={styles.addLocationContainer}>
                <button
                  className={styles.addButton}
                  onClick={() => setOpenLocation(true)}
                >
                  Add Location
                </button>
              </div>
            </div>
            <div className={styles.budgetContainer}>
              <div className={styles.tableContainer}>
                <table className={styles.table}>
                  <thead className={styles.head}>
                    <tr>
                      <th className={styles.th}></th>
                      <th className={styles.th}>Budgets</th>
                      <th className={styles.th}>Created At</th>
                      <th className={styles.th}></th>
                    </tr>
                  </thead>
                  <tbody>
                    {budgetsList?.map((item: any, index: number) => (
                      <tr key={index}>
                        <td className={styles.td}>
                          <FiEdit3
                            size={15}
                            color="#279EA0"
                            onClick={() => onEdit("Budget", item.budget)}
                          />
                        </td>
                        <td className={styles.td}>{item.budget}</td>
                        <td className={styles.td}>
                          {getDateString(item.created_at)}
                        </td>
                        <td className={styles.td} style={{ width: "40px" }}>
                          <MdDelete
                            color={"#ff0000"}
                            size={20}
                            onClick={() =>
                              deleteBudgetFirebase(item.budget, index)
                            }
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className={styles.addLocationContainer}>
                <button
                  className={styles.addButton}
                  onClick={() => setOpenBudget(true)}
                >
                  Add Budget
                </button>
              </div>
            </div>

            <div className={styles.budgetContainer}>
              <div className={styles.tableContainer}>
                <table className={styles.table}>
                  <thead className={styles.head}>
                    <tr>
                      <th className={styles.th}></th>
                      <th className={styles.th}>Residential Type</th>

                      <th className={styles.th}></th>
                    </tr>
                  </thead>
                  <tbody>
                    {resTypes?.map((item: any, index: number) => (
                      <tr key={index}>
                        <td className={styles.td}>
                          <FiEdit3
                            size={15}
                            color="#279EA0"
                            onClick={() => onEdit("Residential", item)}
                          />
                        </td>
                        <td className={styles.td}>{item}</td>

                        <td className={styles.td} style={{ width: "40px" }}>
                          <MdDelete
                            color={"#ff0000"}
                            size={20}
                            onClick={() => {
                              deleteResidentialType(item, index);
                            }}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className={styles.addLocationContainer}>
                <button
                  className={styles.addButton}
                  onClick={() => setOpenResidential(true)}
                >
                  Add Residential Category
                </button>
              </div>
            </div>

            <div className={styles.budgetContainer}>
              <div className={styles.tableContainer}>
                <table className={styles.table}>
                  <thead className={styles.head}>
                    <tr>
                      <th className={styles.th}></th>
                      <th className={styles.th}>Transfer Reason</th>

                      <th className={styles.th}></th>
                    </tr>
                  </thead>
                  <tbody>
                    {transferReasons?.map((item: any, index: number) => (
                      <tr key={index}>
                        <td className={styles.td}>
                          {/* <FiEdit3
                            size={15}
                            color="#279EA0"
                            onClick={() => onEdit("Residential", item)}
                          /> */}
                        </td>
                        <td className={styles.td}>{item}</td>

                        <td className={styles.td} style={{ width: "40px" }}>
                          {/* <MdDelete
                            color={"#ff0000"}
                            size={20}
                            onClick={() => {
                              deleteResidentialType(item, index);
                            }}
                          /> */}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className={styles.addLocationContainer}>
                <button
                  className={styles.addButton}
                  onClick={() => setOpenTransferReason(true)}
                >
                  Add Transfer Reason
                </button>
              </div>
            </div>
          </div>
        </div>

        {openLocation && (
          <AddResources
            open={openLocation}
            close={() => setOpenLocation(false)}
            heading={"Location"}
            title={"Enter Location"}
            onSave={(value: any) => {
              if (value === "") {
                dispatcher(showSnackbarAction("Enter Location!!", "error"));
              } else {
                setLoad(true);
                if (locationNames.includes(value)) {
                  dispatcher(
                    showSnackbarAction("This Location Already Exists!!", "info")
                  );
                  setLoad(false);
                  setOpenLocation(false);
                } else {
                  createLocation(
                    user.organization_id,
                    value,
                    dispatcher,
                    history,
                    (data) => {
                      setOpenLocation(data);
                      setLoad(data);
                    }
                  );
                }
              }
            }}
          />
        )}
        {openTransferReason && (
          <AddResources
            open={openTransferReason}
            close={() => setOpenTransferReason(false)}
            heading={"Transfer Reason"}
            title={"Enter Reason"}
            onSave={(value: any) => {
              if (value === "") {
                dispatcher(
                  showSnackbarAction("Enter Transfer Reason!!", "error")
                );
              } else {
                setLoad(true);

                createTransferReason(
                  user.organization_id,
                  value,
                  dispatcher,
                  history,
                  (data) => {
                    setOpenTransferReason(data);
                    setLoad(data);
                  }
                );
              }
            }}
          />
        )}
        {openBudget && (
          <AddResources
            open={openBudget}
            close={() => setOpenBudget(false)}
            heading={"Budget"}
            title={"Enter Budget"}
            onSave={(value: any) => {
              if (value === "") {
                dispatcher(showSnackbarAction("Enter Budget!!", "error"));
              } else {
                setLoad(true);
                createBudget(
                  user.organization_id,
                  value,
                  dispatcher,
                  history,
                  (data) => {
                    setOpenBudget(data);
                    setLoad(data);
                  }
                );
              }
            }}
          />
        )}
        {openLeadSource && (
          <AddResources
            open={openLeadSource}
            close={() => setOpenLeadSource(false)}
            heading={"Lead Source"}
            title={"Enter Lead Source"}
            onSave={(value: any) => {
              if (value === "") {
                dispatcher(showSnackbarAction("Enter Lead Source!!", "error"));
              } else {
                setLoad(true);

                createLeadSource(
                  user.organization_id,
                  value,
                  dispatcher,
                  history,
                  (data) => {
                    setOpenLeadSource(data);
                    setLoad(data);
                  }
                );
              }
            }}
          />
        )}
        {openCommercial && (
          <AddResources
            open={openCommercial}
            close={() => setOpenCommercial(false)}
            heading={"Commercial Type"}
            title={"Enter Commercial Type"}
            onSave={(value: any) => {
              if (value === "") {
                dispatcher(
                  showSnackbarAction("Enter Commercial Type!!", "error")
                );
              } else {
                setLoad(true);

                createCommercialType(
                  user.organization_id,
                  value,
                  dispatcher,
                  history,
                  (data) => {
                    setOpenCommercial(data);
                    setLoad(data);
                  }
                );
              }
            }}
          />
        )}
        {openResidential && (
          <AddResources
            open={openResidential}
            close={() => setOpenResidential(false)}
            heading={"Residential Type"}
            title={"Enter Residential Type"}
            onSave={(value: any) => {
              if (value === "") {
                dispatcher(
                  showSnackbarAction("Enter Residential Type!!", "error")
                );
              } else {
                setLoad(true);

                createResidentialType(
                  user.organization_id,
                  value,
                  dispatcher,
                  history,
                  (data) => {
                    setOpenResidential(data);
                    setLoad(data);
                  }
                );
              }
            }}
          />
        )}

        {importImageModal && (
          <ImportImageModal
            open={importImageModal}
            close={() => setImportImageModal(false)}
            organization_id={user.organization_id}
            history={history}
          />
        )}
        {editModal && (
          <EditResources
            open={editModal}
            title={`${editValue}`}
            heading={`Edit ${editModalType}`}
            close={() => setEditModal(false)}
            onSave={(newValue) => {
              setLoad(true);
              if (editModalType === "Budget") {
                editBudget(
                  user.organization_id,
                  editValue,
                  newValue,
                  budgetsList,
                  dispatcher,
                  (val: boolean) => {
                    setLoad(val);
                    setEditModal(val);
                  }
                );
              } else if (editModalType === "Lead Source") {
                editLeadSource(
                  user.organization_id,
                  editValue,
                  newValue,
                  leadSourceList,
                  dispatcher,
                  (val: boolean) => {
                    setLoad(val);
                    setEditModal(val);
                  }
                );
              } else if (editModalType === "Residential") {
                editResType(
                  user.organization_id,
                  editValue,
                  newValue,
                  resTypes,
                  dispatcher,
                  (val: boolean) => {
                    setLoad(val);
                    setEditModal(val);
                  }
                );
              } else if (editModalType === "Commercial") {
                editCommType(
                  user.organization_id,
                  editValue,
                  newValue,
                  comTypes,
                  dispatcher,
                  (val: boolean) => {
                    setLoad(val);
                    setEditModal(val);
                  }
                );
              } else {
                editLocation(
                  user.organization_id,
                  editValue,
                  newValue,
                  locationsList,
                  dispatcher,
                  (val: boolean) => {
                    setLoad(val);
                    setEditModal(val);
                  }
                );
              }
            }}
          />
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    user: state.user.data,
    contacts: state.contacts,
  };
};

export default connect(mapStateToProps)(Resources);

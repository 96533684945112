import React from "react";
import { Route, Link } from "react-router-dom";
import SignIn from "../Screens/SignIn/SignIn";
import Password from "../Screens/ForgotPassword/ForgotPassword";
import { connect } from "react-redux";
import PasswordReset from "../Screens/PasswordReset/PasswordReset";

const AuthRoutes = ({
  history,
  firstLogin,
}: {
  history: any;
  firstLogin: boolean;
}) => {
  return (
    <>
      {String(history.location.pathname).startsWith("/resetpassword") !==
        true && (
        <>
          <Route path="/forgetpassword" component={Password} />
          {firstLogin === true && (
            <Route path={"/"} component={PasswordReset} />
          )}
          <Route path="/" component={SignIn} />
        </>
      )}
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    firstLogin: state.user.firstLogin,
  };
};
export default connect(mapStateToProps)(AuthRoutes);

import React, { FunctionComponent, useRef, useState } from "react";
import styles from "./SignIn.module.css";
import Logo from "../../Assets/Images/readPro_logo.png";
import background from "../../Assets/Images/background.svg";
import TextInput from "../../Components/TextInput/TextInput";
import PasswordInput from "../../Components/Password/PasswordInput";
import { MdEmail } from "react-icons/md";
import { RiLockPasswordFill } from "react-icons/ri";
import { signInFirebase } from "../../Services/auth";
import { emailValidate, passwordValidate } from "../../Values/validatorsnew";
import { useDispatch } from "react-redux";
import Loading from "../../Components/Loading/Loading";
import Logo2 from "../../Assets/Images/ify_logo.png";

type props = {
  history: any;
};

const SignIn: FunctionComponent<props> = ({ history }) => {
  const emailRef: any = useRef();
  const passwordRef: any = useRef();
  const dispatcher = useDispatch();
  const [load, setLoad] = useState(false);

  const changeLoad = (value: boolean) => {
    setLoad(value);
  };
  const login = async (email: string, password: string) => {
    signInFirebase(email, password, history, dispatcher, changeLoad);
  };

  return (
    <div
      style={{
        backgroundImage: `url(${background})`,
        backgroundRepeat: "no-repeat",
        width: "100%",
        backgroundSize: "contain",
        backgroundPositionY: "85px",
        backgroundColor: "rgba(0, 0, 0, 0.02)",
      }}
      className={styles.parent}
    >
      {load && <Loading />}
      <div className={styles.container}>
        <div className={styles.logoContainer}>
          <img
            src={Logo}
            alt={"top"}
            className={styles.image}
            width={"150vw"}
          />
          <p className={styles.welcome}>Welcome,</p>
          <p className={styles.text}>Log in Into Your Account.</p>
        </div>
        <div className={styles.inputContainer}>
          <div className={styles.form}>
            <div className={styles.emailInput}>
              <TextInput
                title={"Email"}
                ref={emailRef}
                validator={emailValidate}
              >
                <MdEmail color={"#808080"} />
              </TextInput>
            </div>

            <div className={styles.input}>
              <PasswordInput
                title={"Password"}
                ref={passwordRef}
                validator={passwordValidate}
                onPress={() =>
                  login(emailRef.current?.value, passwordRef.current?.value)
                }
              >
                <RiLockPasswordFill color={"#808080"} />
              </PasswordInput>
            </div>
            <div
              className={styles.forgetBox}
              onClick={() => history.push("/forgetpassword")}
            >
              <p className={styles.forgetText}>Forget Password?</p>
            </div>
          </div>
          <div className={styles.buttonView}>
            <button
              className={styles.button}
              onClick={() =>
                login(emailRef.current?.value, passwordRef.current?.value)
              }
            >
              Login
            </button>
          </div>
        </div>
        <div className={styles.bottomView}>
          <p className={styles.bottomText}>POWERED BY</p>
          <img src={Logo2} alt={"bottom "} width={"150vw"} />
        </div>
      </div>
    </div>
  );
};

export default SignIn;

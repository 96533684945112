import React, { FunctionComponent } from "react";
import { useState } from "react";
import Modal from "react-modal";
import styles from "./ProjectDocumentModal.module.css";
import { IoMdDocument } from "react-icons/io";
import { uploadProjectResources } from "../../../Services/resources";
import { connect, useDispatch } from "react-redux";
import Loading from "react-loading";

type props = {
  open: boolean;
  close: () => void;
  title: string;
setLoad:(data:boolean)=>void;
  projectData: any;
  type: string;
};
const ProjectDocumentModal: FunctionComponent<props> = ({
  open,
  close,
  title,
setLoad,
  projectData,
  type,
}) => {
  const dispatcher = useDispatch();
  
  const upload = async (file: any) => {
    
    let name:any=document.getElementById('file-input')
    let nameList=name.value.split("\\")
    let fileName=nameList[nameList.length-1]
    close();
    setLoad(true);
    await uploadProjectResources(
      file[0],
      projectData,
      type,
      fileName,
      dispatcher
    );
    setLoad(false);
    
  };
  return (
    <>
    
    <Modal
      isOpen={open}
      onRequestClose={close}
      className={styles.parent}
      overlayClassName={styles.overlay}
      shouldCloseOnOverlayClick={true}
      shouldCloseOnEsc={true}
    >
      
      <p className={styles.title}>{title}</p>
      <div
        className={styles.dragBox}
        onDragOver={(e) => {
          e.preventDefault();
        }}
        onDrop={(e) => {
          e.preventDefault();
          upload(e.dataTransfer.files);
        }}
      >
        <IoMdDocument size={80} color={"#808080"} />
        <p>Drag And Drop</p>
      </div>

      <div className={styles.buttonContainer}>
        <button className={styles.cancelButton} onClick={close}>
          Cancel
        </button>
        <div className={styles.selectBox}>
          <label htmlFor="file-input" className={styles.uploadButton}>
            Upload File
          </label>
          <input
            id="file-input"
            className={styles.select}
            type="file"
            accept={type==="images"?".png,.jpeg,.jpg":".pdf,.png,.jpg,.png"}
            onChange={(e: any) => upload(e.target.files)}
          />
        </div>
      </div>
    </Modal>
    </>
  );
};

export default ProjectDocumentModal;

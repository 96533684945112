import React, { useState, useEffect } from "react";
import commonStyle from "../common.module.css";
import Topbar from "../../Components/TopBar/TopBar";
import {
  booleanObject,
  datesField,
  TASK_COLUMNS,
} from "../../Values/tables";
import { connect, useDispatch } from "react-redux";
import { fetchTasks } from "../../Services/contacts";
import {
  getFilterObject,
  searchTaskItem,
  filterTaskStatus,
} from "../../Values/utils";
import Loading from "../../Components/Loading/Loading";
import CustomTable from "../../Components/CustomTable/CustomTable";
import {
  getDateString,
  searchContacts,
} from "../../Values/utils";

import axios from "axios";

import moment from "moment";

import { url } from "../../Values/constants";
import {
  setFilterObject,
  setRefreshTasks,
  showSnackbarAction,
} from "../../Redux/actions";
import ApiCustomTable from "../../Components/CustomTable/ApiCustomTable";
import ApiTopBar from "../../Components/TopBar/ApiTopBar";

let isFinished = false;
const Task = ({
  history,
  user,
  branchUsers,
  filterObject,
  searchString,
  filterSort,
  refreshTasks,
}: any) => {
  const [tasksList, setTasksList] = useState<
    any[] | undefined
  >(undefined);
  const [filterData, setFilterData] = useState<
    any[] | undefined
  >(undefined);
  const [temporaryData, setTemporaryData] = useState<any[]>(
    []
  );
  const [searchQuery, setsearchQuery] = useState("");
  const [status, setStatus] = useState("ALL");
  const [columnModal, setColumnModal] = useState(false);
  const [selectedRows, setSelectedRows] = useState<any[]>(
    []
  );
  const [selectedRowsData, setSelectedRowsData] = useState<
    any[]
  >([]);
  const [showImportModal, setShowImportModal] =
    useState(false);
  const [uidMap, setUidMap] = useState<any>();

  const [lastPage, setLastPage] = useState(-1);
  // const [allContacts, setAllContacts] = useState<any[] | undefined>(undefined);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(100);
  const [load, setLoad] = useState(false);
  const [allTasks, setAllTasks] = useState(false);
  const [totalCounts, setTotalCounts] = useState<any>();
  const [userMap, setUserMap] = useState<any>({});
  const [searchedItem, setsearchedItem] = useState("");

  const dispatcher = useDispatch();

  useEffect(() => {
    console.log(searchString);
  }, [searchString]);

  const callApi = async (
    paginate?: boolean,
    localPageSize?: Number,
    localPage?: Number
  ) => {
    if (isFinished === true) {
      return;
    } else {
      setLoad(true);
      const filters: { [key: string]: any[] } = {};
      let feild;
      Object.keys(filterObject).forEach((item) => {
        if (filterObject[item].length > 0) {
          filters[item] = filterObject[item];
        }
      });
      // if (!allTasks) {
      //   filters['transfer_status'] = [false];
      // }
      if (searchString === "") {
        feild = "contact_no";
      } else {
        if (searchString.match(/^[0-9]+$/) != null) {
          feild = "contact_no";
        } else {
          feild = "customer_name";
        }
      }
      let records = localPageSize
        ? localPageSize
        : pageSize;
      const apiData = {
        uid: user.uid,
        page: localPage ? localPage : page + 1,
        searchString: searchString,
        sort:
          Object.keys(filterSort).length === 0
            ? { created_at: "-1" }
            : filterSort,
        pageSize: localPageSize ? localPageSize : pageSize,
        filter: filters,
      };
      try {
        axios.defaults.headers.common["x-access-token"] =
          "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoibGFrc2hheSIsImlhdCI6MTYyOTczOTgzMX0.E0mqgfrDj5su4grI1r8PH973srqJy3k2AG_hvCx-EaA";
        const res = await axios.post(
          url + "/tasks/search",
          apiData
        );
        let data: any[] = res.data;
        if (data.length < records && paginate) {
          isFinished = true;
          setLastPage(page);
        }

        data.forEach((task) => {
          task.contactId = task.leadId;
          task.reporting_to =
            userMap[task.contact_owner_email];
          delete task["Id"];
          Object.keys(task).forEach((key) => {
            if (
              datesField.includes(key) &&
              task[key] !== ""
            ) {
              if (task[key] !== null) {
                task[key] = moment(task[key]);
              } else {
                task[key] = "";
              }
            }
          });
        });
        if (paginate && filterData) {
          let newData = [...filterData, ...data];
          setFilterData(newData);
          setLoad(false);
        } else {
          setFilterData(data);
          setLoad(false);
        }
      } catch {
        setFilterData([]);
        setLoad(false);
        dispatcher(
          showSnackbarAction("Please Try Again!!", "error")
        );
      }
    }
  };

  useEffect(() => {
    if (user === undefined || user.uid === undefined) {
      setFilterData([]);
      return;
    }
    if (userMap === undefined) {
      setFilterData([]);
      return;
    }
    isFinished = false;
    setLastPage(-1);
    if (page !== 0) {
      setPage(0);
    }
    callApi(false, pageSize, 1);
  }, [
    user,
    filterObject,
    filterSort,
    searchString,
    allTasks,
  ]);

  useEffect(() => {
    if (
      filterData &&
      filterData.length <= (page + 1) * pageSize &&
      isFinished === false
    ) {
      if (page === 0) {
        callApi(false, pageSize);
      } else {
        callApi(true, pageSize);
      }
    }
  }, [page, pageSize]);

  const callCountApi = async () => {
    axios.defaults.headers.common["x-access-token"] =
      "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoibGFrc2hheSIsImlhdCI6MTYyOTczOTgzMX0.E0mqgfrDj5su4grI1r8PH973srqJy3k2AG_hvCx-EaA";
    const res = await axios.post(url + "/tasks/taskCount", {
      uid: user.uid,
    });

    setTotalCounts(res.data.total);
  };

  useEffect(() => {
    if (user === undefined || user.uid === undefined) {
      setFilterData([]);
      return;
    }
    callCountApi();
  }, [refreshTasks, user.uid]);

  useEffect(() => {
    if (refreshTasks === true) {
      callApi();
      getTasksFilter();
      dispatcher(setRefreshTasks(false));
    }
  }, [refreshTasks]);

  const getTasksFilter = async () => {
    axios.defaults.headers.common["x-access-token"] =
      "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoibGFrc2hheSIsImlhdCI6MTYyOTczOTgzMX0.E0mqgfrDj5su4grI1r8PH973srqJy3k2AG_hvCx-EaA";
    const res = await axios.post(
      url + "/tasks/filterValues",
      {
        uid: user.uid,
      }
    );
    if (res.data[0]) {
      dispatcher(
        setFilterObject({
          ...res.data[0],
          ...booleanObject,
          status: ["Pending", "Overdue", "Completed"],
        })
      );
    } else {
      dispatcher(setFilterObject({}));
    }
  };

  useEffect(() => {
    if (user === undefined || user.uid === undefined) {
      return;
    }
    getTasksFilter();
  }, [user]);

  const exportFile = () => {
    let data: any[] = [];
    if (selectedRowsData.length === 0) {
    } else {
      selectedRowsData.forEach((item) => {
        data.push({
          "Lead Id": item.leadId,
          "Customer Name": item.customer_name,
          "Task Type": item.type,
          Owner: item.contact_owner_email,
          Stage: item.stage,
          Project: item.project,
          Location: item.location,
          Budget: item.budget,
          "Contact No": item.contact_no,
          "Inventory Type": item.inventory_type,
          Source: item.source,
          "Transfer Status": item.transfer_status,
          "Due Date and Time": getDateString(item.due_date),
          "Completed Date and Time": getDateString(
            item.completed_at
          ),
          "Call Reason": item.call_back_reason,
          Status: item.status,
          "Created By": item.created_by,
          "Created Date and Time": getDateString(
            item.created_at
          ),
        });
      });
    }

    return data;
  };

  return (
    <>
      <div className={commonStyle.topBar}>
        <ApiTopBar
          history={history}
          title={"Add Task"}
          path={"/addTasks"}
          onClick={() => {
            setShowImportModal(true);
          }}
          onChange={(val) => setsearchedItem(val)}
          filterText={"Status"}
          setColumnModal={(data) => setColumnModal(data)}
          show={true}
          showStatusBox={false}
          onExport={exportFile}
          searchedString={searchString}
        />
      </div>
      <div className={commonStyle.parent}>
        {load === true && <Loading logo />}

        <ApiCustomTable
          tableColumns={TASK_COLUMNS}
          tableRows={filterData}
          selectedRows={selectedRows}
          setSelectedRows={(data) => setSelectedRows(data)}
          tableType="Task"
          showColumnModal={columnModal}
          hideColumnModal={() => setColumnModal(false)}
          selectedRowsData={selectedRowsData}
          setSelectedRowsData={(data) =>
            setSelectedRowsData(data)
          }
          setPage={(val) => setPage(val)}
          setPageSize={(val) => setPageSize(val)}
          callApi={callApi}
          pageSize={pageSize}
          page={page}
          isFinished={isFinished}
          totalCount={totalCounts}
          lastPage={lastPage}
        />
      </div>
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    user: state.user.data,
    branchUsers: state.branchUsers.data,
    filterObject: state.filter,
    filterSort: state.filterSort,
    searchString: state.searchItem.tasksSearchString,
    refreshTasks: state.refresh.refreshTasks,
  };
};

export default connect(mapStateToProps)(Task);

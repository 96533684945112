const searchItemReducer = (
  state = {
    contactsSearchString: "",
    tasksSearchString: "",
    callLogsSearchString: "",
  },
  action: {
    type:
      | "SET_CONTACTS_SEARCH_ITEM"
      | "SET_TASKS_SEARCH_ITEM"
      | "SET_CALLS_SEARCH_ITEM";
    payload: any;
  }
) => {
  switch (action.type) {
    case "SET_CONTACTS_SEARCH_ITEM":
      return { ...state, contactsSearchString: action.payload };
    case "SET_TASKS_SEARCH_ITEM":
      return { ...state, tasksSearchString: action.payload };
    case "SET_CALLS_SEARCH_ITEM":
      return { ...state, callLogsSearchString: action.payload };
    default:
      return state;
  }
};

export default searchItemReducer;

import React, {
  FunctionComponent,
  useEffect,
  useState,
  useRef,
} from "react";
import styles from "../BulkEditModal/BulkEditModal.module.css";
import Modal from "react-modal";
import { IoIosClose } from "react-icons/io";

import Dropdown from "../../DropDown/Dropdown";
import {
  addNoteFirebase,
  createTaskFirebase,
  fetchConstants,
} from "../../../Services/contacts";

import Loading from "../../Loading/Loading";
import { connect, useDispatch } from "react-redux";
import { showSnackbarAction } from "../../../Redux/actions";
import moment from "moment";
import Firebase from "firebase/app";
import { useHistory, useLocation } from "react-router-dom";
import TextInput from "../../TextInput/TextInput";

type props = {
  contactDetail: any;
  close: () => void;
  open: boolean;
  tasksData: any[];
  user: any;
  notes: any[];
};

const CallBackModal: FunctionComponent<props> = ({
  contactDetail,
  close,
  open,
  tasksData,
  user,
  notes,
}) => {
  const dateRef: any = useRef();
  const noteRef: any = useRef();
  const [load, setLoad] = useState(false);
  const [callBackSelected, setCallBackSelected] =
    useState<any>({
      label: contactDetail.call_back_reason
        ? contactDetail.call_back_reason
        : "Select",
      value: contactDetail.call_back_reason
        ? contactDetail.call_back_reason
        : "Select",
    });

  const [propertyTypeList, setPropertyTypeList] = useState<
    any[]
  >([]);
  const [propertyStageList, setPropertyStageList] =
    useState<any[]>([]);
  const [nextFollowUpList, setnextFollowUpList] = useState<
    any[]
  >([]);
  const [notIntReasonList, setNotIntReasonList] = useState<
    any[]
  >([]);
  const [lostReasonList, setLostReasonList] = useState<
    any[]
  >([]);
  const [callbackList, setCallBackList] = useState<any[]>(
    []
  );

  useEffect(() => {
    const unsubConst = fetchConstants(
      (data) => setNotIntReasonList(data),
      (data) => setLostReasonList(data),
      (data) => setPropertyStageList(data),
      (data) => setPropertyTypeList(data),
      (data) => setnextFollowUpList(data),
      (data) => setCallBackList(data)
    );

    return () => {
      unsubConst();
    };
    // eslint-disable-next-line
  }, []);
  const dispatcher = useDispatch();
  const history = useHistory();
  const location: any = useLocation();
  const onSubmit = () => {
    if (callBackSelected.value === "Select") {
      dispatcher(
        showSnackbarAction("Please Select Reason", "error")
      );
    } else if (dateRef.current.value < new Date()) {
      dispatcher(
        showSnackbarAction(
          "Task Cannot be Schedule For Old Date & Time!",
          "error"
        )
      );
    } else {
      const data = {
        call_back_reason: callBackSelected.value,
        due_date: moment(dateRef.current.value).toDate(),
        type: "Call Back",
        status: "Pending",
        customer_name: contactDetail.customer_name,
      };

      const changeLeadData =
        contactDetail.stage === "CALLBACK"
          ? {}
          : {
              stage_change_at:
                Firebase.firestore.Timestamp.now(),
            };

      if (noteRef.current.value.length !== 0) {
        addNoteFirebase(
          contactDetail.contactId,
          notes,
          noteRef.current.value,
          () => {},
          dispatcher,
          () => {},
          user.user_email
        );
        // console.log("useremail=", user.user_email);
      }
      createTaskFirebase(
        contactDetail.contactId,
        tasksData,
        data,
        {
          stage: "CALLBACK",
          call_back_reason: callBackSelected.value,
          next_follow_up_type: "Call Back",
          next_follow_up_date_time: moment(
            dateRef.current.value
          ).toDate(),
          modified_at: Firebase.firestore.Timestamp.now(),
          ...changeLeadData,
        },
        (value: boolean) => setLoad(value),
        dispatcher,
        user,
        close,
        history
      );
      location.state.detail.stage = "Call Back";
      location.state.detail.next_follow_up_date_time =
        moment(dateRef.current.value).toDate();
      location.state.detail.modified_at =
        Firebase.firestore.Timestamp.now().toDate();
      location.state.detail.call_back_reason =
        callBackSelected.value;
    }
  };
  return (
    <Modal
      className={styles.parent}
      isOpen={open}
      shouldCloseOnOverlayClick={true}
      overlayClassName={styles.overlay}
      shouldCloseOnEsc={true}
      onRequestClose={close}
    >
      {load === true && <Loading />}
      <div className={styles.firstDiv}>
        <p className={styles.contactForm}>
          Call Back Details
        </p>
        <div className={styles.cross} onClick={close}>
          <IoIosClose size={35} color={"#808080"} />
        </div>
      </div>
      <div className={styles.line}></div>

      <div className={styles.box2}>
        <div
          className={styles.divide}
          style={{ width: "100%" }}
        >
          <div className={styles.title}>
            <p className={styles.one}>Call Back Reason</p>
            <p className={styles.two}></p>
          </div>
          <div style={{ marginTop: "7px" }}>
            <Dropdown
              option={callbackList}
              selectedValue={callBackSelected}
              setSelectedValue={(value) => {
                setCallBackSelected(value);
              }}
            />
          </div>
        </div>
      </div>

      <div className={styles.box2}>
        <div
          className={styles.divide}
          style={{ width: "100%" }}
        >
          <div className={styles.title}>
            <p className={styles.one}>
              Next Follow Up Date & Time
            </p>
            <p className={styles.two}></p>
          </div>
          <div>
            <input
              type="datetime-local"
              placeholder="Enter Date"
              ref={dateRef}
            />
          </div>
        </div>
      </div>

      <div className={styles.box2}>
        <div
          className={styles.divide}
          style={{ width: "100%" }}
        >
          <div className={styles.title}>
            <p className={styles.one}>Note</p>
            <p className={styles.two}></p>
          </div>
          <div>
            <TextInput
              title={"Enter Note"}
              style={{ backgroundColor: "#fff" }}
              ref={noteRef}
            ></TextInput>
          </div>
        </div>
      </div>

      <button
        className={styles.apply}
        style={{ marginTop: "auto" }}
        onClick={onSubmit}
      >
        Save
      </button>
    </Modal>
  );
};

const mapStateToProps = (state: any) => {
  return {
    user: state.user.data,
  };
};

export default connect(mapStateToProps)(CallBackModal);

import React, { useEffect, useRef, useState } from "react";
import styles from "./EditContact.module.css";
import TextInput from "../../Components/TextInput/TextInput";

import { FaUser } from "react-icons/fa";
import { phoneValidate } from "../../Values/validatorsnew";
import { connect, useDispatch } from "react-redux";
import {
  contactResources,
  editContact,
  fetchConstants,
  fetchpropertySubType,
} from "../../Services/contacts";
import Loading from "../../Components/Loading/Loading";
import { AiOutlineClose } from "react-icons/ai";
import Select from "react-select";
import Dropdown from "../../Components/DropDown/Dropdown";
import { useLocation } from "react-router";
import { showSnackbarAction } from "../../Redux/actions";
import { country_code } from "../../Values/constants";

const EditContact = ({
  history,
  userRole,
}: {
  history: any;
  userRole: any;
}) => {
  const location: any = useLocation();
  const dispatcher = useDispatch();
  const firstNameRef: any = useRef();
  const lastNameRef: any = useRef();
  const numberRef: any = useRef();
  const alternateNumberRef: any = useRef();

  const otherNotIntRef: any = useRef();

  const otherLostRef: any = useRef();
  const leadSourceRef: any = useRef();
  const [load, setLoad] = useState(false);
  const [locationsList, setLocationsList] = useState<any[]>([]);
  const [budgetsList, setBudgetsList] = useState<any[]>([]);
  const [projectsList, setProjectsList] = useState<any[]>([]);
  const [notIntReasonList, setNotIntReasonList] = useState<any[]>([]);
  const [lostReasonList, setLostReasonList] = useState<any[]>([]);
  const [propertyTypeList, setPropertyTypeList] = useState<any[]>([]);
  const [propertyStageList, setPropertyStageList] = useState<any[]>([]);
  const [stageSelected, setStageSelected] = useState<any>({
    label: "Select",
    value: "Select",
  });
  const [propertyTypeSelected, setPropertyTypeSelected] = useState<any>({
    label: "Select",
    value: "Select",
  });
  const [propertyStageSelected, setPropertyStageSelected] = useState<any>({
    label: "Select",
    value: "Select",
  });
  const [locationSelected, setlocationSelected] = useState<any>({
    label: "Select",
    value: "Select",
  });
  const [budgetSelected, setBudgetSelected] = useState<any>({
    label: "Select",
    value: "Select",
  });
  const [projectSelected, setProjectSelected] = useState<any>({
    label: "Select",
    value: "Select",
  });
  const [notIntSelected, setNotIntSelected] = useState<any>({
    label: "Select",
    value: "Select",
  });
  const [lostSelected, setLostSelected] = useState<any>({
    label: "Select",
    value: "Select",
  });
  const [leadSourceSelected, setLeadSourceSelected] = useState<any>({
    label: location.state.detail.lead_source
      ? location.state.detail.lead_source
      : "Select",
    value: location.state.detail.lead_source
      ? location.state.detail.lead_source
      : "Select",
  });
  const [propertySubTypeSelected, setPropertySubTypeSelected] = useState<any>({
    label: location.state.detail.property_sub_type
      ? location.state.detail.property_sub_type
      : "Select",
    value: location.state.detail.property_sub_type
      ? location.state.detail.property_sub_type
      : "Select",
  });

  const [leadSourceList, setLeadSourceList] = useState<any[]>([]);
  const [comTypes, setComTypes] = useState<any[]>([]);
  const [resTypes, setResTypes] = useState<any[]>([]);
  const [propertySubType, setPropertySubType] = useState<any[]>([]);
  const [countryCode, setCountryCode] = useState<string>("");
  const [countryCodeList, setCountryCodeList] = useState<string[]>([]);

  const stage = [
    "FRESH",
    "WON",
    "CALL BACK",
    "INTERESTED",
    "LOST",
    "NOT-INTERESTED",
  ];

  useEffect(() => {
    let data: any[] = [];
    country_code.forEach((item) => {
      data.push(item.dial_code + " " + item.name);
    });
    setCountryCodeList(data);
    if (location.state.detail.country_code) {
      setCountryCode(location.state.detail.country_code);
    }
  }, []);

  const choice = () => {
    let data = countryCodeList.map((item: any) => ({
      value: item,
      label: item,
    }));

    return data;
  };

  useEffect(() => {
    const unsub = contactResources(
      (data) => setLocationsList(data),
      (data) => setBudgetsList(data),
      (data) => setProjectsList(data),
      location.state.detail.organization_id,
      (data) => setLeadSourceList(data)
    );
    const unsubConst = fetchConstants(
      (data) => setNotIntReasonList(data),
      (data) => setLostReasonList(data),
      (data) => setPropertyStageList(data),
      (data) => setPropertyTypeList(data)
    );

    const unsubProperty = fetchpropertySubType(
      location.state.detail.organization_id,
      (data) => setComTypes(data),
      (data) => setResTypes(data),
      (data) => setLeadSourceList(data)
    );

    return () => {
      unsub();
      unsubConst();
      unsubProperty();
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (propertyTypeSelected.value === "Commercial") {
      setPropertySubType(comTypes);
    } else if (propertyTypeSelected.value === "Residential") {
      setPropertySubType(resTypes);
    } else {
      setPropertySubType([]);
    }
  }, [propertyTypeSelected]);

  useEffect(() => {
    setlocationSelected({
      label: location.state.detail.location,
      value: location.state.detail.location,
    });
    setBudgetSelected({
      label: location.state.detail.budget,
      value: location.state.detail.budget,
    });
    setProjectSelected({
      label: location.state.detail.project,
      value: location.state.detail.project,
    });
    setNotIntSelected({
      label: location.state.detail.not_int_reason,
      value: location.state.detail.not_int_reason,
    });
    setLostSelected({
      label: location.state.detail.lost_reason,
      value: location.state.detail.lost_reason,
    });
    setPropertyStageSelected({
      label: location.state.detail.property_stage,
      value: location.state.detail.property_stage,
    });
    setPropertyTypeSelected({
      label: location.state.detail.property_type,
      value: location.state.detail.property_type,
    });
    // eslint-disable-next-line
  }, [
    locationsList,
    budgetsList,
    notIntReasonList,
    projectsList,
    lostReasonList,
    propertyTypeList,
    propertyStageList,
  ]);

  const handleCheck = () => {
    if (firstNameRef.current.value === "") {
      firstNameRef.current.value =
        location.state.detail.customer_name.split(" ")[0];
    }
    if (lastNameRef.current.value === "") {
      lastNameRef.current.value =
        location.state.detail.customer_name.split(" ")[1];
    }
    if (numberRef.current.value === "") {
      numberRef.current.value = location.state.detail.contact_no;
    }
    if (alternateNumberRef.current.value === "") {
      alternateNumberRef.current.value = location.state.detail.alternate_no;
    }
    if (stageSelected.label === "Select") {
      stageSelected.label = location.state.detail.stage;
    }
    if (propertyTypeSelected.label === "Select") {
      propertyTypeSelected.label = location.state.detail.property_type;
    }
    if (propertyStageSelected.label === "Select") {
      propertyStageSelected.label = location.state.detail.property_stage;
    }

    if (locationSelected.label === "Select") {
      locationSelected.label = location.state.detail.location;
    }
    if (budgetSelected.label === "Select") {
      budgetSelected.label = location.state.detail.budget;
    }
    if (projectSelected.label === "Select") {
      projectSelected.label = location.state.detail.project;
    }
    if (notIntSelected.label === "Select" || notIntSelected.label === "") {
      notIntSelected.label = location.state.detail.not_int_reason;
    }
    if (otherNotIntRef.current.value === "") {
      otherNotIntRef.current.value = location.state.detail.other_not_int_reason;
    }
    if (lostSelected.label === "Select") {
      lostSelected.label = location.state.detail.lost_reason;
    }
    if (otherLostRef.current.value === "") {
      otherLostRef.current.value = location.state.detail.other_lost_reason;
    }
    return true;
  };
  const edit = async () => {
    const val = handleCheck();
    if (val) {
      setLoad(true);

      editContact(
        location.state.detail.contactId,
        firstNameRef.current.value,
        lastNameRef.current.value,
        numberRef.current.value,
        alternateNumberRef.current.value,
        stageSelected.label,
        projectSelected.label,
        budgetSelected.label,
        locationSelected.label,
        propertyTypeSelected.label,
        propertyStageSelected.label,
        leadSourceSelected.value,
        notIntSelected.label,
        otherNotIntRef.current.value,
        lostSelected.label,
        otherLostRef.current.value,
        dispatcher,
        history,
        (data) => setLoad(data),
        numberRef.current.value === location.state.detail.contact_no
          ? false
          : true,
        location.state.detail.organization_id,
        propertySubTypeSelected.value === "Select"
          ? ""
          : propertySubTypeSelected.value,
        countryCode.split(" ")[0]
      );
    }
  };
  return (
    <div className={styles.parent}>
      {load && <Loading />}
      <div className={styles.child}>
        <div className={styles.headerView}>
          <p className={styles.heading}> Edit Contact Details</p>
          <AiOutlineClose
            className={styles.closeIcon}
            size={25}
            onClick={() => history.replace("/")}
          />
        </div>
        <div className={styles.box2}>
          <div className={styles.divide}>
            <div className={styles.title}>
              <p className={styles.one}>First Name</p>
              <p className={styles.two}>*</p>
            </div>
            <div>
              <TextInput
                title={"Enter first name"}
                style={{ backgroundColor: "#fff" }}
                ref={firstNameRef}
                value={location.state.detail.customer_name.split(" ")[0]}
              >
                <FaUser color={"#808080"} />
              </TextInput>
            </div>
          </div>

          <div className={styles.divide}>
            <div className={styles.title}>
              <p className={styles.one}>Last Name</p>
              <p className={styles.two}>*</p>
            </div>
            <div>
              <TextInput
                title={"Enter last name"}
                style={{ backgroundColor: "#fff" }}
                ref={lastNameRef}
                value={location.state.detail.customer_name.slice(
                  location.state.detail.customer_name.split(" ")[0].length
                )}
              >
                <FaUser color={"#808080"} />
              </TextInput>
            </div>
          </div>
        </div>

        <div className={styles.box2}>
          <div className={styles.divide}>
            <div className={styles.title}>
              <p className={styles.one}>Mobile No.</p>
              <p className={styles.two}>*</p>
            </div>
            <div>
              <TextInput
                title={"Enter Number"}
                style={
                  userRole !== "Lead Manager"
                    ? { backgroundColor: "#f5f5f5" }
                    : { backgroundColor: "#fff" }
                }
                ref={numberRef}
                disabled={userRole !== "Lead Manager" ? true : false}
                value={location.state.detail.contact_no}
              />
            </div>
          </div>
          <div className={styles.divide}>
            <div className={styles.title}>
              <p className={styles.one}>Alternate No.</p>
              <p className={styles.two}>*</p>
            </div>
            <div>
              <TextInput
                title={"Enter Number"}
                style={{ backgroundColor: "#fff" }}
                ref={alternateNumberRef}
                value={location.state.detail.alternate_no}
              />
            </div>
          </div>
        </div>

        <div className={styles.box2}>
          <div className={styles.divide}>
            <div className={styles.title}>
              <p className={styles.one}>Stage</p>
              <p className={styles.two}>*</p>
            </div>
            <div style={{ marginTop: "7px" }}>
              <Dropdown
                option={stage}
                disable={true}
                selectedValue={stageSelected}
                setSelectedValue={(value) => {
                  setStageSelected(value);
                }}
              />
            </div>
          </div>
          <div className={styles.divide}>
            <div className={styles.title}>
              <p className={styles.one}>Project</p>
              <p className={styles.two}>*</p>
            </div>
            <div style={{ marginTop: "7px" }}>
              <Dropdown
                option={projectsList}
                selectedValue={projectSelected}
                setSelectedValue={(value) => {
                  setProjectSelected(value);
                }}
              />
            </div>
          </div>
        </div>

        <div className={styles.box2}>
          <div className={styles.divide}>
            <div className={styles.title}>
              <p className={styles.one}>Location</p>
              <p className={styles.two}>*</p>
            </div>
            <div style={{ marginTop: "7px" }}>
              <Dropdown
                option={locationsList}
                selectedValue={locationSelected}
                setSelectedValue={(value) => {
                  setlocationSelected(value);
                }}
              />
            </div>
          </div>

          <div className={styles.divide}>
            <div className={styles.title}>
              <p className={styles.one}>Budget</p>
              <p className={styles.two}>*</p>
            </div>
            <div style={{ marginTop: "7px" }}>
              <Dropdown
                option={budgetsList}
                selectedValue={budgetSelected}
                setSelectedValue={(value) => {
                  setBudgetSelected(value);
                }}
              />
            </div>
          </div>
        </div>

        <div className={styles.box2}>
          <div className={styles.divide}>
            <div className={styles.title}>
              <p className={styles.one}>Property Type</p>
              <p className={styles.two}>*</p>
            </div>
            <div style={{ marginTop: "7px" }}>
              <Dropdown
                option={propertyTypeList}
                selectedValue={propertyTypeSelected}
                setSelectedValue={(value) => {
                  setPropertyTypeSelected(value);
                }}
              />
            </div>
          </div>
          <div className={styles.divide}>
            <div className={styles.title}>
              <p className={styles.one}>Property Stage</p>
              <p className={styles.two}>*</p>
            </div>
            <div style={{ marginTop: "7px" }}>
              <Dropdown
                option={propertyStageList}
                selectedValue={propertyStageSelected}
                setSelectedValue={(value) => {
                  setPropertyStageSelected(value);
                }}
              />
            </div>
          </div>
        </div>

        <div className={styles.box2}>
          <div className={styles.divide}>
            <div className={styles.title}>
              <p className={styles.one}>Not Interested Reason</p>
              <p className={styles.two}>*</p>
            </div>
            <div style={{ marginTop: "7px" }}>
              <Dropdown
                option={notIntReasonList}
                selectedValue={notIntSelected}
                setSelectedValue={(value) => {
                  setNotIntSelected(value);
                }}
                disable={userRole !== "Lead Manager" ? true : false}
              />
            </div>
          </div>

          <div className={styles.divide}>
            <div className={styles.title}>
              <p className={styles.one}>Other Not Interested Reason</p>
              <p className={styles.two}>*</p>
            </div>
            <div>
              <TextInput
                title={"Enter Other Reason"}
                style={
                  userRole !== "Lead Manager"
                    ? { backgroundColor: "#f5f5f5" }
                    : { backgroundColor: "#fff" }
                }
                ref={otherNotIntRef}
                disabled={userRole !== "Lead Manager" ? true : false}
                value={location.state.detail.other_not_int_reason}
              ></TextInput>
            </div>
          </div>
        </div>

        <div className={styles.box2}>
          <div className={styles.divide}>
            <div className={styles.title}>
              <p className={styles.one}>Lost Reason</p>
              <p className={styles.two}>*</p>
            </div>
            <div style={{ marginTop: "7px" }}>
              <Dropdown
                option={lostReasonList}
                selectedValue={lostSelected}
                setSelectedValue={(value) => {
                  setLostSelected(value);
                }}
                disable={userRole !== "Lead Manager" ? true : false}
              />
            </div>
          </div>

          <div className={styles.divide}>
            <div className={styles.title}>
              <p className={styles.one}>Other Lost Reason</p>
              <p className={styles.two}>*</p>
            </div>
            <div>
              <TextInput
                title={"Enter Other Reason"}
                style={
                  userRole !== "Lead Manager"
                    ? { backgroundColor: "#f5f5f5" }
                    : { backgroundColor: "#fff" }
                }
                ref={otherLostRef}
                disabled={userRole !== "Lead Manager" ? true : false}
                value={location.state.detail.other_lost_reason}
              ></TextInput>
            </div>
          </div>
        </div>

        <div className={styles.box2}>
          {userRole === "Lead Manager" && (
            <div className={styles.divide}>
              <div className={styles.title}>
                <p className={styles.one}>Lead Source</p>
                <p className={styles.two}></p>
              </div>
              <div style={{ marginTop: "7px" }}>
                <Dropdown
                  option={leadSourceList}
                  selectedValue={leadSourceSelected}
                  setSelectedValue={(value) => {
                    setLeadSourceSelected(value);
                  }}
                />
              </div>
            </div>
          )}

          <div className={styles.divide}>
            <div className={styles.title}>
              <p className={styles.one}>Property Sub Type</p>
              <p className={styles.two}></p>
            </div>
            <div style={{ marginTop: "7px" }}>
              <Dropdown
                option={propertySubType}
                selectedValue={propertySubTypeSelected}
                setSelectedValue={(value) => {
                  setPropertySubTypeSelected(value);
                }}
              />
            </div>
          </div>
        </div>

        <div className={styles.box2}>
          <div className={styles.divide}>
            <div className={styles.title} style={{ marginBottom: "3%" }}>
              <p className={styles.one}>Country Code</p>
              <p className={styles.two}>*</p>
            </div>
            <div>
              <Select
                options={choice()}
                onChange={(data) => {
                  if (data) {
                    setCountryCode(data.label);
                  }
                }}
                isSearchable={true}
                value={{ label: countryCode, value: countryCode }}
              />
            </div>
          </div>
        </div>

        <button className={styles.button} onClick={() => edit()}>
          Update
        </button>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    userRole: state.user.role,
  };
};

export default connect(mapStateToProps)(EditContact);

import React, { useEffect } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import HomeRoute from "./HomeRoute";
import { connect, useDispatch } from "react-redux";
import AuthRoute from "./AuthRoute";
import { checkUser } from "../Services/auth";
import PasswordReset from "../Screens/PasswordReset/PasswordReset";

const AppRoutes = ({
  userStatus,
}: {
  userStatus: boolean;
  userRole: string;
}) => {
  const dispatcher = useDispatch();
  useEffect(() => {
    checkUser(dispatcher);
    // eslint-disable-next-line
  }, []);

  return (
    <Router>
      {userStatus !== undefined && (
        <>
          {userStatus === false && <Route component={AuthRoute} />}
          {userStatus === true && <Route component={HomeRoute} />}
        </>
      )}
      <Route path="/resetPassword" component={PasswordReset} />
    </Router>
  );
};

const mapStateToProps = (state: any) => {
  return {
    userStatus: state.user.status,
    userRole: state.user.role,
  };
};

export default connect(mapStateToProps)(AppRoutes);

import React, { useState, useEffect } from "react";
import Topbar from "../../Components/TopBar/TopBar";
import commonStyle from "../common.module.css";
import { connect, useDispatch } from "react-redux";
import { USER_COLUMNS } from "../../Values/tables";
import Loading from "../../Components/Loading/Loading";
import {
  filterStatus,
  getDateString,
  getFilterBranch,
  searchUserItem,
} from "../../Values/utils";
import ImportUsersModal from "../../Components/Modals/ImportModal/ImportUsersModal";
import CustomTable from "../../Components/CustomTable/CustomTable";
import ListViewModal from "../../Components/Modals/ListViewModal/ListViewModal";
import BranchModal from "../../Components/Modals/BranchModal/BranchModal";
import ChangeStageModal from "../../Components/Modals/ChangeStageModal/ChangeStageModal";
import { setTeamLeadUsersList } from "../../Redux/actions";
import { setBranchLists } from "../../Redux/actions";
import DeleteRecordsModal from "../../Components/Modals/DeleteRecordsModal/DeleteRecordsModal";
const Users = ({
  history,
  organizationId,
  organizationUsers,
  user,
}: any) => {
  const [selectedRows, setSelectedRows] = useState<any[]>(
    []
  );
  const [filterData, setFilterData] = useState<
    any[] | undefined
  >(undefined);
  const [temporaryData, setTemporaryData] = useState<any[]>(
    []
  );
  const [searchQuery, setsearchQuery] = useState("");
  const [status, setStatus] = useState("ALL");
  const [showImportModal, setShowImportModal] =
    useState(false);
  const [columnModal, setColumnModal] = useState(false);
  const [listViewModal, setListViewModal] = useState(false);
  const [selectedRowsData, setSelectedRowsData] = useState<
    any[]
  >([]);
  const [branchModal, setBranchModal] = useState(false);
  const [branchList, setBranchList] = useState<any[]>([]);
  const [uid, setUid] = useState("");
  const [load, setLoad] = useState(false);
  const [changeStageModal, setChangeStageModal] =
    useState(false);
  const [deleteRecordsModal, setDeleteRecordsModal] =
    useState(false);

  const dispatcher = useDispatch();

  useEffect(() => {
    if (organizationUsers === undefined) {
    } else {
      setFilterData(organizationUsers);
      const filterData = getFilterBranch(organizationUsers);
      if (filterData.branch) {
        setBranchList(filterData.branch);

        dispatcher(setBranchLists(filterData.branch));
      } else {
        setBranchList([]);
      }
    }
  }, [organizationUsers, dispatcher]);

  useEffect(() => {
    if (status === "ALL") {
      if (organizationUsers) {
        const data = searchUserItem(
          organizationUsers,
          searchQuery
        );
        setTemporaryData(data);
        setFilterData(data);
      }
    } else {
      const data = searchUserItem(
        temporaryData,
        searchQuery
      );
      setFilterData(data);
    }
    // eslint-disable-next-line
  }, [searchQuery, organizationUsers]);

  useEffect(() => {
    if (searchQuery === "") {
      if (organizationUsers) {
        const data = filterStatus(
          organizationUsers,
          status
        );
        setTemporaryData(data);
        setFilterData(data);
      }
    } else {
      const data = filterStatus(temporaryData, status);
      setFilterData(data);
    }
    // eslint-disable-next-line
  }, [status, organizationUsers]);

  const exportFile = () => {
    let data: any[] = [];
    if (selectedRowsData.length === 0) {
    } else {
      selectedRowsData.forEach((item) => {
        data.push({
          Name:
            item.user_first_name +
            " " +
            item.user_last_name,
          "Mobile No": item.contact_no,
          "Email ID": item.user_email,
          Team: item.team,
          "Reporting To": item.reporting_to,
          Designation: item.designation,
          Status: item.status,
          "Created By": item.created_by,
          "Created At": getDateString(item.created_at),
          Profile: item.profile,
        });
      });
    }
    return data;
  };

  return (
    <>
      <div className={commonStyle.topBar}>
        <Topbar
          history={history}
          title={"Add User"}
          path={"/addUsers"}
          onClick={() => {
            setShowImportModal(true);
          }}
          onChange={(val) => setsearchQuery(val)}
          setStatus={(status) => setStatus(status)}
          filterText="Status"
          status={status}
          setColumnModal={(data) => setColumnModal(data)}
          show={true}
          showStatusBox={true}
          onExport={exportFile}
          setListViewModal={(data) =>
            setListViewModal(data)
          }
          setLoad={(data) => setLoad(data)}
          setChangeStageModal={(data) =>
            setChangeStageModal(data)
          }
          setDeleteRecordsModal={(data) =>
            setDeleteRecordsModal(data)
          }
        />
      </div>
      <div className={commonStyle.parent}>
        {(organizationUsers === undefined ||
          load === true) && <Loading logo />}
        <CustomTable
          tableColumns={USER_COLUMNS}
          tableRows={filterData}
          selectedRows={selectedRows}
          setSelectedRows={(data) => setSelectedRows(data)}
          tableType="Organization"
          showColumnModal={columnModal}
          hideColumnModal={() => setColumnModal(false)}
          selectedRowsData={selectedRowsData}
          setSelectedRowsData={(data) =>
            setSelectedRowsData(data)
          }
          setSearchedItem={(val) => setsearchQuery(val)}
          setBranch={(val) => setBranchModal(val)}
          setUid={(data) => setUid(data)}
        />
        {showImportModal === true && (
          <ImportUsersModal
            open={showImportModal}
            close={() => setShowImportModal(false)}
            organization_id={organizationId}
            history={history}
            usersList={
              organizationUsers ? organizationUsers : []
            }
            user={user}
          />
        )}
        {listViewModal === true && (
          <ListViewModal
            open={listViewModal}
            close={() => setListViewModal(false)}
          />
        )}
        {branchModal === true && (
          <BranchModal
            open={branchModal}
            close={() => setBranchModal(false)}
            branchList={branchList}
            uid={uid}
          />
        )}
        {changeStageModal === true && (
          <ChangeStageModal
            open={changeStageModal}
            close={() => setChangeStageModal(false)}
          />
        )}
        {deleteRecordsModal && (
          <DeleteRecordsModal
            open={deleteRecordsModal}
            close={() => setDeleteRecordsModal(false)}
            filterData={filterData}
          />
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    user: state.user.data,
    organizationId: state.organization.id,
    organizationUsers: state.organizationUsers.data,
    filterObject: state.filterObject,
    organization: state.organization,
    role: state.user.role,
  };
};

export default connect(mapStateToProps)(Users);

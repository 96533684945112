import React, { FunctionComponent } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Pie } from "react-chartjs-2";
import { connect, useDispatch } from "react-redux";
import { setClearFilter } from "../../Redux/actions";
import {
  drillDownTableOrg,
  getDrillDownChartOrg,
  getInterestedLeadsDrillDown,
  getTaskDrillDownChartSales,
  getTaskDrillDownTableSales,
} from "../../Services/analytics";
import { changeDateForRoute } from "../../Services/contacts";
import { properFormat } from "../../Values/utils";
import styles from "./AnalyticsGraph.module.css";

type props = {
  GraphType: any;
  data: any;
  Heading: string;
  stage?: any;
  title: string;
  value: string;
  role: string;
  history: any;
  contacts: any;
  filter: "MTD" | "YTD" | "PM" | "All" | "CUSTOM" | "T" | "Y";
  type:
    | "project"
    | "location"
    | "budget"
    | "stage"
    | "property_type"
    | "property_stage"
    | "other"
    | "Overdue"
    | "Pending"
    | "Completed";
  user: any;
  organizationUsers: any[];
  teamLeadUsers: any[];
};
const AnalyticsGraph: FunctionComponent<props> = ({
  GraphType,
  data,
  Heading,
  stage,
  title,
  value,
  role,
  history,
  contacts,
  filter,
  type,
  user,
  organizationUsers,
  teamLeadUsers,
}) => {
  const [tableData, setTableData] = useState<any[]>([]);
  const [allContacts, setAllContacts] = useState<any[] | undefined>(undefined);

  useEffect(() => {
    let contact: any[] = [];
    Object.keys(contacts.data).forEach((key) => {
      contact = [...contact, ...contacts.data[key]];
    });
    setAllContacts(contact);
  }, [contacts.data]);
  useEffect(() => {
    if (
      (Heading === "FeedBack" ||
        Heading === "Interested Budget Summary" ||
        Heading === "Interested Location Summary" ||
        Heading === "Interested Project Summary" ||
        Heading === "Over Due Tasks" ||
        Heading === "Pending Tasks") &&
      stage
    ) {
      const tempList: any[] = [];

      Object.keys(stage).forEach((key) => {
        tempList.push({
          val: key === "" ? "Undefined" : key,
          count: stage[key],
        });
      });
      if (Heading !== "Over Due Tasks" && Heading !== "Pending Tasks") {
        let gt = 0;
        tempList.forEach((item) => {
          gt += item.count;
        });
        tempList.unshift({ val: "Grand Total", count: gt });
      }

      setTableData(tempList);
    }
    if (Heading === "Completed Tasks") {
      const tempList: any[] = [];

      Object.keys(stage).forEach((key) => {
        tempList.push({
          val: key === "" ? "Undefined" : key,
          count: stage[key]["Completed"],
        });
      });
      setTableData(tempList);
    }
  }, [stage]);
  const dispatcher = useDispatch();
  const barOptions = {
    title: {
      display: false,
      fontSize: 20,
    },
    responsive: true,
    maintainAspectRatio: false,
    onClick: async (e: any, item: any) => {
      var key: any;
      for (var i = 0; i < localStorage.length; i++) {
        key = localStorage.key(i);

        if (key !== "columns") {
          localStorage.removeItem(key);
        }
      }
      dispatcher(setClearFilter(true));
      let mapReportingTo: { [key: string]: string } = {};
      if (organizationUsers) {
        organizationUsers.forEach((item: any) => {
          if (mapReportingTo[item.uid] === undefined) {
            mapReportingTo[item.uid] = item.reporting_to;
          }
        });
      }
      if (
        type !== "other" &&
        type !== "Overdue" &&
        type !== "Pending" &&
        type !== "Completed"
      ) {
        if (allContacts) {
          let drillDownData = await getDrillDownChartOrg(
            allContacts,
            filter,
            type,
            data.labels[item[0].index],
            user.organization_id
          );

          drillDownData.forEach((item: any) => {
            if (item.uid === "") {
              item["reporting_to"] = "";
            } else {
              item["reporting_to"] = mapReportingTo[item.uid]
                ? mapReportingTo[item.uid]
                : "";
            }
          });

          localStorage.setItem(
            "drilldownData",
            changeDateForRoute(drillDownData)
          );
          window.open("/drilldownData", "_blank");
        }
      }

      if (
        type !== "other" &&
        type !== "budget" &&
        type !== "location" &&
        type !== "project" &&
        type !== "stage" &&
        type !== "property_type" &&
        type !== "property_stage"
      ) {
        if (allContacts) {
          let drillDownData = getTaskDrillDownChartSales(
            allContacts,
            filter,
            type,
            data.labels[item[0]?.index]
          );

          drillDownData.forEach((item: any) => {
            if (item.uid === "") {
              item["reporting_to"] = "";
            } else {
              item["reporting_to"] = mapReportingTo[item.uid]
                ? mapReportingTo[item.uid]
                : "";
            }
          });

          localStorage.setItem(
            "drilldownData",
            changeDateForRoute(drillDownData)
          );
          window.open("/drilldownData", "_blank");
        }
      }

      if (
        type === "other" &&
        (Heading === "Interested Lead Trends Summary" ||
          Heading === "Interested Lead Trends")
      ) {
        if (allContacts) {
          if (user.profile === "Team Lead") {
            let drillDownData = getInterestedLeadsDrillDown(
              allContacts.filter((item) => teamLeadUsers.includes(item.uid)),
              filter,
              data.labels[item[0]?.index]
            );

            drillDownData.forEach((item: any) => {
              if (item.uid === "") {
                item["reporting_to"] = "";
              } else {
                item["reporting_to"] = mapReportingTo[item.uid]
                  ? mapReportingTo[item.uid]
                  : "";
              }
            });
            localStorage.setItem(
              "drilldownData",
              changeDateForRoute(drillDownData)
            );
            window.open("/drilldownData", "_blank");
          } else {
            let drillDownData = getInterestedLeadsDrillDown(
              allContacts,
              filter,
              data.labels[item[0]?.index]
            );

            drillDownData.forEach((item: any) => {
              if (item.uid === "") {
                item["reporting_to"] = "";
              } else {
                item["reporting_to"] = mapReportingTo[item.uid]
                  ? mapReportingTo[item.uid]
                  : "";
              }
            });
            localStorage.setItem(
              "drilldownData",
              changeDateForRoute(drillDownData)
            );
            window.open("/drilldownData", "_blank");
          }
        }
      }
    },
  };

  const pieOptions = {
    title: {
      display: false,
      fontSize: 20,
    },
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      datalabels: {
        formatter: (value: any, ctx: any) => {
          let sum = 0;
          let dataArr = ctx.chart.data.datasets[0].data;
          dataArr.map((data: any) => {
            sum += data;
          });
          let percentage = Math.floor((value * 100) / sum) + "%";
          return percentage;
        },
        color: "#fff",
      },
    },
    onClick: async (e: any, item: any) => {
      var key: any;
      for (var i = 0; i < localStorage.length; i++) {
        key = localStorage.key(i);

        if (key !== "columns") {
          localStorage.removeItem(key);
        }
      }
      dispatcher(setClearFilter(true));
      let mapReportingTo: { [key: string]: string } = {};
      if (organizationUsers) {
        organizationUsers.forEach((item: any) => {
          if (mapReportingTo[item.uid] === undefined) {
            mapReportingTo[item.uid] = item.reporting_to;
          }
        });
      }
      if (
        type !== "other" &&
        type !== "Overdue" &&
        type !== "Pending" &&
        type !== "Completed"
      ) {
        if (allContacts) {
          let drillDownData = await getDrillDownChartOrg(
            allContacts,
            filter,
            type,
            data.labels[item[0].index],
            user.organization_id
          );

          drillDownData.forEach((item: any) => {
            if (item.uid === "") {
              item["reporting_to"] = "";
            } else {
              item["reporting_to"] = mapReportingTo[item.uid]
                ? mapReportingTo[item.uid]
                : "";
            }
          });
          localStorage.setItem(
            "drilldownData",
            changeDateForRoute(drillDownData)
          );
          window.open("/drilldownData", "_blank");
        }
      }
    },
  };

  const getDrillDownData = async (key: string) => {
    var keyF: any;
    for (var i = 0; i < localStorage.length; i++) {
      keyF = localStorage.key(i);

      if (keyF !== "columns") {
        localStorage.removeItem(key);
      }
    }
    dispatcher(setClearFilter(true));
    let mapReportingTo: { [key: string]: string } = {};
    if (organizationUsers) {
      organizationUsers.forEach((item: any) => {
        if (mapReportingTo[item.uid] === undefined) {
          mapReportingTo[item.uid] = item.reporting_to;
        }
      });
    }
    if (
      type !== "other" &&
      type !== "Overdue" &&
      type !== "Pending" &&
      type !== "Completed"
    ) {
      if (allContacts) {
        const drillDownData = await drillDownTableOrg(
          allContacts,
          filter,
          type,
          key,
          false,
          key === "Grand Total" ? "SuperTotal" : "Normal",
          true,
          "",
          [],
          user.uid
        );
        drillDownData.forEach((item: any) => {
          if (item.uid === "") {
            item["reporting_to"] = "";
          } else {
            item["reporting_to"] = mapReportingTo[item.uid]
              ? mapReportingTo[item.uid]
              : "";
          }
        });
        localStorage.setItem(
          "drilldownData",
          changeDateForRoute(drillDownData)
        );
        window.open("/drilldownData", "_blank");
      }
    }
  };

  const getTaskDrillDownData = (
    date: string,
    feild: "Meeting" | "Call Back" | "Site Visit"
  ) => {
    var key: any;
    for (var i = 0; i < localStorage.length; i++) {
      key = localStorage.key(i);

      if (key !== "columns") {
        localStorage.removeItem(key);
      }
    }
    dispatcher(setClearFilter(true));
    let mapReportingTo: { [key: string]: string } = {};
    if (organizationUsers) {
      organizationUsers.forEach((item: any) => {
        if (mapReportingTo[item.uid] === undefined) {
          mapReportingTo[item.uid] = item.reporting_to;
        }
      });
    }
    if (
      type !== "other" &&
      type !== "budget" &&
      type !== "location" &&
      type !== "project" &&
      type !== "stage" &&
      type !== "property_type" &&
      type !== "property_stage"
    ) {
      if (allContacts) {
        const drillDownData = getTaskDrillDownTableSales(
          allContacts,
          filter,
          type,
          date,
          feild
        );
        drillDownData.forEach((item: any) => {
          if (item.uid === "") {
            item["reporting_to"] = "";
          } else {
            item["reporting_to"] = mapReportingTo[item.uid]
              ? mapReportingTo[item.uid]
              : "";
          }
        });
        localStorage.setItem(
          "drilldownData",
          changeDateForRoute(drillDownData)
        );
        window.open("/drilldownData", "_blank");
      }
    }
  };
  return (
    <div className={styles.graphContainer}>
      <div
        className={styles.graphBox}
        style={
          Heading === "Completed vs. Overdue" ||
          Heading === "Call Log Trends Summary" ||
          Heading === "Call Log Trends" ||
          Heading === "Interested Lead Trends" ||
          Heading === "Interested Lead Trends Summary"
            ? { width: "100%" }
            : {}
        }
      >
        <GraphType
          data={data}
          options={GraphType === Pie ? pieOptions : barOptions}
          type="line"
        />
      </div>
      <div
        className={styles.line}
        style={
          Heading === "Completed vs. Overdue" ||
          Heading === "Call Log Trends Summary" ||
          Heading === "Call Log Trends" ||
          Heading === "Interested Lead Trends" ||
          Heading === "Interested Lead Trends Summary"
            ? { height: "0%" }
            : {}
        }
      ></div>

      {(Heading === "FeedBack" ||
        Heading === "Interested Budget Summary" ||
        Heading === "Interested Project Summary" ||
        Heading === "Interested Location Summary") && (
        <div className={styles.detailsBox}>
          <p className={styles.graphHeading}>{Heading}</p>
          <div className={styles.typeBox}>
            <p className={styles.text}>{properFormat(title)}</p>
            <p className={styles.text}>{properFormat(value)}</p>
          </div>
          <div className={styles.graphLine}></div>
          {tableData.map((item, index) => (
            <>
              <div className={styles.typeBox}>
                <p className={styles.text}>{properFormat(item.val)}</p>
                <p
                  className={styles.text}
                  onClick={() => getDrillDownData(item.val)}
                >
                  {item.count}
                </p>
              </div>
              <div className={styles.graphLine}></div>
            </>
          ))}
        </div>
      )}

      {(Heading === "Over Due Tasks" ||
        Heading === "Completed Tasks" ||
        Heading === "Pending Tasks") && (
        <div className={styles.detailsBox}>
          <p className={styles.graphHeading}>{Heading}</p>
          <div className={styles.typeBox}>
            <p className={styles.text}>Date</p>
            <p className={styles.text} style={{ marginLeft: "50px" }}>
              Meeting
            </p>
            <p className={styles.text}>Call Back</p>
            <p className={styles.text}>Site Visit</p>
          </div>
          <div className={styles.graphLine}></div>
          {tableData.map((item, index) => (
            <>
              <div className={styles.typeBox}>
                <p className={styles.text}>{item.val}</p>
                <p
                  className={styles.text}
                  onClick={() => getTaskDrillDownData(item.val, "Meeting")}
                >
                  {item.count.Meeting}
                </p>
                <p
                  className={styles.text}
                  onClick={() => getTaskDrillDownData(item.val, "Call Back")}
                >
                  {item.count["Call Back"]}
                </p>
                <p
                  className={styles.text}
                  onClick={() => getTaskDrillDownData(item.val, "Site Visit")}
                >
                  {item.count["Site Visit"]}
                </p>
              </div>
              <div className={styles.graphLine}></div>
            </>
          ))}
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    role: state.user.role,
    contacts: state.contacts,
    user: state.user.data,
    organizationUsers: state.organizationUsers.data,

    teamLeadUsers: state.teamLeadUsers.data,
  };
};

export default connect(mapStateToProps)(AnalyticsGraph);

import React, {
  FunctionComponent,
  useEffect,
  useState,
  useRef,
} from "react";
import styles from "../BulkEditModal/BulkEditModal.module.css";
import Modal from "react-modal";
import { IoIosClose } from "react-icons/io";

import Dropdown from "../../DropDown/Dropdown";
import {
  addNoteFirebase,
  changeLeadStage,
  createTaskFirebase,
  fetchConstants,
} from "../../../Services/contacts";

import Loading from "../../Loading/Loading";
import { connect, useDispatch } from "react-redux";
import { showSnackbarAction } from "../../../Redux/actions";
import { useHistory } from "react-router-dom";
import moment from "moment";
import TextInput from "../../TextInput/TextInput";
type props = {
  contactDetail: any;
  close: () => void;
  open: boolean;
  tasksData: any[];
  user: any;
  notes: any[];
};

const CreateModal: FunctionComponent<props> = ({
  contactDetail,
  close,
  open,
  tasksData,
  user,
  notes,
}) => {
  const dateRef: any = useRef();
  const noteRef: any = useRef();
  const [load, setLoad] = useState(false);
  const [nextFollowSelected, setNextFollowSelected] =
    useState<any>({
      label: "Select",
      value: "Select",
    });

  const [propertyTypeList, setPropertyTypeList] = useState<
    any[]
  >([]);
  const [propertyStageList, setPropertyStageList] =
    useState<any[]>([]);
  const [nextFollowUpList, setnextFollowUpList] = useState<
    any[]
  >([]);
  const [notIntReasonList, setNotIntReasonList] = useState<
    any[]
  >([]);
  const [lostReasonList, setLostReasonList] = useState<
    any[]
  >([]);
  const [existingTaskSelected, setExistingTaskSelected] =
    useState<any>({
      label: "Select",
      value: "Select",
    });
  const [existingTaskStatus, setExistingTaskStatus] =
    useState(false);

  useEffect(() => {
    const unsubConst = fetchConstants(
      (data) => setNotIntReasonList(data),
      (data) => setLostReasonList(data),
      (data) => setPropertyStageList(data),
      (data) => setPropertyTypeList(data),
      (data) => setnextFollowUpList(data)
    );

    if (
      tasksData[0].status === "Pending" &&
      tasksData[0].type !== "Call Back"
    ) {
      setExistingTaskStatus(true);
    }

    return () => {
      unsubConst();
    };
    // eslint-disable-next-line
  }, []);

  const dispatcher = useDispatch();
  const history = useHistory();

  const onSubmit = () => {
    if (nextFollowSelected.value === "Select") {
      dispatcher(
        showSnackbarAction(
          "Select Next Follow Up Type!!",
          "error"
        )
      );
    } else if (
      dateRef.current.value === "" ||
      dateRef.current.value < new Date()
    ) {
      dispatcher(
        showSnackbarAction("Enter A Valid Date!!", "error")
      );
    } else if (
      existingTaskStatus &&
      existingTaskSelected.value === "Select"
    ) {
      dispatcher(
        showSnackbarAction(
          "Select Exisiting Task Status!!",
          "error"
        )
      );
    } else {
      setLoad(true);
      const data = {
        due_date: moment(dateRef.current.value).toDate(),
        type: nextFollowSelected.value,
        status: "Pending",
        customer_name: contactDetail.customer_name,
      };

      if (noteRef.current.value.length !== 0) {
        addNoteFirebase(
          contactDetail.contactId,
          notes,
          noteRef.current.value,
          () => {},
          dispatcher,
          () => {},
          user.user_email
        );
      }
      createTaskFirebase(
        contactDetail.contactId,
        tasksData,
        data,
        {
          next_follow_up_type: nextFollowSelected.value,
          next_follow_up_date_time: moment(
            dateRef.current.value
          ).toDate(),
        },
        (value: boolean) => setLoad(value),
        dispatcher,
        user,
        close,
        history,
        existingTaskStatus &&
          existingTaskSelected.value === "Completed"
          ? true
          : false
      );
    }
  };
  return (
    <Modal
      className={styles.parent}
      isOpen={open}
      shouldCloseOnOverlayClick={true}
      overlayClassName={styles.overlay}
      shouldCloseOnEsc={true}
      onRequestClose={close}
    >
      {load === true && <Loading />}
      <div className={styles.firstDiv}>
        <p className={styles.contactForm}>
          Create New Task
        </p>
        <div className={styles.cross} onClick={close}>
          <IoIosClose size={35} color={"#808080"} />
        </div>
      </div>
      <div className={styles.line}></div>

      {existingTaskStatus && (
        <div className={styles.box2}>
          <div
            className={styles.divide}
            style={{ width: "100%" }}
          >
            <div className={styles.title}>
              <p className={styles.one}>
                Exisiting Task Status
              </p>
              <p className={styles.two}>*</p>
            </div>
            <div style={{ marginTop: "7px" }}>
              <Dropdown
                option={["Completed", "Cancelled"]}
                selectedValue={existingTaskSelected}
                setSelectedValue={(value) => {
                  setExistingTaskSelected(value);
                }}
              />
            </div>
          </div>
        </div>
      )}

      <div className={styles.box2}>
        <div
          className={styles.divide}
          style={{ width: "100%" }}
        >
          <div className={styles.title}>
            <p className={styles.one}>
              Next Follow Up Type
            </p>
            <p className={styles.two}></p>
          </div>
          <div style={{ marginTop: "7px" }}>
            <Dropdown
              option={nextFollowUpList}
              selectedValue={nextFollowSelected}
              setSelectedValue={(value) => {
                setNextFollowSelected(value);
              }}
            />
          </div>
        </div>
      </div>

      <div
        className={styles.divide}
        style={{ marginTop: "30px" }}
      >
        <div className={styles.title}>
          <p className={styles.one}>
            Next Follow Up Date & Time
          </p>
          <p className={styles.two}></p>
        </div>
        <div>
          <input
            type="datetime-local"
            placeholder="Enter Date"
            ref={dateRef}
          />
        </div>
      </div>

      <div className={styles.box2}>
        <div
          className={styles.divide}
          style={{ width: "100%" }}
        >
          <div className={styles.title}>
            <p className={styles.one}>Note</p>
            <p className={styles.two}></p>
          </div>
          <div>
            <TextInput
              title={"Enter Note"}
              style={{ backgroundColor: "#fff" }}
              ref={noteRef}
            ></TextInput>
          </div>
        </div>
      </div>

      <button
        className={styles.apply}
        style={{ marginTop: "auto" }}
        onClick={onSubmit}
      >
        Save
      </button>
    </Modal>
  );
};

const mapStateToProps = (state: any) => {
  return {
    user: state.user.data,
  };
};

export default connect(mapStateToProps)(CreateModal);

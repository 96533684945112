import React, { FunctionComponent, useState, useEffect } from 'react';
import styles from './ProjectDetails.module.css';
import { IoIosAddCircleOutline } from 'react-icons/io';
import { ImFilePdf } from 'react-icons/im';
import { useLocation } from 'react-router-dom';
import { getDateAndTime } from '../../Values/utils';
import ProjectDocumentModal from '../../Components/Modals/ProjectDocumentModal/ProjectDocumentModal';
import {
  deleteProjectBrochures,
  deleteProjectForms,
  deleteProjectImage,
  deleteProjectLayouts,
  deleteProjectPriceLists,
  fetchProjectDocuments,
  setProjectImage,
} from '../../Services/resources';
import { connect, useDispatch } from 'react-redux';
import { showSnackbarAction } from '../../Redux/actions';
import Loading from '../../Components/Loading/Loading';
import { MdDelete } from 'react-icons/md';
type props = { user: any };
const ProjectDetails: FunctionComponent<props> = ({ user }) => {
  const location: any = useLocation();
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState('');
  const [type, setType] = useState('');
  const [brochures, setBrochures] = useState<any[] | undefined>(undefined);
  const [priceLists, setPriceLists] = useState<any[] | undefined>(undefined);
  const [layouts, setLayouts] = useState<any[] | undefined>(undefined);
  const [forms, setForms] = useState<any[] | undefined>(undefined);
  const [images, setImages] = useState<any[] | undefined>(undefined);
  const [load, setLoad] = useState(false);
  const dispatcher = useDispatch();
  useEffect(() => {
    if (location.state.detail) {
      fetchProjectDocuments(
        location.state.detail.project_id,
        (data) => setBrochures(data),
        (data) => setPriceLists(data),
        (data) => setLayouts(data),
        (data) => setForms(data),
        (data) => setImages(data)
      );
    }
  }, []);

  const deleteImage = async (fileName: string, index: number) => {
    console.log('deleteImages is clicked');
    console.log(images);
    if (images) {
      await deleteProjectImage(
        'images',
        images,
        location.state.detail.project_id,
        index,
        fileName,
        dispatcher
      );
    }
  };
  const deleteBrochures = async (fileName: string, index: number) => {
    console.log('deleteBrouchers is clicked');
    console.log(brochures);
    if (brochures) {
      await deleteProjectBrochures(
        'brochures',
        brochures,
        location.state.detail.project_id,
        index,
        fileName,
        dispatcher
      );
    }
  };
  const deletePriceLists = async (fileName: string, index: number) => {
    console.log('deletePriceLists is clicked');
    console.log(priceLists);
    if (priceLists) {
      await deleteProjectPriceLists(
        'priceLists',
        priceLists,
        location.state.detail.project_id,
        index,
        fileName,
        dispatcher
      );
    }
  };
  const deleteLayouts = async (fileName: string, index: number) => {
    console.log('deleteLayouts is clicked');
    console.log(layouts);
    if (layouts) {
      await deleteProjectLayouts(
        'layouts',
        layouts,
        location.state.detail.project_id,
        index,
        fileName,
        dispatcher
      );
    }
  };
  const deleteForms = async (fileName: string, index: number) => {
    console.log('deleteForms is clicked');
    console.log(forms);
    if (forms) {
      await deleteProjectForms(
        'forms',
        forms,
        location.state.detail.project_id,
        index,
        fileName,
        dispatcher
      );
    }
  };

  const upload = async (data: FileList | null) => {
    if (data && user.organization_id) {
      setLoad(true);
      await setProjectImage(
        user.organization_id,
        data[0],
        location.state.projectList,
        location.state.detail,
        location.state.detail.project_image ? true : false,
        dispatcher
      );
      setLoad(false);
      dispatcher(showSnackbarAction('Thumbnail Uploaded!!', 'success'));
    }
  };
  return (
    <>
      {load === true && <Loading />}
      <div className={styles.parent}>
        <div className={styles.child}>
          <div className={styles.documentContainer}>
            <p className={styles.heading}>Project Documents</p>
            <div className={styles.addContainer}>
              <p className={styles.title}>Images</p>
              <IoIosAddCircleOutline
                color="#279ea0"
                size="20"
                onClick={() => {
                  setOpen(true);
                  setTitle('Project Image');
                  setType('images');
                }}
              />
            </div>
            <div className={styles.allDocuments}>
              {images?.map((item, index) => (
                <div className={styles.document}>
                  <ImFilePdf color="#808080" size="10" />
                  <p className={styles.name}>
                    {' '}
                    <a href={item.link}>{item.name}</a>
                  </p>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      marginLeft: 'auto',
                    }}
                  >
                    <MdDelete
                      color={'#ff0000'}
                      style={{}}
                      size={16}
                      onClick={() => {
                        deleteImage(item.name, index);
                      }}
                    />
                  </div>
                </div>
              ))}
              {images?.length === 0 && (
                <p style={{ margin: '0px', fontSize: '12px' }}>
                  No Document Added
                </p>
              )}
            </div>

            <div className={styles.addContainer}>
              <p className={styles.title}>Brochures</p>
              <IoIosAddCircleOutline
                color="#279ea0"
                size="20"
                onClick={() => {
                  setOpen(true);
                  setTitle('Brochures Document');
                  setType('brochures');
                }}
              />
            </div>
            <div className={styles.allDocuments}>
              {brochures?.map((item, index) => (
                <div className={styles.document}>
                  <ImFilePdf color="#808080" size="10" />
                  <p className={styles.name}>
                    {' '}
                    <a href={item.link}>{item.name}</a>
                  </p>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      marginLeft: 'auto',
                    }}
                  >
                    <MdDelete
                      color={'#ff0000'}
                      style={{}}
                      size={16}
                      onClick={() => {
                        deleteBrochures(item.name, index);
                      }}
                    />
                  </div>
                </div>
              ))}
              {brochures?.length === 0 && (
                <p style={{ margin: '0px', fontSize: '12px' }}>
                  No Document Added
                </p>
              )}
            </div>

            <div className={styles.addContainer}>
              <p className={styles.title}>Pricelists</p>
              <IoIosAddCircleOutline
                color="#279ea0"
                size="20"
                onClick={() => {
                  setOpen(true);
                  setTitle('PriceLists Document');
                  setType('priceLists');
                }}
              />
            </div>
            <div className={styles.allDocuments}>
              {priceLists?.map((item, index) => (
                <div className={styles.document}>
                  <ImFilePdf color="#808080" size="10" />
                  <p className={styles.name}>
                    {' '}
                    <a href={item.link}>{item.name}</a>
                  </p>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      marginLeft: 'auto',
                    }}
                  >
                    <MdDelete
                      color={'#ff0000'}
                      style={{}}
                      size={16}
                      onClick={() => {
                        deletePriceLists(item.name, index);
                      }}
                    />
                  </div>
                </div>
              ))}
              {priceLists?.length === 0 && (
                <p style={{ margin: '0px', fontSize: '12px' }}>
                  No Document Added
                </p>
              )}
            </div>

            <div className={styles.addContainer}>
              <p className={styles.title}>Layouts</p>
              <IoIosAddCircleOutline
                color="#279ea0"
                size="20"
                onClick={() => {
                  setOpen(true);
                  setTitle('Layouts Document');
                  setType('layouts');
                }}
              />
            </div>
            <div className={styles.allDocuments}>
              {layouts?.map((item, index) => (
                <div className={styles.document}>
                  <ImFilePdf color="#808080" size="10" />
                  <p className={styles.name}>
                    {' '}
                    <a href={item.link}>{item.name}</a>
                  </p>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      marginLeft: 'auto',
                    }}
                  >
                    <MdDelete
                      color={'#ff0000'}
                      style={{}}
                      size={16}
                      onClick={() => {
                        deleteLayouts(item.name, index);
                      }}
                    />
                  </div>
                </div>
              ))}
              {layouts?.length === 0 && (
                <p style={{ margin: '0px', fontSize: '12px' }}>
                  No Document Added
                </p>
              )}
            </div>

            <div className={styles.addContainer}>
              <p className={styles.title}>Forms</p>
              <IoIosAddCircleOutline
                color="#279ea0"
                size="20"
                onClick={() => {
                  setOpen(true);
                  setTitle('Forms Document');
                  setType('forms');
                }}
              />
            </div>
            <div className={styles.allDocuments}>
              {forms?.map((item, index) => (
                <div className={styles.document}>
                  <ImFilePdf color="#808080" size="10" />
                  <p className={styles.name}>
                    {' '}
                    <a href={item.link}>{item.name}</a>
                  </p>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      marginLeft: 'auto',
                    }}
                  >
                    <MdDelete
                      color={'#ff0000'}
                      style={{}}
                      size={16}
                      onClick={() => {
                        deleteForms(item.name, index);
                      }}
                    />
                  </div>
                </div>
              ))}
              {forms?.length === 0 && (
                <p style={{ margin: '0px', fontSize: '12px' }}>
                  No Document Added
                </p>
              )}
            </div>
          </div>

          <div className={styles.projectDetails}>
            <p className={styles.detailsHeading}>Project Details</p>
            <div className={styles.data}>
              <p className={styles.head}>Project Name:</p>
              <p className={styles.text}>
                {location.state.detail.project_name}
              </p>
            </div>
            <div className={styles.data}>
              <p className={styles.head}>Developer Name:</p>
              <p className={styles.text}>
                {location.state.detail.developer_name}
              </p>
            </div>
            <div className={styles.data}>
              <p className={styles.head}>Address:</p>
              <p className={styles.text}>{location.state.detail.address}</p>
            </div>
            <div className={styles.data}>
              <p className={styles.head}>Property Type:</p>
              <p className={styles.text}>
                {location.state.detail.property_type}
              </p>
            </div>
            <div className={styles.data}>
              <p className={styles.head}>RERA Link:</p>
              <p className={styles.text}>{location.state.detail.rera_link}</p>
            </div>
            <div className={styles.data}>
              <p className={styles.head}>Walkthrough Link:</p>
              <p className={styles.text}>
                {location.state.detail.walkthrough_link}
              </p>
            </div>
            <div className={styles.data}>
              <p className={styles.head}>Created By:</p>
              <p className={styles.text}>{location.state.detail.created_by}</p>
            </div>
            <div className={styles.data}>
              <p className={styles.head}>Created Date & Time:</p>
              <p className={styles.text}>
                {getDateAndTime(location.state.detail.created_at, 'LLL')}
              </p>
            </div>

            <div className={styles.uploadContainer}>
              <div className={styles.selectBox}>
                <label htmlFor="file" className={styles.uploadButton}>
                  Upload Thumbnail
                </label>
                <input
                  id="file"
                  className={styles.select}
                  type="file"
                  accept=".png,.jpg,.jpeg"
                  onChange={(e) => upload(e.target.files)}
                />
              </div>
            </div>
          </div>
        </div>
        {open === true && (
          <ProjectDocumentModal
            open={open}
            close={() => setOpen(false)}
            title={title}
            projectData={location.state.detail}
            type={type}
            setLoad={(data) => setLoad(data)}
          />
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    user: state.user.data,
  };
};

export default connect(mapStateToProps)(ProjectDetails);

import React, { useRef, FunctionComponent, useState, useEffect } from "react";
import styles from "./AddOrganization.module.css";
import TextInput from "../../Components/TextInput/TextInput";

import { createOrganization } from "../../Services/organizations";

import { emailValidate, phoneValidate } from "../../Values/validatorsnew";
import { useDispatch } from "react-redux";
import Loading from "../../Components/Loading/Loading";
import { AiOutlineClose } from "react-icons/ai";
import Select from "react-select";
import { countryData } from "../../Values/constants";

type props = {
  history: any;
};
const AddOrganization: FunctionComponent<props> = ({ history }) => {
  const dispatcher = useDispatch();
  const [load, setLoad] = useState(false);
  const [state, setState] = useState("");

  const [country, setCountry] = useState("India");
  const organizationNameRef: any = useRef();
  const mobileNumberRef: any = useRef();
  const emailRef: any = useRef();
  const cityRef: any = useRef();
  const addressRef: any = useRef();
  const pincodeRef: any = useRef();
  const adminFirstNameRef: any = useRef();
  const adminLastNameRef: any = useRef();
  const adminContactNumberRef: any = useRef();
  const adminEmailRef: any = useRef();
  const employeesRef: any = useRef();

  useEffect(() => {
    window.onbeforeunload = function () {
      return "Are you really want to perform the action?";
    };
  }, []);

  const create = () => {
    setLoad(true);
    createOrganization(
      organizationNameRef.current?.value,
      mobileNumberRef.current?.value,
      emailRef.current?.value,
      addressRef.current?.value,
      country,
      state,
      cityRef.current?.value,
      pincodeRef.current?.value,
      adminFirstNameRef.current?.value,
      adminLastNameRef.current?.value,
      adminContactNumberRef.current?.value,
      adminEmailRef.current?.value,
      employeesRef.current?.value,
      history,
      dispatcher,
      window,
      (data: boolean) => setLoad(data)
    );
  };

  const choice = () => {
    let data = countryData[country].map((item: any) => ({
      value: item,
      label: item,
    }));

    return data;
  };

  const countryChoice = () => {
    let data = Object.keys(countryData).map((item: any, index: number) => ({
      value: item,
      label: item,
    }));

    return data;
  };

  return (
    <div className={styles.parent}>
      {load === true && <Loading />}
      <div className={styles.child}>
        <div className={styles.headerView}>
          <p className={styles.heading}> Add Organization Details</p>
          <AiOutlineClose
            className={styles.closeIcon}
            size={25}
            onClick={() => history.replace("/")}
          />
        </div>
        <div className={styles.title}>
          <p className={styles.one}>Organization Name</p>
          <p className={styles.two}>*</p>
        </div>
        <div className={styles.box}>
          <TextInput
            title={"Enter Name"}
            style={{ backgroundColor: "#fff" }}
            ref={organizationNameRef}
          ></TextInput>
        </div>

        <div className={styles.box2}>
          <div className={styles.divide}>
            <div className={styles.title}>
              <p className={styles.one}>Mobile Number</p>
              <p className={styles.two}>*</p>
            </div>
            <div>
              <TextInput
                title={"Enter Number"}
                style={{ backgroundColor: "#fff" }}
                ref={mobileNumberRef}
                validator={phoneValidate}
                length={true}
              />
            </div>
          </div>

          <div className={styles.divide}>
            <div className={styles.title}>
              <p className={styles.one}>Email Id</p>
              <p className={styles.two}>*</p>
            </div>
            <div>
              <TextInput
                title={"Enter Email"}
                style={{ backgroundColor: "#fff" }}
                ref={emailRef}
                validator={emailValidate}
              />
            </div>
          </div>
        </div>

        <div style={{ marginTop: "22px" }} className={styles.title}>
          <p className={styles.one}>Address</p>
          <p className={styles.two}>*</p>
        </div>
        <div className={styles.box}>
          <TextInput
            title={"Enter Address"}
            style={{ backgroundColor: "#fff" }}
            ref={addressRef}
          />
        </div>

        <div className={styles.box2}>
          <div className={styles.divide}>
            <div className={styles.title}>
              <p className={styles.one}>COUNTRY</p>
              <p className={styles.two}>*</p>
            </div>
            <div>
              <Select
                options={countryChoice()}
                onChange={(data) => {
                  setCountry(data?.label);
                }}
                isSearchable={true}
              />
            </div>
          </div>

          <div className={styles.divide}>
            <div className={styles.title} style={{ marginBottom: "3%" }}>
              <p className={styles.one}>STATE</p>
              <p className={styles.two}>*</p>
            </div>
            <div>
              <Select
                options={choice()}
                onChange={(data) => {
                  setState(data?.label);
                }}
                isSearchable={true}
              />
            </div>
          </div>
        </div>

        <div className={styles.box2}>
          <div className={styles.divide}>
            <div className={styles.title}>
              <p className={styles.one}>CITY</p>
              <p className={styles.two}>*</p>
            </div>
            <div>
              <TextInput
                title={"Enter City"}
                style={{ backgroundColor: "#fff" }}
                ref={cityRef}
              />
            </div>
          </div>

          <div className={styles.divide}>
            <div className={styles.title}>
              <p className={styles.one}>PINCODE</p>
              <p className={styles.two}>*</p>
            </div>
            <div>
              <TextInput
                title={"Enter Pincode"}
                style={{ backgroundColor: "#fff" }}
                ref={pincodeRef}
              />
            </div>
          </div>
        </div>

        <div className={styles.box2}>
          <div className={styles.divide}>
            <div className={styles.title}>
              <p className={styles.one}>ADMIN FIRST NAME</p>
              <p className={styles.two}>*</p>
            </div>
            <div>
              <TextInput
                title={"Enter First Name"}
                style={{ backgroundColor: "#fff" }}
                ref={adminFirstNameRef}
              />
            </div>
          </div>

          <div className={styles.divide}>
            <div className={styles.title}>
              <p className={styles.one}>ADMIN LAST NAME</p>
              <p className={styles.two}>*</p>
            </div>
            <div>
              <TextInput
                title={"Enter Last Name"}
                style={{ backgroundColor: "#fff" }}
                ref={adminLastNameRef}
              />
            </div>
          </div>
        </div>

        <div className={styles.box2}>
          <div className={styles.divide}>
            <div className={styles.title}>
              <p className={styles.one}>ADMIN CONTACT NUMBER</p>
              <p className={styles.two}>*</p>
            </div>
            <div>
              <TextInput
                title={"Enter Number"}
                style={{ backgroundColor: "#fff" }}
                ref={adminContactNumberRef}
                validator={phoneValidate}
              />
            </div>
          </div>

          <div className={styles.divide}>
            <div className={styles.title}>
              <p className={styles.one}>ADMIN EMAIL ID</p>
              <p className={styles.two}>*</p>
            </div>
            <div>
              <TextInput
                title={"Enter Email"}
                style={{ backgroundColor: "#fff" }}
                ref={adminEmailRef}
                validator={emailValidate}
              />
            </div>
          </div>
        </div>

        <div className={styles.box2}>
          <div className={styles.divide}>
            <div className={styles.title}>
              <p className={styles.one}>NUMBER OF EMPLOYEES</p>
              <p className={styles.two}>*</p>
            </div>
            <div>
              <TextInput
                title={"Enter Number Of Employees"}
                style={{ backgroundColor: "#fff" }}
                ref={employeesRef}
              />
            </div>
          </div>
        </div>

        <button className={styles.button} onClick={() => create()}>
          Submit
        </button>
      </div>
    </div>
  );
};

export default AddOrganization;

import React, { useState, FunctionComponent, useEffect } from 'react';
import { BsColumnsGap } from 'react-icons/bs';
import Modal from 'react-modal';
import styles from './KanbanViewModal.module.css';
import axios from 'axios';
import { url } from '../../../Values/constants';
import { connect, useDispatch } from 'react-redux';
import { dateFieldList } from '../../../Values/tables';
import moment from 'moment';

type props = {
  panel: 'Contacts' | 'Tasks' | 'Call Logs';
  user: any;
  setTaskFilters: (data: any) => void;
  setContactsFilter: (data: any) => void;
  setCallsFilter: (data: any) => void;
  clearFilter: boolean;
  usersList: any[];
};
const KanbanViewFilter: FunctionComponent<props> = ({
  panel,
  user,
  setTaskFilters,
  setCallsFilter,
  setContactsFilter,
  clearFilter,
  usersList,
}) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [filObj, setFilObj] = useState<any>({});
  const [contactFilterObject, setContactFilterObject] = useState<any>({});
  const [taskFilterObject, setTaskFilterObject] = useState({});
  const [callFilterObject, setCallFilterObject] = useState({});

  const taskList = ['completed_at', 'due_date', 'type', 'status'];
  const callsList = ['created_at', 'duration'];
  const contactsList = [
    'stage',
    'contact_owner_email',
    'lead_source',
    'budget',
    'call_back_reason',
    'project',
    'property_type',
    'property_stage',
    'property_sub_type',
    'location',
    'next_follow_up_type',
    'next_follow_up_date_time',
    'created_at',
    'lead_assign_time',
    'stage_change_at',
    'addset',
    'campaign',
    'inventory_type',
    'team',
    'branch',
    'previous_owner_1',
    'previous_owner_2',
    'stage_1',
    'stage_2',
    'transfer_by_1',
    'transfer_by_2',
    'reporting_to',
    'not_int_reason',
    'lost_reason',
  ];
  const taskFilters: any = {
    Stage: 'stage',
    Owner: 'contact_owner_email',
    'Lead Source': 'lead_source',
    Budget: 'budget',
    'Call Back Reason': 'call_back_reason',
    Project: 'project',
    'Property Type': 'property_type',
    'Property Sub Type': 'property_sub_type',
    'Property Stage': 'property_stage',
    Location: 'location',
    Addset: 'addset',
    Campaign: 'campaign',
    'Inventory Type': 'inventory_type',
    Team: 'team',
    Branch: 'branch',
    'Reporting To': 'reporting_to',
    'Not Interested Reason': 'not_int_reason',
    'Lost Reason': 'lost_reason',
    'Completion Time': 'completed_at',
    'Task Type': 'type',
    'Due Date': 'due_date',
    Status: 'status',
  };

  const contactFilters: any = {
    Stage: 'stage',
    Owner: 'contact_owner_email',
    'Lead Source': 'lead_source',
    Budget: 'budget',
    'Call Back Reason': 'call_back_reason',
    Project: 'project',
    'Property Type': 'property_type',
    'Property Sub Type': 'property_sub_type',
    'Property Stage': 'property_stage',
    Location: 'location',
    'Next Follow Up Type': 'next_follow_up_type',
    'Next Follow Up Date & Time': 'next_follow_up_date_time',
    'Created At': 'created_at',
    'Lead Assign At': 'lead_assign_time',
    'Stage Change At': 'stage_change_at',
    Addset: 'addset',
    Campaign: 'campaign',
    'Inventory Type': 'inventory_type',
    Team: 'team',
    Branch: 'branch',
    'Previous Owner 1': 'previous_owner_1',
    'Previous Owner 2': 'previous_owner_2',
    'Stage 1': 'stage_1',
    'Stage 2': 'stage_2',
    'Transfer By 1': 'transfer_by_1',
    'Transfer By 2': 'transfer_by_2',
    'Reporting To': 'reporting_to',
    'Not Interested Reason': 'not_int_reason',
    'Lost Reason': 'lost_reason',
    'Completion Time': 'completed_at',
    'Task Type': 'type',
    Status: 'status',
  };

  const callFilters: any = {
    Stage: 'stage',
    Owner: 'contact_owner_email',
    'Lead Source': 'lead_source',
    Budget: 'budget',
    Project: 'project',
    'Property Type': 'property_type',
    'Property Sub Type': 'property_sub_type',
    'Property Stage': 'property_stage',
    Location: 'location',
    Addset: 'addset',
    Campaign: 'campaign',
    'Inventory Type': 'inventory_type',
    Team: 'team',
    Branch: 'branch',
    'Reporting To': 'reporting_to',
    'Not Interested Reason': 'not_int_reason',
    'Lost Reason': 'lost_reason',
    'Created At': 'created_at',
    'Call Duration': 'duration',
  };
  const dispatcher = useDispatch();
  const [filterValue, setFilterValue] = useState<any>();
  const [dateArray, setDateArray] = useState<
    Array<{ item: string; from?: Date; to?: Date }>
  >([]);
  const getContactsFilter = async () => {
    let finalFilter = {};
    axios.defaults.headers.common['x-access-token'] =
      'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoibGFrc2hheSIsImlhdCI6MTYyOTczOTgzMX0.E0mqgfrDj5su4grI1r8PH973srqJy3k2AG_hvCx-EaA';
    const res = await axios.post(url + '/leads/filterValues', {
      uid: user.uid,
    });
    let l: any[] = [];
    if (res.data[0]) {
      l = Object.keys(res.data[0]);
      finalFilter = res.data[0];
    }

    axios.defaults.headers.common['x-access-token'] =
      'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoibGFrc2hheSIsImlhdCI6MTYyOTczOTgzMX0.E0mqgfrDj5su4grI1r8PH973srqJy3k2AG_hvCx-EaA';
    const taskRes = await axios.post(url + '/tasks/filterValues', {
      uid: user.uid,
    });
    let tasks: { [key: string]: [] } = {};
    if (taskRes.data[0]) {
      Object.keys(taskRes.data[0]).forEach((key) => {
        if (!l.includes(key)) {
          tasks[key] = taskRes.data[0][key];
          l.push(key);
        }
      });
    }

    axios.defaults.headers.common['x-access-token'] =
      'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoibGFrc2hheSIsImlhdCI6MTYyOTczOTgzMX0.E0mqgfrDj5su4grI1r8PH973srqJy3k2AG_hvCx-EaA';
    const callRes = await axios.post(url + '/callLogs/filterValues', {
      uid: user.uid,
    });
    let calls: { [key: string]: [] } = {};
    if (callRes.data[0]) {
      Object.keys(callRes.data[0]).forEach((key) => {
        if (!l.includes(key)) {
          calls[key] = callRes.data[0][key];
          l.push(key);
        }
      });
    }

    let otherFilters = {
      reporting_to: usersList,
      previous_owner_1: usersList,
      previous_owner_2: usersList,
      transfer_by_1: usersList,
      transfer_by_2: usersList,
      stage_1: [
        'FRESH',
        'INTERESTED',
        'LOST',
        'WON',
        'NOT INTERESTED',
        'CALL BACK',
      ],
      stage_2: [
        'FRESH',
        'INTERESTED',
        'LOST',
        'WON',
        'NOT INTERESTED',
        'CALL BACK',
      ],
      status: ['Completed', 'Pending', 'Overdue'],
    };

    finalFilter = { ...finalFilter, ...tasks, ...calls, ...otherFilters };

    setFilterValue(finalFilter);
  };
  useEffect(() => {
    if (user) {
      getContactsFilter();
    }
  }, [user, usersList]);

  useEffect(() => {
    if (filterValue) {
      let contacts: { [key: string]: [] } = {};

      let obj = { ...contactFilters, ...taskFilters, ...callFilters };
      Object.keys(obj).forEach((key: any) => {
        let s = obj[key];
        contacts[key] = filterValue[s];
      });

      setFilObj(contacts);
    }
  }, [filterValue]);

  useEffect(() => {
    setDateArray([]);
    setTaskFilterObject({});
    setContactFilterObject({});
    setCallFilterObject({});
  }, [panel]);

  useEffect(() => {
    if (clearFilter === true) {
      setDateArray([]);
      setTaskFilterObject({});
      setContactFilterObject({});
      setCallFilterObject({});
    }
  }, [clearFilter]);

  const onSave = () => {
    setTaskFilters(taskFilterObject);
    setContactsFilter(contactFilterObject);
    setCallsFilter(callFilterObject);
    setModalIsOpen(false);
  };

  const clearKanbanFilters = () => {
    setTaskFilterObject({});
    setContactFilterObject({});
    setCallFilterObject({});
    setDateArray([]);
    setModalIsOpen(false);
  };

  const setDateFilter = (
    date: Date | null,
    type: 'from' | 'to',
    item: string
  ) => {
    if (date === null) {
      return;
    }
    let tempDateArray = dateArray;
    if (type === 'from') {
      const newDate = moment(date).startOf('day').toDate();
      if (!tempDateArray.some((temp) => temp.item === item)) {
        console.log(tempDateArray);
        tempDateArray.push({ item, from: newDate });
      } else {
        tempDateArray.forEach((temp) => {
          if (temp.item === item) temp.from = newDate;
        });
      }
    } else {
      const newDate = moment(date).endOf('day').toDate();
      if (!tempDateArray.some((temp) => temp.item === item))
        tempDateArray.push({ item, to: newDate });
      else {
        tempDateArray.forEach((temp) => {
          if (temp.item === item) temp.to = newDate;
        });
      }
    }

    setDateArray(tempDateArray);
    if (
      tempDateArray.some((temp) =>
        Object.keys(temp).includes('item' && 'to' && 'from')
      )
    ) {
      let data = tempDateArray.find((temp) => temp.item === item);
      if (panel === 'Contacts') {
        let contObj = { ...contactFilterObject };
        let taskObj: any = { ...taskFilterObject };
        let callObj: any = { ...callFilterObject };
        if (taskList.includes(contactFilters[item])) {
          taskObj[contactFilters[item]] = [
            data?.from?.toISOString(),
            data?.to?.toISOString(),
          ];
          setTaskFilterObject(taskObj);
        } else if (contactsList.includes(contactFilters[item])) {
          contObj[contactFilters[item]] = [
            data?.from?.toISOString(),
            data?.to?.toISOString(),
          ];
          setContactFilterObject(contObj);
        } else {
          callObj[contactFilters[item]] = [
            data?.from?.toISOString(),
            data?.to?.toISOString(),
          ];
          setCallFilterObject(callObj);
        }
      }
      if (panel === 'Tasks') {
        let contObj = { ...contactFilterObject };
        let taskObj: any = { ...taskFilterObject };
        let callObj: any = { ...callFilterObject };
        if (taskList.includes(taskFilters[item])) {
          taskObj[taskFilters[item]] = [
            data?.from?.toISOString(),
            data?.to?.toISOString(),
          ];
          setTaskFilterObject(taskObj);
        } else if (contactsList.includes(taskFilters[item])) {
          contObj[taskFilters[item]] = [
            data?.from?.toISOString(),
            data?.to?.toISOString(),
          ];
          setContactFilterObject(contObj);
        } else {
          callObj[taskFilters[item]] = [
            data?.from?.toISOString(),
            data?.to?.toISOString(),
          ];
          setCallFilterObject(callObj);
        }
      }
      if (panel === 'Call Logs') {
        let contObj = { ...contactFilterObject };
        let taskObj: any = { ...taskFilterObject };
        let callObj: any = { ...callFilterObject };
        if (callsList.includes(callFilters[item])) {
          callObj[callFilters[item]] = [
            data?.from?.toISOString(),
            data?.to?.toISOString(),
          ];
          setCallFilterObject(callObj);
        } else if (contactsList.includes(callFilters[item])) {
          contObj[callFilters[item]] = [
            data?.from?.toISOString(),
            data?.to?.toISOString(),
          ];
          setContactFilterObject(contObj);
        } else {
          taskObj[taskFilters[item]] = [
            data?.from?.toISOString(),
            data?.to?.toISOString(),
          ];
          setTaskFilterObject(callObj);
        }
      }
      // dispatcher(
      //   setFilter({
      //     [filter]: [
      //       tempDateArray[0].toISOString(),
      //       tempDateArray[1].toISOString(),
      //     ],
      //   })
      // );
      // localStorage.setItem(filterId, JSON.stringify(tempDateArray));
    }
  };

  return (
    <div>
      <BsColumnsGap size={20} onClick={() => setModalIsOpen(true)} />
      <Modal
        className={styles.parent}
        isOpen={modalIsOpen}
        shouldCloseOnOverlayClick={true}
        overlayClassName={styles.overlay}
        shouldCloseOnEsc={true}
        onRequestClose={() => setModalIsOpen(false)}
      >
        <div className={styles.header}>
          <p className={styles.heading}>Set Filters</p>
        </div>
        <div className={styles.tableHeader}>
          <p className={styles.columnName}>{`${panel} Filter`}</p>
          <p className={styles.columnName}>Values</p>
        </div>
        <div className={styles.body}>
          {panel === 'Contacts' &&
            Object.keys(contactFilters).map((item) => (
              <>
                <div className={styles.tableContent}>
                  <p className={styles.item}>{item}</p>
                  {dateFieldList.includes(item) === true ? (
                    <>
                      <div className={styles.dateInputContainer}>
                        <p
                          className={styles.optionText}
                          style={{ marginTop: '5px', marginBottom: '4px' }}
                        >
                          From:
                        </p>
                        <input
                          type="date"
                          id="from"
                          name="from"
                          value={
                            dateArray.some((i) => i.item === item && i.from)
                              ? moment(
                                  dateArray.find((temp) => temp.item === item)
                                    ?.from
                                ).format('YYYY-MM-DD')
                              : ''
                          }
                          onChange={(e) => {
                            setDateFilter(e.target.valueAsDate, 'from', item);
                          }}
                        />
                        <p
                          className={styles.optionText}
                          style={{ marginTop: '12px', marginBottom: '4px' }}
                        >
                          To:
                        </p>
                        <input
                          type="date"
                          id="to"
                          name="to"
                          value={
                            dateArray.some((i) => i.item === item && i.to)
                              ? moment(
                                  dateArray.find((temp) => temp.item === item)
                                    ?.to
                                ).format('YYYY-MM-DD')
                              : ''
                          }
                          onChange={(e) => {
                            setDateFilter(e.target.valueAsDate, 'to', item);
                          }}
                        />
                      </div>
                    </>
                  ) : (
                    <select
                      onChange={(val) => {
                        let contObj = { ...contactFilterObject };
                        let taskObj: any = { ...taskFilterObject };
                        let callObj: any = { ...callFilterObject };
                        if (taskList.includes(contactFilters[item])) {
                          taskObj[contactFilters[item]] = [val.target.value];
                          setTaskFilterObject(taskObj);
                        } else if (
                          contactsList.includes(contactFilters[item])
                        ) {
                          contObj[contactFilters[item]] = [val.target.value];
                          setContactFilterObject(contObj);
                        } else {
                          callObj[contactFilters[item]] = [val.target.value];
                          setCallFilterObject(callObj);
                        }
                      }}
                    >
                      <option>Select</option>
                      {filObj[item] ? (
                        filObj[item].map((val: any) => <option>{val}</option>)
                      ) : (
                        <option disabled={true}>No Options</option>
                      )}
                    </select>
                  )}
                </div>
                <div className={styles.line}></div>
              </>
            ))}
          {panel === 'Tasks' &&
            Object.keys(taskFilters).map((item) => (
              <>
                <div className={styles.tableContent}>
                  <p className={styles.item}>{item}</p>
                  {dateFieldList.includes(item) ? (
                    <>
                      <div className={styles.dateInputContainer}>
                        <p
                          className={styles.optionText}
                          style={{ marginTop: '5px', marginBottom: '4px' }}
                        >
                          From:
                        </p>
                        <input
                          type="date"
                          id="from"
                          name="from"
                          value={
                            dateArray.some((i) => i.item === item && i.from)
                              ? moment(
                                  dateArray.find((temp) => temp.item === item)
                                    ?.from
                                ).format('YYYY-MM-DD')
                              : ''
                          }
                          onChange={(e) => {
                            setDateFilter(e.target.valueAsDate, 'from', item);
                          }}
                        />
                        <p
                          className={styles.optionText}
                          style={{ marginTop: '12px', marginBottom: '4px' }}
                        >
                          To:
                        </p>
                        <input
                          type="date"
                          id="to"
                          name="to"
                          value={
                            dateArray.some((i) => i.item === item && i.to)
                              ? moment(
                                  dateArray.find((temp) => temp.item === item)
                                    ?.to
                                ).format('YYYY-MM-DD')
                              : ''
                          }
                          onChange={(e) => {
                            setDateFilter(e.target.valueAsDate, 'to', item);
                          }}
                        />
                      </div>
                    </>
                  ) : (
                    <select
                      onChange={(val) => {
                        let contObj = { ...contactFilterObject };
                        let taskObj: any = { ...taskFilterObject };
                        let callObj: any = { ...callFilterObject };
                        if (taskList.includes(taskFilters[item])) {
                          taskObj[taskFilters[item]] = [val.target.value];
                          setTaskFilterObject(taskObj);
                        } else if (contactsList.includes(taskFilters[item])) {
                          contObj[taskFilters[item]] = [val.target.value];
                          setContactFilterObject(contObj);
                        } else {
                          callObj[taskFilters[item]] = [val.target.value];
                          setCallFilterObject(callObj);
                        }
                      }}
                    >
                      <option>Select</option>
                      {filObj[item] ? (
                        filObj[item].map((val: any) => <option>{val}</option>)
                      ) : (
                        <option disabled={true}>No Options</option>
                      )}
                    </select>
                  )}
                </div>
                <div className={styles.line}></div>
              </>
            ))}
          {panel === 'Call Logs' &&
            Object.keys(callFilters).map((item) => (
              <>
                <div className={styles.tableContent}>
                  <p className={styles.item}>{item}</p>
                  {dateFieldList.includes(item) ? (
                    <>
                      <div className={styles.dateInputContainer}>
                        <p
                          className={styles.optionText}
                          style={{ marginTop: '5px', marginBottom: '4px' }}
                        >
                          From:
                        </p>
                        <input
                          type="date"
                          id="from"
                          name="from"
                          value={
                            dateArray.some((i) => i.item === item && i.from)
                              ? moment(
                                  dateArray.find((temp) => temp.item === item)
                                    ?.from
                                ).format('YYYY-MM-DD')
                              : ''
                          }
                          onChange={(e) => {
                            setDateFilter(e.target.valueAsDate, 'from', item);
                          }}
                        />
                        <p
                          className={styles.optionText}
                          style={{ marginTop: '12px', marginBottom: '4px' }}
                        >
                          To:
                        </p>
                        <input
                          type="date"
                          id="to"
                          name="to"
                          value={
                            dateArray.some((i) => i.item === item && i.to)
                              ? moment(
                                  dateArray.find((temp) => temp.item === item)
                                    ?.to
                                ).format('YYYY-MM-DD')
                              : ''
                          }
                          onChange={(e) => {
                            setDateFilter(e.target.valueAsDate, 'to', item);
                          }}
                        />
                      </div>
                    </>
                  ) : (
                    <select
                      onChange={(val) => {
                        let contObj = { ...contactFilterObject };
                        let taskObj: any = { ...taskFilterObject };
                        let callObj: any = { ...callFilterObject };
                        if (taskList.includes(callFilters[item])) {
                          taskObj[callFilters[item]] = [val.target.value];
                          setTaskFilterObject(taskObj);
                        } else if (contactsList.includes(callFilters[item])) {
                          contObj[callFilters[item]] = [val.target.value];
                          setContactFilterObject(contObj);
                        } else {
                          callObj[callFilters[item]] = [val.target.value];
                          setCallFilterObject(callObj);
                        }
                      }}
                    >
                      <option>Select</option>
                      {filObj[item] ? (
                        filObj[item].map((val: any) => <option>{val}</option>)
                      ) : (
                        <option disabled={true}>No Options</option>
                      )}
                    </select>
                  )}
                </div>
                <div className={styles.line}></div>
              </>
            ))}
        </div>
        <div className={styles.bottom}>
          <button className={styles.save} onClick={clearKanbanFilters}>
            Clear Filters
          </button>
          <button className={styles.save} onClick={onSave}>
            Save
          </button>
        </div>
      </Modal>
    </div>
  );
};
const mapStateToProps = (state: any) => {
  return {
    user: state.user.data,
  };
};
export default connect(mapStateToProps)(KanbanViewFilter);

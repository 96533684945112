export const emailValidate = (email: string) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const valid = re.test(String(email).toLowerCase());
  if (valid) {
    return "";
  } else {
    return "Invalid Email";
  }
};

export const passwordValidate = (password: string) => {
  if (password.length >= 8) {
    return "";
  } else {
    return "Minimum 8 char password required";
  }
};

export const phoneValidate = (phone: string) => {
  const re = /[0-9]{2}\d{8}/;
  const valid = re.test(phone);
  if (valid) {
    return "";
  } else {
    return "Invalid Phone No.";
  }
};

export const validateNumber = (request: {
  contact_no: string;
  country: string;
}) => {
  let contact = request.contact_no.replace(/ /g, "").replace(/-/g, "");
  if (request.country === "United Arab Emirates") {
    if (
      contact.startsWith("+") &&
      (contact.length === 13 || contact.length === 12)
    ) {
      return { country_code: "+971", contact_no: contact.slice(3) };
    } else if (
      contact.startsWith("971") &&
      (contact.length === 12 || contact.length === 11)
    ) {
      return { country_code: "+971", contact_no: contact.slice(2) };
    } else if (contact.length === 10 || contact.length === 9) {
      return { country_code: "+971", contact_no: contact };
    } else {
      return { error: "Invalid Contact" };
    }
  } else {
    if (contact.startsWith("+") && contact.length === 13) {
      return { country_code: "+91", contact_no: contact.slice(3) };
    } else if (contact.startsWith("91") && contact.length === 12) {
      return { country_code: "+91", contact_no: contact.slice(2) };
    } else if (contact.length === 10) {
      return { country_code: "+91", contact_no: contact };
    } else {
      return { error: "Invalid Contact" };
    }
  }
};

import React, { FunctionComponent } from "react";

import commonStyles from "../../CommonStyles/CommonGraphStyles.module.css";

import IntBarTable from "../IntBarTable/IntBarTable";

import DoughnutChartOrg from "../DoughnutChartOrg/DoughnutChartOrg";

type props = {
  analyticsData: any;
  usersList: any;
  checked: boolean;
  teamsData: any;
  history: any;
  filter: "MTD" | "YTD" | "PM" | "All" | "CUSTOM";
  source: boolean;
  taskFilter: any;
  leadFilter: any;
};

const LostReason: FunctionComponent<props> = ({
  analyticsData,
  usersList,
  checked,
  history,
  teamsData,
  filter,
  source,
  taskFilter,
  leadFilter,
}) => {
  return (
    <>
      <div id="lostReason" className={commonStyles.graphContainer}>
        <DoughnutChartOrg
          analyticsData={analyticsData}
          type={"lost_reason"}
          color={["#FFC6C6", "#2FA2D3"]}
          style={{ width: "100%", height: "350px" }}
          history={history}
          filter={filter}
        />
      </div>

      <div id="lostReason" className={commonStyles.graphContainer}>
        <IntBarTable
          data={analyticsData}
          type={"lost_reason"}
          heading={"Lost Reason Summary"}
          usersList={usersList}
          checked={checked}
          teamsData={teamsData}
          style={{ width: "100%" }}
          filter={filter}
          history={history}
          source={source}
          taskFilter={taskFilter}
          leadFilter={leadFilter}
        />
      </div>
    </>
  );
};

export default LostReason;

import Modal from "react-modal";
import React, { FunctionComponent, useEffect, useState } from "react";
import styles from "./ImportModal.module.css";

import { IoIosClose } from "react-icons/io";

import { useDispatch } from "react-redux";
import Loading from "../../Loading/Loading";
import { BiImages } from "react-icons/bi";
import { uploadCarouselImages } from "../../../Services/resources";

type props = {
  open: boolean;
  close: () => void;
  organization_id: string;
  history: any;
  usersList?: any[];
};

const ImportModal: FunctionComponent<props> = ({
  open,
  close,
  organization_id,
  history,
  usersList,
}) => {
  const [files, setFiles] = useState<FileList>();
  const [load, setLoad] = useState(false);
  const [images, setImages] = useState<any[]>([]);
  const [selectedImages, setSelectedImages] = useState<number[]>([]);

  // const [colSelect, setColSelect] = useState<any[]>([]);

  const readImage = (file: any) => {
    return new Promise((resolve, reject) => {
      const fr = new FileReader();
      fr.onload = (e) => {
        resolve(e.target?.result);
      };
      fr.readAsDataURL(file);
    });
  };

  const upload = async (data: FileList) => {
    const imageList: any[] = [];
    setFiles(data);
    for await (const result of Array.from(data).map(async (item) => {
      const image = await readImage(item);
      imageList.push(image);
      return true;
    })) {
      if (result) {
        setSelectedImages(Array.from(Array(imageList.length).keys()));
        setImages([...imageList]);
      }
    }
  };

  const dispatcher = useDispatch();
  const onSubmit = () => {
    setLoad(true);
    let data: any[] = [];
    selectedImages.forEach((item: any, index: number) => {
      files && data.push(files[item]);
    });
    uploadCarouselImages(
      data,
      organization_id,
      dispatcher,
      (data) => setLoad(data),
      close
    );
  };
  useEffect(() => {
    console.log(images);
  }, [images]);
  return (
    <Modal
      style={{ overlay: { zIndex: 100 } }}
      className={styles.parent}
      isOpen={open}
      onRequestClose={close}
      shouldCloseOnOverlayClick={true}
      shouldCloseOnEsc={true}
    >
      {load === true && <Loading />}
      {images.length !== 0 ? (
        <>
          <p className={styles.contactForm}>Selected Images</p>
          <div className={styles.imageContainer}>
            {images.map((item: any, index: number) => (
              <div className={styles.imageBox}>
                <input
                  type="checkbox"
                  checked={selectedImages.includes(index)}
                  onChange={(e) => {
                    if (!e.target.checked) {
                      let data = [...selectedImages];
                      let item = selectedImages.indexOf(index);
                      if (item > -1) {
                        data.splice(item, 1);
                      }
                      setSelectedImages(data);
                    } else {
                      let data = [...selectedImages];
                      data.push(index);
                      setSelectedImages(data);
                    }
                  }}
                />
                <img
                  src={item}
                  width={"50%"}
                  alt={"uploaded"}
                  height={"100px"}
                  style={{ backgroundSize: "contain" }}
                />
              </div>
            ))}
          </div>
          <div className={styles.buttonBox}>
            <button className={styles.cancel} onClick={close}>
              Cancel
            </button>
            <button className={styles.confirm} onClick={() => onSubmit()}>
              Confirm
            </button>
          </div>
        </>
      ) : (
        <div className={styles.child}>
          <div className={styles.cross} onClick={close}>
            <IoIosClose size={35} color={"#808080"} />
          </div>
          <p className={styles.contactForm}>Import Data for "Image Form"</p>
          <div
            className={styles.dragBox}
            onDragOver={(e) => {
              e.preventDefault();
            }}
            onDrop={(e) => {
              e.preventDefault();
              //@ts-ignore
              upload(e.dataTransfer.files);
            }}
          >
            <BiImages size={100} color={"#808080"} />
            <p>Drag Your Images Here</p>
          </div>
          <div className={styles.selectBox}>
            <label htmlFor="file-input" className={styles.uploadButton}>
              Upload Image
            </label>
            <input
              id="file-input"
              className={styles.select}
              type="file"
              multiple={true}
              accept=".png,.jpg,.jpeg"
              onChange={(e: any) => upload(e.target.files)}
            />
          </div>
        </div>
      )}
    </Modal>
  );
};

export default ImportModal;

import React, { FunctionComponent, useEffect } from "react";
import Navbar from "../Components/Navbar/Navbar";
import Analytics from "../Screens/Analytics/Analytics";
import Reports from "../Screens/Reports/Reports";
import { Route } from "react-router-dom";
import OrganizationPanel from "../Screens/OrganizationPanel/OrganizationPanel";
import AddUsers from "../Screens/AddUsers/AddUsers";
import { connect, useDispatch } from "react-redux";
import { fetchOrganizationsUsers } from "../Services/users";
import ApiPanel from "../Screens/ApiPanel/ApiPanel";
import NewsPanel from "../Screens/NewsPanel/NewsPanel";
import CustomButtonPanel from "../Screens/CustomButtonPanel/CustomButtonPanel";

type props = {
  organizationId: string;
  history: any;
};

const OrganizationRoute: FunctionComponent<props> = ({
  organizationId,
  history,
}) => {
  const dispatcher = useDispatch();
  useEffect(() => {
    if (organizationId) {
      const unSub = fetchOrganizationsUsers(
        dispatcher,
        organizationId
      );
      return unSub;
    }
    // eslint-disable-next-line
  }, [organizationId]);

  return (
    <>
      <Navbar
        props={{ history }}
        title={"Users"}
        path={"/"}
        leadManger={false}
        api={"/apis"}
        news={"/news"}
        customButton={"/customButton"}
      />
      <Route exact path="/" component={OrganizationPanel} />
      <Route path="/analytics" component={Analytics} />
      <Route path="/reports" component={Reports} />
      <Route path="/apis" component={ApiPanel} />
      <Route path="/addUsers" component={AddUsers} />
      <Route path="/news" component={NewsPanel} />
      <Route
        path="/customButton"
        component={CustomButtonPanel}
      />
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    organizationId: state.organization.id,
  };
};

export default connect(mapStateToProps)(OrganizationRoute);

import React, {
  FunctionComponent,
  useEffect,
  useState,
} from "react";
import styles from "./Navbar.module.css";
import Logo from "../../Assets/Images/head.svg";
import classNames from "classnames";
import { AiOutlineArrowRight } from "react-icons/ai";
import { FaUserCircle } from "react-icons/fa";
import UserDetails from "../UserDetails/UserDetails";
import { connect } from "react-redux";
import ProfileImageModal from "../Modals/ProfileImageModal/ProfileImageModal";

type props = {
  props?: any;
  title:
    | "Organizations"
    | "Contacts"
    | "Users"
    | "Drilldown";
  path: string;
  leadManger: Boolean;
  project?: string;
  task?: string;
  resources?: string;
  callLogs?: string;
  faq?: string;
  user: any;
  api?: string;
  news?: string;
  apiData?: string;
  leadDistributor?: string;
  customButton?: string;
};

const Navbar: FunctionComponent<props> = ({
  props,
  title,
  path,
  leadManger,
  project,
  task,
  resources,
  callLogs,
  faq,
  user,
  api,
  news,
  apiData,
  leadDistributor,
  customButton,
}) => {
  const [showUserDetail, setShowUserDetails] =
    useState(false);
  const [expand, setExpand] = useState(false);
  const [profileModal, setProfileModal] = useState(false);
  useEffect(() => {
    let timeOut: any;
    if (showUserDetail === true) {
      setExpand(true);
      document.body.style.overflowX = "hidden";
      timeOut = setTimeout(() => {
        document.body.style.overflowX = "auto";
      }, 300);
    }
    return () => clearTimeout(timeOut);
  }, [showUserDetail]);
  useEffect(() => {
    let timeOut: any;
    if (expand === false) {
      document.body.style.overflowX = "hidden";
      timeOut = setTimeout(() => {
        setShowUserDetails(false);
        document.body.style.overflowX = "auto";
      }, 300);
    }
    return () => clearTimeout(timeOut);
  }, [expand]);

  const toggleUserDetails = () => {
    if (showUserDetail === false) {
      setShowUserDetails(true);
    } else {
      setExpand(false);
    }
  };
  return (
    <div className={styles.navbar}>
      <img
        className={styles.logo}
        src={Logo}
        alt={"logo"}
      />
      {title !== "Drilldown" && (
        <button
          className={classNames(styles.button, {
            [styles.active]:
              "/analytics" ===
              props.history.location.pathname,
          })}
          onClick={() => {
            props.history.push("/analytics");
          }}
        >
          Analytics
        </button>
      )}

      <button
        className={classNames(styles.button, {
          [styles.active]:
            path === props.history.location.pathname,
        })}
        onClick={() => {
          props.history.push(path);
        }}
      >
        {title}
      </button>
      {title !== "Drilldown" &&
        (user.profile === "Lead Manager" ||
          user.profile === "Sales" ||
          user.profile === "Team Lead") && (
          <button
            className={classNames(styles.button, {
              [styles.active]:
                task === props.history.location.pathname,
            })}
            onClick={() => {
              props.history.push(task);
            }}
          >
            Tasks
          </button>
        )}

      {title !== "Drilldown" && leadManger === true && (
        <>
          <button
            className={classNames(styles.button, {
              [styles.active]:
                project === props.history.location.pathname,
            })}
            onClick={() => {
              props.history.push(project);
            }}
          >
            Projects
          </button>

          <button
            className={classNames(styles.button, {
              [styles.active]:
                resources ===
                props.history.location.pathname,
            })}
            onClick={() => {
              props.history.push(resources);
            }}
          >
            Resources
          </button>

          <button
            className={classNames(styles.button, {
              [styles.active]:
                callLogs ===
                props.history.location.pathname,
            })}
            onClick={() => {
              props.history.push(callLogs);
            }}
          >
            Call Logs
          </button>

          <button
            className={classNames(styles.button, {
              [styles.active]:
                faq === props.history.location.pathname,
            })}
            onClick={() => {
              props.history.push(faq);
            }}
          >
            FAQ
          </button>

          <button
            className={classNames(styles.button, {
              [styles.active]:
                apiData === props.history.location.pathname,
            })}
            onClick={() => {
              props.history.push(apiData);
            }}
          >
            API
          </button>
          <button
            className={classNames(styles.button, {
              [styles.active]:
                leadDistributor ===
                props.history.location.pathname,
            })}
            onClick={() => {
              props.history.push(leadDistributor);
            }}
          >
            Lead Distribution
          </button>
        </>
      )}

      {/* {title !== "Drilldown" && (
        <button
          className={classNames(styles.button, {
            [styles.active]: "/reports" === props.history.location.pathname,
          })}
          onClick={() => {
            props.history.push("/reports");
          }}
        >
          Reports
        </button>
      )} */}

      {title === "Contacts" && leadManger === false && (
        <button
          className={classNames(styles.button, {
            [styles.active]:
              callLogs === props.history.location.pathname,
          })}
          onClick={() => {
            props.history.push(callLogs);
          }}
        >
          Call Logs
        </button>
      )}
      {api && (
        <button
          className={classNames(styles.button, {
            [styles.active]:
              api === props.history.location.pathname,
          })}
          onClick={() => {
            props.history.push(api);
          }}
        >
          API
        </button>
      )}

      {news && (
        <button
          className={classNames(styles.button, {
            [styles.active]:
              news === props.history.location.pathname,
          })}
          onClick={() => {
            props.history.push(news);
          }}
        >
          NEWS
        </button>
      )}

      {customButton && (
        <button
          className={classNames(styles.button, {
            [styles.active]:
              customButton ===
              props.history.location.pathname,
          })}
          onClick={() => {
            props.history.push(customButton);
          }}
        >
          CUSTOM BUTTON
        </button>
      )}

      {title !== "Drilldown" && (
        <>
          <p className={styles.text}>
            Knowledge Center
            <AiOutlineArrowRight
              size={18}
              style={{ marginLeft: "5px" }}
            />
          </p>
          <div className={styles.line} />
          <div className={styles.logOutView}>
            {user.user_image && user.user_image !== "" ? (
              <img
                alt="userImage"
                src={user.user_image}
                className={styles.image}
                onClick={toggleUserDetails}
              />
            ) : (
              <FaUserCircle
                color="#C0C0C0"
                size={25}
                onClick={toggleUserDetails}
              />
            )}
            {showUserDetail && (
              <div
                className={
                  expand
                    ? styles.userDetailExpand
                    : styles.userDetailClosed
                }
              >
                <UserDetails
                  showUserDetail={showUserDetail}
                  close={toggleUserDetails}
                  openModal={(data) =>
                    setProfileModal(data)
                  }
                />
              </div>
            )}
          </div>{" "}
        </>
      )}

      {profileModal && (
        <ProfileImageModal
          open={profileModal}
          close={() => setProfileModal(false)}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    user: state.user.data,
  };
};

export default connect(mapStateToProps)(Navbar);

import React, { FunctionComponent, useRef } from "react";
import { useState } from "react";
import Loading from "../../Components/Loading/Loading";
import styles from "../AddContacts/AddContacts.module.css";
import { AiOutlineClose } from "react-icons/ai";
import { connect, useDispatch } from "react-redux";
import { showSnackbarAction } from "../../Redux/actions";
import { createFAQ } from "../../Services/resources";

type props = {
  history: any;
  user: any;
};
const AddFAQ: FunctionComponent<props> = ({ history, user }) => {
  const [load, setLoad] = useState(false);
  const [question, setQuestion] = useState("");
  const [answer, setAnswer] = useState("");
  const dispatcher = useDispatch();
  const questionRef: any = useRef();
  const answerRef: any = useRef();

  const handleCheck = () => {
    if (questionRef.current.value === "") {
      dispatcher(showSnackbarAction("Please Enter Question!!", "error"));
      return false;
    } else if (answerRef.current.value === "") {
      dispatcher(showSnackbarAction("Please Enter Answer!!", "error"));
      return false;
    } else {
      return true;
    }
  };

  const create = () => {
    const val = handleCheck();

    if (val) {
      setLoad(true);
      createFAQ(
        user.organization_id,
        questionRef.current.value,
        answerRef.current.value,
        dispatcher,

        (data: boolean) => setLoad(data),
        history
      );
    }
  };

  return (
    <div className={styles.parent}>
      {load === true && <Loading />}
      <div className={styles.child}>
        <div className={styles.headerView}>
          <p className={styles.heading}> Add FAQ</p>
          <AiOutlineClose
            className={styles.closeIcon}
            size={25}
            onClick={() => history.replace("/faq")}
          />
        </div>
        <div className={styles.title}>
          <p className={styles.one}>Question</p>
          <p className={styles.two}>*</p>
        </div>
        <div className={styles.box}>
          <textarea
            style={{ width: "100%", resize: "none", marginTop: "17px" }}
            rows={4}
            cols={10}
            placeholder={"Enter Question"}
            ref={questionRef}
            onChange={(e) => setQuestion(e.target.value)}
            value={question}
          ></textarea>
        </div>

        <div style={{ marginTop: "22px" }} className={styles.title}>
          <p className={styles.one}>Answer</p>
          <p className={styles.two}>*</p>
        </div>
        <div className={styles.box}>
          <textarea
            style={{ width: "100%", resize: "none", marginTop: "17px" }}
            rows={6}
            cols={10}
            placeholder={"Enter Answer"}
            ref={answerRef}
            onChange={(e) => setAnswer(e.target.value)}
            value={answer}
          ></textarea>
        </div>

        <button className={styles.button} onClick={() => create()}>
          Submit
        </button>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    user: state.user.data,
  };
};

export default connect(mapStateToProps)(AddFAQ);

import React, { FunctionComponent } from "react";
import styles from "../../Screens/Analytics/Analytics.module.css";
import commonStyles from "../../CommonStyles/CommonGraphStyles.module.css";
import IntBarChartOrg from "../IntBarChartOrg/IntBarChartOrg";
import IntBarTable from "../IntBarTable/IntBarTable";
import { Pie } from "react-chartjs-2";
import TaskBarChartOrg from "../TaskBarChartOrg/TaskBarChartOrg";
import TaskBarTable from "../TaskBarChartOrg/TaskBarTable";

type props = {
  analyticsData: any;
  usersList: any;
  checked: boolean;
  teamsData: any;
  history: any;
  filter: "MTD" | "YTD" | "PM" | "All" | "CUSTOM";
  source: boolean;
  passOnFilters: any;
  tasksFilters: any;
};

const OverdueTask: FunctionComponent<props> = ({
  analyticsData,
  usersList,
  checked,
  history,
  teamsData,
  filter,
  source,
  passOnFilters,
  tasksFilters,
}) => {
  return (
    <>
      <div id="overdueTasks" className={commonStyles.graphContainer}>
        <TaskBarChartOrg
          analyticsData={analyticsData}
          type={"Overdue"}
          color={["#279F9F"]}
          style={{ width: "45%" }}
          history={history}
          filter={filter}
        />
        <div className={commonStyles.line}></div>
        <TaskBarTable
          data={analyticsData}
          heading={"Overdue Task Summary"}
          type={"Overdue"}
          usersList={usersList}
          checked={checked}
          teamsData={teamsData}
          style={{ width: "55%" }}
          filter={filter}
          history={history}
          source={source}
          taskFilter={tasksFilters}
          leadFilter={passOnFilters}
        />
      </div>
    </>
  );
};

export default OverdueTask;

import React, { FunctionComponent } from "react";
import styles from "./ContactButton.module.css";

type props = {
  color: string;
  title: string;
  clicked: () => void;
};

const ContactButton: FunctionComponent<props> = ({ color, title, clicked }) => {
  return (
    <div>
      <button
        className={styles.button}
        style={{ color: color, borderColor: color }}
        onClick={clicked}
      >
        {title}
      </button>
    </div>
  );
};

export default ContactButton;

import React, { FunctionComponent } from "react";
import styles from "../../Screens/Analytics/Analytics.module.css";
import {
  AiFillPieChart,
  AiOutlineBarChart,
  AiOutlineDotChart,
} from "react-icons/ai";
import { FiBarChart2, FiBarChart } from "react-icons/fi";
import { BiBarChart } from "react-icons/bi";
import { BsGraphUp } from "react-icons/bs";

type props = {
  panel?: string;
  setClearFilter?: (data: boolean) => void;
};

const AnalyticsSideNav: FunctionComponent<props> = ({
  panel,
  setClearFilter,
}) => {
  return (
    <>
      {panel ? (
        <p className={styles.analyticsText}>{`${panel} Analytics`}</p>
      ) : (
        <p className={styles.analyticsText}>Analytics</p>
      )}

      {panel && panel === "Contacts" && (
        <>
          <div className={styles.navigationContainer}>
            <AiFillPieChart size={23} color={"#fff"} />
            <a href="#feedback">
              <p className={styles.navigateText}>Feedback Chart</p>
            </a>
          </div>
          <div className={styles.navigationContainer}>
            <FiBarChart2 size={23} color={"#fff"} />
            <a href="#interestedBudget">
              <p className={styles.navigateText}>Interested Budget</p>
            </a>
          </div>
          <div className={styles.navigationContainer}>
            <FiBarChart size={23} color={"#fff"} />
            <a href="#interestedProject">
              <p className={styles.navigateText}>Interested Project</p>
            </a>
          </div>
          <div className={styles.navigationContainer}>
            <BiBarChart size={23} color={"#fff"} />
            <a href="#interestedLocation">
              <p className={styles.navigateText}> Interested Location </p>
            </a>
          </div>

          <div className={styles.navigationContainer}>
            <AiFillPieChart size={23} color={"#fff"} />
            <a href="#propertyType">
              <p className={styles.navigateText}>Interested Property Type</p>
            </a>
          </div>

          <div className={styles.navigationContainer}>
            <AiFillPieChart size={23} color={"#fff"} />
            <a href="#propertyStage">
              <p className={styles.navigateText}>Interested Property Stage</p>
            </a>
          </div>

          <div className={styles.navigationContainer}>
            <AiOutlineBarChart size={23} color={"#fff"} />
            <a href="#notIntReason">
              <p className={styles.navigateText}>Not Interested Reason</p>
            </a>
          </div>

          <div className={styles.navigationContainer}>
            <AiOutlineBarChart size={23} color={"#fff"} />
            <a href="#lostReason">
              <p className={styles.navigateText}>Lost Reason</p>
            </a>
          </div>
        </>
      )}

      {panel && panel === "Call Logs" && (
        <div className={styles.navigationContainer}>
          <BsGraphUp size={20} color={"#fff"} />
          <a href="#trends">
            <p className={styles.navigateText}>Trends</p>
          </a>
        </div>
      )}

      {panel && panel === "Tasks" && (
        <>
          <div className={styles.navigationContainer}>
            <AiOutlineBarChart size={23} color={"#fff"} />
            <a href="#completedTasks">
              <p className={styles.navigateText}>Completed Tasks</p>
            </a>
          </div>
          <div className={styles.navigationContainer}>
            <AiOutlineDotChart size={23} color={"#fff"} />
            <a href="#overdueTasks">
              <p className={styles.navigateText}>Overdue Tasks</p>
            </a>
          </div>
          <div className={styles.navigationContainer}>
            <AiOutlineBarChart size={23} color={"#fff"} />
            <a href="#scheduledTasks">
              <p className={styles.navigateText}>Pending Tasks</p>
            </a>
          </div>
        </>
      )}
      <div className={styles.bottomDiv}>
        <button
          className={styles.clear}
          onClick={() => setClearFilter && setClearFilter(true)}
        >
          Clear Filter
        </button>
      </div>
    </>
  );
};

export default AnalyticsSideNav;

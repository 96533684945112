import React, { FunctionComponent, useState, useEffect } from "react";
import styles from "../../CommonStyles/CommonGraphStyles.module.css";
import { Doughnut } from "react-chartjs-2";
import { connect, useDispatch } from "react-redux";
import { getDrillDownChartOrg } from "../../Services/analytics";
import { setClearFilter } from "../../Redux/actions";
import { changeDateForRoute } from "../../Services/contacts";

type props = {
  analyticsData: any;
  type: "not_int_reason" | "lost_reason";
  color: string[];
  style?: any;
  role: any;
  history: any;
  filter: "MTD" | "YTD" | "PM" | "All" | "CUSTOM";
  contacts: any;
  user: any;
  organizationUsers: any[];
  teamLeadUsers: any;
  branchUsers: any;
};

const DoughnutChartOrg: FunctionComponent<props> = ({
  analyticsData,
  type,
  color,
  style,
  role,
  history,
  filter,
  contacts,
  user,
  organizationUsers,
  teamLeadUsers,
  branchUsers,
}) => {
  const [data, setData] = useState<any>({});
  const [allContacts, setAllContacts] = useState<any[] | undefined>(undefined);

  useEffect(() => {
    let contact: any[] = [];
    Object.keys(contacts.data).forEach((key) => {
      contact = [...contact, ...contacts.data[key]];
    });
    setAllContacts(contact);
  }, [contacts.data]);

  useEffect(() => {
    const temp: { [key: string]: number } = {};
    Object.values(analyticsData).forEach((analytics: any) => {
      Object.keys(analytics.leadAnalytics[type]).forEach((key) => {
        if (temp[key]) {
          temp[key] += analytics.leadAnalytics[type][key];
        } else {
          temp[key] = analytics.leadAnalytics[type][key];
        }
      });
    });
    setData(temp);
  }, [analyticsData]);

  const chartData = {
    labels: Object.keys(data),
    datasets: [
      {
        label: `Interested ${type}s`,
        backgroundColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
          "#00008b",
          "#2FA2D3",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
          "#00008b",
          "#2FA2D3",
        ],
        barThickness: 40,
        borderWidth: 2,
        data: Object.values(data),
      },
    ],
  };
  const dispatcher = useDispatch();

  return (
    <div className={styles.graphBox} style={style}>
      <Doughnut
        data={chartData}
        options={{
          title: {
            display: false,
            fontSize: 20,
          },
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            datalabels: {
              formatter: (value: any, ctx: any) => {
                let sum = 0;
                let dataArr = ctx.chart.data.datasets[0].data;
                dataArr.map((data: any) => {
                  sum += data;
                });
                let percentage = Math.floor((value * 100) / sum) + "%";
                return percentage;
              },
              color: "#fff",
            },
          },
          onClick: async (e: any, item: any) => {
            var key: any;
            for (var i = 0; i < localStorage.length; i++) {
              key = localStorage.key(i);

              if (key !== "columns") {
                localStorage.removeItem(key);
              }
            }
            dispatcher(setClearFilter(true));
            if (allContacts) {
              let mapReportingTo: { [key: string]: string } = {};
              if (organizationUsers) {
                organizationUsers.forEach((item: any) => {
                  if (mapReportingTo[item.uid] === undefined) {
                    mapReportingTo[item.uid] = item.reporting_to;
                  }
                });
              }
              if (user.profile === "Team Lead") {
                let drillDownData = await getDrillDownChartOrg(
                  allContacts.filter((item) =>
                    teamLeadUsers.includes(item.uid)
                  ),
                  filter,
                  type,
                  Object.keys(data)[item[0].index],
                  user.organization_id,
                  teamLeadUsers
                );

                drillDownData.forEach((item: any) => {
                  if (item.uid === "") {
                    item["reporting_to"] = "";
                  } else {
                    item["reporting_to"] = mapReportingTo[item.uid]
                      ? mapReportingTo[item.uid]
                      : "";
                  }
                });
                localStorage.setItem(
                  "drilldownData",
                  changeDateForRoute(drillDownData)
                );
                window.open("/drilldownData", "_blank");
              } else {
                let drillDownData = await getDrillDownChartOrg(
                  allContacts,
                  filter,
                  type,
                  Object.keys(data)[item[0].index],
                  user.organization_id,
                  [],
                  user.branchPermission ? branchUsers : []
                );

                drillDownData.forEach((item: any) => {
                  if (item.uid === "") {
                    item["reporting_to"] = "";
                  } else {
                    item["reporting_to"] = mapReportingTo[item.uid]
                      ? mapReportingTo[item.uid]
                      : "";
                  }
                });
                localStorage.setItem(
                  "drilldownData",
                  changeDateForRoute(drillDownData)
                );
                window.open("/drilldownData", "_blank");
              }
            }
          },
        }}
        type
      />
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    role: state.user.role,
    contacts: state.contacts,
    user: state.user.data,
    organizationUsers: state.organizationUsers.data,
    teamLeadUsers: state.teamLeadUsers.data,
    branchUsers: state.branchUsers.data,
  };
};
export default connect(mapStateToProps)(DoughnutChartOrg);

import React, { FunctionComponent, useState, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { setClearFilter, updateStage } from '../../Redux/actions';
import styles from '../../Screens/Analytics/Analytics.module.css';
import {
  getDrillDownChartOrg,
  getPendingTaskDrillDown,
  getTaskDrillDownChartOrg,
  kpiLeadsDrilldownData,
  kpiTaskDrilldownData,
} from '../../Services/analytics';
import { changeDateForRoute } from '../../Services/contacts';

type props = {
  count: string | number;
  text: string;
  style: any;
  role: any;
  filterText?: string;
  history?: any;
  filter: 'MTD' | 'YTD' | 'PM' | 'All' | 'CUSTOM' | 'T' | 'Y';
  contacts: any;
  user: any;
  organizationUsers: any[];
  teamLeadUsers: any[];
  branchUsers: any;
  taskFilter?: any;
  leadFilter?: any;
};

const CountBox: FunctionComponent<props> = ({
  count,
  text,
  style,
  role,
  filterText,
  history,
  filter,
  contacts,
  user,
  organizationUsers,
  teamLeadUsers,
  branchUsers,
  taskFilter,
  leadFilter,
}) => {
  const dispatcher = useDispatch();
  const [allContacts, setAllContacts] = useState<any[] | undefined>(undefined);

  useEffect(() => {
    let contact: any[] = [];
    Object.keys(contacts.data).forEach((key) => {
      contact = [...contact, ...contacts.data[key]];
    });
    setAllContacts(contact);
  }, [contacts.data]);

  const setFilter = async () => {
    let mapReportingTo: { [key: string]: string } = {};
    if (organizationUsers) {
      organizationUsers.forEach((item: any) => {
        if (mapReportingTo[item.uid] === undefined) {
          mapReportingTo[item.uid] = item.reporting_to;
        }
      });
    }
    if (filterText === 'Pending' || filterText === 'Completed') {
      const drillDownData = await kpiTaskDrilldownData(
        filter,
        user.uid,
        filterText,
        taskFilter,
        leadFilter,
        true
      );
      localStorage.setItem('taskDrilldownData', JSON.stringify(drillDownData));
      window.open('/taskDrilldownData', '_blank');
    } else {
      const drillDownData = await kpiLeadsDrilldownData(
        user.uid,
        filter,
        filterText,
        taskFilter,
        leadFilter,
        true
      );
      console.log('loacalStorage:', drillDownData);
      localStorage.setItem('drilldownData', JSON.stringify(drillDownData));
      window.open('/drilldownData', '_blank');
    }
  };
  return (
    <div className={styles.countBox} onClick={setFilter}>
      <p className={styles.leadCount}>{count}</p>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <p className={styles.leadText} style={style}>
          {text}
        </p>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    role: state.user.role,
    contacts: state.contacts,
    user: state.user.data,
    organizationUsers: state.organizationUsers.data,
    teamLeadUsers: state.teamLeadUsers.data,
    branchUsers: state.branchUsers.data,
  };
};

export default connect(mapStateToProps)(CountBox);

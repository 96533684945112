import React, { FunctionComponent, useEffect } from "react";
import { useState } from "react";
import styles from "./AnalyticsFilter.module.css";

type props = {
  title: string;
  setFilterState: (data: any) => void;
  filterType: any;
  filterUsed: string;
  showFilter: (data: boolean) => void;
};

const AnalyticsFilterValue: FunctionComponent<props> = ({
  title,
  setFilterState,
  filterType,
  filterUsed,
  showFilter,
}) => {
  return (
    <>
      <div className={styles.box}>
        <p className={styles.filterText}>{title}</p>
        <input
          type="radio"
          onChange={(val) => {
            if (val.target.checked) {
              setFilterState(filterType);
              showFilter(false);
            }
          }}
          value={filterType}
          checked={filterUsed === filterType ? true : false}
        />
      </div>
      <div className={styles.line}></div>
    </>
  );
};

export default AnalyticsFilterValue;
